import { requestApp } from '@/lib/api';
import { useQuery } from '@tanstack/react-query';
type TBaseMetrics = {
  success: true;
  traders: number;
  totalPositionSize: number;
  uniqueTradesCount: number;
};
const useLeaderBoardMetrics = () => {
  const {
    data: baseMetrics,
    isLoading
  } = useQuery({
    queryKey: ['leaderboard-metrics-base'],
    queryFn: async () => {
      const response = await requestApp<TBaseMetrics>({
        endpoint: '/cached/history/base-stats',
        version: 'v2'
      });
      return response;
    }
  });
  return {
    totalTraders: baseMetrics?.traders,
    totalPositionSize: baseMetrics?.totalPositionSize,
    uniqueTradesCount: baseMetrics?.uniqueTradesCount,
    isLoading
  };
};
export default useLeaderBoardMetrics;