import React from 'react';
import AnimatedCard from './AnimatedCard';
import Image from 'next/image';
import { SlCursor } from 'react-icons/sl';
import { useMediaQuery } from 'usehooks-ts';
const LossProtectionCard = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return <AnimatedCard data-sentry-element="AnimatedCard" data-sentry-component="LossProtectionCard" data-sentry-source-file="LossProtectionCard.tsx">
      <div className="relative h-full">
        <div className="px-4 md:px-[60px] py-6 md:py-12 space-y-4">
          <p className="text-[#DDABF8] text-sm font-medium leading-none">TRADER</p>
          <p className="text-[32px] font-medium leading-none">Loss Protection</p>
          <p className="text-grey-100 leading-[24px]">
            Trade with confidence: Get up to 20% rebate on your losses when going against popular
            sentiment. Use this rebate to trade directionally or create unique arbitrage strategies.
          </p>
        </div>
        <Image className="absolute bottom-16 left-0 right-0" src="/images/landing/features/card-lines-bg.svg" alt="feature-1" width={652} height={282} data-sentry-element="Image" data-sentry-source-file="LossProtectionCard.tsx" data-sentry-element="Image" />
        <Image className="hidden md:block absolute top-40 left-[20%]" src="/images/landing/features/card-center-blur.svg" alt="feature-1" width={400} height={255} data-sentry-element="Image" data-sentry-source-file="LossProtectionCard.tsx" data-sentry-element="Image" />
        <SlCursor className="absolute -rotate-90 top-[400px] left-[35%] z-20 group-hover:translate-x-[140px] md:group-hover:translate-x-[200px] transition-transform duration-700" data-sentry-element="SlCursor" data-sentry-source-file="LossProtectionCard.tsx" data-sentry-element="SlCursor" />
        <Image className="absolute bottom-8 left-[50%] translate-x-[-50%] z-10 group-hover:opacity-0 transition-opacity duration-700" src="/images/landing/features/lossProtection/disabled.svg" alt="feature-1" width={isMobile ? 300 : 485} height={isMobile ? 190 : 290} data-sentry-element="Image" data-sentry-source-file="LossProtectionCard.tsx" data-sentry-element="Image" />
        <Image className="absolute bottom-8 left-[50%] translate-x-[-50%] z-10 group-hover:opacity-100 opacity-0 transition-opacity duration-700" src="/images/landing/features/lossProtection/enabled.svg" alt="feature-1" width={isMobile ? 300 : 485} height={isMobile ? 190 : 290} data-sentry-element="Image" data-sentry-source-file="LossProtectionCard.tsx" data-sentry-element="Image" />
      </div>
    </AnimatedCard>;
};
export default LossProtectionCard;