import { create } from 'zustand';
import { GroupInfoType, PairInfoType } from '@/services/trade/types';
import merge from 'lodash.merge';

export type PurePriceType = {
  price: number;
  timestamp: number;
};

type State = {
  pairCurrentPricePure: { [x: number]: PurePriceType };
  pairCurrentPricePureDelta: string[];
  pairMarketExecutionPrice: number;

  groupInfo: { [key: string]: GroupInfoType };
  pairInfos: { [key: string]: PairInfoType };
  pairCount: number;
  maxTradesPerPair: number;
  totalOi: number;
  maxOpenInterest: number;
};

const initialState = {
  pairCurrentPricePure: {},
  pairCurrentPricePureDelta: [],
  pairMarketExecutionPrice: 0,

  groupInfo: {},
  pairInfos: {},
  pairCount: 0,
  maxTradesPerPair: 0,
  totalOi: 0,
  maxOpenInterest: 0,
};

type Action = {
  updatePairCurrentPricePure: (index: number, priceData: PurePriceType) => void;
  updatePairCurrentPricePureInBatch: (data: Record<number, PurePriceType>) => void;
  setInitialData: (data: Partial<State>) => void;
  updateGroupInfos: (groupInfos: { [key: string]: Partial<GroupInfoType> }) => void;
  updatePairInfos: (pairInfos: { [key: string]: Partial<PairInfoType> }) => void;
  updatePairStore: (key: keyof State, value: any) => void;
  resetStore: () => void;
};

export const usePairStore = create<State & Action>((set) => ({
  ...initialState,
  updatePairCurrentPricePure: (index, priceData) =>
    set((state) => {
      const pairCurrentPricePure = { ...state.pairCurrentPricePure, [index]: priceData }; // copy the array
      return { pairCurrentPricePure };
    }),
  updatePairCurrentPricePureInBatch: (data) =>
    set((state) => {
      const pairCurrentPricePure = { ...state.pairCurrentPricePure, ...data }; // copy the array
      return { pairCurrentPricePure };
    }),
  setInitialData: (data) =>
    set((state) => ({
      ...state,
      ...data,
    })),
  updateGroupInfos: (groupInfosUpdate) =>
    set((state) => ({
      groupInfo: {
        ...state.groupInfo,
        ...Object.keys(groupInfosUpdate).reduce(
          (updatedGroupInfo: { [x: string]: any }, groupId) => {
            updatedGroupInfo[groupId] = merge(
              {},
              state.groupInfo[groupId] || {},
              groupInfosUpdate[groupId]
            );
            return updatedGroupInfo;
          },
          {}
        ),
      },
    })),
  updatePairInfos: (pairInfosUpdate) =>
    set((state) => ({
      pairInfos: {
        ...state.pairInfos,
        ...Object.keys(pairInfosUpdate).reduce((updatedPairInfos: { [x: string]: any }, pairId) => {
          updatedPairInfos[pairId] = merge(
            {},
            state.pairInfos[pairId] || {},
            pairInfosUpdate[pairId]
          );
          return updatedPairInfos;
        }, {}),
      },
    })),
  updatePairStore: (key, value) => set(() => ({ [key]: value })),
  resetStore: () => set(() => initialState),
}));
