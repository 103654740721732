export const strategyData = [
  {
    date: '2022-08-26',
    crude: '100',
    gold: '100',
    btc: '100',
    eth: '100',
    jpy: '100',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-08-27',
    crude: '100',
    gold: '100',
    btc: '98.92260099',
    eth: '98.91311819',
    jpy: '100',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-08-28',
    crude: '100',
    gold: '100',
    btc: '96.82524947',
    eth: '94.87754657',
    jpy: '100',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-08-29',
    crude: '101.2921752',
    gold: '100.058006',
    btc: '100.1874362',
    eth: '103.0013949',
    jpy: '100.700776',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-08-30',
    crude: '104.0201005',
    gold: '99.24594453',
    btc: '97.71366984',
    eth: '101.0648768',
    jpy: '101.4886825',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-08-31',
    crude: '99.92821249',
    gold: '98.43387203',
    btc: '98.96221295',
    eth: '103.0443442',
    jpy: '101.5897351',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-01',
    crude: '96.87724336',
    gold: '97.56380463',
    btc: '99.34413239',
    eth: '105.1992846',
    jpy: '102.0012753',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-02',
    crude: '96.55419957',
    gold: '98.43387203',
    btc: '98.56738516',
    eth: '104.60528',
    jpy: '102.5760931',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-03',
    crude: '96.55419957',
    gold: '98.43387203',
    btc: '97.88780243',
    eth: '103.2557635',
    jpy: '102.5760931',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-04',
    crude: '96.55419957',
    gold: '98.43387203',
    btc: '98.65100505',
    eth: '104.6332113',
    jpy: '102.5760931',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-05',
    crude: '97.41564968',
    gold: '98.43387203',
    btc: '97.79048368',
    eth: '107.2557127',
    jpy: '102.8733961',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-06',
    crude: '98.0258435',
    gold: '97.91182938',
    btc: '92.97951534',
    eth: '103.5791426',
    jpy: '102.8316521',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-07',
    crude: '96.48241206',
    gold: '98.89790872',
    btc: '95.21374937',
    eth: '108.0995441',
    jpy: '104.8241451',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-08',
    crude: '93.18018665',
    gold: '98.31787116',
    btc: '95.40876283',
    eth: '108.4604239',
    jpy: '105.5395695',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-09',
    crude: '93.43144293',
    gold: '98.83991382',
    btc: '105.5337203',
    eth: '114.0141277',
    jpy: '105.3403917',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-10',
    crude: '93.43144293',
    gold: '98.83991382',
    btc: '107.0114421',
    eth: '117.802358',
    jpy: '105.3403917',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-11',
    crude: '93.43144293',
    gold: '98.83991382',
    btc: '107.449333',
    eth: '116.8470688',
    jpy: '105.3403917',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-12',
    crude: '96.01579325',
    gold: '99.3039505',
    btc: '110.4167209',
    eth: '113.6612791',
    jpy: '104.4177335',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-13',
    crude: '97.48743719',
    gold: '97.96983535',
    btc: '100.1810832',
    eth: '104.8418861',
    jpy: '104.4096886',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-14',
    crude: '97.6310122',
    gold: '97.6218106',
    btc: '99.90656629',
    eth: '108.4211177',
    jpy: '105.7416747',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-15',
    crude: '99.06676238',
    gold: '95.82366982',
    btc: '97.24181612',
    eth: '97.60646198',
    jpy: '104.6571915',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-16',
    crude: '95.62096195',
    gold: '96.34571248',
    btc: '97.5941013',
    eth: '95.00358532',
    jpy: '104.9808526',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-17',
    crude: '95.62096195',
    gold: '96.34571248',
    btc: '99.34628217',
    eth: '97.47701481',
    jpy: '104.9808526',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-18',
    crude: '95.62096195',
    gold: '96.34571248',
    btc: '95.85136791',
    eth: '88.56242881',
    jpy: '104.9808526',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-19',
    crude: '95.62096195',
    gold: '96.34571248',
    btc: '96.46648601',
    eth: '91.36205481',
    jpy: '104.5414905',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-20',
    crude: '96.05168701',
    gold: '95.82366982',
    btc: '93.241712',
    eth: '87.83679925',
    jpy: '104.8768726',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-21',
    crude: '95.1902369',
    gold: '96.28770651',
    btc: '91.5468041',
    eth: '83.0761405',
    jpy: '105.1543985',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-22',
    crude: '94.93898062',
    gold: '96.22970054',
    btc: '95.82197466',
    eth: '88.05513256',
    jpy: '105.6772263',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-23',
    crude: '94.93898062',
    gold: '94.6055666',
    btc: '95.24985226',
    eth: '88.09355624',
    jpy: '104.1863426',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-24',
    crude: '94.93898062',
    gold: '94.6055666',
    btc: '93.46985915',
    eth: '87.41267335',
    jpy: '104.1863426',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-25',
    crude: '94.93898062',
    gold: '94.6055666',
    btc: '92.80394635',
    eth: '85.835756',
    jpy: '104.1863426',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-26',
    crude: '88.33452979',
    gold: '93.50348639',
    btc: '94.87982894',
    eth: '88.5618378',
    jpy: '105.0877601',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-27',
    crude: '87.86791098',
    gold: '93.67749323',
    btc: '94.32639907',
    eth: '88.21745765',
    jpy: '105.7819438',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-28',
    crude: '87.72433597',
    gold: '95.59164595',
    btc: '95.88697915',
    eth: '88.70049824',
    jpy: '105.9518248',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-29',
    crude: '91.92390524',
    gold: '95.59164595',
    btc: '96.60923945',
    eth: '88.58386706',
    jpy: '105.7372724',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-09-30',
    crude: '91.5649677',
    gold: '95.70765788',
    btc: '95.9119957',
    eth: '88.07492732',
    jpy: '105.7614405',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-01',
    crude: '91.5649677',
    gold: '95.70765788',
    btc: '95.3212097',
    eth: '86.99159966',
    jpy: '105.7614405',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-02',
    crude: '91.5649677',
    gold: '95.70765788',
    btc: '93.99846527',
    eth: '84.63377315',
    jpy: '105.7614405',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-03',
    crude: '92.53409907',
    gold: '97.91182938',
    btc: '96.85864344',
    eth: '87.77386084',
    jpy: '106.0433577',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-04',
    crude: '94.58004307',
    gold: '99.3039505',
    btc: '100.3791912',
    eth: '90.33970633',
    jpy: '105.8258778',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-05',
    crude: '96.48241206',
    gold: '98.78190785',
    btc: '99.50985864',
    eth: '89.72360774',
    jpy: '105.2898654',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-06',
    crude: '99.10265614',
    gold: '98.72390188',
    btc: '98.49666398',
    eth: '89.64881676',
    jpy: '105.7746252',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-07',
    crude: '100.143575',
    gold: '97.6218106',
    btc: '96.47991493',
    eth: '88.37592093',
    jpy: '106.2066575',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-08',
    crude: '100.143575',
    gold: '97.6218106',
    btc: '95.83686891',
    eth: '87.24734465',
    jpy: '106.2066575',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-09',
    crude: '100.143575',
    gold: '97.6218106',
    btc: '95.98424005',
    eth: '87.7184841',
    jpy: '106.2066575',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-10',
    crude: '100.143575',
    gold: '96.1136886',
    btc: '94.47910129',
    eth: '85.64480341',
    jpy: '106.5310449',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-11',
    crude: '102.7279253',
    gold: '95.93968176',
    btc: '94.03454888',
    eth: '84.86472006',
    jpy: '106.6716403',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-12',
    crude: '100.6101938',
    gold: '96.46171335',
    btc: '94.55788176',
    eth: '85.88149038',
    jpy: '106.8151632',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-13',
    crude: '99.35391242',
    gold: '95.82366982',
    btc: '95.67070884',
    eth: '85.43165964',
    jpy: '107.4500382',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-14',
    crude: '102.1536253',
    gold: '94.6055666',
    btc: '94.69712613',
    eth: '86.04834114',
    jpy: '107.8571761',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-15',
    crude: '102.1536253',
    gold: '94.6055666',
    btc: '94.11459222',
    eth: '84.55274015',
    jpy: '107.8571761',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-16',
    crude: '102.1536253',
    gold: '94.6055666',
    btc: '95.10402357',
    eth: '86.63692137',
    jpy: '107.8571761',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-17',
    crude: '100.2512563',
    gold: '94.83759048',
    btc: '96.49920516',
    eth: '88.32264106',
    jpy: '108.8376793',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-18',
    crude: '100.3230438',
    gold: '95.06960329',
    btc: '95.43137896',
    eth: '86.91218585',
    jpy: '109.0859085',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-19',
    crude: '97.73869347',
    gold: '93.7354992',
    btc: '94.46948028',
    eth: '85.27383594',
    jpy: '109.2601918',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-20',
    crude: '101.4716439',
    gold: '93.67749323',
    btc: '94.04601118',
    eth: '85.10515549',
    jpy: '109.7083363',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-21',
    crude: '100.6101938',
    gold: '95.30162717',
    btc: '94.63203488',
    eth: '86.21575862',
    jpy: '109.9682864',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-22',
    crude: '100.6101938',
    gold: '95.30162717',
    btc: '94.80834618',
    eth: '87.16767177',
    jpy: '109.9682864',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-23',
    crude: '100.6101938',
    gold: '95.30162717',
    btc: '96.57941239',
    eth: '90.42728091',
    jpy: '109.9682864',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-24',
    crude: '99.31801866',
    gold: '95.01159732',
    btc: '95.4864443',
    eth: '89.20373029',
    jpy: '108.2423471',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-25',
    crude: '99.85642498',
    gold: '95.18561523',
    btc: '99.18972383',
    eth: '96.94137441',
    jpy: '109.0243987',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-26',
    crude: '99.49748744',
    gold: '95.82366982',
    btc: '102.5193553',
    eth: '103.8986923',
    jpy: '108.3602381',
    gold_crude_corr: null,
    crude_btc_corr: null,
    crude_eth_corr: null,
    crude_jpy_corr: null,
    gold_btc_corr: null,
    gold_eth_corr: null,
    gold_jpy_corr: null,
    btc_eth_corr: null,
    btc_jpy_corr: null,
    eth_jpy_corr: null,
  },
  {
    date: '2022-10-27',
    crude: '100.8973439',
    gold: '95.70765788',
    btc: '100.1274254',
    eth: '100.437201',
    jpy: '107.0084861',
    gold_crude_corr: '0.0925651467',
    crude_btc_corr: '-0.06609420848',
    crude_eth_corr: '-0.07371364082',
    crude_jpy_corr: '0.135630167',
    gold_btc_corr: '0.5761965845',
    gold_eth_corr: '0.6588368981',
    gold_jpy_corr: '-0.6860957739',
    btc_eth_corr: '0.7786635383',
    btc_jpy_corr: '-0.2313058097',
    eth_jpy_corr: '-0.4608151627',
  },
  {
    date: '2022-10-28',
    crude: '101.7229002',
    gold: '94.72157854',
    btc: '101.6551417',
    eth: '103.1632585',
    jpy: '107.1110025',
    gold_crude_corr: '0.0507474296',
    crude_btc_corr: '-0.05058985013',
    crude_eth_corr: '-0.06208804721',
    crude_jpy_corr: '0.1800600079',
    gold_btc_corr: '0.5370873232',
    gold_eth_corr: '0.633415546',
    gold_jpy_corr: '-0.6685616331',
    btc_eth_corr: '0.7807190721',
    btc_jpy_corr: '-0.1965292089',
    eth_jpy_corr: '-0.4447223402',
  },
  {
    date: '2022-10-29',
    crude: '101.7229002',
    gold: '94.72157854',
    btc: '102.7564486',
    eth: '107.4225311',
    jpy: '107.1110025',
    gold_crude_corr: '0.008829698564',
    crude_btc_corr: '-0.02619279796',
    crude_eth_corr: '-0.04062479782',
    crude_jpy_corr: '0.2277373961',
    gold_btc_corr: '0.4987710948',
    gold_eth_corr: '0.6000180513',
    gold_jpy_corr: '-0.647618785',
    btc_eth_corr: '0.7869913706',
    btc_jpy_corr: '-0.1683260816',
    eth_jpy_corr: '-0.4261501141',
  },
  {
    date: '2022-10-30',
    crude: '101.7229002',
    gold: '94.72157854',
    btc: '101.8538185',
    eth: '105.5048039',
    jpy: '107.1110025',
    gold_crude_corr: '-0.03357138577',
    crude_btc_corr: '-0.00126719734',
    crude_eth_corr: '-0.01920736292',
    crude_jpy_corr: '0.2799493228',
    gold_btc_corr: '0.4880676133',
    gold_eth_corr: '0.5878432877',
    gold_jpy_corr: '-0.622274512',
    btc_eth_corr: '0.7918102561',
    btc_jpy_corr: '-0.1685920841',
    eth_jpy_corr: '-0.4313417592',
  },
  {
    date: '2022-10-31',
    crude: '101.8664752',
    gold: '93.96752307',
    btc: '101.1636411',
    eth: '104.3064319',
    jpy: '108.2372184',
    gold_crude_corr: '-0.09782403755',
    crude_btc_corr: '0.005612604071',
    crude_eth_corr: '-0.01447296219',
    crude_jpy_corr: '0.3529737959',
    gold_btc_corr: '0.4457393436',
    gold_eth_corr: '0.5454286745',
    gold_jpy_corr: '-0.6003596554',
    btc_eth_corr: '0.7930753287',
    btc_jpy_corr: '-0.1258026244',
    eth_jpy_corr: '-0.3949442877',
  },
  {
    date: '2022-11-01',
    crude: '102.6202441',
    gold: '94.89559645',
    btc: '101.1118149',
    eth: '104.7700339',
    jpy: '108.9145637',
    gold_crude_corr: '-0.1696960944',
    crude_btc_corr: '0.02569564216',
    crude_eth_corr: '-0.009780511953',
    crude_jpy_corr: '0.4592546361',
    gold_btc_corr: '0.4338989692',
    gold_eth_corr: '0.5196971793',
    gold_jpy_corr: '-0.5836682644',
    btc_eth_corr: '0.7983224293',
    btc_jpy_corr: '-0.1021116267',
    eth_jpy_corr: '-0.3551482387',
  },
  {
    date: '2022-11-02',
    crude: '104.2713568',
    gold: '94.19953589',
    btc: '99.50387202',
    eth: '100.7911587',
    jpy: '108.3126392',
    gold_crude_corr: '-0.2161181731',
    crude_btc_corr: '0.03652754133',
    crude_eth_corr: '-0.0009475573276',
    crude_jpy_corr: '0.5196029848',
    gold_btc_corr: '0.414239031',
    gold_eth_corr: '0.4913307112',
    gold_jpy_corr: '-0.5778130981',
    btc_eth_corr: '0.7995557955',
    btc_jpy_corr: '-0.08061350552',
    eth_jpy_corr: '-0.3250019508',
  },
  {
    date: '2022-11-03',
    crude: '104.2713568',
    gold: '93.85151114',
    btc: '99.75305429',
    eth: '101.5757515',
    jpy: '108.2958008',
    gold_crude_corr: '-0.2460874991',
    crude_btc_corr: '0.05369280055',
    crude_eth_corr: '0.02108910361',
    crude_jpy_corr: '0.5467514117',
    gold_btc_corr: '0.3889527009',
    gold_eth_corr: '0.4586606521',
    gold_jpy_corr: '-0.5845787979',
    btc_eth_corr: '0.8012859627',
    btc_jpy_corr: '-0.0545487607',
    eth_jpy_corr: '-0.28572937',
  },
  {
    date: '2022-11-04',
    crude: '105.8147882',
    gold: '96.75174319',
    btc: '104.3791218',
    eth: '109.1067854',
    jpy: '108.6626696',
    gold_crude_corr: '-0.2251005757',
    crude_btc_corr: '0.1095680394',
    crude_eth_corr: '0.07185445664',
    crude_jpy_corr: '0.5713259968',
    gold_btc_corr: '0.3879693677',
    gold_eth_corr: '0.4466592563',
    gold_jpy_corr: '-0.5540400579',
    btc_eth_corr: '0.8115567536',
    btc_jpy_corr: '-0.009612122935',
    eth_jpy_corr: '-0.2267989809',
  },
  {
    date: '2022-11-05',
    crude: '105.8147882',
    gold: '96.75174319',
    btc: '105.0477339',
    eth: '107.9709874',
    jpy: '108.6626696',
    gold_crude_corr: '-0.2044997501',
    crude_btc_corr: '0.161578145',
    crude_eth_corr: '0.1141569482',
    crude_jpy_corr: '0.5953877422',
    gold_btc_corr: '0.3914782411',
    gold_eth_corr: '0.43779559',
    gold_jpy_corr: '-0.5213337834',
    btc_eth_corr: '0.8209669265',
    btc_jpy_corr: '0.02968332042',
    eth_jpy_corr: '-0.1750885198',
  },
  {
    date: '2022-11-06',
    crude: '105.8147882',
    gold: '96.75174319',
    btc: '103.2895664',
    eth: '104.2746145',
    jpy: '108.6626696',
    gold_crude_corr: '-0.1840087828',
    crude_btc_corr: '0.1969787917',
    crude_eth_corr: '0.144198603',
    crude_jpy_corr: '0.6195150133',
    gold_btc_corr: '0.3944328932',
    gold_eth_corr: '0.4277234286',
    gold_jpy_corr: '-0.4859340829',
    btc_eth_corr: '0.8266140411',
    btc_jpy_corr: '0.06498262744',
    eth_jpy_corr: '-0.126553575',
  },
  {
    date: '2022-11-07',
    crude: '106.7480258',
    gold: '96.34571248',
    btc: '101.6919869',
    eth: '104.0329728',
    jpy: '107.5261964',
    gold_crude_corr: '-0.1735858471',
    crude_btc_corr: '0.2198120247',
    crude_eth_corr: '0.1727638196',
    crude_jpy_corr: '0.6328298328',
    gold_btc_corr: '0.4003234164',
    gold_eth_corr: '0.4098235779',
    gold_jpy_corr: '-0.4593654486',
    btc_eth_corr: '0.839753967',
    btc_jpy_corr: '0.07518358639',
    eth_jpy_corr: '-0.08000190935',
  },
  {
    date: '2022-11-08',
    crude: '106.2455133',
    gold: '98.54988397',
    btc: '91.51655286',
    eth: '88.39705153',
    jpy: '107.3672877',
    gold_crude_corr: '-0.1238661102',
    crude_btc_corr: '0.1684613206',
    crude_eth_corr: '0.1509843643',
    crude_jpy_corr: '0.6521674906',
    gold_btc_corr: '0.3773806596',
    gold_eth_corr: '0.3751384461',
    gold_jpy_corr: '-0.4214294771',
    btc_eth_corr: '0.8692469666',
    btc_jpy_corr: '0.02392774441',
    eth_jpy_corr: '-0.05451968729',
  },
  {
    date: '2022-11-09',
    crude: '102.7279253',
    gold: '98.14385325',
    btc: '78.38482213',
    eth: '72.96606467',
    jpy: '106.3941144',
    gold_crude_corr: '-0.09352209242',
    crude_btc_corr: '0.07757210891',
    crude_eth_corr: '0.1219501414',
    crude_jpy_corr: '0.6446478942',
    gold_btc_corr: '0.2584357267',
    gold_eth_corr: '0.2872739007',
    gold_jpy_corr: '-0.4023466163',
    btc_eth_corr: '0.8824663445',
    btc_jpy_corr: '0.0234028113',
    eth_jpy_corr: '-0.02249733372',
  },
  {
    date: '2022-11-10',
    crude: '99.92821249',
    gold: '101.1020913',
    btc: '86.8053037',
    eth: '86.18380365',
    jpy: '107.0634036',
    gold_crude_corr: '-0.04865598587',
    crude_btc_corr: '0.05594726699',
    crude_eth_corr: '0.147348305',
    crude_jpy_corr: '0.6412450866',
    gold_btc_corr: '0.1400040483',
    gold_eth_corr: '0.2054066206',
    gold_jpy_corr: '-0.355356287',
    btc_eth_corr: '0.89785095',
    btc_jpy_corr: '0.01076166822',
    eth_jpy_corr: '-0.008380784582',
  },
  {
    date: '2022-11-11',
    crude: '99.64106246',
    gold: '101.7401459',
    btc: '84.078364',
    eth: '85.37178152',
    jpy: '103.7865233',
    gold_crude_corr: '-0.006383375191',
    crude_btc_corr: '0.07282739939',
    crude_eth_corr: '0.188207697',
    crude_jpy_corr: '0.613071338',
    gold_btc_corr: '-0.04135982808',
    gold_eth_corr: '0.1044387988',
    gold_jpy_corr: '-0.3942189169',
    btc_eth_corr: '0.874104748',
    btc_jpy_corr: '0.1035584146',
    eth_jpy_corr: '0.04343776644',
  },
  {
    date: '2022-11-12',
    crude: '99.64106246',
    gold: '101.7401459',
    btc: '82.91791388',
    eth: '83.25259313',
    jpy: '103.7865233',
    gold_crude_corr: '0.03071932839',
    crude_btc_corr: '0.09672324812',
    crude_eth_corr: '0.245599052',
    crude_jpy_corr: '0.5869512382',
    gold_btc_corr: '-0.2064271047',
    gold_eth_corr: '-0.007632422996',
    gold_jpy_corr: '-0.4276607701',
    btc_eth_corr: '0.8530676745',
    btc_jpy_corr: '0.1957668176',
    eth_jpy_corr: '0.1084117398',
  },
  {
    date: '2022-11-13',
    crude: '99.64106246',
    gold: '101.7401459',
    btc: '80.71742087',
    eth: '81.0341638',
    jpy: '103.7865233',
    gold_crude_corr: '0.06389508203',
    crude_btc_corr: '0.1221684904',
    crude_eth_corr: '0.3091411439',
    crude_jpy_corr: '0.5627201967',
    gold_btc_corr: '-0.3603272675',
    gold_eth_corr: '-0.1248266828',
    gold_jpy_corr: '-0.4568419806',
    btc_eth_corr: '0.8325183174',
    btc_jpy_corr: '0.2870986018',
    eth_jpy_corr: '0.1810371561',
  },
  {
    date: '2022-11-14',
    crude: '98.56424982',
    gold: '101.9721587',
    btc: '82.02459624',
    eth: '82.34635769',
    jpy: '102.1894585',
    gold_crude_corr: '0.0738866232',
    crude_btc_corr: '0.1504913843',
    crude_eth_corr: '0.350275939',
    crude_jpy_corr: '0.5330689631',
    gold_btc_corr: '-0.5208441285',
    gold_eth_corr: '-0.2360398593',
    gold_jpy_corr: '-0.4994863694',
    btc_eth_corr: '0.8069796305',
    btc_jpy_corr: '0.4314929415',
    eth_jpy_corr: '0.2789360747',
  },
  {
    date: '2022-11-15',
    crude: '95.94400574',
    gold: '102.3781894',
    btc: '83.3395706',
    eth: '83.01833496',
    jpy: '102.6009987',
    gold_crude_corr: '0.04535107501',
    crude_btc_corr: '0.1707207274',
    crude_eth_corr: '0.3716787793',
    crude_jpy_corr: '0.5341028519',
    gold_btc_corr: '-0.5802720154',
    gold_eth_corr: '-0.2898742282',
    gold_jpy_corr: '-0.5387801242',
    btc_eth_corr: '0.8045476665',
    btc_jpy_corr: '0.4969587438',
    eth_jpy_corr: '0.3417059749',
  },
  {
    date: '2022-11-16',
    crude: '97.34386217',
    gold: '102.1461766',
    btc: '82.2775093',
    eth: '80.62185809',
    jpy: '101.7588898',
    gold_crude_corr: '0.03299479977',
    crude_btc_corr: '0.1786146589',
    crude_eth_corr: '0.3955800629',
    crude_jpy_corr: '0.5188599723',
    gold_btc_corr: '-0.6281111375',
    gold_eth_corr: '-0.3525054157',
    gold_jpy_corr: '-0.5819522172',
    btc_eth_corr: '0.8137882651',
    btc_jpy_corr: '0.5497698491',
    eth_jpy_corr: '0.3992125792',
  },
  {
    date: '2022-11-17',
    crude: '94.7954056',
    gold: '101.3921101',
    btc: '82.36673984',
    eth: '79.64068594',
    jpy: '102.0203149',
    gold_crude_corr: '0.003718825203',
    crude_btc_corr: '0.200211862',
    crude_eth_corr: '0.4074862309',
    crude_jpy_corr: '0.5307442943',
    gold_btc_corr: '-0.6532787928',
    gold_eth_corr: '-0.3785535441',
    gold_jpy_corr: '-0.620487083',
    btc_eth_corr: '0.8192962111',
    btc_jpy_corr: '0.5930086453',
    eth_jpy_corr: '0.4437733536',
  },
  {
    date: '2022-11-18',
    crude: '94.11342426',
    gold: '100.6380546',
    btc: '82.41738029',
    eth: '80.40284471',
    jpy: '102.8184786',
    gold_crude_corr: '-0.02408504022',
    crude_btc_corr: '0.2301289226',
    crude_eth_corr: '0.4276931687',
    crude_jpy_corr: '0.5379490165',
    gold_btc_corr: '-0.6714475868',
    gold_eth_corr: '-0.3984102203',
    gold_jpy_corr: '-0.6419193477',
    btc_eth_corr: '0.8216969057',
    btc_jpy_corr: '0.6242241154',
    eth_jpy_corr: '0.4716494702',
  },
  {
    date: '2022-11-19',
    crude: '94.11342426',
    gold: '100.6380546',
    btc: '82.48534435',
    eth: '80.80916747',
    jpy: '102.8184786',
    gold_crude_corr: '-0.05021321868',
    crude_btc_corr: '0.2616622697',
    crude_eth_corr: '0.4519020913',
    crude_jpy_corr: '0.5449238944',
    gold_btc_corr: '-0.6885247018',
    gold_eth_corr: '-0.4162177524',
    gold_jpy_corr: '-0.6613767146',
    btc_eth_corr: '0.8222059002',
    btc_jpy_corr: '0.6570282471',
    eth_jpy_corr: '0.5018040161',
  },
  {
    date: '2022-11-20',
    crude: '94.11342426',
    gold: '100.6380546',
    btc: '80.41370348',
    eth: '75.7713012',
    jpy: '102.8184786',
    gold_crude_corr: '-0.07486554768',
    crude_btc_corr: '0.2868806905',
    crude_eth_corr: '0.4646401187',
    crude_jpy_corr: '0.5516808821',
    gold_btc_corr: '-0.7026849105',
    gold_eth_corr: '-0.4410429906',
    gold_jpy_corr: '-0.6791434553',
    btc_eth_corr: '0.8322768187',
    btc_jpy_corr: '0.6801541265',
    eth_jpy_corr: '0.5244769419',
  },
  {
    date: '2022-11-21',
    crude: '91.34960517',
    gold: '100.1160119',
    btc: '77.92334136',
    eth: '73.50879717',
    jpy: '102.7020513',
    gold_crude_corr: '-0.1066295027',
    crude_btc_corr: '0.3317755382',
    crude_eth_corr: '0.4930510824',
    crude_jpy_corr: '0.5667796471',
    gold_btc_corr: '-0.7104350185',
    gold_eth_corr: '-0.4612173353',
    gold_jpy_corr: '-0.6956500448',
    btc_eth_corr: '0.8425725638',
    btc_jpy_corr: '0.7066325095',
    eth_jpy_corr: '0.5540321308',
  },
  {
    date: '2022-11-22',
    crude: '93.25197416',
    gold: '100.1740179',
    btc: '79.90994039',
    eth: '75.28759674',
    jpy: '104.053077',
    gold_crude_corr: '-0.1307586307',
    crude_btc_corr: '0.3534638437',
    crude_eth_corr: '0.5065175411',
    crude_jpy_corr: '0.5725711719',
    gold_btc_corr: '-0.7206371175',
    gold_eth_corr: '-0.4803066059',
    gold_jpy_corr: '-0.7093535363',
    btc_eth_corr: '0.8497135155',
    btc_jpy_corr: '0.7129661566',
    eth_jpy_corr: '0.5647499822',
  },
  {
    date: '2022-11-23',
    crude: '93.25197416',
    gold: '100.8120614',
    btc: '81.98761608',
    eth: '78.4728116',
    jpy: '103.362547',
    gold_crude_corr: '-0.1564796473',
    crude_btc_corr: '0.3695769958',
    crude_eth_corr: '0.513347515',
    crude_jpy_corr: '0.580083404',
    gold_btc_corr: '-0.732943855',
    gold_eth_corr: '-0.4997541684',
    gold_jpy_corr: '-0.7222181885',
    btc_eth_corr: '0.8540566875',
    btc_jpy_corr: '0.7219587296',
    eth_jpy_corr: '0.5736066842',
  },
  {
    date: '2022-11-24',
    crude: '89.1959799',
    gold: '100.8120614',
    btc: '81.95680571',
    eth: '79.85123088',
    jpy: '101.9880907',
    gold_crude_corr: '-0.1967695894',
    crude_btc_corr: '0.4047067713',
    crude_eth_corr: '0.5265395194',
    crude_jpy_corr: '0.6038991571',
    gold_btc_corr: '-0.7418644615',
    gold_eth_corr: '-0.5120257402',
    gold_jpy_corr: '-0.7348074091',
    btc_eth_corr: '0.8575010808',
    btc_jpy_corr: '0.7366438281',
    eth_jpy_corr: '0.5836229218',
  },
  {
    date: '2022-11-25',
    crude: '90.02153625',
    gold: '100.9280734',
    btc: '81.54899244',
    eth: '79.51582111',
    jpy: '101.4938111',
    gold_crude_corr: '-0.2393917169',
    crude_btc_corr: '0.4343087128',
    crude_eth_corr: '0.5393015331',
    crude_jpy_corr: '0.6213778368',
    gold_btc_corr: '-0.7522257139',
    gold_eth_corr: '-0.5281719495',
    gold_jpy_corr: '-0.7665325463',
    btc_eth_corr: '0.8605727191',
    btc_jpy_corr: '0.7570639242',
    eth_jpy_corr: '0.5956313197',
  },
  {
    date: '2022-11-26',
    crude: '90.02153625',
    gold: '100.9280734',
    btc: '81.26488341',
    eth: '79.97822496',
    jpy: '101.4938111',
    gold_crude_corr: '-0.2789549827',
    crude_btc_corr: '0.4591802294',
    crude_eth_corr: '0.5496202499',
    crude_jpy_corr: '0.6371640057',
    gold_btc_corr: '-0.764763769',
    gold_eth_corr: '-0.5440552889',
    gold_jpy_corr: '-0.7962940451',
    btc_eth_corr: '0.8622170229',
    btc_jpy_corr: '0.7725139294',
    eth_jpy_corr: '0.6048067158',
  },
  {
    date: '2022-11-27',
    crude: '90.02153625',
    gold: '100.9280734',
    btc: '81.16787315',
    eth: '79.26386505',
    jpy: '101.4938111',
    gold_crude_corr: '-0.3158971658',
    crude_btc_corr: '0.4812189621',
    crude_eth_corr: '0.5592385715',
    crude_jpy_corr: '0.6514903521',
    gold_btc_corr: '-0.7777537605',
    gold_eth_corr: '-0.5633470828',
    gold_jpy_corr: '-0.8244657117',
    btc_eth_corr: '0.8649380941',
    btc_jpy_corr: '0.7855862866',
    eth_jpy_corr: '0.6132194957',
  },
  {
    date: '2022-11-28',
    crude: '84.99641062',
    gold: '100.1160119',
    btc: '80.04593599',
    eth: '77.60309728',
    jpy: '101.8577525',
    gold_crude_corr: '-0.3937709458',
    crude_btc_corr: '0.5330500931',
    crude_eth_corr: '0.5881023061',
    crude_jpy_corr: '0.6785571861',
    gold_btc_corr: '-0.7875484663',
    gold_eth_corr: '-0.5806631355',
    gold_jpy_corr: '-0.8490205967',
    btc_eth_corr: '0.868598854',
    btc_jpy_corr: '0.7979029569',
    eth_jpy_corr: '0.6252288809',
  },
  {
    date: '2022-11-29',
    crude: '89.80617373',
    gold: '100.6380546',
    btc: '81.16962768',
    eth: '80.70799158',
    jpy: '101.5787516',
    gold_crude_corr: '-0.4740059396',
    crude_btc_corr: '0.5738578065',
    crude_eth_corr: '0.6117060928',
    crude_jpy_corr: '0.7106153827',
    gold_btc_corr: '-0.7998605589',
    gold_eth_corr: '-0.5964688039',
    gold_jpy_corr: '-0.8667956367',
    btc_eth_corr: '0.8694612841',
    btc_jpy_corr: '0.8056779318',
    eth_jpy_corr: '0.6299378861',
  },
  {
    date: '2022-11-30',
    crude: '89.51902369',
    gold: '101.8561467',
    btc: '84.74111478',
    eth: '85.93336964',
    jpy: '101.5977911',
    gold_crude_corr: '-0.5369669567',
    crude_btc_corr: '0.6163379804',
    crude_eth_corr: '0.6259696316',
    crude_jpy_corr: '0.7465596019',
    gold_btc_corr: '-0.8013394414',
    gold_eth_corr: '-0.5935918257',
    gold_jpy_corr: '-0.8758146039',
    btc_eth_corr: '0.8682504604',
    btc_jpy_corr: '0.8081705063',
    eth_jpy_corr: '0.6228148843',
  },
  {
    date: '2022-12-01',
    crude: '90.91888011',
    gold: '103.7702995',
    btc: '83.74687293',
    eth: '84.64573906',
    jpy: '100.5828738',
    gold_crude_corr: '-0.5796345695',
    crude_btc_corr: '0.6438464121',
    crude_eth_corr: '0.6320421059',
    crude_jpy_corr: '0.763308157',
    gold_btc_corr: '-0.7989427278',
    gold_eth_corr: '-0.5872460778',
    gold_jpy_corr: '-0.8883423127',
    btc_eth_corr: '0.8684962117',
    btc_jpy_corr: '0.8108729119',
    eth_jpy_corr: '0.6154499569',
  },
  {
    date: '2022-12-02',
    crude: '90.34458004',
    gold: '103.4802807',
    btc: '84.34671117',
    eth: '85.84149607',
    jpy: '98.96530568',
    gold_crude_corr: '-0.6228469579',
    crude_btc_corr: '0.6694252229',
    crude_eth_corr: '0.6342283257',
    crude_jpy_corr: '0.7811647317',
    gold_btc_corr: '-0.7970613995',
    gold_eth_corr: '-0.5804390901',
    gold_jpy_corr: '-0.8986901745',
    btc_eth_corr: '0.8680630005',
    btc_jpy_corr: '0.8043067056',
    eth_jpy_corr: '0.5966708592',
  },
  {
    date: '2022-12-03',
    crude: '90.34458004',
    gold: '103.4802807',
    btc: '83.45616993',
    eth: '82.46113488',
    jpy: '98.96530568',
    gold_crude_corr: '-0.6645527364',
    crude_btc_corr: '0.6943284628',
    crude_eth_corr: '0.6411852988',
    crude_jpy_corr: '0.7992834417',
    gold_btc_corr: '-0.7978145157',
    gold_eth_corr: '-0.585538796',
    gold_jpy_corr: '-0.9077592183',
    btc_eth_corr: '0.8708970313',
    btc_jpy_corr: '0.8016494581',
    eth_jpy_corr: '0.5928022984',
  },
  {
    date: '2022-12-04',
    crude: '90.34458004',
    gold: '103.4802807',
    btc: '84.55315801',
    eth: '84.90987962',
    jpy: '98.96530568',
    gold_crude_corr: '-0.7050499915',
    crude_btc_corr: '0.7132075058',
    crude_eth_corr: '0.6387395742',
    crude_jpy_corr: '0.8175119579',
    gold_btc_corr: '-0.7976596914',
    gold_eth_corr: '-0.5886960537',
    gold_jpy_corr: '-0.9158215777',
    btc_eth_corr: '0.8734719712',
    btc_jpy_corr: '0.7963651926',
    eth_jpy_corr: '0.5835058503',
  },
  {
    date: '2022-12-05',
    crude: '89.51902369',
    gold: '101.6821399',
    btc: '83.78484604',
    eth: '83.54497259',
    jpy: '98.47103726',
    gold_crude_corr: '-0.7199363682',
    crude_btc_corr: '0.7367211357',
    crude_eth_corr: '0.6417650711',
    crude_jpy_corr: '0.8357816337',
    gold_btc_corr: '-0.8025664069',
    gold_eth_corr: '-0.5906322797',
    gold_jpy_corr: '-0.9109301629',
    btc_eth_corr: '0.8768571688',
    btc_jpy_corr: '0.7931254371',
    eth_jpy_corr: '0.5776422536',
  },
  {
    date: '2022-12-06',
    crude: '87.58076095',
    gold: '101.9721587',
    btc: '84.35087577',
    eth: '84.33932112',
    jpy: '100.0651635',
    gold_crude_corr: '-0.7265908107',
    crude_btc_corr: '0.7563505021',
    crude_eth_corr: '0.6388430228',
    crude_jpy_corr: '0.8473198612',
    gold_btc_corr: '-0.8195079458',
    gold_eth_corr: '-0.5929607596',
    gold_jpy_corr: '-0.9141529102',
    btc_eth_corr: '0.8812928607',
    btc_jpy_corr: '0.7986912666',
    eth_jpy_corr: '0.5750902879',
  },
  {
    date: '2022-12-07',
    crude: '84.60157933',
    gold: '102.8422261',
    btc: '83.15948031',
    eth: '81.7383956',
    jpy: '100.2365194',
    gold_crude_corr: '-0.7360685513',
    crude_btc_corr: '0.7626255978',
    crude_eth_corr: '0.6342381923',
    crude_jpy_corr: '0.8512574078',
    gold_btc_corr: '-0.8314772336',
    gold_eth_corr: '-0.5971356385',
    gold_jpy_corr: '-0.9172624166',
    btc_eth_corr: '0.8869197081',
    btc_jpy_corr: '0.8078080532',
    eth_jpy_corr: '0.5788265294',
  },
  {
    date: '2022-12-08',
    crude: '82.91457286',
    gold: '102.958238',
    btc: '85.0614906',
    eth: '84.96689985',
    jpy: '99.86672317',
    gold_crude_corr: '-0.7450790092',
    crude_btc_corr: '0.7500303383',
    crude_eth_corr: '0.6142596198',
    crude_jpy_corr: '0.854302141',
    gold_btc_corr: '-0.8380420153',
    gold_eth_corr: '-0.5946549847',
    gold_jpy_corr: '-0.9206967875',
    btc_eth_corr: '0.8903258562',
    btc_jpy_corr: '0.8092041449',
    eth_jpy_corr: '0.5746991726',
  },
  {
    date: '2022-12-09',
    crude: '81.26346016',
    gold: '103.3062628',
    btc: '84.56631701',
    eth: '83.85058903',
    jpy: '100.0373416',
    gold_crude_corr: '-0.7530053213',
    crude_btc_corr: '0.7346647353',
    crude_eth_corr: '0.5984661106',
    crude_jpy_corr: '0.8538858813',
    gold_btc_corr: '-0.8377399221',
    gold_eth_corr: '-0.5934503012',
    gold_jpy_corr: '-0.9233720162',
    btc_eth_corr: '0.8935297422',
    btc_jpy_corr: '0.8089993798',
    eth_jpy_corr: '0.5741853276',
  },
  {
    date: '2022-12-10',
    crude: '81.26346016',
    gold: '103.3062628',
    btc: '84.54446247',
    eth: '83.98982428',
    jpy: '100.0373416',
    gold_crude_corr: '-0.7604028696',
    crude_btc_corr: '0.7224601282',
    crude_eth_corr: '0.5866565141',
    crude_jpy_corr: '0.8543945568',
    gold_btc_corr: '-0.8371323854',
    gold_eth_corr: '-0.5927348591',
    gold_jpy_corr: '-0.9257651226',
    btc_eth_corr: '0.8974041417',
    btc_jpy_corr: '0.8085333187',
    eth_jpy_corr: '0.5742608676',
  },
  {
    date: '2022-12-11',
    crude: '81.26346016',
    gold: '103.3062628',
    btc: '84.42338041',
    eth: '83.82298162',
    jpy: '100.0373416',
    gold_crude_corr: '-0.7672283642',
    crude_btc_corr: '0.7128553998',
    crude_eth_corr: '0.5776971736',
    crude_jpy_corr: '0.8554292763',
    gold_btc_corr: '-0.8368582342',
    gold_eth_corr: '-0.5923803224',
    gold_jpy_corr: '-0.9279118924',
    btc_eth_corr: '0.9011213322',
    btc_jpy_corr: '0.8083025684',
    eth_jpy_corr: '0.5745894312',
  },
  {
    date: '2022-12-12',
    crude: '81.29935391',
    gold: '102.4361954',
    btc: '84.92804004',
    eth: '84.5359814',
    jpy: '100.1091087',
    gold_crude_corr: '-0.7677672367',
    crude_btc_corr: '0.7034377411',
    crude_eth_corr: '0.5706306858',
    crude_jpy_corr: '0.8562233489',
    gold_btc_corr: '-0.8360502731',
    gold_eth_corr: '-0.5975895104',
    gold_jpy_corr: '-0.9293938838',
    btc_eth_corr: '0.905479837',
    btc_jpy_corr: '0.8066914109',
    eth_jpy_corr: '0.5761854885',
  },
  {
    date: '2022-12-13',
    crude: '84.70926059',
    gold: '104.1763302',
    btc: '87.76555389',
    eth: '87.5821877',
    jpy: '100.689055',
    gold_crude_corr: '-0.7741057652',
    crude_btc_corr: '0.6935446226',
    crude_eth_corr: '0.5682343324',
    crude_jpy_corr: '0.8584925574',
    gold_btc_corr: '-0.8249531964',
    gold_eth_corr: '-0.5941324845',
    gold_jpy_corr: '-0.92993433',
    btc_eth_corr: '0.9102574417',
    btc_jpy_corr: '0.8018172876',
    eth_jpy_corr: '0.5751814352',
  },
  {
    date: '2022-12-14',
    crude: '84.02727925',
    gold: '104.0023233',
    btc: '87.93501094',
    eth: '86.83801827',
    jpy: '99.28311191',
    gold_crude_corr: '-0.7806816581',
    crude_btc_corr: '0.682100316',
    crude_eth_corr: '0.5629364254',
    crude_jpy_corr: '0.8626517297',
    gold_btc_corr: '-0.8144917461',
    gold_eth_corr: '-0.5911276743',
    gold_jpy_corr: '-0.931869624',
    btc_eth_corr: '0.9149686159',
    btc_jpy_corr: '0.7918249335',
    eth_jpy_corr: '0.5715176884',
  },
  {
    date: '2022-12-15',
    crude: '85.78607322',
    gold: '102.2621775',
    btc: '85.71001231',
    eth: '83.98781647',
    jpy: '99.11030345',
    gold_crude_corr: '-0.7832154407',
    crude_btc_corr: '0.679416541',
    crude_eth_corr: '0.5661565284',
    crude_jpy_corr: '0.8664012672',
    gold_btc_corr: '-0.8125352975',
    gold_eth_corr: '-0.6000884966',
    gold_jpy_corr: '-0.9305774117',
    btc_eth_corr: '0.9226830674',
    btc_jpy_corr: '0.7860129811',
    eth_jpy_corr: '0.5767525107',
  },
  {
    date: '2022-12-16',
    crude: '85.67839196',
    gold: '103.1902619',
    btc: '82.16914278',
    eth: '77.48194042',
    jpy: '100.8699307',
    gold_crude_corr: '-0.7850052615',
    crude_btc_corr: '0.6815040524',
    crude_eth_corr: '0.5819760016',
    crude_jpy_corr: '0.8661865841',
    gold_btc_corr: '-0.8152001658',
    gold_eth_corr: '-0.6196740619',
    gold_jpy_corr: '-0.9310546191',
    btc_eth_corr: '0.9286371004',
    btc_jpy_corr: '0.7871661194',
    eth_jpy_corr: '0.5900610534',
  },
  {
    date: '2022-12-17',
    crude: '85.67839196',
    gold: '103.1902619',
    btc: '82.89770783',
    eth: '78.80110498',
    jpy: '100.8699307',
    gold_crude_corr: '-0.7862815415',
    crude_btc_corr: '0.6827046645',
    crude_eth_corr: '0.5984647493',
    crude_jpy_corr: '0.8657603174',
    gold_btc_corr: '-0.818035794',
    gold_eth_corr: '-0.6418418707',
    gold_jpy_corr: '-0.9314668901',
    btc_eth_corr: '0.9356814562',
    btc_jpy_corr: '0.7881914665',
    eth_jpy_corr: '0.6051213345',
  },
  {
    date: '2022-12-18',
    crude: '85.67839196',
    gold: '103.1902619',
    btc: '82.71451336',
    eth: '78.57333171',
    jpy: '100.8699307',
    gold_crude_corr: '-0.7870635069',
    crude_btc_corr: '0.6827929526',
    crude_eth_corr: '0.6111340483',
    crude_jpy_corr: '0.865116111',
    gold_btc_corr: '-0.8194227141',
    gold_eth_corr: '-0.6592263418',
    gold_jpy_corr: '-0.9318229629',
    btc_eth_corr: '0.9413839452',
    btc_jpy_corr: '0.7882150264',
    eth_jpy_corr: '0.6163215365',
  },
  {
    date: '2022-12-19',
    crude: '84.31442929',
    gold: '102.8422261',
    btc: '81.14345429',
    eth: '77.43886153',
    jpy: '100.0929966',
    gold_crude_corr: '-0.7892573231',
    crude_btc_corr: '0.6846498267',
    crude_eth_corr: '0.620617681',
    crude_jpy_corr: '0.867439581',
    gold_btc_corr: '-0.8194133743',
    gold_eth_corr: '-0.6720878689',
    gold_jpy_corr: '-0.930933779',
    btc_eth_corr: '0.9473238076',
    btc_jpy_corr: '0.7880318406',
    eth_jpy_corr: '0.6289611037',
  },
  {
    date: '2022-12-20',
    crude: '82.98636037',
    gold: '104.6403779',
    btc: '83.44663568',
    eth: '80.76120669',
    jpy: '100.3163313',
    gold_crude_corr: '-0.7947358545',
    crude_btc_corr: '0.6818049381',
    crude_eth_corr: '0.6265005509',
    crude_jpy_corr: '0.8688411088',
    gold_btc_corr: '-0.8159842928',
    gold_eth_corr: '-0.6840437526',
    gold_jpy_corr: '-0.9286989182',
    btc_eth_corr: '0.9533321075',
    btc_jpy_corr: '0.7873044693',
    eth_jpy_corr: '0.6428062074',
  },
  {
    date: '2022-12-21',
    crude: '82.91457286',
    gold: '104.4083541',
    btc: '83.00848442',
    eth: '80.48904298',
    jpy: '96.57959687',
    gold_crude_corr: '-0.8090311931',
    crude_btc_corr: '0.6816942263',
    crude_eth_corr: '0.632013653',
    crude_jpy_corr: '0.8786269356',
    gold_btc_corr: '-0.8186864136',
    gold_eth_corr: '-0.7093111778',
    gold_jpy_corr: '-0.926559145',
    btc_eth_corr: '0.9603487968',
    btc_jpy_corr: '0.7811100527',
    eth_jpy_corr: '0.6582020588',
  },
  {
    date: '2022-12-22',
    crude: '85.35534817',
    gold: '103.1322559',
    btc: '83.07169581',
    eth: '80.79294306',
    jpy: '96.92961614',
    gold_crude_corr: '-0.8115118988',
    crude_btc_corr: '0.6807110422',
    crude_eth_corr: '0.6431202391',
    crude_jpy_corr: '0.8791554026',
    gold_btc_corr: '-0.8248028804',
    gold_eth_corr: '-0.7380103507',
    gold_jpy_corr: '-0.9199043143',
    btc_eth_corr: '0.9670479854',
    btc_jpy_corr: '0.7781886928',
    eth_jpy_corr: '0.6774239097',
  },
  {
    date: '2022-12-23',
    crude: '85.10409189',
    gold: '103.4802807',
    btc: '82.90689503',
    eth: '80.9240823',
    jpy: '96.92009636',
    gold_crude_corr: '-0.8127782867',
    crude_btc_corr: '0.6792394091',
    crude_eth_corr: '0.6509860125',
    crude_jpy_corr: '0.8823068077',
    gold_btc_corr: '-0.8250562574',
    gold_eth_corr: '-0.7541373712',
    gold_jpy_corr: '-0.9157812642',
    btc_eth_corr: '0.9733211798',
    btc_jpy_corr: '0.7752967387',
    eth_jpy_corr: '0.6956369235',
  },
  {
    date: '2022-12-24',
    crude: '85.10409189',
    gold: '103.4802807',
    btc: '83.15764866',
    eth: '80.9896843',
    jpy: '96.92009636',
    gold_crude_corr: '-0.8137708755',
    crude_btc_corr: '0.6769301429',
    crude_eth_corr: '0.6573870098',
    crude_jpy_corr: '0.885602095',
    gold_btc_corr: '-0.8247499453',
    gold_eth_corr: '-0.768735007',
    gold_jpy_corr: '-0.9114469879',
    btc_eth_corr: '0.9787437423',
    btc_jpy_corr: '0.7716346689',
    eth_jpy_corr: '0.7121380369',
  },
  {
    date: '2022-12-25',
    crude: '85.10409189',
    gold: '103.4802807',
    btc: '83.12917123',
    eth: '80.8446685',
    jpy: '96.92009636',
    gold_crude_corr: '-0.8145124678',
    crude_btc_corr: '0.6732069902',
    crude_eth_corr: '0.6595683939',
    crude_jpy_corr: '0.8891260293',
    gold_btc_corr: '-0.8217250764',
    gold_eth_corr: '-0.7768028285',
    gold_jpy_corr: '-0.9068044072',
    btc_eth_corr: '0.9828743378',
    btc_jpy_corr: '0.7641265828',
    eth_jpy_corr: '0.7208559165',
  },
  {
    date: '2022-12-26',
    crude: '84.70926059',
    gold: '103.4802807',
    btc: '83.51326938',
    eth: '81.37606658',
    jpy: '97.23716515',
    gold_crude_corr: '-0.8183525133',
    crude_btc_corr: '0.6699587939',
    crude_eth_corr: '0.6616116936',
    crude_jpy_corr: '0.891475192',
    gold_btc_corr: '-0.8202749698',
    gold_eth_corr: '-0.7894439',
    gold_jpy_corr: '-0.9029179042',
    btc_eth_corr: '0.9871327452',
    btc_jpy_corr: '0.7564845736',
    eth_jpy_corr: '0.7261521077',
  },
  {
    date: '2022-12-27',
    crude: '87.79612347',
    gold: '104.3503481',
    btc: '82.51311803',
    eth: '80.43543117',
    jpy: '97.21813678',
    gold_crude_corr: '-0.8182276494',
    crude_btc_corr: '0.6662566955',
    crude_eth_corr: '0.6583433094',
    crude_jpy_corr: '0.8904246854',
    gold_btc_corr: '-0.8127040068',
    gold_eth_corr: '-0.7864174832',
    gold_jpy_corr: '-0.9000678434',
    btc_eth_corr: '0.9883882415',
    btc_jpy_corr: '0.7451143915',
    eth_jpy_corr: '0.7203704353',
  },
  {
    date: '2022-12-28',
    crude: '88.11916726',
    gold: '103.8283054',
    btc: '81.7006728',
    eth: '78.92290951',
    jpy: '97.7468194',
    gold_crude_corr: '-0.8176714863',
    crude_btc_corr: '0.6648302652',
    crude_eth_corr: '0.6557771882',
    crude_jpy_corr: '0.8894407093',
    gold_btc_corr: '-0.8051269068',
    gold_eth_corr: '-0.7785211637',
    gold_jpy_corr: '-0.8970640192',
    btc_eth_corr: '0.9879746419',
    btc_jpy_corr: '0.7332323907',
    eth_jpy_corr: '0.7092315913',
  },
  {
    date: '2022-12-29',
    crude: '86.71931084',
    gold: '104.46636',
    btc: '82.14375989',
    eth: '79.69286475',
    jpy: '98.14810242',
    gold_crude_corr: '-0.8155278002',
    crude_btc_corr: '0.6585285567',
    crude_eth_corr: '0.6495767731',
    crude_jpy_corr: '0.8873304247',
    gold_btc_corr: '-0.7954399961',
    gold_eth_corr: '-0.7700632846',
    gold_jpy_corr: '-0.8963776668',
    btc_eth_corr: '0.98794236',
    btc_jpy_corr: '0.7251100722',
    eth_jpy_corr: '0.7016418839',
  },
  {
    date: '2022-12-30',
    crude: '86.28858579',
    gold: '104.9303967',
    btc: '81.94753175',
    eth: '79.53617449',
    jpy: '97.32943544',
    gold_crude_corr: '-0.8133745181',
    crude_btc_corr: '0.6497427357',
    crude_eth_corr: '0.6405796805',
    crude_jpy_corr: '0.8836750201',
    gold_btc_corr: '-0.7799612157',
    gold_eth_corr: '-0.7553810209',
    gold_jpy_corr: '-0.8977752506',
    btc_eth_corr: '0.987454971',
    btc_jpy_corr: '0.7148060681',
    eth_jpy_corr: '0.6911637485',
  },
  {
    date: '2022-12-31',
    crude: '86.28858579',
    gold: '104.9303967',
    btc: '81.67561768',
    eth: '79.37291836',
    jpy: '97.32943544',
    gold_crude_corr: '-0.8110584',
    crude_btc_corr: '0.6403034441',
    crude_eth_corr: '0.6313059313',
    crude_jpy_corr: '0.8797479747',
    gold_btc_corr: '-0.7608011859',
    gold_eth_corr: '-0.7337237658',
    gold_jpy_corr: '-0.8993281687',
    btc_eth_corr: '0.9864560975',
    btc_jpy_corr: '0.7042172836',
    eth_jpy_corr: '0.6801963652',
  },
  {
    date: '2023-01-01',
    crude: '86.28858579',
    gold: '104.9303967',
    btc: '82.05855899',
    eth: '79.65104883',
    jpy: '97.32943544',
    gold_crude_corr: '-0.8086562427',
    crude_btc_corr: '0.6287902672',
    crude_eth_corr: '0.6197178755',
    crude_jpy_corr: '0.8754906567',
    gold_btc_corr: '-0.7378553583',
    gold_eth_corr: '-0.7085793282',
    gold_jpy_corr: '-0.9012096478',
    btc_eth_corr: '0.9852323118',
    btc_jpy_corr: '0.6911610949',
    eth_jpy_corr: '0.6666778942',
  },
  {
    date: '2023-01-02',
    crude: '86.28858579',
    gold: '104.9303967',
    btc: '82.3714443',
    eth: '80.55912207',
    jpy: '95.81291992',
    gold_crude_corr: '-0.809351909',
    crude_btc_corr: '0.6145485824',
    crude_eth_corr: '0.6048732664',
    crude_jpy_corr: '0.8673363563',
    gold_btc_corr: '-0.7076119898',
    gold_eth_corr: '-0.674480081',
    gold_jpy_corr: '-0.9007005629',
    btc_eth_corr: '0.9838177333',
    btc_jpy_corr: '0.6653175043',
    eth_jpy_corr: '0.6389755649',
  },
  {
    date: '2023-01-03',
    crude: '86.28858579',
    gold: '105.8004641',
    btc: '82.32893061',
    eth: '80.56722618',
    jpy: '95.77557827',
    gold_crude_corr: '-0.801492461',
    crude_btc_corr: '0.5952327118',
    crude_eth_corr: '0.5847004152',
    crude_jpy_corr: '0.8581797407',
    gold_btc_corr: '-0.6702322116',
    gold_eth_corr: '-0.6317820999',
    gold_jpy_corr: '-0.8969597336',
    btc_eth_corr: '0.9820670541',
    btc_jpy_corr: '0.6314964252',
    eth_jpy_corr: '0.6017299097',
  },
  {
    date: '2023-01-04',
    crude: '82.91457286',
    gold: '106.7865435',
    btc: '83.23406725',
    eth: '83.33604685',
    jpy: '96.10802172',
    gold_crude_corr: '-0.794410119',
    crude_btc_corr: '0.5643265669',
    crude_eth_corr: '0.5502631965',
    crude_jpy_corr: '0.8520040969',
    gold_btc_corr: '-0.6164419197',
    gold_eth_corr: '-0.5706526949',
    gold_jpy_corr: '-0.8967538736',
    btc_eth_corr: '0.9800091218',
    btc_jpy_corr: '0.5950949742',
    eth_jpy_corr: '0.559361403',
  },
  {
    date: '2023-01-05',
    crude: '81.6223977',
    gold: '105.5104453',
    btc: '83.10325813',
    eth: '82.93227433',
    jpy: '96.83882074',
    gold_crude_corr: '-0.7930177104',
    crude_btc_corr: '0.5260733204',
    crude_eth_corr: '0.5076905426',
    crude_jpy_corr: '0.845626575',
    gold_btc_corr: '-0.5513135805',
    gold_eth_corr: '-0.497327712',
    gold_jpy_corr: '-0.8998913731',
    btc_eth_corr: '0.9773143572',
    btc_jpy_corr: '0.5534441129',
    eth_jpy_corr: '0.5117266742',
  },
  {
    date: '2023-01-06',
    crude: '82.44795406',
    gold: '107.424598',
    btc: '83.6720257',
    eth: '84.18845189',
    jpy: '97.61575491',
    gold_crude_corr: '-0.7732018478',
    crude_btc_corr: '0.4611428831',
    crude_eth_corr: '0.432482007',
    crude_jpy_corr: '0.8341724474',
    gold_btc_corr: '-0.4647582945',
    gold_eth_corr: '-0.388749038',
    gold_jpy_corr: '-0.8810587325',
    btc_eth_corr: '0.9699790445',
    btc_jpy_corr: '0.4871113572',
    eth_jpy_corr: '0.431041898',
  },
  {
    date: '2023-01-07',
    crude: '82.44795406',
    gold: '107.424598',
    btc: '83.68737305',
    eth: '83.8496337',
    jpy: '97.61575491',
    gold_crude_corr: '-0.7502837051',
    crude_btc_corr: '0.3681053049',
    crude_eth_corr: '0.3284929458',
    crude_jpy_corr: '0.8197528852',
    gold_btc_corr: '-0.3466395797',
    gold_eth_corr: '-0.2456483973',
    gold_jpy_corr: '-0.8603105447',
    btc_eth_corr: '0.9567168816',
    btc_jpy_corr: '0.3898463551',
    eth_jpy_corr: '0.3166692971',
  },
  {
    date: '2023-01-08',
    crude: '82.44795406',
    gold: '107.424598',
    btc: '84.35897362',
    eth: '85.3809624',
    jpy: '97.61575491',
    gold_crude_corr: '-0.7229145875',
    crude_btc_corr: '0.2214786571',
    crude_eth_corr: '0.1762208845',
    crude_jpy_corr: '0.8012090473',
    gold_btc_corr: '-0.164940315',
    gold_eth_corr: '-0.04123223158',
    gold_jpy_corr: '-0.836481841',
    btc_eth_corr: '0.9375265301',
    btc_jpy_corr: '0.2334506071',
    eth_jpy_corr: '0.1478461874',
  },
  {
    date: '2023-01-09',
    crude: '82.44795406',
    gold: '107.7146168',
    btc: '84.87926017',
    eth: '87.64783018',
    jpy: '96.55762988',
    gold_crude_corr: '-0.6827629255',
    crude_btc_corr: '-0.0860227274',
    crude_eth_corr: '-0.1163838032',
    crude_jpy_corr: '0.7808470394',
    gold_btc_corr: '0.2137951326',
    gold_eth_corr: '0.3526561844',
    gold_jpy_corr: '-0.8179035373',
    btc_eth_corr: '0.883593777',
    btc_jpy_corr: '-0.0236717035',
    eth_jpy_corr: '-0.1248598841',
  },
  {
    date: '2023-01-10',
    crude: '81.47882268',
    gold: '108.1206475',
    btc: '86.11192572',
    eth: '88.64579347',
    jpy: '96.46024215',
    gold_crude_corr: '-0.6603509078',
    crude_btc_corr: '-0.3701195839',
    crude_eth_corr: '-0.2725358005',
    crude_jpy_corr: '0.756852027',
    gold_btc_corr: '0.4586432118',
    gold_eth_corr: '0.5011965686',
    gold_jpy_corr: '-0.8043215651',
    btc_eth_corr: '0.9062456756',
    btc_jpy_corr: '-0.2644851227',
    eth_jpy_corr: '-0.2677531962',
  },
  {
    date: '2023-01-11',
    crude: '81.73007897',
    gold: '108.0046467',
    btc: '88.52358931',
    eth: '92.05123611',
    jpy: '96.73263951',
    gold_crude_corr: '-0.6328720644',
    crude_btc_corr: '-0.3271224136',
    crude_eth_corr: '-0.2230088712',
    crude_jpy_corr: '0.7355379986',
    gold_btc_corr: '0.4631462787',
    gold_eth_corr: '0.5050756337',
    gold_jpy_corr: '-0.7865066215',
    btc_eth_corr: '0.9095511736',
    btc_jpy_corr: '-0.2262855149',
    eth_jpy_corr: '-0.2267333486',
  },
  {
    date: '2023-01-12',
    crude: '84.49389806',
    gold: '109.2807448',
    btc: '93.13706663',
    eth: '94.04129307',
    jpy: '96.58691547',
    gold_crude_corr: '-0.614733147',
    crude_btc_corr: '-0.3977315644',
    crude_eth_corr: '-0.2919811163',
    crude_jpy_corr: '0.7083674777',
    gold_btc_corr: '0.5922816666',
    gold_eth_corr: '0.6020773515',
    gold_jpy_corr: '-0.7973370122',
    btc_eth_corr: '0.9179082594',
    btc_jpy_corr: '-0.3757929339',
    eth_jpy_corr: '-0.3462262188',
  },
  {
    date: '2023-01-13',
    crude: '83.66834171',
    gold: '110.5568429',
    btc: '98.27026172',
    eth: '96.27479718',
    jpy: '94.58784131',
    gold_crude_corr: '-0.6059164452',
    crude_btc_corr: '-0.3916911964',
    crude_eth_corr: '-0.3513115343',
    crude_jpy_corr: '0.6892761579',
    gold_btc_corr: '0.6682904713',
    gold_eth_corr: '0.6821903306',
    gold_jpy_corr: '-0.8126727002',
    btc_eth_corr: '0.9155862775',
    btc_jpy_corr: '-0.4589841683',
    eth_jpy_corr: '-0.4466176948',
  },
  {
    date: '2023-01-14',
    crude: '83.66834171',
    gold: '110.5568429',
    btc: '103.5354324',
    eth: '102.8468339',
    jpy: '94.58784131',
    gold_crude_corr: '-0.6025387969',
    crude_btc_corr: '-0.3682673748',
    crude_eth_corr: '-0.3720839444',
    crude_jpy_corr: '0.6687843387',
    gold_btc_corr: '0.7027863504',
    gold_eth_corr: '0.7317934549',
    gold_jpy_corr: '-0.8271771053',
    btc_eth_corr: '0.9371716692',
    btc_jpy_corr: '-0.5040945504',
    eth_jpy_corr: '-0.5144872109',
  },
  {
    date: '2023-01-15',
    crude: '83.66834171',
    gold: '110.5568429',
    btc: '103.0640607',
    eth: '102.9643961',
    jpy: '94.58784131',
    gold_crude_corr: '-0.6038873459',
    crude_btc_corr: '-0.3508114516',
    crude_eth_corr: '-0.3799829012',
    crude_jpy_corr: '0.646263872',
    gold_btc_corr: '0.733184876',
    gold_eth_corr: '0.7636888978',
    gold_jpy_corr: '-0.8409860848',
    btc_eth_corr: '0.9517708377',
    btc_jpy_corr: '-0.53609049',
    eth_jpy_corr: '-0.5584695447',
  },
  {
    date: '2023-01-16',
    crude: '83.81191673',
    gold: '110.5568429',
    btc: '104.489696',
    eth: '104.5796157',
    jpy: '93.74867106',
    gold_crude_corr: '-0.6081300356',
    crude_btc_corr: '-0.3487387424',
    crude_eth_corr: '-0.3954625079',
    crude_jpy_corr: '0.6367615963',
    gold_btc_corr: '0.7569058504',
    gold_eth_corr: '0.7884007289',
    gold_jpy_corr: '-0.8532797011',
    btc_eth_corr: '0.9620562505',
    btc_jpy_corr: '-0.5807921892',
    eth_jpy_corr: '-0.6060672096',
  },
  {
    date: '2023-01-17',
    crude: '84.60157933',
    gold: '109.8607934',
    btc: '104.4496502',
    eth: '103.9835466',
    jpy: '93.96468723',
    gold_crude_corr: '-0.6085069878',
    crude_btc_corr: '-0.3460500067',
    crude_eth_corr: '-0.4014766072',
    crude_jpy_corr: '0.6159375955',
    gold_btc_corr: '0.771050915',
    gold_eth_corr: '0.8034081567',
    gold_jpy_corr: '-0.8655199319',
    btc_eth_corr: '0.9678269937',
    btc_jpy_corr: '-0.6185488621',
    eth_jpy_corr: '-0.645564017',
  },
  {
    date: '2023-01-18',
    crude: '88.04737976',
    gold: '109.5707636',
    btc: '102.1162947',
    eth: '100.5122834',
    jpy: '93.93540163',
    gold_crude_corr: '-0.5878783379',
    crude_btc_corr: '-0.3053004531',
    crude_eth_corr: '-0.3700687938',
    crude_jpy_corr: '0.578576291',
    gold_btc_corr: '0.783368641',
    gold_eth_corr: '0.814722206',
    gold_jpy_corr: '-0.8727722146',
    btc_eth_corr: '0.9700351035',
    btc_jpy_corr: '-0.6455056993',
    eth_jpy_corr: '-0.669431983',
  },
  {
    date: '2023-01-19',
    crude: '83.2017229',
    gold: '111.2529035',
    btc: '104.0808126',
    eth: '102.9695047',
    jpy: '94.1243222',
    gold_crude_corr: '-0.5842827528',
    crude_btc_corr: '-0.3179592161',
    crude_eth_corr: '-0.3767386124',
    crude_jpy_corr: '0.5704881756',
    gold_btc_corr: '0.8038487497',
    gold_eth_corr: '0.8307635292',
    gold_jpy_corr: '-0.8769407765',
    btc_eth_corr: '0.9729605591',
    btc_jpy_corr: '-0.6667821827',
    eth_jpy_corr: '-0.6874468636',
  },
  {
    date: '2023-01-20',
    crude: '86.4321608',
    gold: '110.9628736',
    btc: '111.9275956',
    eth: '110.0791248',
    jpy: '94.00056515',
    gold_crude_corr: '-0.5463427051',
    crude_btc_corr: '-0.2742904311',
    crude_eth_corr: '-0.3361393247',
    crude_jpy_corr: '0.5307884076',
    gold_btc_corr: '0.8128287',
    gold_eth_corr: '0.8409833546',
    gold_jpy_corr: '-0.8802901855',
    btc_eth_corr: '0.976589956',
    btc_jpy_corr: '-0.6818009171',
    eth_jpy_corr: '-0.7037858007',
  },
  {
    date: '2023-01-21',
    crude: '86.4321608',
    gold: '110.9628736',
    btc: '112.4264711',
    eth: '107.914623',
    jpy: '94.00056515',
    gold_crude_corr: '-0.5075916825',
    crude_btc_corr: '-0.2370435838',
    crude_eth_corr: '-0.3065308811',
    crude_jpy_corr: '0.4872707059',
    gold_btc_corr: '0.8225707209',
    gold_eth_corr: '0.8531510731',
    gold_jpy_corr: '-0.8831333517',
    btc_eth_corr: '0.9770703602',
    btc_jpy_corr: '-0.697451036',
    eth_jpy_corr: '-0.7220799745',
  },
  {
    date: '2023-01-22',
    crude: '86.4321608',
    gold: '110.9628736',
    btc: '112.1440973',
    eth: '107.9984491',
    jpy: '94.00056515',
    gold_crude_corr: '-0.4669607716',
    crude_btc_corr: '-0.1937035791',
    crude_eth_corr: '-0.2559369102',
    crude_jpy_corr: '0.438532343',
    gold_btc_corr: '0.8299408241',
    gold_eth_corr: '0.8586733852',
    gold_jpy_corr: '-0.8855350261',
    btc_eth_corr: '0.9789855196',
    btc_jpy_corr: '-0.7087327991',
    eth_jpy_corr: '-0.7290935618',
  },
  {
    date: '2023-01-23',
    crude: '87.58076095',
    gold: '111.1368915',
    btc: '113.2004419',
    eth: '107.9897621',
    jpy: '94.72111813',
    gold_crude_corr: '-0.411714265',
    crude_btc_corr: '-0.130434533',
    crude_eth_corr: '-0.1913473497',
    crude_jpy_corr: '0.3907465781',
    gold_btc_corr: '0.835190339',
    gold_eth_corr: '0.8626717814',
    gold_jpy_corr: '-0.8834961934',
    btc_eth_corr: '0.9799505196',
    btc_jpy_corr: '-0.7064341457',
    eth_jpy_corr: '-0.7262709367',
  },
  {
    date: '2023-01-24',
    crude: '87.97559225',
    gold: '111.5429223',
    btc: '111.7297479',
    eth: '103.2379604',
    jpy: '95.63131795',
    gold_crude_corr: '-0.3403084823',
    crude_btc_corr: '-0.06543574442',
    crude_eth_corr: '-0.1254789214',
    crude_jpy_corr: '0.3149311777',
    gold_btc_corr: '0.8452283942',
    gold_eth_corr: '0.8669207253',
    gold_jpy_corr: '-0.8780085199',
    btc_eth_corr: '0.9780006955',
    btc_jpy_corr: '-0.7063270036',
    eth_jpy_corr: '-0.7242569468',
  },
  {
    date: '2023-01-25',
    crude: '87.15003589',
    gold: '112.0649649',
    btc: '114.1058099',
    eth: '106.8927846',
    jpy: '95.36916664',
    gold_crude_corr: '-0.2768817701',
    crude_btc_corr: '-0.01541107635',
    crude_eth_corr: '-0.07143529307',
    crude_jpy_corr: '0.2364778206',
    gold_btc_corr: '0.8567378256',
    gold_eth_corr: '0.8746634547',
    gold_jpy_corr: '-0.8743182647',
    btc_eth_corr: '0.9782629483',
    btc_jpy_corr: '-0.7112708132',
    eth_jpy_corr: '-0.7279209653',
  },
  {
    date: '2023-01-26',
    crude: '86.25269203',
    gold: '111.0208796',
    btc: '113.6858595',
    eth: '106.3220722',
    jpy: '94.63544017',
    gold_crude_corr: '-0.2428739549',
    crude_btc_corr: '0.008686844285',
    crude_eth_corr: '-0.04631337584',
    crude_jpy_corr: '0.2001882685',
    gold_btc_corr: '0.8629593683',
    gold_eth_corr: '0.8807227568',
    gold_jpy_corr: '-0.8739131408',
    btc_eth_corr: '0.9782123886',
    btc_jpy_corr: '-0.7194796073',
    eth_jpy_corr: '-0.7357437294',
  },
  {
    date: '2023-01-27',
    crude: '87.54486719',
    gold: '110.9048787',
    btc: '113.9126667',
    eth: '105.9938112',
    jpy: '95.04770669',
    gold_crude_corr: '-0.1903183802',
    crude_btc_corr: '0.0550635198',
    crude_eth_corr: '-0.00187693779',
    crude_jpy_corr: '0.1502627831',
    gold_btc_corr: '0.8676765084',
    gold_eth_corr: '0.8854734232',
    gold_jpy_corr: '-0.8718661051',
    btc_eth_corr: '0.9779616556',
    btc_jpy_corr: '-0.7215366876',
    eth_jpy_corr: '-0.7387333047',
  },
  {
    date: '2023-01-28',
    crude: '87.54486719',
    gold: '110.9048787',
    btc: '113.6775303',
    eth: '104.2879001',
    jpy: '95.04770669',
    gold_crude_corr: '-0.1353133457',
    crude_btc_corr: '0.1020734272',
    crude_eth_corr: '0.0403809025',
    crude_jpy_corr: '0.09641861243',
    gold_btc_corr: '0.8721554778',
    gold_eth_corr: '0.8902865288',
    gold_jpy_corr: '-0.8694587388',
    btc_eth_corr: '0.9766378247',
    btc_jpy_corr: '-0.7236199488',
    eth_jpy_corr: '-0.7426009184',
  },
  {
    date: '2023-01-29',
    crude: '87.54486719',
    gold: '110.9048787',
    btc: '117.3472038',
    eth: '109.1772369',
    jpy: '95.04770669',
    gold_crude_corr: '-0.07723863448',
    crude_btc_corr: '0.1527176211',
    crude_eth_corr: '0.09148046323',
    crude_jpy_corr: '0.03792488837',
    gold_btc_corr: '0.8742815106',
    gold_eth_corr: '0.8931814054',
    gold_jpy_corr: '-0.8666423935',
    btc_eth_corr: '0.9772461693',
    btc_jpy_corr: '-0.7231184922',
    eth_jpy_corr: '-0.7430529081',
  },
  {
    date: '2023-01-30',
    crude: '85.42713568',
    gold: '110.6148489',
    btc: '112.7350279',
    eth: '103.94909',
    jpy: '95.0872495',
    gold_crude_corr: '-0.08249547939',
    crude_btc_corr: '0.1474881823',
    crude_eth_corr: '0.08820291679',
    crude_jpy_corr: '0.04360959006',
    gold_btc_corr: '0.8790330943',
    gold_eth_corr: '0.8962536767',
    gold_jpy_corr: '-0.8616907298',
    btc_eth_corr: '0.976725981',
    btc_jpy_corr: '-0.7250338765',
    eth_jpy_corr: '-0.7432339005',
  },
  {
    date: '2023-01-31',
    crude: '84.02727925',
    gold: '110.9628736',
    btc: '114.2115543',
    eth: '105.2230707',
    jpy: '95.54491372',
    gold_crude_corr: '-0.04989376816',
    crude_btc_corr: '0.1551703077',
    crude_eth_corr: '0.09943744492',
    crude_jpy_corr: '-0.0008128359527',
    gold_btc_corr: '0.8843652306',
    gold_eth_corr: '0.9023930544',
    gold_jpy_corr: '-0.8547680914',
    btc_eth_corr: '0.9763543681',
    btc_jpy_corr: '-0.7234267402',
    eth_jpy_corr: '-0.7450830374',
  },
  {
    date: '2023-02-01',
    crude: '85.60660445',
    gold: '112.4129897',
    btc: '117.0964791',
    eth: '108.8878772',
    jpy: '95.27470635',
    gold_crude_corr: '-0.01013570123',
    crude_btc_corr: '0.173832997',
    crude_eth_corr: '0.1126103824',
    crude_jpy_corr: '-0.05718305848',
    gold_btc_corr: '0.8930881873',
    gold_eth_corr: '0.9133854292',
    gold_jpy_corr: '-0.8492058659',
    btc_eth_corr: '0.9774916455',
    btc_jpy_corr: '-0.7295504594',
    eth_jpy_corr: '-0.7589840946',
  },
  {
    date: '2023-02-02',
    crude: '82.37616655',
    gold: '110.0348003',
    btc: '115.8531514',
    eth: '108.9839688',
    jpy: '94.18509456',
    gold_crude_corr: '0.00144219789',
    crude_btc_corr: '0.1673964899',
    crude_eth_corr: '0.1011264644',
    crude_jpy_corr: '-0.09493672826',
    gold_btc_corr: '0.8908611839',
    gold_eth_corr: '0.9125825263',
    gold_jpy_corr: '-0.8526934005',
    btc_eth_corr: '0.9786026682',
    btc_jpy_corr: '-0.7385871761',
    eth_jpy_corr: '-0.7707772033',
  },
  {
    date: '2023-02-03',
    crude: '80.90452261',
    gold: '107.3085861',
    btc: '115.7418542',
    eth: '110.4101708',
    jpy: '94.27590113',
    gold_crude_corr: '0.0292779573',
    crude_btc_corr: '0.1412310097',
    crude_eth_corr: '0.0659775776',
    crude_jpy_corr: '-0.09212400516',
    gold_btc_corr: '0.8740897611',
    gold_eth_corr: '0.8968248394',
    gold_jpy_corr: '-0.8438652123',
    btc_eth_corr: '0.9799835496',
    btc_jpy_corr: '-0.7440669771',
    eth_jpy_corr: '-0.7771303156',
  },
  {
    date: '2023-02-04',
    crude: '80.90452261',
    gold: '107.3085861',
    btc: '115.1620196',
    eth: '110.5636146',
    jpy: '94.27590113',
    gold_crude_corr: '0.0610344685',
    crude_btc_corr: '0.1249914349',
    crude_eth_corr: '0.04690538331',
    crude_jpy_corr: '-0.09420503283',
    gold_btc_corr: '0.8590083871',
    gold_eth_corr: '0.8801382293',
    gold_jpy_corr: '-0.8351733954',
    btc_eth_corr: '0.9806435456',
    btc_jpy_corr: '-0.74840377',
    eth_jpy_corr: '-0.7807264483',
  },
  {
    date: '2023-02-05',
    crude: '80.90452261',
    gold: '107.3085861',
    btc: '113.3052512',
    eth: '108.2149123',
    jpy: '94.27590113',
    gold_crude_corr: '0.09752919437',
    crude_btc_corr: '0.1150916821',
    crude_eth_corr: '0.03011881502',
    crude_jpy_corr: '-0.1014751371',
    gold_btc_corr: '0.8475892349',
    gold_eth_corr: '0.8695170485',
    gold_jpy_corr: '-0.8265523153',
    btc_eth_corr: '0.9813133086',
    btc_jpy_corr: '-0.7524466285',
    eth_jpy_corr: '-0.7852586679',
  },
  {
    date: '2023-02-06',
    crude: '80.47379756',
    gold: '107.54061',
    btc: '112.3400169',
    eth: '107.1936244',
    jpy: '96.66527483',
    gold_crude_corr: '0.1497085342',
    crude_btc_corr: '0.1037958535',
    crude_eth_corr: '0.01591843372',
    crude_jpy_corr: '-0.1271512268',
    gold_btc_corr: '0.8412187074',
    gold_eth_corr: '0.8647841167',
    gold_jpy_corr: '-0.8276815748',
    btc_eth_corr: '0.9817156976',
    btc_jpy_corr: '-0.7413588981',
    eth_jpy_corr: '-0.7744138891',
  },
  {
    date: '2023-02-07',
    crude: '82.59152907',
    gold: '107.6566109',
    btc: '114.8285715',
    eth: '110.8915355',
    jpy: '97.11488299',
    gold_crude_corr: '0.185330549',
    crude_btc_corr: '0.1006607267',
    crude_eth_corr: '0.008852420757',
    crude_jpy_corr: '-0.1665901879',
    gold_btc_corr: '0.8329701665',
    gold_eth_corr: '0.8563634267',
    gold_jpy_corr: '-0.8203071769',
    btc_eth_corr: '0.9822195156',
    btc_jpy_corr: '-0.7238248678',
    eth_jpy_corr: '-0.7563866897',
  },
  {
    date: '2023-02-08',
    crude: '83.95549174',
    gold: '107.8886347',
    btc: '113.2249572',
    eth: '109.4797445',
    jpy: '95.95718024',
    gold_crude_corr: '0.188102246',
    crude_btc_corr: '0.0962718222',
    crude_eth_corr: '0.004763063251',
    crude_jpy_corr: '-0.1703966833',
    gold_btc_corr: '0.8253282702',
    gold_eth_corr: '0.8472454707',
    gold_jpy_corr: '-0.8151023281',
    btc_eth_corr: '0.982416214',
    btc_jpy_corr: '-0.7178620638',
    eth_jpy_corr: '-0.74948753',
  },
  {
    date: '2023-02-09',
    crude: '85.5707107',
    gold: '107.0185673',
    btc: '107.6950544',
    eth: '102.5637243',
    jpy: '96.20980061',
    gold_crude_corr: '0.1760268772',
    crude_btc_corr: '0.09456106847',
    crude_eth_corr: '0.003724347403',
    crude_jpy_corr: '-0.1583071934',
    gold_btc_corr: '0.8193787945',
    gold_eth_corr: '0.8435975992',
    gold_jpy_corr: '-0.8091123016',
    btc_eth_corr: '0.9825307719',
    btc_jpy_corr: '-0.7143194977',
    eth_jpy_corr: '-0.748676521',
  },
  {
    date: '2023-02-10',
    crude: '84.81694185',
    gold: '107.2505801',
    btc: '106.8665485',
    eth: '100.4699817',
    jpy: '96.30646206',
    gold_crude_corr: '0.1515323353',
    crude_btc_corr: '0.07803287673',
    crude_eth_corr: '-0.01345151574',
    crude_jpy_corr: '-0.1246284897',
    gold_btc_corr: '0.8140546898',
    gold_eth_corr: '0.8406265161',
    gold_jpy_corr: '-0.8039358384',
    btc_eth_corr: '0.9822708989',
    btc_jpy_corr: '-0.7102361682',
    eth_jpy_corr: '-0.7474086654',
  },
  {
    date: '2023-02-11',
    crude: '84.81694185',
    gold: '107.2505801',
    btc: '107.9509078',
    eth: '102.1318667',
    jpy: '96.30646206',
    gold_crude_corr: '0.1245473104',
    crude_btc_corr: '0.05963736674',
    crude_eth_corr: '-0.03197873442',
    crude_jpy_corr: '-0.08667599828',
    gold_btc_corr: '0.8077419001',
    gold_eth_corr: '0.8364966706',
    gold_jpy_corr: '-0.7982944172',
    btc_eth_corr: '0.9821937295',
    btc_jpy_corr: '-0.70529476',
    eth_jpy_corr: '-0.7455342784',
  },
  {
    date: '2023-02-12',
    crude: '84.81694185',
    gold: '107.2505801',
    btc: '107.5428535',
    eth: '100.4808951',
    jpy: '96.30646206',
    gold_crude_corr: '0.09472207043',
    crude_btc_corr: '0.03885449892',
    crude_eth_corr: '-0.0532737182',
    crude_jpy_corr: '-0.04367188024',
    gold_btc_corr: '0.8014025203',
    gold_eth_corr: '0.8332035197',
    gold_jpy_corr: '-0.7921322712',
    btc_eth_corr: '0.9817894531',
    btc_jpy_corr: '-0.700075394',
    eth_jpy_corr: '-0.7441738651',
  },
  {
    date: '2023-02-13',
    crude: '86.57573582',
    gold: '106.6125255',
    btc: '107.6410688',
    eth: '99.95908274',
    jpy: '96.32989277',
    gold_crude_corr: '0.05040818434',
    crude_btc_corr: '0.0269594318',
    crude_eth_corr: '-0.06795635702',
    crude_jpy_corr: '0.005266191267',
    gold_btc_corr: '0.7935858601',
    gold_eth_corr: '0.8308017466',
    gold_jpy_corr: '-0.7813861369',
    btc_eth_corr: '0.981249174',
    btc_jpy_corr: '-0.6953164998',
    eth_jpy_corr: '-0.7445975204',
  },
  {
    date: '2023-02-14',
    crude: '86.8269921',
    gold: '106.7285375',
    btc: '109.678101',
    eth: '103.2559255',
    jpy: '96.90545915',
    gold_crude_corr: '0.04646418325',
    crude_btc_corr: '0.04045421721',
    crude_eth_corr: '-0.05550195407',
    crude_jpy_corr: '0.01101967319',
    gold_btc_corr: '0.7838148317',
    gold_eth_corr: '0.8245001543',
    gold_jpy_corr: '-0.7822444022',
    btc_eth_corr: '0.98138071',
    btc_jpy_corr: '-0.6932197041',
    eth_jpy_corr: '-0.7509771669',
  },
  {
    date: '2023-02-15',
    crude: '86.39626705',
    gold: '105.7424581',
    btc: '119.9793601',
    eth: '111.007098',
    jpy: '97.38435291',
    gold_crude_corr: '0.03252003284',
    crude_btc_corr: '0.05481959959',
    crude_eth_corr: '-0.04173793482',
    crude_jpy_corr: '0.02836818393',
    gold_btc_corr: '0.7506985904',
    gold_eth_corr: '0.797999692',
    gold_jpy_corr: '-0.7780649702',
    btc_eth_corr: '0.9815464332',
    btc_jpy_corr: '-0.6621241415',
    eth_jpy_corr: '-0.7273619051',
  },
  {
    date: '2023-02-16',
    crude: '88.04737976',
    gold: '105.6264462',
    btc: '116.6014405',
    eth: '108.7734644',
    jpy: '98.05217841',
    gold_crude_corr: '0.03024816884',
    crude_btc_corr: '0.09131610254',
    crude_eth_corr: '-0.006792158568',
    crude_jpy_corr: '0.04091059034',
    gold_btc_corr: '0.7238642131',
    gold_eth_corr: '0.7759371708',
    gold_jpy_corr: '-0.7696067993',
    btc_eth_corr: '0.9815499955',
    btc_jpy_corr: '-0.6231343092',
    eth_jpy_corr: '-0.69331519',
  },
  {
    date: '2023-02-17',
    crude: '86.89877961',
    gold: '105.974482',
    btc: '121.2516184',
    eth: '112.4023489',
    jpy: '98.17739919',
    gold_crude_corr: '0.03171082778',
    crude_btc_corr: '0.1213017925',
    crude_eth_corr: '0.02373570008',
    crude_jpy_corr: '0.04307540683',
    gold_btc_corr: '0.6874575474',
    gold_eth_corr: '0.7421985599',
    gold_jpy_corr: '-0.7625345912',
    btc_eth_corr: '0.9820435429',
    btc_jpy_corr: '-0.5688575311',
    eth_jpy_corr: '-0.6395959848',
  },
  {
    date: '2023-02-18',
    crude: '86.89877961',
    gold: '105.974482',
    btc: '121.6251411',
    eth: '112.2057777',
    jpy: '98.17739919',
    gold_crude_corr: '0.03293575515',
    crude_btc_corr: '0.1497955532',
    crude_eth_corr: '0.05227318219',
    crude_jpy_corr: '0.04610310137',
    gold_btc_corr: '0.6508366293',
    gold_eth_corr: '0.7088532853',
    gold_jpy_corr: '-0.7552079878',
    btc_eth_corr: '0.9822655438',
    btc_jpy_corr: '-0.5118872203',
    eth_jpy_corr: '-0.5848137995',
  },
  {
    date: '2023-02-19',
    crude: '86.89877961',
    gold: '105.974482',
    btc: '120.0770934',
    eth: '111.5171307',
    jpy: '98.17739919',
    gold_crude_corr: '0.03391928173',
    crude_btc_corr: '0.1760172446',
    crude_eth_corr: '0.07931599489',
    crude_jpy_corr: '0.05019353292',
    gold_btc_corr: '0.6160816989',
    gold_eth_corr: '0.6755118578',
    gold_jpy_corr: '-0.7477762975',
    btc_eth_corr: '0.9825871168',
    btc_jpy_corr: '-0.4529058938',
    eth_jpy_corr: '-0.5252008177',
  },
  {
    date: '2023-02-20',
    crude: '85.5707107',
    gold: '105.974482',
    btc: '122.5524408',
    eth: '112.9257562',
    jpy: '98.34801766',
    gold_crude_corr: '0.02340082991',
    crude_btc_corr: '0.1766829734',
    crude_eth_corr: '0.07903140066',
    crude_jpy_corr: '0.07187134461',
    gold_btc_corr: '0.5740472148',
    gold_eth_corr: '0.6358776391',
    gold_jpy_corr: '-0.7317822575',
    btc_eth_corr: '0.9827537231',
    btc_jpy_corr: '-0.3748708883',
    eth_jpy_corr: '-0.4483313341',
  },
  {
    date: '2023-02-21',
    crude: '85.39124192',
    gold: '105.5684513',
    btc: '120.6136721',
    eth: '109.9651977',
    jpy: '98.3692472',
    gold_crude_corr: '0.003901890067',
    crude_btc_corr: '0.1636803243',
    crude_eth_corr: '0.06295512724',
    crude_jpy_corr: '0.1264824227',
    gold_btc_corr: '0.5368241618',
    gold_eth_corr: '0.6050121059',
    gold_jpy_corr: '-0.7383319057',
    btc_eth_corr: '0.9821930466',
    btc_jpy_corr: '-0.299440488',
    eth_jpy_corr: '-0.3802211454',
  },
  {
    date: '2023-02-22',
    crude: '85.35534817',
    gold: '104.9303967',
    btc: '119.3920061',
    eth: '108.9833131',
    jpy: '98.83789491',
    gold_crude_corr: '-0.0187455507',
    crude_btc_corr: '0.1480400387',
    crude_eth_corr: '0.04355485122',
    crude_jpy_corr: '0.1341722212',
    gold_btc_corr: '0.4939407958',
    gold_eth_corr: '0.5687353702',
    gold_jpy_corr: '-0.7455236095',
    btc_eth_corr: '0.9815558917',
    btc_jpy_corr: '-0.2487551937',
    eth_jpy_corr: '-0.3377398732',
  },
  {
    date: '2023-02-23',
    crude: '85.35534817',
    gold: '104.9303967',
    btc: '118.2007359',
    eth: '109.5034253',
    jpy: '98.80568187',
    gold_crude_corr: '-0.01715947211',
    crude_btc_corr: '0.1553792942',
    crude_eth_corr: '0.05058409037',
    crude_jpy_corr: '0.1343644203',
    gold_btc_corr: '0.4476510146',
    gold_eth_corr: '0.5254439582',
    gold_jpy_corr: '-0.7550761204',
    btc_eth_corr: '0.9812554597',
    btc_jpy_corr: '-0.2014854952',
    eth_jpy_corr: '-0.2933431348',
  },
  {
    date: '2023-02-24',
    crude: '85.24766691',
    gold: '104.1763302',
    btc: '114.501997',
    eth: '106.6714072',
    jpy: '98.58307337',
    gold_crude_corr: '-0.01857536886',
    crude_btc_corr: '0.159641774',
    crude_eth_corr: '0.05380233894',
    crude_jpy_corr: '0.1344221113',
    gold_btc_corr: '0.4005573758',
    gold_eth_corr: '0.4812795335',
    gold_jpy_corr: '-0.768289065',
    btc_eth_corr: '0.9807667005',
    btc_jpy_corr: '-0.168375759',
    eth_jpy_corr: '-0.2628423385',
  },
  {
    date: '2023-02-25',
    crude: '85.24766691',
    gold: '104.1763302',
    btc: '114.3896972',
    eth: '105.7788216',
    jpy: '98.58307337',
    gold_crude_corr: '-0.02002065274',
    crude_btc_corr: '0.1640682167',
    crude_eth_corr: '0.0570163518',
    crude_jpy_corr: '0.1345275009',
    gold_btc_corr: '0.3524522598',
    gold_eth_corr: '0.4375618951',
    gold_jpy_corr: '-0.7820841899',
    btc_eth_corr: '0.9800715879',
    btc_jpy_corr: '-0.1376706625',
    eth_jpy_corr: '-0.2366079692',
  },
  {
    date: '2023-02-26',
    crude: '85.24766691',
    gold: '104.1763302',
    btc: '116.2941272',
    eth: '108.8231739',
    jpy: '98.58307337',
    gold_crude_corr: '-0.0214962256',
    crude_btc_corr: '0.1687381568',
    crude_eth_corr: '0.06071786049',
    crude_jpy_corr: '0.1346736484',
    gold_btc_corr: '0.298933843',
    gold_eth_corr: '0.3843968428',
    gold_jpy_corr: '-0.796447327',
    btc_eth_corr: '0.9796908234',
    btc_jpy_corr: '-0.1053400365',
    eth_jpy_corr: '-0.2050276155',
  },
  {
    date: '2023-02-27',
    crude: '85.92964824',
    gold: '104.524366',
    btc: '116.1048787',
    eth: '108.3926926',
    jpy: '99.87698039',
    gold_crude_corr: '-0.03466140589',
    crude_btc_corr: '0.1728670447',
    crude_eth_corr: '0.06293211402',
    crude_jpy_corr: '0.1463413668',
    gold_btc_corr: '0.2468474844',
    gold_eth_corr: '0.3333611251',
    gold_jpy_corr: '-0.8036682732',
    btc_eth_corr: '0.9791985435',
    btc_jpy_corr: '-0.05825035077',
    eth_jpy_corr: '-0.1576013968',
  },
  {
    date: '2023-02-28',
    crude: '85.71428571',
    gold: '105.1044146',
    btc: '114.251388',
    eth: '106.5070581',
    jpy: '99.81986176',
    gold_crude_corr: '-0.01160853187',
    crude_btc_corr: '0.2180834064',
    crude_eth_corr: '0.1086181079',
    crude_jpy_corr: '0.143064591',
    gold_btc_corr: '0.2046284573',
    gold_eth_corr: '0.2931072786',
    gold_jpy_corr: '-0.8052935618',
    btc_eth_corr: '0.9784549182',
    btc_jpy_corr: '-0.02028191457',
    eth_jpy_corr: '-0.1212053862',
  },
  {
    date: '2023-03-01',
    crude: '87.79612347',
    gold: '105.6844522',
    btc: '116.7153405',
    eth: '110.3231629',
    jpy: '99.84256619',
    gold_crude_corr: '0.008669002089',
    crude_btc_corr: '0.2851043975',
    crude_eth_corr: '0.1826678353',
    crude_jpy_corr: '0.1641075417',
    gold_btc_corr: '0.1536609404',
    gold_eth_corr: '0.2390650683',
    gold_jpy_corr: '-0.8009981416',
    btc_eth_corr: '0.9780417547',
    btc_jpy_corr: '0.03052334639',
    eth_jpy_corr: '-0.06446008977',
  },
  {
    date: '2023-03-02',
    crude: '87.83201723',
    gold: '105.6264462',
    btc: '115.8708991',
    eth: '109.2544162',
    jpy: '99.72979263',
    gold_crude_corr: '0.007283389362',
    crude_btc_corr: '0.3295291931',
    crude_eth_corr: '0.2317855883',
    crude_jpy_corr: '0.1874168135',
    gold_btc_corr: '0.1068350441',
    gold_eth_corr: '0.1902276404',
    gold_jpy_corr: '-0.795914193',
    btc_eth_corr: '0.9774598029',
    btc_jpy_corr: '0.08333376735',
    eth_jpy_corr: '-0.006708413382',
  },
  {
    date: '2023-03-03',
    crude: '88.44221106',
    gold: '106.7865435',
    btc: '110.3783718',
    eth: '104.0711941',
    jpy: '100.1039801',
    gold_crude_corr: '0.007488649039',
    crude_btc_corr: '0.3593827322',
    crude_eth_corr: '0.2645617636',
    crude_jpy_corr: '0.225995555',
    gold_btc_corr: '0.07094374561',
    gold_eth_corr: '0.153887414',
    gold_jpy_corr: '-0.7827947123',
    btc_eth_corr: '0.9766114418',
    btc_jpy_corr: '0.1083078599',
    eth_jpy_corr: '0.01899889267',
  },
  {
    date: '2023-03-04',
    crude: '88.44221106',
    gold: '106.7865435',
    btc: '110.3323201',
    eth: '103.9223893',
    jpy: '100.1039801',
    gold_crude_corr: '0.007304901698',
    crude_btc_corr: '0.390726575',
    crude_eth_corr: '0.299217847',
    crude_jpy_corr: '0.2604439888',
    gold_btc_corr: '0.03076429828',
    gold_eth_corr: '0.1127108852',
    gold_jpy_corr: '-0.772710781',
    btc_eth_corr: '0.9756416559',
    btc_jpy_corr: '0.1322855751',
    eth_jpy_corr: '0.04312805305',
  },
  {
    date: '2023-03-05',
    crude: '88.44221106',
    gold: '106.7865435',
    btc: '110.7378679',
    eth: '103.7595542',
    jpy: '100.1039801',
    gold_crude_corr: '0.006739220126',
    crude_btc_corr: '0.4238302143',
    crude_eth_corr: '0.335715681',
    crude_jpy_corr: '0.2913983408',
    gold_btc_corr: '-0.01380703355',
    gold_eth_corr: '0.06621566577',
    gold_jpy_corr: '-0.7653059158',
    btc_eth_corr: '0.9746228253',
    btc_jpy_corr: '0.1562207943',
    eth_jpy_corr: '0.06575987033',
  },
  {
    date: '2023-03-06',
    crude: '89.23187365',
    gold: '106.2064948',
    btc: '110.709458',
    eth: '103.9538747',
    jpy: '99.48521714',
    gold_crude_corr: '-0.004241909966',
    crude_btc_corr: '0.4554419084',
    crude_eth_corr: '0.3712306994',
    crude_jpy_corr: '0.3263683512',
    gold_btc_corr: '-0.06409257992',
    gold_eth_corr: '0.01380545424',
    gold_jpy_corr: '-0.7844622459',
    btc_eth_corr: '0.9732525855',
    btc_jpy_corr: '0.1510824586',
    eth_jpy_corr: '0.05404343485',
  },
  {
    date: '2023-03-07',
    crude: '90.91888011',
    gold: '104.3503481',
    btc: '109.6729917',
    eth: '103.5913838',
    jpy: '99.58699603',
    gold_crude_corr: '-0.05596802305',
    crude_btc_corr: '0.4763679951',
    crude_eth_corr: '0.3999568141',
    crude_jpy_corr: '0.3675133864',
    gold_btc_corr: '-0.1036718268',
    gold_eth_corr: '-0.027978226',
    gold_jpy_corr: '-0.8046735385',
    btc_eth_corr: '0.9718605878',
    btc_jpy_corr: '0.1410892605',
    eth_jpy_corr: '0.03691313604',
  },
  {
    date: '2023-03-08',
    crude: '88.76525485',
    gold: '104.3503481',
    btc: '107.1967381',
    eth: '101.7446425',
    jpy: '100.6063157',
    gold_crude_corr: '-0.09637814693',
    crude_btc_corr: '0.4511255517',
    crude_eth_corr: '0.3692059229',
    crude_jpy_corr: '0.3939517152',
    gold_btc_corr: '-0.1212617608',
    gold_eth_corr: '-0.04298058909',
    gold_jpy_corr: '-0.816152473',
    btc_eth_corr: '0.9691752655',
    btc_jpy_corr: '0.1267013935',
    eth_jpy_corr: '0.01665113008',
  },
  {
    date: '2023-03-09',
    crude: '87.25771716',
    gold: '105.3364274',
    btc: '100.5084001',
    eth: '95.41564815',
    jpy: '100.4854861',
    gold_crude_corr: '-0.1363159736',
    crude_btc_corr: '0.4007391502',
    crude_eth_corr: '0.3037297439',
    crude_jpy_corr: '0.413590982',
    gold_btc_corr: '-0.1540259832',
    gold_eth_corr: '-0.07226807428',
    gold_jpy_corr: '-0.8235751083',
    btc_eth_corr: '0.9657624698',
    btc_jpy_corr: '0.1054871881',
    eth_jpy_corr: '-0.01634519692',
  },
  {
    date: '2023-03-10',
    crude: '85.53481694',
    gold: '107.5986049',
    btc: '99.64079309',
    eth: '94.78540583',
    jpy: '99.87698039',
    gold_crude_corr: '-0.1420177038',
    crude_btc_corr: '0.3688310947',
    crude_eth_corr: '0.2612868586',
    crude_jpy_corr: '0.4213466388',
    gold_btc_corr: '-0.1683113182',
    gold_eth_corr: '-0.08276116483',
    gold_jpy_corr: '-0.8121532061',
    btc_eth_corr: '0.9614416366',
    btc_jpy_corr: '0.1014771761',
    eth_jpy_corr: '-0.03155169062',
  },
  {
    date: '2023-03-11',
    crude: '85.53481694',
    gold: '107.5986049',
    btc: '101.8380566',
    eth: '98.33091762',
    jpy: '99.87698039',
    gold_crude_corr: '-0.1480174193',
    crude_btc_corr: '0.3336692876',
    crude_eth_corr: '0.214089014',
    crude_jpy_corr: '0.4290158975',
    gold_btc_corr: '-0.1861417515',
    gold_eth_corr: '-0.09762799321',
    gold_jpy_corr: '-0.8015463978',
    btc_eth_corr: '0.9567360387',
    btc_jpy_corr: '0.102827238',
    eth_jpy_corr: '-0.03658355534',
  },
  {
    date: '2023-03-12',
    crude: '85.53481694',
    gold: '107.5986049',
    btc: '109.3974721',
    eth: '105.4722822',
    jpy: '99.87698039',
    gold_crude_corr: '-0.1543438085',
    crude_btc_corr: '0.2953258192',
    crude_eth_corr: '0.1613690162',
    crude_jpy_corr: '0.4366405223',
    gold_btc_corr: '-0.2100226668',
    gold_eth_corr: '-0.1171921539',
    gold_jpy_corr: '-0.7916838382',
    btc_eth_corr: '0.9491693186',
    btc_jpy_corr: '0.1243934873',
    eth_jpy_corr: '-0.01742641791',
  },
  {
    date: '2023-03-13',
    crude: '85.67839196',
    gold: '110.0348003',
    btc: '119.4348957',
    eth: '111.4423721',
    jpy: '98.84082235',
    gold_crude_corr: '-0.1568783425',
    crude_btc_corr: '0.2472814242',
    crude_eth_corr: '0.1012875339',
    crude_jpy_corr: '0.4378987789',
    gold_btc_corr: '-0.2130609115',
    gold_eth_corr: '-0.1117311504',
    gold_jpy_corr: '-0.7696412231',
    btc_eth_corr: '0.9409731463',
    btc_jpy_corr: '0.1412350336',
    eth_jpy_corr: '-0.009784512802',
  },
  {
    date: '2023-03-14',
    crude: '82.34027279',
    gold: '109.4547627',
    btc: '122.1424006',
    eth: '112.9809306',
    jpy: '97.47442203',
    gold_crude_corr: '-0.1706671983',
    crude_btc_corr: '0.1240630617',
    crude_eth_corr: '-0.031870092',
    crude_jpy_corr: '0.4300970222',
    gold_btc_corr: '-0.2091775224',
    gold_eth_corr: '-0.09932431941',
    gold_jpy_corr: '-0.764329839',
    btc_eth_corr: '0.9315391492',
    btc_jpy_corr: '0.1465516039',
    eth_jpy_corr: '-0.01624653457',
  },
  {
    date: '2023-03-15',
    crude: '80.86862886',
    gold: '110.2668241',
    btc: '120.3155846',
    eth: '109.842114',
    jpy: '98.41391862',
    gold_crude_corr: '-0.1992103041',
    crude_btc_corr: '-0.01390021193',
    crude_eth_corr: '-0.1485386823',
    crude_jpy_corr: '0.3979956813',
    gold_btc_corr: '-0.2044441282',
    gold_eth_corr: '-0.09031906784',
    gold_jpy_corr: '-0.746073048',
    btc_eth_corr: '0.9219781343',
    btc_jpy_corr: '0.1672095659',
    eth_jpy_corr: '-0.01256940179',
  },
  {
    date: '2023-03-16',
    crude: '75.6281407',
    gold: '110.4988369',
    btc: '123.6562927',
    eth: '111.2372029',
    jpy: '97.33895521',
    gold_crude_corr: '-0.2331003371',
    crude_btc_corr: '-0.149342715',
    crude_eth_corr: '-0.2284782613',
    crude_jpy_corr: '0.3356688822',
    gold_btc_corr: '-0.1560037355',
    gold_eth_corr: '-0.05094315387',
    gold_jpy_corr: '-0.7372370978',
    btc_eth_corr: '0.9115916844',
    btc_jpy_corr: '0.1717907436',
    eth_jpy_corr: '-0.01773985145',
  },
  {
    date: '2023-03-17',
    crude: '76.59727207',
    gold: '113.6890988',
    btc: '135.3598384',
    eth: '118.8821801',
    jpy: '97.66041516',
    gold_crude_corr: '-0.3109725874',
    crude_btc_corr: '-0.3290686189',
    crude_eth_corr: '-0.3717397659',
    crude_jpy_corr: '0.2848435769',
    gold_btc_corr: '0.01873693734',
    gold_eth_corr: '0.08946942742',
    gold_jpy_corr: '-0.6911311689',
    btc_eth_corr: '0.9144761157',
    btc_jpy_corr: '0.1402230563',
    eth_jpy_corr: '-0.04417284613',
  },
  {
    date: '2023-03-18',
    crude: '76.59727207',
    gold: '113.6890988',
    btc: '133.0989778',
    eth: '116.8377584',
    jpy: '97.66041516',
    gold_crude_corr: '-0.3732431485',
    crude_btc_corr: '-0.4387567803',
    crude_eth_corr: '-0.4454329829',
    crude_jpy_corr: '0.2447956951',
    gold_btc_corr: '0.1388576891',
    gold_eth_corr: '0.1727878451',
    gold_jpy_corr: '-0.6496735714',
    btc_eth_corr: '0.9208483721',
    btc_jpy_corr: '0.1210440313',
    eth_jpy_corr: '-0.04822205375',
  },
  {
    date: '2023-03-19',
    crude: '76.59727207',
    gold: '113.6890988',
    btc: '138.3941202',
    eth: '118.4239862',
    jpy: '97.66041516',
    gold_crude_corr: '-0.4244368079',
    crude_btc_corr: '-0.5382500389',
    crude_eth_corr: '-0.5125583232',
    crude_jpy_corr: '0.2123774886',
    gold_btc_corr: '0.2566769226',
    gold_eth_corr: '0.2526371149',
    gold_jpy_corr: '-0.6122184013',
    btc_eth_corr: '0.9258149666',
    btc_jpy_corr: '0.09961732304',
    eth_jpy_corr: '-0.05201280739',
  },
  {
    date: '2023-03-20',
    crude: '73.07968413',
    gold: '113.7471048',
    btc: '137.0543414',
    eth: '115.09093',
    jpy: '96.86664261',
    gold_crude_corr: '-0.4783739186',
    crude_btc_corr: '-0.6245836081',
    crude_eth_corr: '-0.5460949444',
    crude_jpy_corr: '0.1939228104',
    gold_btc_corr: '0.3471660214',
    gold_eth_corr: '0.3010290126',
    gold_jpy_corr: '-0.5898889913',
    btc_eth_corr: '0.9224110765',
    btc_jpy_corr: '0.05673476274',
    eth_jpy_corr: '-0.06896883478',
  },
  {
    date: '2023-03-21',
    crude: '73.07968413',
    gold: '111.6009282',
    btc: '139.0710229',
    eth: '119.8289766',
    jpy: '96.2134655',
    gold_crude_corr: '-0.4958456625',
    crude_btc_corr: '-0.6880292457',
    crude_eth_corr: '-0.6015319855',
    crude_jpy_corr: '0.2032989832',
    gold_btc_corr: '0.3934153556',
    gold_eth_corr: '0.338573989',
    gold_jpy_corr: '-0.5891945847',
    btc_eth_corr: '0.9313826239',
    btc_jpy_corr: '0.0005274499363',
    eth_jpy_corr: '-0.1018350393',
  },
  {
    date: '2023-03-22',
    crude: '76.48959081',
    gold: '113.5150809',
    btc: '134.7848528',
    eth: '115.2498224',
    jpy: '96.91351521',
    gold_crude_corr: '-0.5366784544',
    crude_btc_corr: '-0.7077478855',
    crude_eth_corr: '-0.6134032561',
    crude_jpy_corr: '0.2290665405',
    gold_btc_corr: '0.4537828825',
    gold_eth_corr: '0.3831817472',
    gold_jpy_corr: '-0.578637375',
    btc_eth_corr: '0.9283436474',
    btc_jpy_corr: '-0.04656189986',
    eth_jpy_corr: '-0.1432969179',
  },
  {
    date: '2023-03-23',
    crude: '76.52548457',
    gold: '114.965197',
    btc: '139.8516552',
    eth: '120.4686184',
    jpy: '96.05749541',
    gold_crude_corr: '-0.568805122',
    crude_btc_corr: '-0.7478641898',
    crude_eth_corr: '-0.6510425013',
    crude_jpy_corr: '0.2367010052',
    gold_btc_corr: '0.5376281591',
    gold_eth_corr: '0.455661641',
    gold_jpy_corr: '-0.5657750701',
    btc_eth_corr: '0.9349346746',
    btc_jpy_corr: '-0.1058381056',
    eth_jpy_corr: '-0.1861891448',
  },
  {
    date: '2023-03-24',
    crude: '75.80760948',
    gold: '113.6890988',
    btc: '135.7021651',
    eth: '116.2000758',
    jpy: '95.8275683',
    gold_crude_corr: '-0.6045206288',
    crude_btc_corr: '-0.7642568098',
    crude_eth_corr: '-0.67167351',
    crude_jpy_corr: '0.2711326931',
    gold_btc_corr: '0.5756476292',
    gold_eth_corr: '0.4765817516',
    gold_jpy_corr: '-0.5630133897',
    btc_eth_corr: '0.9415448998',
    btc_jpy_corr: '-0.1430120612',
    eth_jpy_corr: '-0.1941953153',
  },
  {
    date: '2023-03-25',
    crude: '75.80760948',
    gold: '113.6890988',
    btc: '135.7091832',
    eth: '115.6509232',
    jpy: '95.8275683',
    gold_crude_corr: '-0.6368739459',
    crude_btc_corr: '-0.7780611557',
    crude_eth_corr: '-0.6848993177',
    crude_jpy_corr: '0.307054',
    gold_btc_corr: '0.609020165',
    gold_eth_corr: '0.4988972668',
    gold_jpy_corr: '-0.5617105571',
    btc_eth_corr: '0.942436943',
    btc_jpy_corr: '-0.1794721479',
    eth_jpy_corr: '-0.2130780444',
  },
  {
    date: '2023-03-26',
    crude: '75.80760948',
    gold: '113.6890988',
    btc: '138.1752374',
    eth: '117.7674075',
    jpy: '95.8275683',
    gold_crude_corr: '-0.666452909',
    crude_btc_corr: '-0.7907503463',
    crude_eth_corr: '-0.7017781522',
    crude_jpy_corr: '0.3447836269',
    gold_btc_corr: '0.641503291',
    gold_eth_corr: '0.5246824024',
    gold_jpy_corr: '-0.5618642598',
    btc_eth_corr: '0.9458677841',
    btc_jpy_corr: '-0.2195954703',
    eth_jpy_corr: '-0.2356502391',
  },
  {
    date: '2023-03-27',
    crude: '75.55635319',
    gold: '112.5870076',
    btc: '133.9578604',
    eth: '113.7737328',
    jpy: '95.81364619',
    gold_crude_corr: '-0.6959606846',
    crude_btc_corr: '-0.8002072245',
    crude_eth_corr: '-0.7084633991',
    crude_jpy_corr: '0.3843927868',
    gold_btc_corr: '0.6637480932',
    gold_eth_corr: '0.5374671754',
    gold_jpy_corr: '-0.5616461462',
    btc_eth_corr: '0.9446435913',
    btc_jpy_corr: '-0.2498020126',
    eth_jpy_corr: '-0.250828966',
  },
  {
    date: '2023-03-28',
    crude: '79.00215363',
    gold: '113.5150809',
    btc: '134.5908419',
    eth: '117.5756615',
    jpy: '96.09703822',
    gold_crude_corr: '-0.7265285649',
    crude_btc_corr: '-0.8021192583',
    crude_eth_corr: '-0.7104454383',
    crude_jpy_corr: '0.4110413483',
    gold_btc_corr: '0.6936427349',
    gold_eth_corr: '0.5771498524',
    gold_jpy_corr: '-0.5595630277',
    btc_eth_corr: '0.9470622407',
    btc_jpy_corr: '-0.2737067451',
    eth_jpy_corr: '-0.2799983726',
  },
  {
    date: '2023-03-29',
    crude: '80.65326633',
    gold: '112.9350323',
    btc: '139.9230705',
    eth: '118.9164505',
    jpy: '95.96010768',
    gold_crude_corr: '-0.7518439796',
    crude_btc_corr: '-0.7932472051',
    crude_eth_corr: '-0.7090071103',
    crude_jpy_corr: '0.4356937046',
    gold_btc_corr: '0.7193991121',
    gold_eth_corr: '0.6040482175',
    gold_jpy_corr: '-0.5569932105',
    btc_eth_corr: '0.949172022',
    btc_jpy_corr: '-0.298873161',
    eth_jpy_corr: '-0.3019611832',
  },
  {
    date: '2023-03-30',
    crude: '80.4379038',
    gold: '113.9791176',
    btc: '138.3688819',
    eth: '118.8988984',
    jpy: '97.08046879',
    gold_crude_corr: '-0.7633528033',
    crude_btc_corr: '-0.7880047409',
    crude_eth_corr: '-0.7076075484',
    crude_jpy_corr: '0.4605741699',
    gold_btc_corr: '0.7429797503',
    gold_eth_corr: '0.630284043',
    gold_jpy_corr: '-0.5438632092',
    btc_eth_corr: '0.9512489351',
    btc_jpy_corr: '-0.3140852349',
    eth_jpy_corr: '-0.3170486724',
  },
  {
    date: '2023-03-31',
    crude: '81.87365398',
    gold: '113.399069',
    btc: '140.5649404',
    eth: '120.8411483',
    jpy: '97.538133',
    gold_crude_corr: '-0.775928018',
    crude_btc_corr: '-0.773704913',
    crude_eth_corr: '-0.6954580774',
    crude_jpy_corr: '0.4897429562',
    gold_btc_corr: '0.7624924498',
    gold_eth_corr: '0.654841583',
    gold_jpy_corr: '-0.528623836',
    btc_eth_corr: '0.9538939087',
    btc_jpy_corr: '-0.3187425453',
    eth_jpy_corr: '-0.3229420714',
  },
  {
    date: '2023-04-01',
    crude: '81.87365398',
    gold: '113.399069',
    btc: '140.2320225',
    eth: '120.2998569',
    jpy: '97.538133',
    gold_crude_corr: '-0.7885499782',
    crude_btc_corr: '-0.7610601519',
    crude_eth_corr: '-0.6833636432',
    crude_jpy_corr: '0.5215674206',
    gold_btc_corr: '0.7813091068',
    gold_eth_corr: '0.6824177663',
    gold_jpy_corr: '-0.5146039773',
    btc_eth_corr: '0.9564603757',
    btc_jpy_corr: '-0.3269572037',
    eth_jpy_corr: '-0.3394612951',
  },
  {
    date: '2023-04-02',
    crude: '81.87365398',
    gold: '113.399069',
    btc: '139.1869764',
    eth: '119.0959391',
    jpy: '97.538133',
    gold_crude_corr: '-0.801258034',
    crude_btc_corr: '-0.7541834645',
    crude_eth_corr: '-0.6820753766',
    crude_jpy_corr: '0.5563291464',
    gold_btc_corr: '0.794251524',
    gold_eth_corr: '0.695856984',
    gold_jpy_corr: '-0.5017730895',
    btc_eth_corr: '0.9581105643',
    btc_jpy_corr: '-0.3305827705',
    eth_jpy_corr: '-0.3406763267',
  },
  {
    date: '2023-04-03',
    crude: '87.2218234',
    gold: '114.2111415',
    btc: '137.1677883',
    eth: '120.0635181',
    jpy: '97.64504051',
    gold_crude_corr: '-0.7648830263',
    crude_btc_corr: '-0.7152717326',
    crude_eth_corr: '-0.6399752619',
    crude_jpy_corr: '0.5755960699',
    gold_btc_corr: '0.8117183048',
    gold_eth_corr: '0.7220213713',
    gold_jpy_corr: '-0.4875411657',
    btc_eth_corr: '0.9595507419',
    btc_jpy_corr: '-0.3463299559',
    eth_jpy_corr: '-0.3622083482',
  },
  {
    date: '2023-04-04',
    crude: '88.8011486',
    gold: '116.357307',
    btc: '139.0328859',
    eth: '124.0898279',
    jpy: '96.87762611',
    gold_crude_corr: '-0.6907428729',
    crude_btc_corr: '-0.6636741648',
    crude_eth_corr: '-0.5697880728',
    crude_jpy_corr: '0.5694944553',
    gold_btc_corr: '0.828668614',
    gold_eth_corr: '0.7537353552',
    gold_jpy_corr: '-0.4792219912',
    btc_eth_corr: '0.9591089847',
    btc_jpy_corr: '-0.3657773421',
    eth_jpy_corr: '-0.3836549081',
  },
  {
    date: '2023-04-05',
    crude: '88.47810481',
    gold: '116.2413062',
    btc: '139.0817236',
    eth: '126.6173063',
    jpy: '96.28156763',
    gold_crude_corr: '-0.6391589081',
    crude_btc_corr: '-0.6177946788',
    crude_eth_corr: '-0.4969079757',
    crude_jpy_corr: '0.5675273981',
    gold_btc_corr: '0.8468542786',
    gold_eth_corr: '0.7804967208',
    gold_jpy_corr: '-0.4764722782',
    btc_eth_corr: '0.9556740765',
    btc_jpy_corr: '-0.3902965262',
    eth_jpy_corr: '-0.4059577158',
  },
  {
    date: '2023-04-06',
    crude: '87.04235463',
    gold: '115.3712277',
    btc: '138.4210937',
    eth: '124.2170001',
    jpy: '95.87809461',
    gold_crude_corr: '-0.5985303308',
    crude_btc_corr: '-0.5905221416',
    crude_eth_corr: '-0.4572834993',
    crude_jpy_corr: '0.5583833392',
    gold_btc_corr: '0.8558761166',
    gold_eth_corr: '0.793509224',
    gold_jpy_corr: '-0.4981349371',
    btc_eth_corr: '0.9557563475',
    btc_jpy_corr: '-0.4351172781',
    eth_jpy_corr: '-0.4460000383',
  },
  {
    date: '2023-04-07',
    crude: '87.43718593',
    gold: '115.3712277',
    btc: '137.8372777',
    eth: '123.7337409',
    jpy: '96.41630818',
    gold_crude_corr: '-0.5655613368',
    crude_btc_corr: '-0.5683774883',
    crude_eth_corr: '-0.4210495708',
    crude_jpy_corr: '0.5436767357',
    gold_btc_corr: '0.8592909486',
    gold_eth_corr: '0.8037978924',
    gold_jpy_corr: '-0.5409273289',
    btc_eth_corr: '0.9570297459',
    btc_jpy_corr: '-0.4776071682',
    eth_jpy_corr: '-0.4737717921',
  },
  {
    date: '2023-04-08',
    crude: '87.43718593',
    gold: '115.3712277',
    btc: '137.9455478',
    eth: '122.6634316',
    jpy: '96.41630818',
    gold_crude_corr: '-0.5366010495',
    crude_btc_corr: '-0.5493238369',
    crude_eth_corr: '-0.3917846494',
    crude_jpy_corr: '0.5283402459',
    gold_btc_corr: '0.8623847126',
    gold_eth_corr: '0.8124139882',
    gold_jpy_corr: '-0.5887499766',
    btc_eth_corr: '0.9594926136',
    btc_jpy_corr: '-0.5267515283',
    eth_jpy_corr: '-0.5045626323',
  },
  {
    date: '2023-04-09',
    crude: '87.43718593',
    gold: '115.3712277',
    btc: '139.847105',
    eth: '123.3193383',
    jpy: '96.41630818',
    gold_crude_corr: '-0.5112173399',
    crude_btc_corr: '-0.5314931718',
    crude_eth_corr: '-0.3670009871',
    crude_jpy_corr: '0.5122681583',
    gold_btc_corr: '0.8662553215',
    gold_eth_corr: '0.81903604',
    gold_jpy_corr: '-0.6425149843',
    btc_eth_corr: '0.9611352516',
    btc_jpy_corr: '-0.5886563271',
    eth_jpy_corr: '-0.5522615818',
  },
  {
    date: '2023-04-10',
    crude: '88.51399856',
    gold: '114.5591663',
    btc: '146.3620527',
    eth: '126.756153',
    jpy: '96.72971207',
    gold_crude_corr: '-0.4878053545',
    crude_btc_corr: '-0.4986108429',
    crude_eth_corr: '-0.3303685331',
    crude_jpy_corr: '0.4900198667',
    gold_btc_corr: '0.8693214953',
    gold_eth_corr: '0.822606235',
    gold_jpy_corr: '-0.6558746646',
    btc_eth_corr: '0.9634023058',
    btc_jpy_corr: '-0.6074131998',
    eth_jpy_corr: '-0.5660588155',
  },
  {
    date: '2023-04-11',
    crude: '88.62167983',
    gold: '115.3132217',
    btc: '149.235091',
    eth: '125.4948426',
    jpy: '97.73437218',
    gold_crude_corr: '-0.456313985',
    crude_btc_corr: '-0.4537128273',
    crude_eth_corr: '-0.2935579265',
    crude_jpy_corr: '0.4845881902',
    gold_btc_corr: '0.8727980615',
    gold_eth_corr: '0.8295041663',
    gold_jpy_corr: '-0.6515482583',
    btc_eth_corr: '0.9670867847',
    btc_jpy_corr: '-0.5980997353',
    eth_jpy_corr: '-0.5556395962',
  },
  {
    date: '2023-04-12',
    crude: '90.12921752',
    gold: '115.8932703',
    btc: '148.7612225',
    eth: '127.3845332',
    jpy: '97.82590502',
    gold_crude_corr: '-0.4115808758',
    crude_btc_corr: '-0.4009127494',
    crude_eth_corr: '-0.2438146736',
    crude_jpy_corr: '0.4845145444',
    gold_btc_corr: '0.8779241458',
    gold_eth_corr: '0.8359582004',
    gold_jpy_corr: '-0.6573495165',
    btc_eth_corr: '0.9705053886',
    btc_jpy_corr: '-0.6089575795',
    eth_jpy_corr: '-0.5537475099',
  },
  {
    date: '2023-04-13',
    crude: '91.1701364',
    gold: '117.3433864',
    btc: '150.0446056',
    eth: '133.4830583',
    jpy: '97.45319249',
    gold_crude_corr: '-0.3507908158',
    crude_btc_corr: '-0.3395145663',
    crude_eth_corr: '-0.1679303943',
    crude_jpy_corr: '0.4805315326',
    gold_btc_corr: '0.8849256796',
    gold_eth_corr: '0.8439240818',
    gold_jpy_corr: '-0.6684426362',
    btc_eth_corr: '0.9688683828',
    btc_jpy_corr: '-0.6358031844',
    eth_jpy_corr: '-0.569053167',
  },
  {
    date: '2023-04-14',
    crude: '90.48815506',
    gold: '115.3712277',
    btc: '150.4722104',
    eth: '139.3858218',
    jpy: '97.0152941',
    gold_crude_corr: '-0.3153188916',
    crude_btc_corr: '-0.2914531725',
    crude_eth_corr: '-0.0942839995',
    crude_jpy_corr: '0.4675290026',
    gold_btc_corr: '0.8890085093',
    gold_eth_corr: '0.8359849756',
    gold_jpy_corr: '-0.6891551576',
    btc_eth_corr: '0.960268229',
    btc_jpy_corr: '-0.6722717944',
    eth_jpy_corr: '-0.5932269217',
  },
  {
    date: '2023-04-15',
    crude: '90.48815506',
    gold: '115.3712277',
    btc: '149.6469243',
    eth: '138.7777302',
    jpy: '97.0152941',
    gold_crude_corr: '-0.2828750841',
    crude_btc_corr: '-0.2494421666',
    crude_eth_corr: '-0.03378106363',
    crude_jpy_corr: '0.4547673475',
    gold_btc_corr: '0.8925479669',
    gold_eth_corr: '0.830520629',
    gold_jpy_corr: '-0.7110952008',
    btc_eth_corr: '0.9542704035',
    btc_jpy_corr: '-0.7091809389',
    eth_jpy_corr: '-0.6166384148',
  },
  {
    date: '2023-04-16',
    crude: '90.48815506',
    gold: '115.3712277',
    btc: '149.6314227',
    eth: '140.604191',
    jpy: '97.0152941',
    gold_crude_corr: '-0.2531542022',
    crude_btc_corr: '-0.2115288931',
    crude_eth_corr: '0.02189040524',
    crude_jpy_corr: '0.442202102',
    gold_btc_corr: '0.8961772742',
    gold_eth_corr: '0.8250423946',
    gold_jpy_corr: '-0.7343073905',
    btc_eth_corr: '0.9471468513',
    btc_jpy_corr: '-0.7495315756',
    eth_jpy_corr: '-0.6453600518',
  },
  {
    date: '2023-04-17',
    crude: '91.67264896',
    gold: '114.849185',
    btc: '145.3357183',
    eth: '137.7017213',
    jpy: '98.10196727',
    gold_crude_corr: '-0.2194711395',
    crude_btc_corr: '-0.1708614581',
    crude_eth_corr: '0.08183949742',
    crude_jpy_corr: '0.4510917665',
    gold_btc_corr: '0.8980372602',
    gold_eth_corr: '0.8206241295',
    gold_jpy_corr: '-0.7522852059',
    btc_eth_corr: '0.9402966822',
    btc_jpy_corr: '-0.7808225564',
    eth_jpy_corr: '-0.6569751582',
  },
  {
    date: '2023-04-18',
    crude: '90.48815506',
    gold: '115.3132217',
    btc: '150.0371344',
    eth: '139.5782802',
    jpy: '98.43442189',
    gold_crude_corr: '-0.1898284933',
    crude_btc_corr: '-0.1302781436',
    crude_eth_corr: '0.1295551868',
    crude_jpy_corr: '0.4606701559',
    gold_btc_corr: '0.8989622703',
    gold_eth_corr: '0.8160583775',
    gold_jpy_corr: '-0.7548569127',
    btc_eth_corr: '0.9378340322',
    btc_jpy_corr: '-0.7842108115',
    eth_jpy_corr: '-0.6374570205',
  },
  {
    date: '2023-04-19',
    crude: '90.02153625',
    gold: '114.6751782',
    btc: '142.2638297',
    eth: '128.4272108',
    jpy: '98.1554322',
    gold_crude_corr: '-0.1669117712',
    crude_btc_corr: '-0.1091405699',
    crude_eth_corr: '0.1498013657',
    crude_jpy_corr: '0.4625303888',
    gold_btc_corr: '0.9012334147',
    gold_eth_corr: '0.8201503626',
    gold_jpy_corr: '-0.7624801187',
    btc_eth_corr: '0.9384477609',
    btc_jpy_corr: '-0.7826709823',
    eth_jpy_corr: '-0.6317358833',
  },
  {
    date: '2023-04-20',
    crude: '87.6166547',
    gold: '115.2552268',
    btc: '139.4173793',
    eth: '128.8711881',
    jpy: '98.63871712',
    gold_crude_corr: '-0.1452143924',
    crude_btc_corr: '-0.09333684327',
    crude_eth_corr: '0.1672804286',
    crude_jpy_corr: '0.4658487388',
    gold_btc_corr: '0.9009386271',
    gold_eth_corr: '0.8227039633',
    gold_jpy_corr: '-0.7529421861',
    btc_eth_corr: '0.9373763172',
    btc_jpy_corr: '-0.7751655253',
    eth_jpy_corr: '-0.6151508916',
  },
  {
    date: '2023-04-21',
    crude: '85.82196698',
    gold: '114.0371236',
    btc: '134.634175',
    eth: '122.6967062',
    jpy: '98.12028055',
    gold_crude_corr: '-0.135311284',
    crude_btc_corr: '-0.0881127948',
    crude_eth_corr: '0.1721324835',
    crude_jpy_corr: '0.465695839',
    gold_btc_corr: '0.901983107',
    gold_eth_corr: '0.8264198954',
    gold_jpy_corr: '-0.753023857',
    btc_eth_corr: '0.9373225378',
    btc_jpy_corr: '-0.7739243944',
    eth_jpy_corr: '-0.6119526925',
  },
  {
    date: '2023-04-22',
    crude: '85.82196698',
    gold: '114.0371236',
    btc: '137.3024343',
    eth: '124.3036193',
    jpy: '98.12028055',
    gold_crude_corr: '-0.1251787463',
    crude_btc_corr: '-0.08210861605',
    crude_eth_corr: '0.1775975751',
    crude_jpy_corr: '0.4655440362',
    gold_btc_corr: '0.9042910808',
    gold_eth_corr: '0.8303681712',
    gold_jpy_corr: '-0.7537692801',
    btc_eth_corr: '0.9374313084',
    btc_jpy_corr: '-0.7712884452',
    eth_jpy_corr: '-0.6076711264',
  },
  {
    date: '2023-04-23',
    crude: '85.82196698',
    gold: '114.0371236',
    btc: '136.186368',
    eth: '123.4966977',
    jpy: '98.12028055',
    gold_crude_corr: '-0.1147843434',
    crude_btc_corr: '-0.07564382754',
    crude_eth_corr: '0.1836272534',
    crude_jpy_corr: '0.4653933465',
    gold_btc_corr: '0.9053000453',
    gold_eth_corr: '0.8333798543',
    gold_jpy_corr: '-0.7552103978',
    btc_eth_corr: '0.9374296428',
    btc_jpy_corr: '-0.7700323627',
    eth_jpy_corr: '-0.6043249141',
  },
  {
    date: '2023-04-24',
    crude: '86.21679828',
    gold: '114.4431543',
    btc: '135.8603816',
    eth: '122.2163697',
    jpy: '98.09464867',
    gold_crude_corr: '-0.1077842392',
    crude_btc_corr: '-0.07184628414',
    crude_eth_corr: '0.1871094569',
    crude_jpy_corr: '0.4650788222',
    gold_btc_corr: '0.9079881907',
    gold_eth_corr: '0.8378625402',
    gold_jpy_corr: '-0.7544624214',
    btc_eth_corr: '0.9375559112',
    btc_jpy_corr: '-0.7678217284',
    eth_jpy_corr: '-0.6015215783',
  },
  {
    date: '2023-04-25',
    crude: '87.86791098',
    gold: '115.0232029',
    btc: '139.7214727',
    eth: '123.8078599',
    jpy: '98.18471779',
    gold_crude_corr: '-0.09618880027',
    crude_btc_corr: '-0.0630736309',
    crude_eth_corr: '0.1946382677',
    crude_jpy_corr: '0.4649718228',
    gold_btc_corr: '0.911053736',
    gold_eth_corr: '0.8400553732',
    gold_jpy_corr: '-0.7526299297',
    btc_eth_corr: '0.9375059351',
    btc_jpy_corr: '-0.7628542064',
    eth_jpy_corr: '-0.5969274102',
  },
  {
    date: '2023-04-26',
    crude: '86.10911701',
    gold: '114.3851483',
    btc: '140.289604',
    eth: '123.7952949',
    jpy: '97.91158298',
    gold_crude_corr: '-0.09079001011',
    crude_btc_corr: '-0.05804806302',
    crude_eth_corr: '0.1995581236',
    crude_jpy_corr: '0.464775502',
    gold_btc_corr: '0.9149447288',
    gold_eth_corr: '0.8424719798',
    gold_jpy_corr: '-0.7510427733',
    btc_eth_corr: '0.9372495268',
    btc_jpy_corr: '-0.7581542379',
    eth_jpy_corr: '-0.5920093746',
  },
  {
    date: '2023-04-27',
    crude: '83.38119167',
    gold: '114.3851483',
    btc: '145.4775849',
    eth: '126.5955766',
    jpy: '97.73144474',
    gold_crude_corr: '-0.09092735637',
    crude_btc_corr: '-0.05961277293',
    crude_eth_corr: '0.197988817',
    crude_jpy_corr: '0.4651109523',
    gold_btc_corr: '0.9172951283',
    gold_eth_corr: '0.8461392409',
    gold_jpy_corr: '-0.751560938',
    btc_eth_corr: '0.937300126',
    btc_jpy_corr: '-0.751853536',
    eth_jpy_corr: '-0.5871608441',
  },
  {
    date: '2023-04-28',
    crude: '84.3862168',
    gold: '114.4431543',
    btc: '144.8185263',
    eth: '125.5162727',
    jpy: '97.9782214',
    gold_crude_corr: '-0.08959758896',
    crude_btc_corr: '-0.05786903412',
    crude_eth_corr: '0.2007802608',
    crude_jpy_corr: '0.4650019389',
    gold_btc_corr: '0.9185938325',
    gold_eth_corr: '0.8476619743',
    gold_jpy_corr: '-0.756976188',
    btc_eth_corr: '0.9364445183',
    btc_jpy_corr: '-0.7458575854',
    eth_jpy_corr: '-0.5819506107',
  },
  {
    date: '2023-04-29',
    crude: '84.3862168',
    gold: '114.4431543',
    btc: '144.3655483',
    eth: '126.6042394',
    jpy: '97.9782214',
    gold_crude_corr: '-0.08819628748',
    crude_btc_corr: '-0.05599762178',
    crude_eth_corr: '0.2041197694',
    crude_jpy_corr: '0.4648830789',
    gold_btc_corr: '0.9203278604',
    gold_eth_corr: '0.8480447839',
    gold_jpy_corr: '-0.7640617078',
    btc_eth_corr: '0.9356492606',
    btc_jpy_corr: '-0.7406340223',
    eth_jpy_corr: '-0.5761499255',
  },
  {
    date: '2023-04-30',
    crude: '84.3862168',
    gold: '114.4431543',
    btc: '144.4658363',
    eth: '124.4824036',
    jpy: '97.9782214',
    gold_crude_corr: '-0.08672736983',
    crude_btc_corr: '-0.0541442456',
    crude_eth_corr: '0.2067371052',
    crude_jpy_corr: '0.4647544127',
    gold_btc_corr: '0.9249789327',
    gold_eth_corr: '0.8545773322',
    gold_jpy_corr: '-0.7732255516',
    btc_eth_corr: '0.9347673682',
    btc_jpy_corr: '-0.7348552085',
    eth_jpy_corr: '-0.5714495455',
  },
  {
    date: '2023-05-01',
    crude: '86.46805456',
    gold: '113.9211117',
    btc: '138.6551889',
    eth: '121.4999129',
    jpy: '99.87038806',
    gold_crude_corr: '-0.07772871499',
    crude_btc_corr: '-0.04693517528',
    crude_eth_corr: '0.2139075742',
    crude_jpy_corr: '0.466990794',
    gold_btc_corr: '0.9294179598',
    gold_eth_corr: '0.8595302461',
    gold_jpy_corr: '-0.7393500926',
    btc_eth_corr: '0.9337785677',
    btc_jpy_corr: '-0.7038988637',
    eth_jpy_corr: '-0.5462075671',
  },
  {
    date: '2023-05-02',
    crude: '86.53984207',
    gold: '116.0672883',
    btc: '141.5622382',
    eth: '124.0755141',
    jpy: '100.7015023',
    gold_crude_corr: '-0.06578380586',
    crude_btc_corr: '-0.03870105041',
    crude_eth_corr: '0.2230949259',
    crude_jpy_corr: '0.4658685539',
    gold_btc_corr: '0.9300543397',
    gold_eth_corr: '0.8590687148',
    gold_jpy_corr: '-0.6650652335',
    btc_eth_corr: '0.932464383',
    btc_jpy_corr: '-0.6486995357',
    eth_jpy_corr: '-0.4987845488',
  },
  {
    date: '2023-05-03',
    crude: '86.53984207',
    gold: '117.0533676',
    btc: '143.1701907',
    eth: '126.3213646',
    jpy: '99.92897042',
    gold_crude_corr: '-0.03843114364',
    crude_btc_corr: '-0.02231991111',
    crude_eth_corr: '0.2368601018',
    crude_jpy_corr: '0.4609997603',
    gold_btc_corr: '0.9310650761',
    gold_eth_corr: '0.8646223919',
    gold_jpy_corr: '-0.6009183982',
    btc_eth_corr: '0.932857062',
    btc_jpy_corr: '-0.6084437043',
    eth_jpy_corr: '-0.4637329481',
  },
  {
    date: '2023-05-04',
    crude: '86.53984207',
    gold: '117.865429',
    btc: '142.3873797',
    eth: '124.5341209',
    jpy: '98.52156357',
    gold_crude_corr: '-0.007378201368',
    crude_btc_corr: '-0.004959733078',
    crude_eth_corr: '0.2519606168',
    crude_jpy_corr: '0.4569083982',
    gold_btc_corr: '0.9287086708',
    gold_eth_corr: '0.8644235749',
    gold_jpy_corr: '-0.5621546753',
    btc_eth_corr: '0.9325053587',
    btc_jpy_corr: '-0.5893617267',
    eth_jpy_corr: '-0.4473392303',
  },
  {
    date: '2023-05-05',
    crude: '86.53984207',
    gold: '116.0672883',
    btc: '145.7766846',
    eth: '132.3175237',
    jpy: '98.25428365',
    gold_crude_corr: '0.02213455302',
    crude_btc_corr: '0.02268748861',
    crude_eth_corr: '0.2811658942',
    crude_jpy_corr: '0.4496571931',
    gold_btc_corr: '0.9256868695',
    gold_eth_corr: '0.860853967',
    gold_jpy_corr: '-0.5309100841',
    btc_eth_corr: '0.9301996162',
    btc_jpy_corr: '-0.5613454755',
    eth_jpy_corr: '-0.4131249687',
  },
  {
    date: '2023-05-06',
    crude: '86.53984207',
    gold: '116.0672883',
    btc: '142.6682882',
    eth: '126.0275522',
    jpy: '98.25428365',
    gold_crude_corr: '0.05394830377',
    crude_btc_corr: '0.05092649307',
    crude_eth_corr: '0.3110360125',
    crude_jpy_corr: '0.4421587724',
    gold_btc_corr: '0.9211694091',
    gold_eth_corr: '0.8551451232',
    gold_jpy_corr: '-0.4959397062',
    btc_eth_corr: '0.9277453755',
    btc_jpy_corr: '-0.5328623946',
    eth_jpy_corr: '-0.3822496119',
  },
  {
    date: '2023-05-07',
    crude: '86.53984207',
    gold: '116.0672883',
    btc: '140.4489195',
    eth: '124.227201',
    jpy: '98.25428365',
    gold_crude_corr: '0.08855290316',
    crude_btc_corr: '0.07998721149',
    crude_eth_corr: '0.3427629597',
    crude_jpy_corr: '0.4343978447',
    gold_btc_corr: '0.9148276424',
    gold_eth_corr: '0.8469938094',
    gold_jpy_corr: '-0.4563612594',
    btc_eth_corr: '0.9245484922',
    btc_jpy_corr: '-0.5027666583',
    eth_jpy_corr: '-0.348894619',
  },
  {
    date: '2023-05-08',
    crude: '80.65326633',
    gold: '116.1833002',
    btc: '136.694209',
    eth: '122.6332254',
    jpy: '99.00119478',
    gold_crude_corr: '0.1201886958',
    crude_btc_corr: '0.1139971253',
    crude_eth_corr: '0.3769136209',
    crude_jpy_corr: '0.4068244762',
    gold_btc_corr: '0.9039854977',
    gold_eth_corr: '0.8366832283',
    gold_jpy_corr: '-0.4111880162',
    btc_eth_corr: '0.9207956295',
    btc_jpy_corr: '-0.4775752334',
    eth_jpy_corr: '-0.3191200429',
  },
  {
    date: '2023-05-09',
    crude: '81.6223977',
    gold: '116.9953616',
    btc: '136.5189967',
    eth: '122.6040716',
    jpy: '98.90892449',
    gold_crude_corr: '0.1945391799',
    crude_btc_corr: '0.1686985035',
    crude_eth_corr: '0.4306839327',
    crude_jpy_corr: '0.377368536',
    gold_btc_corr: '0.8906112286',
    gold_eth_corr: '0.8280105464',
    gold_jpy_corr: '-0.3509903566',
    btc_eth_corr: '0.9164210698',
    btc_jpy_corr: '-0.4488021605',
    eth_jpy_corr: '-0.285516218',
  },
  {
    date: '2023-05-10',
    crude: '82.55563532',
    gold: '116.8213437',
    btc: '136.3362746',
    eth: '122.1927617',
    jpy: '99.06635829',
    gold_crude_corr: '0.2679347304',
    crude_btc_corr: '0.2194695007',
    crude_eth_corr: '0.4818298657',
    crude_jpy_corr: '0.352103772',
    gold_btc_corr: '0.8709581631',
    gold_eth_corr: '0.813962335',
    gold_jpy_corr: '-0.2342582867',
    btc_eth_corr: '0.9101679037',
    btc_jpy_corr: '-0.3898423927',
    eth_jpy_corr: '-0.2227935885',
  },
  {
    date: '2023-05-11',
    crude: '82.26848528',
    gold: '115.8352644',
    btc: '133.2712884',
    eth: '119.1478346',
    jpy: '98.18691896',
    gold_crude_corr: '0.3355693074',
    crude_btc_corr: '0.2826885567',
    crude_eth_corr: '0.5538643125',
    crude_jpy_corr: '0.3410020496',
    gold_btc_corr: '0.8313546394',
    gold_eth_corr: '0.7720924273',
    gold_jpy_corr: '-0.1169314026',
    btc_eth_corr: '0.8955147422',
    btc_jpy_corr: '-0.3107767127',
    eth_jpy_corr: '-0.1324389857',
  },
  {
    date: '2023-05-12',
    crude: '79.79181622',
    gold: '115.6612575',
    btc: '132.3048588',
    eth: '119.9124788',
    jpy: '98.48787564',
    gold_crude_corr: '0.3667703182',
    crude_btc_corr: '0.3503413106',
    crude_eth_corr: '0.6270802182',
    crude_jpy_corr: '0.3287725451',
    gold_btc_corr: '0.7881434906',
    gold_eth_corr: '0.7241475033',
    gold_jpy_corr: '-0.02209870242',
    btc_eth_corr: '0.8726176979',
    btc_jpy_corr: '-0.2358545171',
    eth_jpy_corr: '-0.0420122846',
  },
  {
    date: '2023-05-13',
    crude: '79.79181622',
    gold: '115.6612575',
    btc: '132.2016402',
    eth: '119.1229151',
    jpy: '98.48787564',
    gold_crude_corr: '0.4102154129',
    crude_btc_corr: '0.4432629933',
    crude_eth_corr: '0.7048313555',
    crude_jpy_corr: '0.3158240172',
    gold_btc_corr: '0.7216260332',
    gold_eth_corr: '0.6604525263',
    gold_jpy_corr: '0.09800282256',
    btc_eth_corr: '0.8496038356',
    btc_jpy_corr: '-0.1378784049',
    eth_jpy_corr: '0.05734067866',
  },
  {
    date: '2023-05-14',
    crude: '79.79181622',
    gold: '115.6612575',
    btc: '132.9250381',
    eth: '119.4138129',
    jpy: '98.48787564',
    gold_crude_corr: '0.473294918',
    crude_btc_corr: '0.5391942683',
    crude_eth_corr: '0.7586018079',
    crude_jpy_corr: '0.302061189',
    gold_btc_corr: '0.6251191145',
    gold_eth_corr: '0.6034388051',
    gold_jpy_corr: '0.2582682727',
    btc_eth_corr: '0.8414675867',
    btc_jpy_corr: '-0.02577135158',
    eth_jpy_corr: '0.1417695842',
  },
  {
    date: '2023-05-15',
    crude: '79.36109117',
    gold: '115.8932703',
    btc: '134.2184953',
    eth: '120.5445428',
    jpy: '99.45739527',
    gold_crude_corr: '0.5063740883',
    crude_btc_corr: '0.6063050237',
    crude_eth_corr: '0.7896318193',
    crude_jpy_corr: '0.2671250727',
    gold_btc_corr: '0.5549920484',
    gold_eth_corr: '0.5678421736',
    gold_jpy_corr: '0.3426285467',
    btc_eth_corr: '0.8389922796',
    btc_jpy_corr: '0.008048320036',
    eth_jpy_corr: '0.1643610178',
  },
  {
    date: '2023-05-16',
    crude: '81.15577889',
    gold: '114.4431543',
    btc: '133.4482938',
    eth: '120.9803836',
    jpy: '99.62068396',
    gold_crude_corr: '0.5430325506',
    crude_btc_corr: '0.6369743645',
    crude_eth_corr: '0.799961163',
    crude_jpy_corr: '0.2460362011',
    gold_btc_corr: '0.4801118498',
    gold_eth_corr: '0.5453818609',
    gold_jpy_corr: '0.3529801976',
    btc_eth_corr: '0.83642024',
    btc_jpy_corr: '-0.02701669586',
    eth_jpy_corr: '0.1494328723',
  },
  {
    date: '2023-05-17',
    crude: '80.8327351',
    gold: '114.0951296',
    btc: '135.235816',
    eth: '120.8303726',
    jpy: '99.9026011',
    gold_crude_corr: '0.5647169473',
    crude_btc_corr: '0.6720937178',
    crude_eth_corr: '0.8134956539',
    crude_jpy_corr: '0.2280766607',
    gold_btc_corr: '0.3836590574',
    gold_eth_corr: '0.4993181941',
    gold_jpy_corr: '0.3913979913',
    btc_eth_corr: '0.8276963951',
    btc_jpy_corr: '-0.01659917673',
    eth_jpy_corr: '0.1600863173',
  },
  {
    date: '2023-05-18',
    crude: '83.66834171',
    gold: '112.5870076',
    btc: '132.4392059',
    eth: '119.4952265',
    jpy: '100.7696044',
    gold_crude_corr: '0.5263092877',
    crude_btc_corr: '0.646783318',
    crude_eth_corr: '0.8019360546',
    crude_jpy_corr: '0.2128448043',
    gold_btc_corr: '0.3012615734',
    gold_eth_corr: '0.4555739827',
    gold_jpy_corr: '0.3072205848',
    btc_eth_corr: '0.8168751104',
    btc_jpy_corr: '-0.08998568279',
    eth_jpy_corr: '0.1191557886',
  },
  {
    date: '2023-05-19',
    crude: '84.31442929',
    gold: '113.6890988',
    btc: '132.7250888',
    eth: '120.2155531',
    jpy: '101.5062583',
    gold_crude_corr: '0.5124419887',
    crude_btc_corr: '0.6296146937',
    crude_eth_corr: '0.7981847942',
    crude_jpy_corr: '0.2046824973',
    gold_btc_corr: '0.3059707919',
    gold_eth_corr: '0.4534610943',
    gold_jpy_corr: '0.2434207055',
    btc_eth_corr: '0.8129786811',
    btc_jpy_corr: '-0.1466393411',
    eth_jpy_corr: '0.08451791742',
  },
  {
    date: '2023-05-20',
    crude: '84.31442929',
    gold: '113.6890988',
    btc: '133.9070078',
    eth: '120.7387419',
    jpy: '101.5062583',
    gold_crude_corr: '0.4985720133',
    crude_btc_corr: '0.6102596297',
    crude_eth_corr: '0.7913193683',
    crude_jpy_corr: '0.1968833416',
    gold_btc_corr: '0.3046130471',
    gold_eth_corr: '0.4478609538',
    gold_jpy_corr: '0.1889272835',
    btc_eth_corr: '0.8076959395',
    btc_jpy_corr: '-0.1891255959',
    eth_jpy_corr: '0.05736835661',
  },
  {
    date: '2023-05-21',
    crude: '84.31442929',
    gold: '113.6890988',
    btc: '132.0523217',
    eth: '119.6811191',
    jpy: '101.5062583',
    gold_crude_corr: '0.4846849541',
    crude_btc_corr: '0.6045268955',
    crude_eth_corr: '0.7861245238',
    crude_jpy_corr: '0.1889911687',
    gold_btc_corr: '0.3172324954',
    gold_eth_corr: '0.4459866919',
    gold_jpy_corr: '0.1411102528',
    btc_eth_corr: '0.8086009191',
    btc_jpy_corr: '-0.235068811',
    eth_jpy_corr: '0.02576640896',
  },
  {
    date: '2023-05-22',
    crude: '82.08901651',
    gold: '113.457075',
    btc: '132.5333241',
    eth: '120.5435389',
    jpy: '100.826723',
    gold_crude_corr: '0.4793961737',
    crude_btc_corr: '0.6155426097',
    crude_eth_corr: '0.7803971574',
    crude_jpy_corr: '0.1494865281',
    gold_btc_corr: '0.3288159191',
    gold_eth_corr: '0.4403282214',
    gold_jpy_corr: '0.09367892596',
    btc_eth_corr: '0.8108328678',
    btc_jpy_corr: '-0.2725996455',
    eth_jpy_corr: '-0.008443906531',
  },
  {
    date: '2023-05-23',
    crude: '84.17085427',
    gold: '113.5730869',
    btc: '134.3815415',
    eth: '122.9872479',
    jpy: '101.5443374',
    gold_crude_corr: '0.4168267565',
    crude_btc_corr: '0.6415134359',
    crude_eth_corr: '0.7968732206',
    crude_jpy_corr: '0.1015752719',
    gold_btc_corr: '0.3553397336',
    gold_eth_corr: '0.4363625655',
    gold_jpy_corr: '0.005487013108',
    btc_eth_corr: '0.8090869256',
    btc_jpy_corr: '-0.2983762408',
    eth_jpy_corr: '-0.0259745015',
  },
  {
    date: '2023-05-24',
    crude: '85.60660445',
    gold: '112.6450135',
    btc: '129.9841706',
    eth: '119.3872175',
    jpy: '101.4901574',
    gold_crude_corr: '0.3802757993',
    crude_btc_corr: '0.6136520605',
    crude_eth_corr: '0.7810708796',
    crude_jpy_corr: '0.08720619796',
    gold_btc_corr: '0.3783194584',
    gold_eth_corr: '0.4309756652',
    gold_jpy_corr: '-0.06922099352',
    btc_eth_corr: '0.8054194407',
    btc_jpy_corr: '-0.3529954762',
    eth_jpy_corr: '-0.06968308045',
  },
  {
    date: '2023-05-25',
    crude: '86.79109835',
    gold: '111.6009282',
    btc: '130.682041',
    eth: '119.7754538',
    jpy: '101.9917556',
    gold_crude_corr: '0.3539060402',
    crude_btc_corr: '0.609404587',
    crude_eth_corr: '0.7780761483',
    crude_jpy_corr: '0.06509635882',
    gold_btc_corr: '0.4140401211',
    gold_eth_corr: '0.4378328495',
    gold_jpy_corr: '-0.1536720549',
    btc_eth_corr: '0.8073639167',
    btc_jpy_corr: '-0.3913078653',
    eth_jpy_corr: '-0.1060108678',
  },
  {
    date: '2023-05-26',
    crude: '84.7810481',
    gold: '112.0069589',
    btc: '131.8818621',
    eth: '121.2833607',
    jpy: '102.5292317',
    gold_crude_corr: '0.327497473',
    crude_btc_corr: '0.605129373',
    crude_eth_corr: '0.7711649471',
    crude_jpy_corr: '0.01162942275',
    gold_btc_corr: '0.4329327072',
    gold_eth_corr: '0.4279053046',
    gold_jpy_corr: '-0.2464970999',
    btc_eth_corr: '0.8051592345',
    btc_jpy_corr: '-0.4410160593',
    eth_jpy_corr: '-0.1535945662',
  },
  {
    date: '2023-05-27',
    crude: '84.7810481',
    gold: '112.0069589',
    btc: '132.6176091',
    eth: '121.4444309',
    jpy: '102.5292317',
    gold_crude_corr: '0.3042002451',
    crude_btc_corr: '0.605639549',
    crude_eth_corr: '0.7623526912',
    crude_jpy_corr: '-0.04780660663',
    gold_btc_corr: '0.4472703473',
    gold_eth_corr: '0.4188033383',
    gold_jpy_corr: '-0.3273841898',
    btc_eth_corr: '0.8055344177',
    btc_jpy_corr: '-0.4836234248',
    eth_jpy_corr: '-0.2039696585',
  },
  {
    date: '2023-05-28',
    crude: '84.7810481',
    gold: '112.0069589',
    btc: '138.6259596',
    eth: '126.7366983',
    jpy: '102.5292317',
    gold_crude_corr: '0.2838273935',
    crude_btc_corr: '0.6318147332',
    crude_eth_corr: '0.7619148164',
    crude_jpy_corr: '-0.1155504238',
    gold_btc_corr: '0.4362124853',
    gold_eth_corr: '0.3835206708',
    gold_jpy_corr: '-0.3991627659',
    btc_eth_corr: '0.8090686858',
    btc_jpy_corr: '-0.4792216424',
    eth_jpy_corr: '-0.2114258946',
  },
  {
    date: '2023-05-29',
    crude: '86.64752333',
    gold: '112.0069589',
    btc: '136.9489537',
    eth: '125.5537653',
    jpy: '103.1545869',
    gold_crude_corr: '0.2158638698',
    crude_btc_corr: '0.6306703301',
    crude_eth_corr: '0.7460526916',
    crude_jpy_corr: '-0.1737957893',
    gold_btc_corr: '0.4234208101',
    gold_eth_corr: '0.3369688421',
    gold_jpy_corr: '-0.494359914',
    btc_eth_corr: '0.8072407045',
    btc_jpy_corr: '-0.5050627197',
    eth_jpy_corr: '-0.2490450704',
  },
  {
    date: '2023-05-30',
    crude: '85.46302943',
    gold: '112.7030195',
    btc: '136.7340716',
    eth: '126.0809292',
    jpy: '102.8397081',
    gold_crude_corr: '0.1935902846',
    crude_btc_corr: '0.6264666201',
    crude_eth_corr: '0.7382122905',
    crude_jpy_corr: '-0.2184346328',
    gold_btc_corr: '0.4196341946',
    gold_eth_corr: '0.3141334524',
    gold_jpy_corr: '-0.5451839842',
    btc_eth_corr: '0.8014088567',
    btc_jpy_corr: '-0.5290864748',
    eth_jpy_corr: '-0.264399905',
  },
  {
    date: '2023-05-31',
    crude: '81.58650395',
    gold: '112.9350323',
    btc: '134.3515892',
    eth: '124.2971101',
    jpy: '102.3644793',
    gold_crude_corr: '0.1890924073',
    crude_btc_corr: '0.6467740588',
    crude_eth_corr: '0.7250918197',
    crude_jpy_corr: '-0.2898392388',
    gold_btc_corr: '0.436800291',
    gold_eth_corr: '0.2972012434',
    gold_jpy_corr: '-0.6061652884',
    btc_eth_corr: '0.8071162323',
    btc_jpy_corr: '-0.542984016',
    eth_jpy_corr: '-0.2914216631',
  },
  {
    date: '2023-06-01',
    crude: '80.90452261',
    gold: '113.7471048',
    btc: '132.3788095',
    eth: '123.5059433',
    jpy: '101.8775183',
    gold_crude_corr: '0.1920284083',
    crude_btc_corr: '0.6658447612',
    crude_eth_corr: '0.7117683649',
    crude_jpy_corr: '-0.3467696572',
    gold_btc_corr: '0.4426578951',
    gold_eth_corr: '0.2932101412',
    gold_jpy_corr: '-0.6217421853',
    btc_eth_corr: '0.8074260133',
    btc_jpy_corr: '-0.5615041518',
    eth_jpy_corr: '-0.3126499837',
  },
  {
    date: '2023-06-02',
    crude: '82.69921034',
    gold: '112.0649649',
    btc: '134.4993266',
    eth: '126.4941174',
    jpy: '101.5999923',
    gold_crude_corr: '0.1980608896',
    crude_btc_corr: '0.6818661908',
    crude_eth_corr: '0.6992106863',
    crude_jpy_corr: '-0.3769130428',
    gold_btc_corr: '0.458469132',
    gold_eth_corr: '0.2685476175',
    gold_jpy_corr: '-0.652282963',
    btc_eth_corr: '0.8059574281',
    btc_jpy_corr: '-0.5668084689',
    eth_jpy_corr: '-0.3121602719',
  },
  {
    date: '2023-06-03',
    crude: '82.69921034',
    gold: '112.0649649',
    btc: '133.6382172',
    eth: '125.5096178',
    jpy: '101.5999923',
    gold_crude_corr: '0.2041856017',
    crude_btc_corr: '0.6977551193',
    crude_eth_corr: '0.688675141',
    crude_jpy_corr: '-0.4075226271',
    gold_btc_corr: '0.4757359875',
    gold_eth_corr: '0.2497435391',
    gold_jpy_corr: '-0.6810892412',
    btc_eth_corr: '0.8070520195',
    btc_jpy_corr: '-0.5742077887',
    eth_jpy_corr: '-0.3191378798',
  },
  {
    date: '2023-06-04',
    crude: '82.69921034',
    gold: '112.0649649',
    btc: '133.8550852',
    eth: '125.3836762',
    jpy: '101.5999923',
    gold_crude_corr: '0.2104050022',
    crude_btc_corr: '0.710171087',
    crude_eth_corr: '0.6766345376',
    crude_jpy_corr: '-0.4386930911',
    gold_btc_corr: '0.4886257359',
    gold_eth_corr: '0.2306718046',
    gold_jpy_corr: '-0.708428672',
    btc_eth_corr: '0.8105124574',
    btc_jpy_corr: '-0.5826725128',
    eth_jpy_corr: '-0.3324420866',
  },
  {
    date: '2023-06-05',
    crude: '85.64249821',
    gold: '112.7610255',
    btc: '127.1474473',
    eth: '120.1650745',
    jpy: '102.6683634',
    gold_crude_corr: '0.2096627281',
    crude_btc_corr: '0.6907159897',
    crude_eth_corr: '0.6861938761',
    crude_jpy_corr: '-0.4224730952',
    gold_btc_corr: '0.5049393079',
    gold_eth_corr: '0.2420764783',
    gold_jpy_corr: '-0.7234061386',
    btc_eth_corr: '0.8112886069',
    btc_jpy_corr: '-0.6109258512',
    eth_jpy_corr: '-0.3633187098',
  },
  {
    date: '2023-06-06',
    crude: '84.60157933',
    gold: '112.9350323',
    btc: '134.4459869',
    eth: '124.9845022',
    jpy: '102.0649751',
    gold_crude_corr: '0.1955986474',
    crude_btc_corr: '0.6955859475',
    crude_eth_corr: '0.6954245342',
    crude_jpy_corr: '-0.4092880319',
    gold_btc_corr: '0.5139230249',
    gold_eth_corr: '0.2455477743',
    gold_jpy_corr: '-0.723042561',
    btc_eth_corr: '0.8086507383',
    btc_jpy_corr: '-0.6195928347',
    eth_jpy_corr: '-0.3649141427',
  },
  {
    date: '2023-06-07',
    crude: '83.84781048',
    gold: '111.5429223',
    btc: '130.0393517',
    eth: '121.5291719',
    jpy: '102.1704189',
    gold_crude_corr: '0.1892945544',
    crude_btc_corr: '0.6980566338',
    crude_eth_corr: '0.697778461',
    crude_jpy_corr: '-0.3988079133',
    gold_btc_corr: '0.5363804656',
    gold_eth_corr: '0.2540838325',
    gold_jpy_corr: '-0.7264502683',
    btc_eth_corr: '0.8080100911',
    btc_jpy_corr: '-0.6401526142',
    eth_jpy_corr: '-0.3715928827',
  },
  {
    date: '2023-06-08',
    crude: '85.35534817',
    gold: '112.9930383',
    btc: '130.8400357',
    eth: '122.4514456',
    jpy: '102.4428274',
    gold_crude_corr: '0.1831320211',
    crude_btc_corr: '0.6907817306',
    crude_eth_corr: '0.6994384337',
    crude_jpy_corr: '-0.3858762889',
    gold_btc_corr: '0.5455685662',
    gold_eth_corr: '0.2606515048',
    gold_jpy_corr: '-0.7342997902',
    btc_eth_corr: '0.8061707953',
    btc_jpy_corr: '-0.6659172844',
    eth_jpy_corr: '-0.3885973763',
  },
  {
    date: '2023-06-09',
    crude: '83.30940416',
    gold: '112.7030195',
    btc: '130.7026134',
    eth: '122.048426',
    jpy: '101.6842066',
    gold_crude_corr: '0.1858142615',
    crude_btc_corr: '0.6960054794',
    crude_eth_corr: '0.7065417329',
    crude_jpy_corr: '-0.3829130171',
    gold_btc_corr: '0.5570600278',
    gold_eth_corr: '0.2697811534',
    gold_jpy_corr: '-0.7405491209',
    btc_eth_corr: '0.8053502754',
    btc_jpy_corr: '-0.6874296544',
    eth_jpy_corr: '-0.4067183199',
  },
  {
    date: '2023-06-10',
    crude: '83.30940416',
    gold: '112.7030195',
    btc: '127.5973115',
    eth: '116.2226232',
    jpy: '101.6842066',
    gold_crude_corr: '0.1875445185',
    crude_btc_corr: '0.6977304767',
    crude_eth_corr: '0.7139671696',
    crude_jpy_corr: '-0.3789425041',
    gold_btc_corr: '0.5694585101',
    gold_eth_corr: '0.2939881108',
    gold_jpy_corr: '-0.7466996699',
    btc_eth_corr: '0.8145759527',
    btc_jpy_corr: '-0.7090592927',
    eth_jpy_corr: '-0.4415417231',
  },
  {
    date: '2023-06-11',
    crude: '83.30940416',
    gold: '112.7030195',
    btc: '128.0362436',
    eth: '116.2909698',
    jpy: '101.6842066',
    gold_crude_corr: '0.1883393037',
    crude_btc_corr: '0.6969123283',
    crude_eth_corr: '0.7193999005',
    crude_jpy_corr: '-0.3739107194',
    gold_btc_corr: '0.5779299499',
    gold_eth_corr: '0.3147926603',
    gold_jpy_corr: '-0.752857768',
    btc_eth_corr: '0.8238840864',
    btc_jpy_corr: '-0.7232615088',
    eth_jpy_corr: '-0.4721041504',
  },
  {
    date: '2023-06-12',
    crude: '81.55061019',
    gold: '112.5870076',
    btc: '127.850321',
    eth: '115.5689429',
    jpy: '102.0561928',
    gold_crude_corr: '0.2025884406',
    crude_btc_corr: '0.6970615529',
    crude_eth_corr: '0.727170422',
    crude_jpy_corr: '-0.3692435833',
    gold_btc_corr: '0.5926383925',
    gold_eth_corr: '0.3320095822',
    gold_jpy_corr: '-0.7691604359',
    btc_eth_corr: '0.8351182605',
    btc_jpy_corr: '-0.7209728208',
    eth_jpy_corr: '-0.4873904383',
  },
  {
    date: '2023-06-13',
    crude: '79.93539124',
    gold: '111.7169402',
    btc: '127.9304222',
    eth: '115.337405',
    jpy: '102.1213675',
    gold_crude_corr: '0.2244322448',
    crude_btc_corr: '0.6996719696',
    crude_eth_corr: '0.7431298483',
    crude_jpy_corr: '-0.3737996167',
    gold_btc_corr: '0.6060404421',
    gold_eth_corr: '0.3574710257',
    gold_jpy_corr: '-0.7735350203',
    btc_eth_corr: '0.8586516099',
    btc_jpy_corr: '-0.7217217152',
    eth_jpy_corr: '-0.5035011943',
  },
  {
    date: '2023-06-14',
    crude: '82.84278536',
    gold: '111.7749461',
    btc: '124.0111133',
    eth: '109.4666451',
    jpy: '102.6463964',
    gold_crude_corr: '0.2123261419',
    crude_btc_corr: '0.679004274',
    crude_eth_corr: '0.7325526027',
    crude_jpy_corr: '-0.3620249941',
    gold_btc_corr: '0.6139198939',
    gold_eth_corr: '0.3861136071',
    gold_jpy_corr: '-0.7767006575',
    btc_eth_corr: '0.8771317014',
    btc_jpy_corr: '-0.7260110748',
    eth_jpy_corr: '-0.5225022046',
  },
  {
    date: '2023-06-15',
    crude: '82.30437904',
    gold: '112.6450135',
    btc: '126.24072',
    eth: '110.4615157',
    jpy: '102.4999461',
    gold_crude_corr: '0.1640881652',
    crude_btc_corr: '0.6569632606',
    crude_eth_corr: '0.7142678565',
    crude_jpy_corr: '-0.3426082907',
    gold_btc_corr: '0.5934980905',
    gold_eth_corr: '0.3686799996',
    gold_jpy_corr: '-0.7726023462',
    btc_eth_corr: '0.8766230259',
    btc_jpy_corr: '-0.7252360716',
    eth_jpy_corr: '-0.5241300412',
  },
  {
    date: '2023-06-16',
    crude: '84.35032304',
    gold: '112.4709956',
    btc: '129.9478653',
    eth: '113.8538509',
    jpy: '102.7130348',
    gold_crude_corr: '0.1421516575',
    crude_btc_corr: '0.6251483326',
    crude_eth_corr: '0.6758062528',
    crude_jpy_corr: '-0.302291093',
    gold_btc_corr: '0.5978997961',
    gold_eth_corr: '0.371067008',
    gold_jpy_corr: '-0.7756512184',
    btc_eth_corr: '0.8654101006',
    btc_jpy_corr: '-0.7161446302',
    eth_jpy_corr: '-0.5111312242',
  },
  {
    date: '2023-06-17',
    crude: '84.35032304',
    gold: '112.4709956',
    btc: '130.8521729',
    eth: '114.5525774',
    jpy: '102.7130348',
    gold_crude_corr: '0.1170566047',
    crude_btc_corr: '0.5900619015',
    crude_eth_corr: '0.6326924541',
    crude_jpy_corr: '-0.256060728',
    gold_btc_corr: '0.60034546',
    gold_eth_corr: '0.3712843969',
    gold_jpy_corr: '-0.7784755199',
    btc_eth_corr: '0.8521393894',
    btc_jpy_corr: '-0.7036570292',
    eth_jpy_corr: '-0.4935723052',
  },
  {
    date: '2023-06-18',
    crude: '84.35032304',
    gold: '112.4709956',
    btc: '129.9910538',
    eth: '114.1130853',
    jpy: '102.7130348',
    gold_crude_corr: '0.08816036566',
    crude_btc_corr: '0.5460226345',
    crude_eth_corr: '0.5758984434',
    crude_jpy_corr: '-0.2023333666',
    gold_btc_corr: '0.6040322282',
    gold_eth_corr: '0.3727603992',
    gold_jpy_corr: '-0.7811378687',
    btc_eth_corr: '0.8382197851',
    btc_jpy_corr: '-0.6905746115',
    eth_jpy_corr: '-0.4704788723',
  },
  {
    date: '2023-06-19',
    crude: '85.06819813',
    gold: '112.4709956',
    btc: '132.5320998',
    eth: '115.246001',
    jpy: '103.8839222',
    gold_crude_corr: '0.06021137619',
    crude_btc_corr: '0.506414037',
    crude_eth_corr: '0.4912392012',
    crude_jpy_corr: '-0.1425939132',
    gold_btc_corr: '0.6066271709',
    gold_eth_corr: '0.3851706966',
    gold_jpy_corr: '-0.7810873464',
    btc_eth_corr: '0.8304928777',
    btc_jpy_corr: '-0.6753507428',
    eth_jpy_corr: '-0.4721477537',
  },
  {
    date: '2023-06-20',
    crude: '85.24766691',
    gold: '111.3109094',
    btc: '139.8196494',
    eth: '118.8579649',
    jpy: '103.9073529',
    gold_crude_corr: '0.01067546146',
    crude_btc_corr: '0.4455200506',
    crude_eth_corr: '0.3958647752',
    crude_jpy_corr: '-0.08618765348',
    gold_btc_corr: '0.5694329112',
    gold_eth_corr: '0.3853362052',
    gold_jpy_corr: '-0.7873469668',
    btc_eth_corr: '0.7871163482',
    btc_jpy_corr: '-0.6269242257',
    eth_jpy_corr: '-0.4703994458',
  },
  {
    date: '2023-06-21',
    crude: '85.5707107',
    gold: '111.1948975',
    btc: '148.2096146',
    eth: '125.4164165',
    jpy: '103.4943601',
    gold_crude_corr: '-0.03006475019',
    crude_btc_corr: '0.4221837387',
    crude_eth_corr: '0.3637773809',
    crude_jpy_corr: '-0.01745083171',
    gold_btc_corr: '0.467104076',
    gold_eth_corr: '0.3485600199',
    gold_jpy_corr: '-0.7964678815',
    btc_eth_corr: '0.7707003687',
    btc_jpy_corr: '-0.5147786774',
    eth_jpy_corr: '-0.421082943',
  },
  {
    date: '2023-06-22',
    crude: '86.3603733',
    gold: '110.0928062',
    btc: '147.6419171',
    eth: '124.2183521',
    jpy: '103.7528466',
    gold_crude_corr: '-0.09131849102',
    crude_btc_corr: '0.4311339494',
    crude_eth_corr: '0.3402448824',
    crude_jpy_corr: '0.03895454943',
    gold_btc_corr: '0.3492541079',
    gold_eth_corr: '0.2993298732',
    gold_jpy_corr: '-0.8026498797',
    btc_eth_corr: '0.7590015436',
    btc_jpy_corr: '-0.4201969079',
    eth_jpy_corr: '-0.3801802923',
  },
  {
    date: '2023-06-23',
    crude: '83.84781048',
    gold: '110.3248301',
    btc: '151.507597',
    eth: '125.5394354',
    jpy: '104.7355397',
    gold_crude_corr: '-0.0904646249',
    crude_btc_corr: '0.406200476',
    crude_eth_corr: '0.3356125974',
    crude_jpy_corr: '0.05512063606',
    gold_btc_corr: '0.2363138957',
    gold_eth_corr: '0.2598581134',
    gold_jpy_corr: '-0.8215942166',
    btc_eth_corr: '0.7489996637',
    btc_jpy_corr: '-0.3018439089',
    eth_jpy_corr: '-0.3353202765',
  },
  {
    date: '2023-06-24',
    crude: '83.84781048',
    gold: '110.3248301',
    btc: '150.7831484',
    eth: '124.4250595',
    jpy: '104.7355397',
    gold_crude_corr: '-0.09084326389',
    crude_btc_corr: '0.3833463451',
    crude_eth_corr: '0.3281322994',
    crude_jpy_corr: '0.07270814817',
    gold_btc_corr: '0.1473104123',
    gold_eth_corr: '0.2314228566',
    gold_jpy_corr: '-0.8387124319',
    btc_eth_corr: '0.7356798326',
    btc_jpy_corr: '-0.2015216281',
    eth_jpy_corr: '-0.2959479636',
  },
  {
    date: '2023-06-25',
    crude: '83.84781048',
    gold: '110.3248301',
    btc: '150.4453718',
    eth: '126.0464078',
    jpy: '104.7355397',
    gold_crude_corr: '-0.09237145014',
    crude_btc_corr: '0.3679329792',
    crude_eth_corr: '0.3211454463',
    crude_jpy_corr: '0.09185033545',
    gold_btc_corr: '0.0750480215',
    gold_eth_corr: '0.1938234984',
    gold_jpy_corr: '-0.8544470246',
    btc_eth_corr: '0.7370281856',
    btc_jpy_corr: '-0.1226212959',
    eth_jpy_corr: '-0.249982463',
  },
  {
    date: '2023-06-26',
    crude: '84.49389806',
    gold: '110.5568429',
    btc: '149.4131376',
    eth: '123.3223257',
    jpy: '105.1097271',
    gold_crude_corr: '-0.1081625886',
    crude_btc_corr: '0.3692568902',
    crude_eth_corr: '0.3233699791',
    crude_jpy_corr: '0.1266666295',
    gold_btc_corr: '0.02214869563',
    gold_eth_corr: '0.1797020433',
    gold_jpy_corr: '-0.8645781386',
    btc_eth_corr: '0.7247677739',
    btc_jpy_corr: '-0.05507339221',
    eth_jpy_corr: '-0.231218579',
  },
  {
    date: '2023-06-27',
    crude: '85.06819813',
    gold: '110.0348003',
    btc: '151.4715423',
    eth: '125.3299429',
    jpy: '105.0504185',
    gold_crude_corr: '-0.1529267892',
    crude_btc_corr: '0.3709627647',
    crude_eth_corr: '0.3238085398',
    crude_jpy_corr: '0.1927058393',
    gold_btc_corr: '-0.04721725621',
    gold_eth_corr: '0.1451526843',
    gold_jpy_corr: '-0.8713788637',
    btc_eth_corr: '0.7210512399',
    btc_jpy_corr: '0.0240358578',
    eth_jpy_corr: '-0.1912603693',
  },
  {
    date: '2023-06-28',
    crude: '83.48887294',
    gold: '109.7447815',
    btc: '148.5005779',
    eth: '121.2357076',
    jpy: '105.3594312',
    gold_crude_corr: '-0.1560501325',
    crude_btc_corr: '0.3525484067',
    crude_eth_corr: '0.3189543315',
    crude_jpy_corr: '0.2178112881',
    gold_btc_corr: '-0.09933199759',
    gold_eth_corr: '0.1407611754',
    gold_jpy_corr: '-0.8846882918',
    btc_eth_corr: '0.6999716757',
    btc_jpy_corr: '0.09001818459',
    eth_jpy_corr: '-0.180486431',
  },
  {
    date: '2023-06-29',
    crude: '84.92462312',
    gold: '109.7447815',
    btc: '150.2730613',
    eth: '122.8444342',
    jpy: '105.7306912',
    gold_crude_corr: '-0.16616867',
    crude_btc_corr: '0.3671598344',
    crude_eth_corr: '0.3279695476',
    crude_jpy_corr: '0.2280080091',
    gold_btc_corr: '-0.159733768',
    gold_eth_corr: '0.1206443468',
    gold_jpy_corr: '-0.8985729681',
    btc_eth_corr: '0.6806530378',
    btc_jpy_corr: '0.1841358827',
    eth_jpy_corr: '-0.1388840643',
  },
  {
    date: '2023-06-30',
    crude: '86.00143575',
    gold: '110.440842',
    btc: '150.4305162',
    eth: '128.2140185',
    jpy: '106.0169995',
    gold_crude_corr: '-0.1888724226',
    crude_btc_corr: '0.3847199099',
    crude_eth_corr: '0.3435849616',
    crude_jpy_corr: '0.2665747784',
    gold_btc_corr: '-0.2065083597',
    gold_eth_corr: '0.07567561546',
    gold_jpy_corr: '-0.9054500609',
    btc_eth_corr: '0.6898360361',
    btc_jpy_corr: '0.2706992831',
    eth_jpy_corr: '-0.06014694003',
  },
  {
    date: '2023-07-01',
    crude: '86.00143575',
    gold: '110.440842',
    btc: '150.9874069',
    eth: '127.642116',
    jpy: '106.0169995',
    gold_crude_corr: '-0.2101311777',
    crude_btc_corr: '0.4014182582',
    crude_eth_corr: '0.3576866039',
    crude_jpy_corr: '0.3031524106',
    gold_btc_corr: '-0.2502148884',
    gold_eth_corr: '0.03377465498',
    gold_jpy_corr: '-0.912401426',
    btc_eth_corr: '0.6956954412',
    btc_jpy_corr: '0.3514992353',
    eth_jpy_corr: '0.0165672712',
  },
  {
    date: '2023-07-02',
    crude: '86.00143575',
    gold: '110.440842',
    btc: '151.1388944',
    eth: '128.4958488',
    jpy: '106.0169995',
    gold_crude_corr: '-0.2300940524',
    crude_btc_corr: '0.4171496392',
    crude_eth_corr: '0.3722741929',
    crude_jpy_corr: '0.3382368776',
    gold_btc_corr: '-0.2912391576',
    gold_eth_corr: '-0.005173377887',
    gold_jpy_corr: '-0.9195242504',
    btc_eth_corr: '0.7069271557',
    btc_jpy_corr: '0.4284795419',
    eth_jpy_corr: '0.08212529711',
  },
  {
    date: '2023-07-03',
    crude: '86.50394831',
    gold: '110.4988369',
    btc: '153.7828698',
    eth: '129.686392',
    jpy: '105.7372724',
    gold_crude_corr: '-0.2608834745',
    crude_btc_corr: '0.4408319164',
    crude_eth_corr: '0.4000933137',
    crude_jpy_corr: '0.3860524373',
    gold_btc_corr: '-0.319758279',
    gold_eth_corr: '-0.03807196372',
    gold_jpy_corr: '-0.9212580495',
    btc_eth_corr: '0.7241183933',
    btc_jpy_corr: '0.4673877218',
    eth_jpy_corr: '0.1262383428',
  },
  {
    date: '2023-07-04',
    crude: '86.14501077',
    gold: '110.4988369',
    btc: '151.9128942',
    eth: '128.4424718',
    jpy: '105.8734766',
    gold_crude_corr: '-0.3156634729',
    crude_btc_corr: '0.4525920739',
    crude_eth_corr: '0.4111435218',
    crude_jpy_corr: '0.4215430511',
    gold_btc_corr: '-0.3610049323',
    gold_eth_corr: '-0.07611395917',
    gold_jpy_corr: '-0.9265330897',
    btc_eth_corr: '0.7336528195',
    btc_jpy_corr: '0.5009419821',
    eth_jpy_corr: '0.1654717277',
  },
  {
    date: '2023-07-05',
    crude: '87.29361091',
    gold: '110.1508122',
    btc: '150.6127177',
    eth: '126.7150657',
    jpy: '105.7431384',
    gold_crude_corr: '-0.3964568698',
    crude_btc_corr: '0.4690248235',
    crude_eth_corr: '0.4149371728',
    crude_jpy_corr: '0.4737904819',
    gold_btc_corr: '-0.4191476028',
    gold_eth_corr: '-0.131338306',
    gold_jpy_corr: '-0.9349947653',
    btc_eth_corr: '0.7364063405',
    btc_jpy_corr: '0.5375002822',
    eth_jpy_corr: '0.2042195273',
  },
  {
    date: '2023-07-06',
    crude: '87.43718593',
    gold: '109.8027875',
    btc: '147.6273892',
    eth: '122.6062818',
    jpy: '105.8039108',
    gold_crude_corr: '-0.4998066691',
    crude_btc_corr: '0.4810657457',
    crude_eth_corr: '0.4041132488',
    crude_jpy_corr: '0.5444179187',
    gold_btc_corr: '-0.4841962188',
    gold_eth_corr: '-0.1639947615',
    gold_jpy_corr: '-0.9400799997',
    btc_eth_corr: '0.7289318476',
    btc_jpy_corr: '0.5788998857',
    eth_jpy_corr: '0.2233002052',
  },
  {
    date: '2023-07-07',
    crude: '87.68844221',
    gold: '110.6728549',
    btc: '149.7642467',
    eth: '124.0631272',
    jpy: '105.4099575',
    gold_crude_corr: '-0.5657909085',
    crude_btc_corr: '0.4923248906',
    crude_eth_corr: '0.3827320141',
    crude_jpy_corr: '0.6173654365',
    gold_btc_corr: '-0.5393259367',
    gold_eth_corr: '-0.2508768181',
    gold_jpy_corr: '-0.9372838031',
    btc_eth_corr: '0.7274944475',
    btc_jpy_corr: '0.6393646557',
    eth_jpy_corr: '0.3151389814',
  },
  {
    date: '2023-07-08',
    crude: '87.68844221',
    gold: '110.6728549',
    btc: '149.5188145',
    eth: '123.7273369',
    jpy: '105.4099575',
    gold_crude_corr: '-0.6334609941',
    crude_btc_corr: '0.5073447188',
    crude_eth_corr: '0.373658874',
    crude_jpy_corr: '0.6912371848',
    gold_btc_corr: '-0.5826456323',
    gold_eth_corr: '-0.2939759599',
    gold_jpy_corr: '-0.9341011928',
    btc_eth_corr: '0.7232809334',
    btc_jpy_corr: '0.6870860025',
    eth_jpy_corr: '0.3602630497',
  },
  {
    date: '2023-07-09',
    crude: '87.68844221',
    gold: '110.6728549',
    btc: '148.9200656',
    eth: '123.5595551',
    jpy: '105.4099575',
    gold_crude_corr: '-0.7036349027',
    crude_btc_corr: '0.5243205777',
    crude_eth_corr: '0.3700780492',
    crude_jpy_corr: '0.7669008019',
    gold_btc_corr: '-0.6182072621',
    gold_eth_corr: '-0.3255585611',
    gold_jpy_corr: '-0.9304385741',
    btc_eth_corr: '0.7207736683',
    btc_jpy_corr: '0.7264049575',
    eth_jpy_corr: '0.393270263',
  },
  {
    date: '2023-07-10',
    crude: '88.65757358',
    gold: '110.7308608',
    btc: '150.1206386',
    eth: '124.7232924',
    jpy: '104.1343526',
    gold_crude_corr: '-0.6902478527',
    crude_btc_corr: '0.5511138629',
    crude_eth_corr: '0.3906369424',
    crude_jpy_corr: '0.7502862968',
    gold_btc_corr: '-0.641939137',
    gold_eth_corr: '-0.3506996556',
    gold_jpy_corr: '-0.9283098547',
    btc_eth_corr: '0.7228741212',
    btc_jpy_corr: '0.7402794896',
    eth_jpy_corr: '0.4122276933',
  },
  {
    date: '2023-07-11',
    crude: '87.36539842',
    gold: '111.1368915',
    btc: '151.139791',
    eth: '124.576034',
    jpy: '103.4665271',
    gold_crude_corr: '-0.6894498867',
    crude_btc_corr: '0.5689502466',
    crude_eth_corr: '0.4051997145',
    crude_jpy_corr: '0.7393274501',
    gold_btc_corr: '-0.67434313',
    gold_eth_corr: '-0.3845301883',
    gold_jpy_corr: '-0.9288079725',
    btc_eth_corr: '0.7240178469',
    btc_jpy_corr: '0.7489798177',
    eth_jpy_corr: '0.4301799483',
  },
  {
    date: '2023-07-12',
    crude: '88.29863604',
    gold: '112.6450135',
    btc: '150.0079822',
    eth: '124.1633559',
    jpy: '102.6031887',
    gold_crude_corr: '-0.6754285066',
    crude_btc_corr: '0.583846987',
    crude_eth_corr: '0.412004189',
    crude_jpy_corr: '0.7182023792',
    gold_btc_corr: '-0.6923009446',
    gold_eth_corr: '-0.4098257717',
    gold_jpy_corr: '-0.9308017136',
    btc_eth_corr: '0.7244012465',
    btc_jpy_corr: '0.7501579125',
    eth_jpy_corr: '0.4419416512',
  },
  {
    date: '2023-07-13',
    crude: '88.87293611',
    gold: '112.7030195',
    btc: '155.3604071',
    eth: '133.0769542',
    jpy: '101.2829235',
    gold_crude_corr: '-0.6421515989',
    crude_btc_corr: '0.6043239286',
    crude_eth_corr: '0.451187234',
    crude_jpy_corr: '0.6698670088',
    gold_btc_corr: '-0.6730217831',
    gold_eth_corr: '-0.3702135904',
    gold_jpy_corr: '-0.9237263753',
    btc_eth_corr: '0.7397492742',
    btc_jpy_corr: '0.716525867',
    eth_jpy_corr: '0.3847784291',
  },
  {
    date: '2023-07-14',
    crude: '89.73438622',
    gold: '112.3549948',
    btc: '149.7237864',
    eth: '128.6224381',
    jpy: '101.1071764',
    gold_crude_corr: '-0.5812568807',
    crude_btc_corr: '0.6140681684',
    crude_eth_corr: '0.476233274',
    crude_jpy_corr: '0.5810613521',
    gold_btc_corr: '-0.6684054798',
    gold_eth_corr: '-0.3590039825',
    gold_jpy_corr: '-0.9133806816',
    btc_eth_corr: '0.7450324082',
    btc_jpy_corr: '0.6934331323',
    eth_jpy_corr: '0.3544132939',
  },
  {
    date: '2023-07-15',
    crude: '89.73438622',
    gold: '112.3549948',
    btc: '149.534933',
    eth: '128.0997837',
    jpy: '101.1071764',
    gold_crude_corr: '-0.515390672',
    crude_btc_corr: '0.6221558649',
    crude_eth_corr: '0.4914099097',
    crude_jpy_corr: '0.4880831949',
    gold_btc_corr: '-0.6647016737',
    gold_eth_corr: '-0.3417075114',
    gold_jpy_corr: '-0.9013737093',
    btc_eth_corr: '0.7485031909',
    btc_jpy_corr: '0.6700496941',
    eth_jpy_corr: '0.3192628908',
  },
  {
    date: '2023-07-16',
    crude: '89.73438622',
    gold: '112.3549948',
    btc: '149.304559',
    eth: '127.5834847',
    jpy: '101.1071764',
    gold_crude_corr: '-0.441798126',
    crude_btc_corr: '0.6310403909',
    crude_eth_corr: '0.504023264',
    crude_jpy_corr: '0.3883287698',
    gold_btc_corr: '-0.6656446055',
    gold_eth_corr: '-0.3263642992',
    gold_jpy_corr: '-0.8872137428',
    btc_eth_corr: '0.7513644752',
    btc_jpy_corr: '0.6490933787',
    eth_jpy_corr: '0.2864218453',
  },
  {
    date: '2023-07-17',
    crude: '89.73438622',
    gold: '112.4129897',
    btc: '148.7949635',
    eth: '126.785242',
    jpy: '101.5516672',
    gold_crude_corr: '-0.3443776569',
    crude_btc_corr: '0.6449373641',
    crude_eth_corr: '0.5211573616',
    crude_jpy_corr: '0.2981026401',
    gold_btc_corr: '-0.6797954362',
    gold_eth_corr: '-0.3233061287',
    gold_jpy_corr: '-0.8840241262',
    btc_eth_corr: '0.7539928139',
    btc_jpy_corr: '0.6322208486',
    eth_jpy_corr: '0.2671739541',
  },
  {
    date: '2023-07-18',
    crude: '87.00646088',
    gold: '113.7471048',
    btc: '147.3668989',
    eth: '125.8536174',
    jpy: '101.5860814',
    gold_crude_corr: '-0.2765662502',
    crude_btc_corr: '0.6474108943',
    crude_eth_corr: '0.5286983853',
    crude_jpy_corr: '0.2446574113',
    gold_btc_corr: '-0.6476795551',
    gold_eth_corr: '-0.2990456645',
    gold_jpy_corr: '-0.8749563552',
    btc_eth_corr: '0.7558301133',
    btc_jpy_corr: '0.6155175647',
    eth_jpy_corr: '0.253093717',
  },
  {
    date: '2023-07-19',
    crude: '89.30366116',
    gold: '113.7471048',
    btc: '147.6500246',
    eth: '125.2838199',
    jpy: '101.8042875',
    gold_crude_corr: '-0.1767132593',
    crude_btc_corr: '0.654518427',
    crude_eth_corr: '0.5335973165',
    crude_jpy_corr: '0.1711477292',
    gold_btc_corr: '-0.6156804323',
    gold_eth_corr: '-0.2755344987',
    gold_jpy_corr: '-0.8653085761',
    btc_eth_corr: '0.7564958019',
    btc_jpy_corr: '0.6034637314',
    eth_jpy_corr: '0.2404082379',
  },
  {
    date: '2023-07-20',
    crude: '89.23187365',
    gold: '113.1670562',
    btc: '147.0483065',
    eth: '125.4138905',
    jpy: '102.2224201',
    gold_crude_corr: '-0.1360788829',
    crude_btc_corr: '0.6527510276',
    crude_eth_corr: '0.5315280597',
    crude_jpy_corr: '0.1400521249',
    gold_btc_corr: '-0.5923942725',
    gold_eth_corr: '-0.2577489017',
    gold_jpy_corr: '-0.8670845015',
    btc_eth_corr: '0.7558122859',
    btc_jpy_corr: '0.5879391365',
    eth_jpy_corr: '0.223668439',
  },
  {
    date: '2023-07-21',
    crude: '90.84709261',
    gold: '112.8190314',
    btc: '147.6244586',
    eth: '125.4875723',
    jpy: '102.4171956',
    gold_crude_corr: '-0.09252506325',
    crude_btc_corr: '0.6482964658',
    crude_eth_corr: '0.5265214549',
    crude_jpy_corr: '0.1131502715',
    gold_btc_corr: '-0.5712527289',
    gold_eth_corr: '-0.2404149966',
    gold_jpy_corr: '-0.8656758493',
    btc_eth_corr: '0.7558810635',
    btc_jpy_corr: '0.5742553867',
    eth_jpy_corr: '0.2125637842',
  },
  {
    date: '2023-07-22',
    crude: '90.84709261',
    gold: '112.8190314',
    btc: '146.9485392',
    eth: '123.6857396',
    jpy: '102.4171956',
    gold_crude_corr: '-0.0504760709',
    crude_btc_corr: '0.6426458994',
    crude_eth_corr: '0.5108838323',
    crude_jpy_corr: '0.08759060416',
    gold_btc_corr: '-0.5530389044',
    gold_eth_corr: '-0.2304692075',
    gold_jpy_corr: '-0.8642368347',
    btc_eth_corr: '0.754020231',
    btc_jpy_corr: '0.5623656476',
    eth_jpy_corr: '0.2057727481',
  },
  {
    date: '2023-07-23',
    crude: '90.84709261',
    gold: '112.8190314',
    btc: '148.4921523',
    eth: '125.2960044',
    jpy: '102.4171956',
    gold_crude_corr: '-0.009261660627',
    crude_btc_corr: '0.6419150419',
    crude_eth_corr: '0.5062473573',
    crude_jpy_corr: '0.06295201244',
    gold_btc_corr: '-0.5273252944',
    gold_eth_corr: '-0.2096501917',
    gold_jpy_corr: '-0.8627688211',
    btc_eth_corr: '0.7527910677',
    btc_jpy_corr: '0.5463366489',
    eth_jpy_corr: '0.1926912278',
  },
  {
    date: '2023-07-24',
    crude: '92.31873654',
    gold: '112.2969888',
    btc: '144.0122798',
    eth: '122.696852',
    jpy: '103.7982443',
    gold_crude_corr: '0.03864201831',
    crude_btc_corr: '0.6152937559',
    crude_eth_corr: '0.4757556303',
    crude_jpy_corr: '0.04566702648',
    gold_btc_corr: '-0.5152746831',
    gold_eth_corr: '-0.2015513663',
    gold_jpy_corr: '-0.8529440666',
    btc_eth_corr: '0.7509109314',
    btc_jpy_corr: '0.5385187378',
    eth_jpy_corr: '0.1831889412',
  },
  {
    date: '2023-07-25',
    crude: '94.68772434',
    gold: '112.9350323',
    btc: '144.2614139',
    eth: '123.2101797',
    jpy: '103.6312907',
    gold_crude_corr: '0.1001637998',
    crude_btc_corr: '0.5797887774',
    crude_eth_corr: '0.4422132918',
    crude_jpy_corr: '0.04613015583',
    gold_btc_corr: '-0.4994654946',
    gold_eth_corr: '-0.2030337115',
    gold_jpy_corr: '-0.838810226',
    btc_eth_corr: '0.7537047399',
    btc_jpy_corr: '0.5336684399',
    eth_jpy_corr: '0.1846249024',
  },
  {
    date: '2023-07-26',
    crude: '94.47236181',
    gold: '113.5150809',
    btc: '144.891137',
    eth: '124.1664162',
    jpy: '103.1853362',
    gold_crude_corr: '0.1601687359',
    crude_btc_corr: '0.5631091667',
    crude_eth_corr: '0.4251976093',
    crude_jpy_corr: '0.03999253181',
    gold_btc_corr: '-0.4748978083',
    gold_eth_corr: '-0.186314233',
    gold_jpy_corr: '-0.8228800879',
    btc_eth_corr: '0.7513043186',
    btc_jpy_corr: '0.5224682929',
    eth_jpy_corr: '0.172962255',
  },
  {
    date: '2023-07-27',
    crude: '94.11342426',
    gold: '111.7749461',
    btc: '144.1789797',
    eth: '123.3836287',
    jpy: '102.7855281',
    gold_crude_corr: '0.1513991186',
    crude_btc_corr: '0.5553724625',
    crude_eth_corr: '0.4102396544',
    crude_jpy_corr: '0.02891130064',
    gold_btc_corr: '-0.4843375854',
    gold_eth_corr: '-0.1894402312',
    gold_jpy_corr: '-0.8293208493',
    btc_eth_corr: '0.7490235617',
    btc_jpy_corr: '0.5135951284',
    eth_jpy_corr: '0.164746503',
  },
  {
    date: '2023-07-28',
    crude: '93.75448672',
    gold: '112.6450135',
    btc: '144.7147968',
    eth: '124.3378574',
    jpy: '101.6849328',
    gold_crude_corr: '0.1716584405',
    crude_btc_corr: '0.5414754925',
    crude_eth_corr: '0.4004786183',
    crude_jpy_corr: '-0.01157214638',
    gold_btc_corr: '-0.480682995',
    gold_eth_corr: '-0.1850441558',
    gold_jpy_corr: '-0.8311752517',
    btc_eth_corr: '0.7503771716',
    btc_jpy_corr: '0.5018760994',
    eth_jpy_corr: '0.1568341878',
  },
  {
    date: '2023-07-29',
    crude: '93.75448672',
    gold: '112.6450135',
    btc: '144.9007387',
    eth: '124.7572795',
    jpy: '101.6849328',
    gold_crude_corr: '0.1900944668',
    crude_btc_corr: '0.5292986158',
    crude_eth_corr: '0.394825664',
    crude_jpy_corr: '-0.04803469787',
    gold_btc_corr: '-0.4769783301',
    gold_eth_corr: '-0.1798554397',
    gold_jpy_corr: '-0.8329706876',
    btc_eth_corr: '0.7518154673',
    btc_jpy_corr: '0.4903988487',
    eth_jpy_corr: '0.1479539117',
  },
  {
    date: '2023-07-30',
    crude: '93.75448672',
    gold: '112.6450135',
    btc: '144.4979288',
    eth: '123.4689607',
    jpy: '101.6849328',
    gold_crude_corr: '0.2070057063',
    crude_btc_corr: '0.5179260309',
    crude_eth_corr: '0.3919625718',
    crude_jpy_corr: '-0.08120355109',
    gold_btc_corr: '-0.4721144347',
    gold_eth_corr: '-0.1811491488',
    gold_jpy_corr: '-0.8347096719',
    btc_eth_corr: '0.7610227156',
    btc_jpy_corr: '0.4811814359',
    eth_jpy_corr: '0.1522972012',
  },
  {
    date: '2023-07-31',
    crude: '96.26704953',
    gold: '112.9350323',
    btc: '144.2748428',
    eth: '123.1054173',
    jpy: '103.0718364',
    gold_crude_corr: '0.2325161322',
    crude_btc_corr: '0.5002092001',
    crude_eth_corr: '0.3716188905',
    crude_jpy_corr: '-0.08067018266',
    gold_btc_corr: '-0.4659397157',
    gold_eth_corr: '-0.1816618176',
    gold_jpy_corr: '-0.8302035408',
    btc_eth_corr: '0.7695225232',
    btc_jpy_corr: '0.4818081131',
    eth_jpy_corr: '0.1529370347',
  },
  {
    date: '2023-08-01',
    crude: '98.52835607',
    gold: '111.7749461',
    btc: '146.4743525',
    eth: '124.1420229',
    jpy: '104.2178294',
    gold_crude_corr: '0.2187854638',
    crude_btc_corr: '0.4837514778',
    crude_eth_corr: '0.3609052804',
    crude_jpy_corr: '-0.0474945266',
    gold_btc_corr: '-0.461634979',
    gold_eth_corr: '-0.1905553451',
    gold_jpy_corr: '-0.8293282861',
    btc_eth_corr: '0.7811929609',
    btc_jpy_corr: '0.4832023825',
    eth_jpy_corr: '0.1574977044',
  },
  {
    date: '2023-08-02',
    crude: '99.17444365',
    gold: '111.2529035',
    btc: '143.8890962',
    eth: '121.9731169',
    jpy: '104.6916057',
    gold_crude_corr: '0.2056398883',
    crude_btc_corr: '0.4483508273',
    crude_eth_corr: '0.3363123202',
    crude_jpy_corr: '-0.015581671',
    gold_btc_corr: '-0.453771716',
    gold_eth_corr: '-0.1932537816',
    gold_jpy_corr: '-0.8290683989',
    btc_eth_corr: '0.7898968264',
    btc_jpy_corr: '0.4778063465',
    eth_jpy_corr: '0.1553436011',
  },
  {
    date: '2023-08-03',
    crude: '96.91313711',
    gold: '111.1948975',
    btc: '144.020985',
    eth: '121.7109274',
    jpy: '104.9654668',
    gold_crude_corr: '0.2282724694',
    crude_btc_corr: '0.4199238809',
    crude_eth_corr: '0.3207437541',
    crude_jpy_corr: '0.0001054673281',
    gold_btc_corr: '-0.4364554752',
    gold_eth_corr: '-0.1969466956',
    gold_jpy_corr: '-0.8290667905',
    btc_eth_corr: '0.7993247906',
    btc_jpy_corr: '0.466755917',
    eth_jpy_corr: '0.1508908478',
  },
  {
    date: '2023-08-04',
    crude: '98.4206748',
    gold: '111.6009282',
    btc: '143.504757',
    eth: '121.2185764',
    jpy: '104.4192084',
    gold_crude_corr: '0.2184197874',
    crude_btc_corr: '0.3923706161',
    crude_eth_corr: '0.3080665612',
    crude_jpy_corr: '0.006635551789',
    gold_btc_corr: '-0.4360755416',
    gold_eth_corr: '-0.1995967166',
    gold_jpy_corr: '-0.8316712305',
    btc_eth_corr: '0.8194623235',
    btc_jpy_corr: '0.4561528218',
    eth_jpy_corr: '0.161136255',
  },
  {
    date: '2023-08-05',
    crude: '98.4206748',
    gold: '111.6009282',
    btc: '143.346984',
    eth: '121.7010746',
    jpy: '104.4192084',
    gold_crude_corr: '0.2108185547',
    crude_btc_corr: '0.3645461963',
    crude_eth_corr: '0.2988158774',
    crude_jpy_corr: '0.009761009662',
    gold_btc_corr: '-0.4359113334',
    gold_eth_corr: '-0.2015919239',
    gold_jpy_corr: '-0.834688398',
    btc_eth_corr: '0.8386645289',
    btc_jpy_corr: '0.4435040651',
    eth_jpy_corr: '0.1696404321',
  },
  {
    date: '2023-08-06',
    crude: '98.4206748',
    gold: '111.6009282',
    btc: '143.345644',
    eth: '121.2019391',
    jpy: '104.4192084',
    gold_crude_corr: '0.2051040674',
    crude_btc_corr: '0.3378682067',
    crude_eth_corr: '0.2881982583',
    crude_jpy_corr: '0.009767648489',
    gold_btc_corr: '-0.4356137724',
    gold_eth_corr: '-0.203282957',
    gold_jpy_corr: '-0.8381500973',
    btc_eth_corr: '0.8575194461',
    btc_jpy_corr: '0.4301633154',
    eth_jpy_corr: '0.1775000155',
  },
  {
    date: '2023-08-07',
    crude: '99.46159368',
    gold: '111.3109094',
    btc: '144.0303554',
    eth: '121.1672396',
    jpy: '103.8905146',
    gold_crude_corr: '0.1924247031',
    crude_btc_corr: '0.319468975',
    crude_eth_corr: '0.2592565415',
    crude_jpy_corr: '0.0146310562',
    gold_btc_corr: '-0.4229148014',
    gold_eth_corr: '-0.1936295167',
    gold_jpy_corr: '-0.8381448883',
    btc_eth_corr: '0.8663613855',
    btc_jpy_corr: '0.4279434566',
    eth_jpy_corr: '0.1714694204',
  },
  {
    date: '2023-08-08',
    crude: '99.67695621',
    gold: '110.6148489',
    btc: '146.9173914',
    eth: '123.0818741',
    jpy: '104.3650172',
    gold_crude_corr: '0.1659431107',
    crude_btc_corr: '0.3104544184',
    crude_eth_corr: '0.2583799901',
    crude_jpy_corr: '0.02205752976',
    gold_btc_corr: '-0.4134149409',
    gold_eth_corr: '-0.2028881268',
    gold_jpy_corr: '-0.8358992672',
    btc_eth_corr: '0.8844225998',
    btc_jpy_corr: '0.4201364749',
    eth_jpy_corr: '0.1796503616',
  },
  {
    date: '2023-08-09',
    crude: '100.0717875',
    gold: '110.0928062',
    btc: '145.9104918',
    eth: '122.9817426',
    jpy: '104.9171417',
    gold_crude_corr: '0.1086326693',
    crude_btc_corr: '0.2898775121',
    crude_eth_corr: '0.2473003066',
    crude_jpy_corr: '0.03836881878',
    gold_btc_corr: '-0.4252185286',
    gold_eth_corr: '-0.2008185561',
    gold_jpy_corr: '-0.8446561871',
    btc_eth_corr: '0.8977716496',
    btc_jpy_corr: '0.4084397228',
    eth_jpy_corr: '0.1757928077',
  },
  {
    date: '2023-08-10',
    crude: '102.6202441',
    gold: '110.0348003',
    btc: '145.2594444',
    eth: '122.7466829',
    jpy: '105.2459314',
    gold_crude_corr: '0.06051857885',
    crude_btc_corr: '0.2693632557',
    crude_eth_corr: '0.2339587433',
    crude_jpy_corr: '0.07044247305',
    gold_btc_corr: '-0.4030276669',
    gold_eth_corr: '-0.1970838463',
    gold_jpy_corr: '-0.8468618355',
    btc_eth_corr: '0.9158191559',
    btc_jpy_corr: '0.3976220011',
    eth_jpy_corr: '0.1731415405',
  },
  {
    date: '2023-08-11',
    crude: '102.6202441',
    gold: '110.0348003',
    btc: '145.1021052',
    eth: '122.5059965',
    jpy: '106.0711907',
    gold_crude_corr: '0.02355632635',
    crude_btc_corr: '0.238878225',
    crude_eth_corr: '0.219722933',
    crude_jpy_corr: '0.1037590732',
    gold_btc_corr: '-0.3839796252',
    gold_eth_corr: '-0.1906890447',
    gold_jpy_corr: '-0.8496225143',
    btc_eth_corr: '0.9340462288',
    btc_jpy_corr: '0.3704022089',
    eth_jpy_corr: '0.1659280464',
  },
  {
    date: '2023-08-12',
    crude: '102.6202441',
    gold: '110.0348003',
    btc: '145.192184',
    eth: '122.6230892',
    jpy: '106.0711907',
    gold_crude_corr: '-0.006675182547',
    crude_btc_corr: '0.2030966804',
    crude_eth_corr: '0.1885321681',
    crude_jpy_corr: '0.1305543566',
    gold_btc_corr: '-0.3619378631',
    gold_eth_corr: '-0.1675051195',
    gold_jpy_corr: '-0.8518615536',
    btc_eth_corr: '0.9352442968',
    btc_jpy_corr: '0.3370939935',
    eth_jpy_corr: '0.1351449764',
  },
  {
    date: '2023-08-13',
    crude: '102.6202441',
    gold: '110.0348003',
    btc: '144.5354681',
    eth: '121.9857466',
    jpy: '106.0711907',
    gold_crude_corr: '-0.0312707752',
    crude_btc_corr: '0.1615721699',
    crude_eth_corr: '0.1519867513',
    crude_jpy_corr: '0.1518698894',
    gold_btc_corr: '-0.3354635903',
    gold_eth_corr: '-0.1396592256',
    gold_jpy_corr: '-0.8536218824',
    btc_eth_corr: '0.9368302847',
    btc_jpy_corr: '0.2978726983',
    eth_jpy_corr: '0.09875047955',
  },
  {
    date: '2023-08-14',
    crude: '100.7178751',
    gold: '109.6867755',
    btc: '145.1550593',
    eth: '122.3111012',
    jpy: '106.0440951',
    gold_crude_corr: '-0.05068812802',
    crude_btc_corr: '0.1079400253',
    crude_eth_corr: '0.108840882',
    crude_jpy_corr: '0.1619506781',
    gold_btc_corr: '-0.3088280254',
    gold_eth_corr: '-0.1098869999',
    gold_jpy_corr: '-0.8562614509',
    btc_eth_corr: '0.9371682737',
    btc_jpy_corr: '0.2638761294',
    eth_jpy_corr: '0.06427892809',
  },
  {
    date: '2023-08-15',
    crude: '101.3639627',
    gold: '109.3967567',
    btc: '143.9798595',
    eth: '121.1668348',
    jpy: '106.4980944',
    gold_crude_corr: '-0.09096235293',
    crude_btc_corr: '0.02824920948',
    crude_eth_corr: '0.04490165167',
    crude_jpy_corr: '0.1741998127',
    gold_btc_corr: '-0.3025088418',
    gold_eth_corr: '-0.08957005079',
    gold_jpy_corr: '-0.8674352351',
    btc_eth_corr: '0.9374655326',
    btc_jpy_corr: '0.2195284571',
    eth_jpy_corr: '0.01845876516',
  },
  {
    date: '2023-08-16',
    crude: '99.31801866',
    gold: '108.7587021',
    btc: '141.667086',
    eth: '119.75591',
    jpy: '106.6233152',
    gold_crude_corr: '-0.1276502928',
    crude_btc_corr: '-0.06688195335',
    crude_eth_corr: '-0.04839290329',
    crude_jpy_corr: '0.1895522572',
    gold_btc_corr: '-0.2791874214',
    gold_eth_corr: '-0.04471237111',
    gold_jpy_corr: '-0.8743310328',
    btc_eth_corr: '0.9242555122',
    btc_jpy_corr: '0.169330438',
    eth_jpy_corr: '-0.05230980085',
  },
  {
    date: '2023-08-17',
    crude: '98.8155061',
    gold: '108.5266783',
    btc: '131.6116736',
    eth: '111.7490815',
    jpy: '107.154199',
    gold_crude_corr: '-0.1442394064',
    crude_btc_corr: '-0.2097119686',
    crude_eth_corr: '-0.1918959121',
    crude_jpy_corr: '0.2019450139',
    gold_btc_corr: '-0.1300235764',
    gold_eth_corr: '0.1210785369',
    gold_jpy_corr: '-0.8800688107',
    btc_eth_corr: '0.9175882527',
    btc_jpy_corr: '0.03860307203',
    eth_jpy_corr: '-0.1967775393',
  },
  {
    date: '2023-08-18',
    crude: '99.03086863',
    gold: '108.5846842',
    btc: '128.5761675',
    eth: '110.1581095',
    jpy: '106.7009371',
    gold_crude_corr: '-0.1637147525',
    crude_btc_corr: '-0.3253708909',
    crude_eth_corr: '-0.2951028648',
    crude_jpy_corr: '0.2139328434',
    gold_btc_corr: '0.02270278273',
    gold_eth_corr: '0.257844078',
    gold_jpy_corr: '-0.8828758737',
    btc_eth_corr: '0.9236741333',
    btc_jpy_corr: '-0.08085409285',
    eth_jpy_corr: '-0.3009791315',
  },
  {
    date: '2023-08-19',
    crude: '99.03086863',
    gold: '108.5846842',
    btc: '128.8064162',
    eth: '110.7236323',
    jpy: '106.7009371',
    gold_crude_corr: '-0.1793469942',
    crude_btc_corr: '-0.432049206',
    crude_eth_corr: '-0.3839796988',
    crude_jpy_corr: '0.2233736413',
    gold_btc_corr: '0.1592426276',
    gold_eth_corr: '0.3697823215',
    gold_jpy_corr: '-0.8852873314',
    btc_eth_corr: '0.9304346595',
    btc_jpy_corr: '-0.1899878094',
    eth_jpy_corr: '-0.3885158798',
  },
  {
    date: '2023-08-20',
    crude: '99.03086863',
    gold: '108.5846842',
    btc: '129.2673185',
    eth: '111.7436815',
    jpy: '106.7009371',
    gold_crude_corr: '-0.1915820286',
    crude_btc_corr: '-0.5430486622',
    crude_eth_corr: '-0.4709631899',
    crude_jpy_corr: '0.2304405308',
    gold_btc_corr: '0.2882050545',
    gold_eth_corr: '0.4696957087',
    gold_jpy_corr: '-0.8873328963',
    btc_eth_corr: '0.9354345594',
    btc_jpy_corr: '-0.2964848828',
    eth_jpy_corr: '-0.4703735782',
  },
  {
    date: '2023-08-21',
    crude: '100.7896626',
    gold: '108.8747141',
    btc: '128.9443013',
    eth: '110.5775316',
    jpy: '106.401433',
    gold_crude_corr: '-0.2057025066',
    crude_btc_corr: '-0.6361206667',
    crude_eth_corr: '-0.5485920101',
    crude_jpy_corr: '0.2497317742',
    gold_btc_corr: '0.389418197',
    gold_eth_corr: '0.5474165338',
    gold_jpy_corr: '-0.8934455242',
    btc_eth_corr: '0.941668469',
    btc_jpy_corr: '-0.351738507',
    eth_jpy_corr: '-0.5091624411',
  },
  {
    date: '2023-08-22',
    crude: '100.0717875',
    gold: '109.1067269',
    btc: '128.4878142',
    eth: '108.3639194',
    jpy: '107.0846443',
    gold_crude_corr: '-0.2263065666',
    crude_btc_corr: '-0.6734130006',
    crude_eth_corr: '-0.5866027334',
    crude_jpy_corr: '0.2711463586',
    gold_btc_corr: '0.4265965583',
    gold_eth_corr: '0.5706198975',
    gold_jpy_corr: '-0.8967960311',
    btc_eth_corr: '0.9484761158',
    btc_jpy_corr: '-0.3995023854',
    eth_jpy_corr: '-0.5437144024',
  },
  {
    date: '2023-08-23',
    crude: '99.46159368',
    gold: '110.2088182',
    btc: '130.4620688',
    eth: '111.3737584',
    jpy: '106.736815',
    gold_crude_corr: '-0.2339584234',
    crude_btc_corr: '-0.6786306244',
    crude_eth_corr: '-0.5944837325',
    crude_jpy_corr: '0.2822983634',
    gold_btc_corr: '0.4275724747',
    gold_eth_corr: '0.5654540868',
    gold_jpy_corr: '-0.8950130207',
    btc_eth_corr: '0.9529467671',
    btc_jpy_corr: '-0.4238419635',
    eth_jpy_corr: '-0.5595857748',
  },
  {
    date: '2023-08-24',
    crude: '98.0258435',
    gold: '110.2088182',
    btc: '129.13301',
    eth: '110.0917546',
    jpy: '105.9386513',
    gold_crude_corr: '-0.2538327031',
    crude_btc_corr: '-0.6759414601',
    crude_eth_corr: '-0.5961047104',
    crude_jpy_corr: '0.2869434093',
    gold_btc_corr: '0.4334700796',
    gold_eth_corr: '0.5640325446',
    gold_jpy_corr: '-0.9032777709',
    btc_eth_corr: '0.957319223',
    btc_jpy_corr: '-0.4326349668',
    eth_jpy_corr: '-0.561468268',
  },
  {
    date: '2023-08-25',
    crude: '98.95908112',
    gold: '110.0348003',
    btc: '128.5668453',
    eth: '109.6268652',
    jpy: '106.9594235',
    gold_crude_corr: '-0.2788947535',
    crude_btc_corr: '-0.6713503669',
    crude_eth_corr: '-0.599950897',
    crude_jpy_corr: '0.3108186918',
    gold_btc_corr: '0.4493520969',
    gold_eth_corr: '0.56910872',
    gold_jpy_corr: '-0.9020662182',
    btc_eth_corr: '0.9618127359',
    btc_jpy_corr: '-0.4636883429',
    eth_jpy_corr: '-0.5823248128',
  },
  {
    date: '2023-08-26',
    crude: '98.95908112',
    gold: '110.0348003',
    btc: '128.3733357',
    eth: '109.1872598',
    jpy: '106.9594235',
    gold_crude_corr: '-0.3047095447',
    crude_btc_corr: '-0.6686316808',
    crude_eth_corr: '-0.6073905827',
    crude_jpy_corr: '0.3338484991',
    gold_btc_corr: '0.4638379771',
    gold_eth_corr: '0.5723509681',
    gold_jpy_corr: '-0.9012578042',
    btc_eth_corr: '0.9663858473',
    btc_jpy_corr: '-0.4905629524',
    eth_jpy_corr: '-0.5997027431',
  },
  {
    date: '2023-08-27',
    crude: '98.95908112',
    gold: '110.0348003',
    btc: '128.7742755',
    eth: '109.9304901',
    jpy: '106.9594235',
    gold_crude_corr: '-0.3314715988',
    crude_btc_corr: '-0.667479679',
    crude_eth_corr: '-0.6097892276',
    crude_jpy_corr: '0.356256441',
    gold_btc_corr: '0.478192236',
    gold_eth_corr: '0.5815156459',
    gold_jpy_corr: '-0.9008138827',
    btc_eth_corr: '0.9685237424',
    btc_jpy_corr: '-0.5141117109',
    eth_jpy_corr: '-0.6173754054',
  },
  {
    date: '2023-08-28',
    crude: '99.96410625',
    gold: '110.3248301',
    btc: '128.8555046',
    eth: '109.5951855',
    jpy: '107.2991968',
    gold_crude_corr: '-0.3510947561',
    crude_btc_corr: '-0.6720268642',
    crude_eth_corr: '-0.6245329354',
    crude_jpy_corr: '0.3862992706',
    gold_btc_corr: '0.4831127411',
    gold_eth_corr: '0.5787110398',
    gold_jpy_corr: '-0.8950563557',
    btc_eth_corr: '0.9719818365',
    btc_jpy_corr: '-0.5404355674',
    eth_jpy_corr: '-0.6351630407',
  },
  {
    date: '2023-08-29',
    crude: '99.96410625',
    gold: '111.3689154',
    btc: '136.8576794',
    eth: '114.7198168',
    jpy: '107.2501342',
    gold_crude_corr: '-0.3692137225',
    crude_btc_corr: '-0.670108374',
    crude_eth_corr: '-0.6301533441',
    crude_jpy_corr: '0.4132293733',
    gold_btc_corr: '0.4930734752',
    gold_eth_corr: '0.5786720067',
    gold_jpy_corr: '-0.8767821529',
    btc_eth_corr: '0.9729386206',
    btc_jpy_corr: '-0.5567908371',
    eth_jpy_corr: '-0.6482819563',
  },
  {
    date: '2023-08-30',
    crude: '101.758794',
    gold: '111.7169402',
    btc: '134.7346461',
    eth: '113.0873851',
    jpy: '106.9015786',
    gold_crude_corr: '-0.3945999433',
    crude_btc_corr: '-0.6797291533',
    crude_eth_corr: '-0.6599200272',
    crude_jpy_corr: '0.4514005871',
    gold_btc_corr: '0.4927900336',
    gold_eth_corr: '0.5621346117',
    gold_jpy_corr: '-0.8580962078',
    btc_eth_corr: '0.9768387306',
    btc_jpy_corr: '-0.572479976',
    eth_jpy_corr: '-0.6574370022',
  },
  {
    date: '2023-08-31',
    crude: '101.758794',
    gold: '111.5429223',
    btc: '127.9933251',
    eth: '109.1429827',
    jpy: '106.9828542',
    gold_crude_corr: '-0.420392159',
    crude_btc_corr: '-0.6856453689',
    crude_eth_corr: '-0.6799400907',
    crude_jpy_corr: '0.4915627373',
    gold_btc_corr: '0.4872412305',
    gold_eth_corr: '0.5427674788',
    gold_jpy_corr: '-0.8411691185',
    btc_eth_corr: '0.9813558941',
    btc_jpy_corr: '-0.5982657416',
    eth_jpy_corr: '-0.6709433963',
  },
  {
    date: '2023-09-01',
    crude: '103.6252692',
    gold: '111.5429223',
    btc: '127.347975',
    eth: '108.005687',
    jpy: '106.5244638',
    gold_crude_corr: '-0.4246511369',
    crude_btc_corr: '-0.6947666173',
    crude_eth_corr: '-0.6870785988',
    crude_jpy_corr: '0.531002405',
    gold_btc_corr: '0.4737639891',
    gold_eth_corr: '0.5291666874',
    gold_jpy_corr: '-0.8292374545',
    btc_eth_corr: '0.9823640781',
    btc_jpy_corr: '-0.6217928531',
    eth_jpy_corr: '-0.6958362706',
  },
  {
    date: '2023-09-02',
    crude: '103.6252692',
    gold: '111.5429223',
    btc: '127.6839777',
    eth: '108.5716955',
    jpy: '106.5244638',
    gold_crude_corr: '-0.4313387994',
    crude_btc_corr: '-0.701360068',
    crude_eth_corr: '-0.6936916047',
    crude_jpy_corr: '0.5712662195',
    gold_btc_corr: '0.4649782359',
    gold_eth_corr: '0.5191534035',
    gold_jpy_corr: '-0.8176220102',
    btc_eth_corr: '0.9829798877',
    btc_jpy_corr: '-0.6468752646',
    eth_jpy_corr: '-0.7195480058',
  },
  {
    date: '2023-09-03',
    crude: '103.6252692',
    gold: '111.5429223',
    btc: '128.1813493',
    eth: '108.5114935',
    jpy: '106.5244638',
    gold_crude_corr: '-0.4405451014',
    crude_btc_corr: '-0.7062288453',
    crude_eth_corr: '-0.697246277',
    crude_jpy_corr: '0.6126933742',
    gold_btc_corr: '0.4600114922',
    gold_eth_corr: '0.513937257',
    gold_jpy_corr: '-0.8063153836',
    btc_eth_corr: '0.9834804246',
    btc_jpy_corr: '-0.6726433808',
    eth_jpy_corr: '-0.7466315292',
  },
  {
    date: '2023-09-04',
    crude: '105.994257',
    gold: '111.5429223',
    btc: '127.4056818',
    eth: '108.0828906',
    jpy: '106.9521049',
    gold_crude_corr: '-0.4424117225',
    crude_btc_corr: '-0.7100204354',
    crude_eth_corr: '-0.7019012127',
    crude_jpy_corr: '0.6514078495',
    gold_btc_corr: '0.4601829422',
    gold_eth_corr: '0.5123989371',
    gold_jpy_corr: '-0.7917789009',
    btc_eth_corr: '0.9837109644',
    btc_jpy_corr: '-0.7052747296',
    eth_jpy_corr: '-0.7775922276',
  },
  {
    date: '2023-09-05',
    crude: '106.9992821',
    gold: '110.6728549',
    btc: '127.2455951',
    eth: '108.3464644',
    jpy: '107.3079791',
    gold_crude_corr: '-0.4642083681',
    crude_btc_corr: '-0.7158699227',
    crude_eth_corr: '-0.7075363137',
    crude_jpy_corr: '0.6969380796',
    gold_btc_corr: '0.4746049346',
    gold_eth_corr: '0.5267886263',
    gold_jpy_corr: '-0.786849162',
    btc_eth_corr: '0.9840185054',
    btc_jpy_corr: '-0.736438409',
    eth_jpy_corr: '-0.8078922327',
  },
  {
    date: '2023-09-06',
    crude: '108.2914573',
    gold: '110.1508122',
    btc: '127.113581',
    eth: '108.2551333',
    jpy: '107.9875143',
    gold_crude_corr: '-0.4996720201',
    crude_btc_corr: '-0.7229564578',
    crude_eth_corr: '-0.7157728303',
    crude_jpy_corr: '0.7391181455',
    gold_btc_corr: '0.5010045076',
    gold_eth_corr: '0.5510616827',
    gold_jpy_corr: '-0.7851686354',
    btc_eth_corr: '0.9844225061',
    btc_jpy_corr: '-0.7646372151',
    eth_jpy_corr: '-0.8323861497',
  },
  {
    date: '2023-09-07',
    crude: '109.2246949',
    gold: '110.3248301',
    btc: '129.5171274',
    eth: '109.2729156',
    jpy: '108.1617977',
    gold_crude_corr: '-0.5391509707',
    crude_btc_corr: '-0.7285930425',
    crude_eth_corr: '-0.7298172108',
    crude_jpy_corr: '0.7807368165',
    gold_btc_corr: '0.5243197936',
    gold_eth_corr: '0.5670105594',
    gold_jpy_corr: '-0.7817836332',
    btc_eth_corr: '0.9854572947',
    btc_jpy_corr: '-0.783471331',
    eth_jpy_corr: '-0.8451051892',
  },
  {
    date: '2023-09-08',
    crude: '108.1119885',
    gold: '110.3248301',
    btc: '127.86589',
    eth: '108.5128294',
    jpy: '107.884998',
    gold_crude_corr: '-0.5608215968',
    crude_btc_corr: '-0.733036727',
    crude_eth_corr: '-0.7402532178',
    crude_jpy_corr: '0.8124480743',
    gold_btc_corr: '0.539509541',
    gold_eth_corr: '0.5765933568',
    gold_jpy_corr: '-0.7800615316',
    btc_eth_corr: '0.9868056665',
    btc_jpy_corr: '-0.8031952177',
    eth_jpy_corr: '-0.8574479057',
  },
  {
    date: '2023-09-09',
    crude: '108.1119885',
    gold: '110.3248301',
    btc: '127.8166474',
    eth: '108.4481341',
    jpy: '107.884998',
    gold_crude_corr: '-0.5834385135',
    crude_btc_corr: '-0.7368180309',
    crude_eth_corr: '-0.7505992015',
    crude_jpy_corr: '0.8435687261',
    gold_btc_corr: '0.554573968',
    gold_eth_corr: '0.5857829418',
    gold_jpy_corr: '-0.778672795',
    btc_eth_corr: '0.988265118',
    btc_jpy_corr: '-0.821737245',
    eth_jpy_corr: '-0.8685372962',
  },
  {
    date: '2023-09-10',
    crude: '108.1119885',
    gold: '110.3248301',
    btc: '127.5034633',
    eth: '107.23221',
    jpy: '107.884998',
    gold_crude_corr: '-0.6071753812',
    crude_btc_corr: '-0.7409942415',
    crude_eth_corr: '-0.7622903197',
    crude_jpy_corr: '0.8745179046',
    gold_btc_corr: '0.5688384526',
    gold_eth_corr: '0.594198393',
    gold_jpy_corr: '-0.7775818723',
    btc_eth_corr: '0.9893098868',
    btc_jpy_corr: '-0.838620793',
    eth_jpy_corr: '-0.8790251669',
  },
  {
    date: '2023-09-11',
    crude: '108.3991385',
    gold: '110.5568429',
    btc: '124.1985688',
    eth: '102.908566',
    jpy: '107.7253742',
    gold_crude_corr: '-0.6231972556',
    crude_btc_corr: '-0.7460011733',
    crude_eth_corr: '-0.7730126404',
    crude_jpy_corr: '0.8862575785',
    gold_btc_corr: '0.5793826599',
    gold_eth_corr: '0.5955105369',
    gold_jpy_corr: '-0.7782685298',
    btc_eth_corr: '0.9896611287',
    btc_jpy_corr: '-0.8465109424',
    eth_jpy_corr: '-0.8807626901',
  },
  {
    date: '2023-09-12',
    crude: '109.3682699',
    gold: '109.9187994',
    btc: '127.5089775',
    eth: '105.6139786',
    jpy: '107.4068305',
    gold_crude_corr: '-0.6450165133',
    crude_btc_corr: '-0.7444529741',
    crude_eth_corr: '-0.7831844088',
    crude_jpy_corr: '0.8927372267',
    gold_btc_corr: '0.5948223836',
    gold_eth_corr: '0.605539362',
    gold_jpy_corr: '-0.7842085178',
    btc_eth_corr: '0.9908160161',
    btc_jpy_corr: '-0.8504898955',
    eth_jpy_corr: '-0.8811455313',
  },
  {
    date: '2023-09-13',
    crude: '111.5936827',
    gold: '109.6867755',
    btc: '129.4585337',
    eth: '106.6458966',
    jpy: '107.7583247',
    gold_crude_corr: '-0.6417206488',
    crude_btc_corr: '-0.7375258677',
    crude_eth_corr: '-0.7898619759',
    crude_jpy_corr: '0.8900483398',
    gold_btc_corr: '0.5924073098',
    gold_eth_corr: '0.6079554195',
    gold_jpy_corr: '-0.7839391604',
    btc_eth_corr: '0.9911115183',
    btc_jpy_corr: '-0.850006703',
    eth_jpy_corr: '-0.8825938422',
  },
  {
    date: '2023-09-14',
    crude: '111.701364',
    gold: '109.7447815',
    btc: '130.9953023',
    eth: '107.9050859',
    jpy: '107.8959815',
    gold_crude_corr: '-0.6359712834',
    crude_btc_corr: '-0.7213624102',
    crude_eth_corr: '-0.7825835181',
    crude_jpy_corr: '0.8838785563',
    gold_btc_corr: '0.5851433819',
    gold_eth_corr: '0.6033083178',
    gold_jpy_corr: '-0.7823131962',
    btc_eth_corr: '0.9894618403',
    btc_jpy_corr: '-0.8432513767',
    eth_jpy_corr: '-0.8792950843',
  },
  {
    date: '2023-09-15',
    crude: '113.9267767',
    gold: '110.4988369',
    btc: '131.3359709',
    eth: '108.8777734',
    jpy: '107.9955704',
    gold_crude_corr: '-0.6142328843',
    crude_btc_corr: '-0.7048440992',
    crude_eth_corr: '-0.7720805223',
    crude_jpy_corr: '0.8754702523',
    gold_btc_corr: '0.5782812331',
    gold_eth_corr: '0.5957083697',
    gold_jpy_corr: '-0.7784051898',
    btc_eth_corr: '0.9887306638',
    btc_jpy_corr: '-0.8390540554',
    eth_jpy_corr: '-0.8765535274',
  },
  {
    date: '2023-09-16',
    crude: '113.9267767',
    gold: '110.4988369',
    btc: '131.1365036',
    eth: '108.4520688',
    jpy: '107.9955704',
    gold_crude_corr: '-0.5936750219',
    crude_btc_corr: '-0.6896219201',
    crude_eth_corr: '-0.7635250498',
    crude_jpy_corr: '0.8678985196',
    gold_btc_corr: '0.5706872053',
    gold_eth_corr: '0.5871700045',
    gold_jpy_corr: '-0.7746028853',
    btc_eth_corr: '0.9877333626',
    btc_jpy_corr: '-0.8347946082',
    eth_jpy_corr: '-0.8741505083',
  },
  {
    date: '2023-09-17',
    crude: '113.9267767',
    gold: '110.4988369',
    btc: '130.9682227',
    eth: '107.6346466',
    jpy: '107.9955704',
    gold_crude_corr: '-0.5737759664',
    crude_btc_corr: '-0.6751442409',
    crude_eth_corr: '-0.7575267966',
    crude_jpy_corr: '0.8608562992',
    gold_btc_corr: '0.5623198789',
    gold_eth_corr: '0.5773841655',
    gold_jpy_corr: '-0.7709567023',
    btc_eth_corr: '0.9861432887',
    btc_jpy_corr: '-0.8305158963',
    eth_jpy_corr: '-0.8723969943',
  },
  {
    date: '2023-09-18',
    crude: '113.9267767',
    gold: '111.1368915',
    btc: '132.0545679',
    eth: '108.5930285',
    jpy: '108.278225',
    gold_crude_corr: '-0.5385166905',
    crude_btc_corr: '-0.657522911',
    crude_eth_corr: '-0.7492189139',
    crude_jpy_corr: '0.8558899028',
    gold_btc_corr: '0.5487664808',
    gold_eth_corr: '0.5599380748',
    gold_jpy_corr: '-0.7542327014',
    btc_eth_corr: '0.9844653895',
    btc_jpy_corr: '-0.82339506',
    eth_jpy_corr: '-0.8699688434',
  },
  {
    date: '2023-09-19',
    crude: '114.0703518',
    gold: '111.0208796',
    btc: '134.3094322',
    eth: '109.0040712',
    jpy: '108.0856395',
    gold_crude_corr: '-0.4831125485',
    crude_btc_corr: '-0.6332171885',
    crude_eth_corr: '-0.7403342843',
    crude_jpy_corr: '0.8462865613',
    gold_btc_corr: '0.5302968999',
    gold_eth_corr: '0.5352932239',
    gold_jpy_corr: '-0.7264657528',
    btc_eth_corr: '0.981057855',
    btc_jpy_corr: '-0.8150769995',
    eth_jpy_corr: '-0.8683814645',
  },
  {
    date: '2023-09-20',
    crude: '112.6704953',
    gold: '111.1368915',
    btc: '133.9189618',
    eth: '107.6342418',
    jpy: '108.1859659',
    gold_crude_corr: '-0.4298017309',
    crude_btc_corr: '-0.6118907881',
    crude_eth_corr: '-0.7360701245',
    crude_jpy_corr: '0.8401345115',
    gold_btc_corr: '0.507610095',
    gold_eth_corr: '0.5057351017',
    gold_jpy_corr: '-0.693197711',
    btc_eth_corr: '0.9760185142',
    btc_jpy_corr: '-0.8053138169',
    eth_jpy_corr: '-0.8678759201',
  },
  {
    date: '2023-09-21',
    crude: '112.3474515',
    gold: '110.3828361',
    btc: '131.1333031',
    eth: '105.075278',
    jpy: '108.6099422',
    gold_crude_corr: '-0.3949732784',
    crude_btc_corr: '-0.5986102204',
    crude_eth_corr: '-0.7348985076',
    crude_jpy_corr: '0.8359914012',
    gold_btc_corr: '0.4895228983',
    gold_eth_corr: '0.4838773172',
    gold_jpy_corr: '-0.6716244865',
    btc_eth_corr: '0.971218085',
    btc_jpy_corr: '-0.7982887806',
    eth_jpy_corr: '-0.8674841564',
  },
  {
    date: '2023-09-22',
    crude: '113.4960517',
    gold: '110.6728549',
    btc: '131.1922149',
    eth: '105.6696145',
    jpy: '108.1141988',
    gold_crude_corr: '-0.3582831808',
    crude_btc_corr: '-0.5825137777',
    crude_eth_corr: '-0.7320405624',
    crude_jpy_corr: '0.8293279029',
    gold_btc_corr: '0.4681914137',
    gold_eth_corr: '0.458050119',
    gold_jpy_corr: '-0.6498272572',
    btc_eth_corr: '0.9671161895',
    btc_jpy_corr: '-0.7908110931',
    eth_jpy_corr: '-0.8650838418',
  },
  {
    date: '2023-09-23',
    crude: '113.4960517',
    gold: '110.6728549',
    btc: '131.1913376',
    eth: '105.7087101',
    jpy: '108.1141988',
    gold_crude_corr: '-0.3180797294',
    crude_btc_corr: '-0.5660816494',
    crude_eth_corr: '-0.7310449163',
    crude_jpy_corr: '0.8217971619',
    gold_btc_corr: '0.4456745241',
    gold_eth_corr: '0.4342340378',
    gold_jpy_corr: '-0.6253065531',
    btc_eth_corr: '0.9634558001',
    btc_jpy_corr: '-0.7834095071',
    eth_jpy_corr: '-0.8651735972',
  },
  {
    date: '2023-09-24',
    crude: '113.4960517',
    gold: '110.6728549',
    btc: '129.5992145',
    eth: '104.8462255',
    jpy: '108.1141988',
    gold_crude_corr: '-0.2734525188',
    crude_btc_corr: '-0.5487768429',
    crude_eth_corr: '-0.7274112174',
    crude_jpy_corr: '0.8131746526',
    gold_btc_corr: '0.4154755981',
    gold_eth_corr: '0.4024105833',
    gold_jpy_corr: '-0.5973968126',
    btc_eth_corr: '0.9602975488',
    btc_jpy_corr: '-0.7740147683',
    eth_jpy_corr: '-0.862118064',
  },
  {
    date: '2023-09-25',
    crude: '112.8858579',
    gold: '110.0928062',
    btc: '129.804813',
    eth: '105.3416206',
    jpy: '108.6926927',
    gold_crude_corr: '-0.2516468846',
    crude_btc_corr: '-0.5404603849',
    crude_eth_corr: '-0.7265949094',
    crude_jpy_corr: '0.8127842434',
    gold_btc_corr: '0.4030936326',
    gold_eth_corr: '0.3894835857',
    gold_jpy_corr: '-0.585907454',
    btc_eth_corr: '0.9581367398',
    btc_jpy_corr: '-0.7695429546',
    eth_jpy_corr: '-0.8618166419',
  },
  {
    date: '2023-09-26',
    crude: '112.5269203',
    gold: '109.2227388',
    btc: '129.4038733',
    eth: '105.6794916',
    jpy: '108.9687549',
    gold_crude_corr: '-0.2393467802',
    crude_btc_corr: '-0.5335398637',
    crude_eth_corr: '-0.7246378523',
    crude_jpy_corr: '0.8127404143',
    gold_btc_corr: '0.3884426685',
    gold_eth_corr: '0.3766456051',
    gold_jpy_corr: '-0.5785335435',
    btc_eth_corr: '0.9565879109',
    btc_jpy_corr: '-0.7639880452',
    eth_jpy_corr: '-0.8602015567',
  },
  {
    date: '2023-09-27',
    crude: '114.8241206',
    gold: '107.8886347',
    btc: '130.0725143',
    eth: '105.949688',
    jpy: '109.1400997',
    gold_crude_corr: '-0.2444213559',
    crude_btc_corr: '-0.5213492297',
    crude_eth_corr: '-0.7198133857',
    crude_jpy_corr: '0.8128861979',
    gold_btc_corr: '0.3619857298',
    gold_eth_corr: '0.357750915',
    gold_jpy_corr: '-0.5695070914',
    btc_eth_corr: '0.9544500234',
    btc_jpy_corr: '-0.7546216085',
    eth_jpy_corr: '-0.8563846734',
  },
  {
    date: '2023-09-28',
    crude: '117.3007897',
    gold: '107.3085861',
    btc: '133.3737454',
    eth: '109.623392',
    jpy: '109.4718168',
    gold_crude_corr: '-0.2902500561',
    crude_btc_corr: '-0.4931454906',
    crude_eth_corr: '-0.6996851081',
    crude_jpy_corr: '0.8138798986',
    gold_btc_corr: '0.3307345401',
    gold_eth_corr: '0.3390018403',
    gold_jpy_corr: '-0.5818834092',
    btc_eth_corr: '0.9524153521',
    btc_jpy_corr: '-0.734810298',
    eth_jpy_corr: '-0.8441766957',
  },
  {
    date: '2023-09-29',
    crude: '114.8241206',
    gold: '106.2064948',
    btc: '132.8316622',
    eth: '110.6222864',
    jpy: '109.3539258',
    gold_crude_corr: '-0.3092510993',
    crude_btc_corr: '-0.4686345462',
    crude_eth_corr: '-0.6789949637',
    crude_jpy_corr: '0.8166332461',
    gold_btc_corr: '0.2821305239',
    gold_eth_corr: '0.2900333535',
    gold_jpy_corr: '-0.5685846739',
    btc_eth_corr: '0.9509979571',
    btc_jpy_corr: '-0.7194224097',
    eth_jpy_corr: '-0.8315243959',
  },
  {
    date: '2023-09-30',
    crude: '114.8241206',
    gold: '106.2064948',
    btc: '133.1090327',
    eth: '110.8357135',
    jpy: '109.3539258',
    gold_crude_corr: '-0.3219509105',
    crude_btc_corr: '-0.4406227415',
    crude_eth_corr: '-0.6553283809',
    crude_jpy_corr: '0.8206842315',
    gold_btc_corr: '0.234689826',
    gold_eth_corr: '0.2407073863',
    gold_jpy_corr: '-0.5578491098',
    btc_eth_corr: '0.949494624',
    btc_jpy_corr: '-0.7013049548',
    eth_jpy_corr: '-0.8155744201',
  },
  {
    date: '2023-10-01',
    crude: '114.8241206',
    gold: '106.2064948',
    btc: '138.1230159',
    eth: '114.9907256',
    jpy: '109.3539258',
    gold_crude_corr: '-0.3293497003',
    crude_btc_corr: '-0.3924076345',
    crude_eth_corr: '-0.6150359711',
    crude_jpy_corr: '0.8273726337',
    gold_btc_corr: '0.1557345172',
    gold_eth_corr: '0.1656440788',
    gold_jpy_corr: '-0.5487094176',
    btc_eth_corr: '0.9476114112',
    btc_jpy_corr: '-0.6635673287',
    eth_jpy_corr: '-0.7870940867',
  },
  {
    date: '2023-10-02',
    crude: '114.034458',
    gold: '105.1044146',
    btc: '135.8872587',
    eth: '110.3360194',
    jpy: '109.5025661',
    gold_crude_corr: '-0.3368007863',
    crude_btc_corr: '-0.3583234578',
    crude_eth_corr: '-0.5968299358',
    crude_jpy_corr: '0.8326377488',
    gold_btc_corr: '0.08354398488',
    gold_eth_corr: '0.1197648584',
    gold_jpy_corr: '-0.5397036518',
    btc_eth_corr: '0.9428823657',
    btc_jpy_corr: '-0.6238324566',
    eth_jpy_corr: '-0.7657091876',
  },
  {
    date: '2023-10-03',
    crude: '111.3424264',
    gold: '104.8723907',
    btc: '135.3896943',
    eth: '109.8756153',
    jpy: '109.7303033',
    gold_crude_corr: '-0.337465575',
    crude_btc_corr: '-0.33135979',
    crude_eth_corr: '-0.5862079363',
    crude_jpy_corr: '0.8302056323',
    gold_btc_corr: '0.03034331289',
    gold_eth_corr: '0.09496811294',
    gold_jpy_corr: '-0.5591771253',
    btc_eth_corr: '0.9364704626',
    btc_jpy_corr: '-0.5753019673',
    eth_jpy_corr: '-0.7390150797',
  },
  {
    date: '2023-10-04',
    crude: '111.988514',
    gold: '104.8143848',
    btc: '137.2130688',
    eth: '109.2888243',
    jpy: '109.2477446',
    gold_crude_corr: '-0.3467018749',
    crude_btc_corr: '-0.3018658256',
    crude_eth_corr: '-0.5772738254',
    crude_jpy_corr: '0.8305196932',
    gold_btc_corr: '-0.01939913681',
    gold_eth_corr: '0.08769157658',
    gold_jpy_corr: '-0.5712100175',
    btc_eth_corr: '0.9262171931',
    btc_jpy_corr: '-0.5318874995',
    eth_jpy_corr: '-0.7224527917',
  },
  {
    date: '2023-10-05',
    crude: '106.5326633',
    gold: '104.6403779',
    btc: '135.320265',
    eth: '106.877224',
    jpy: '109.0822548',
    gold_crude_corr: '-0.3151604564',
    crude_btc_corr: '-0.2737434991',
    crude_eth_corr: '-0.5578392509',
    crude_jpy_corr: '0.814286988',
    gold_btc_corr: '-0.05357218931',
    gold_eth_corr: '0.09820980862',
    gold_jpy_corr: '-0.5783568654',
    btc_eth_corr: '0.9134054167',
    btc_jpy_corr: '-0.4976533032',
    eth_jpy_corr: '-0.7130249487',
  },
  {
    date: '2023-10-06',
    crude: '103.948313',
    gold: '105.1624095',
    btc: '137.9396383',
    eth: '109.155742',
    jpy: '108.7139222',
    gold_crude_corr: '-0.2712161456',
    crude_btc_corr: '-0.2527351519',
    crude_eth_corr: '-0.5394732925',
    crude_jpy_corr: '0.7973581415',
    gold_btc_corr: '-0.1082918226',
    gold_eth_corr: '0.0822473863',
    gold_jpy_corr: '-0.5750067541',
    btc_eth_corr: '0.8997711633',
    btc_jpy_corr: '-0.4522229974',
    eth_jpy_corr: '-0.6962882478',
  },
  {
    date: '2023-10-07',
    crude: '103.948313',
    gold: '105.1624095',
    btc: '138.0494219',
    eth: '108.40495',
    jpy: '108.7139222',
    gold_crude_corr: '-0.229240337',
    crude_btc_corr: '-0.2306023314',
    crude_eth_corr: '-0.5177741902',
    crude_jpy_corr: '0.779989444',
    gold_btc_corr: '-0.1632413879',
    gold_eth_corr: '0.06704819016',
    gold_jpy_corr: '-0.5718154605',
    btc_eth_corr: '0.8826153074',
    btc_jpy_corr: '-0.4024919263',
    eth_jpy_corr: '-0.6768758591',
  },
  {
    date: '2023-10-08',
    crude: '103.948313',
    gold: '105.1624095',
    btc: '137.8828377',
    eth: '108.3411048',
    jpy: '108.7139222',
    gold_crude_corr: '-0.188352187',
    crude_btc_corr: '-0.2067031051',
    crude_eth_corr: '-0.4945232067',
    crude_jpy_corr: '0.7621024581',
    gold_btc_corr: '-0.2181507562',
    gold_eth_corr: '0.05025357594',
    gold_jpy_corr: '-0.5686420746',
    btc_eth_corr: '0.8646435959',
    btc_jpy_corr: '-0.3477765925',
    eth_jpy_corr: '-0.6554370597',
  },
  {
    date: '2023-10-09',
    crude: '103.948313',
    gold: '107.0765622',
    btc: '136.1483275',
    eth: '104.7768022',
    jpy: '109.2243139',
    gold_crude_corr: '-0.1632364477',
    crude_btc_corr: '-0.1821330795',
    crude_eth_corr: '-0.4643980311',
    crude_jpy_corr: '0.7459417218',
    gold_btc_corr: '-0.2622946054',
    gold_eth_corr: '0.04045783673',
    gold_jpy_corr: '-0.5767995119',
    btc_eth_corr: '0.8361959122',
    btc_jpy_corr: '-0.2740908662',
    eth_jpy_corr: '-0.6355821324',
  },
  {
    date: '2023-10-10',
    crude: '107.8248385',
    gold: '106.9025554',
    btc: '135.1973994',
    eth: '103.974722',
    jpy: '108.6143333',
    gold_crude_corr: '-0.1552906074',
    crude_btc_corr: '-0.1442575385',
    crude_eth_corr: '-0.44366151',
    crude_jpy_corr: '0.7464623828',
    gold_btc_corr: '-0.3070768675',
    gold_eth_corr: '0.03943151337',
    gold_jpy_corr: '-0.5915573664',
    btc_eth_corr: '0.7991379174',
    btc_jpy_corr: '-0.183300266',
    eth_jpy_corr: '-0.5999551678',
  },
  {
    date: '2023-10-11',
    crude: '108.3991385',
    gold: '107.7146168',
    btc: '132.6421244',
    eth: '103.878007',
    jpy: '108.8347518',
    gold_crude_corr: '-0.1520885342',
    crude_btc_corr: '-0.1074232264',
    crude_eth_corr: '-0.4243694625',
    crude_jpy_corr: '0.7436452882',
    gold_btc_corr: '-0.3374576087',
    gold_eth_corr: '0.0386068671',
    gold_jpy_corr: '-0.6097035112',
    btc_eth_corr: '0.7677726298',
    btc_jpy_corr: '-0.1000166171',
    eth_jpy_corr: '-0.5654675561',
  },
  {
    date: '2023-10-12',
    crude: '105.5635319',
    gold: '107.3665921',
    btc: '132.0669942',
    eth: '102.1110195',
    jpy: '109.1335073',
    gold_crude_corr: '-0.1440220309',
    crude_btc_corr: '-0.0838224264',
    crude_eth_corr: '-0.4049191393',
    crude_jpy_corr: '0.7373869966',
    gold_btc_corr: '-0.3675986761',
    gold_eth_corr: '0.04554377373',
    gold_jpy_corr: '-0.6282132419',
    btc_eth_corr: '0.7276464642',
    btc_jpy_corr: '-0.01528099419',
    eth_jpy_corr: '-0.5353118045',
  },
  {
    date: '2023-10-13',
    crude: '107.2864322',
    gold: '110.8468728',
    btc: '132.5881002',
    eth: '102.9385293',
    jpy: '109.6856318',
    gold_crude_corr: '-0.1381515571',
    crude_btc_corr: '-0.05744395748',
    crude_eth_corr: '-0.3943100395',
    crude_jpy_corr: '0.720747656',
    gold_btc_corr: '-0.4034790918',
    gold_eth_corr: '0.008550808101',
    gold_jpy_corr: '-0.5893377445',
    btc_eth_corr: '0.6798505562',
    btc_jpy_corr: '0.05484170763',
    eth_jpy_corr: '-0.5254812964',
  },
  {
    date: '2023-10-14',
    crude: '107.2864322',
    gold: '110.8468728',
    btc: '132.5848032',
    eth: '103.1485966',
    jpy: '109.6856318',
    gold_crude_corr: '-0.1324789547',
    crude_btc_corr: '-0.0264915545',
    crude_eth_corr: '-0.3842612639',
    crude_jpy_corr: '0.7049064182',
    gold_btc_corr: '-0.4458356034',
    gold_eth_corr: '-0.03095798654',
    gold_jpy_corr: '-0.5533702997',
    btc_eth_corr: '0.6185156183',
    btc_jpy_corr: '0.139133797',
    eth_jpy_corr: '-0.5118870842',
  },
  {
    date: '2023-10-15',
    crude: '107.2864322',
    gold: '110.8468728',
    btc: '134.0554105',
    eth: '103.3351611',
    jpy: '109.6856318',
    gold_crude_corr: '-0.127000269',
    crude_btc_corr: '0.008644080474',
    crude_eth_corr: '-0.3736159214',
    crude_jpy_corr: '0.6896912529',
    gold_btc_corr: '-0.486696891',
    gold_eth_corr: '-0.07313531574',
    gold_jpy_corr: '-0.5199653538',
    btc_eth_corr: '0.534926667',
    btc_jpy_corr: '0.2449764491',
    eth_jpy_corr: '-0.4942060063',
  },
  {
    date: '2023-10-16',
    crude: '111.2347452',
    gold: '110.2668241',
    btc: '140.7672226',
    eth: '106.1515135',
    jpy: '109.5216057',
    gold_crude_corr: '-0.1175238748',
    crude_btc_corr: '0.09455657375',
    crude_eth_corr: '-0.3556014411',
    crude_jpy_corr: '0.684396642',
    gold_btc_corr: '-0.5026275871',
    gold_eth_corr: '-0.1013134339',
    gold_jpy_corr: '-0.5054444987',
    btc_eth_corr: '0.3904707008',
    btc_jpy_corr: '0.4079345406',
    eth_jpy_corr: '-0.4567999172',
  },
  {
    date: '2023-10-17',
    crude: '110.22972',
    gold: '110.4988369',
    btc: '140.2552846',
    eth: '103.8239418',
    jpy: '109.5303992',
    gold_crude_corr: '-0.1114610408',
    crude_btc_corr: '0.1703178219',
    crude_eth_corr: '-0.3411261583',
    crude_jpy_corr: '0.6769052233',
    gold_btc_corr: '-0.5037220749',
    gold_eth_corr: '-0.1315277069',
    gold_jpy_corr: '-0.4883485053',
    btc_eth_corr: '0.1945483802',
    btc_jpy_corr: '0.547878035',
    eth_jpy_corr: '-0.4457237547',
  },
  {
    date: '2023-10-18',
    crude: '113.0653266',
    gold: '112.0649649',
    btc: '139.8238622',
    eth: '103.7118768',
    jpy: '109.6629386',
    gold_crude_corr: '-0.09504057053',
    crude_btc_corr: '0.2701370912',
    crude_eth_corr: '-0.3110166083',
    crude_jpy_corr: '0.6730081575',
    gold_btc_corr: '-0.4497446689',
    gold_eth_corr: '-0.1611008695',
    gold_jpy_corr: '-0.4532487684',
    btc_eth_corr: '-0.005542746303',
    btc_jpy_corr: '0.6649241901',
    eth_jpy_corr: '-0.4402031274',
  },
  {
    date: '2023-10-19',
    crude: '112.8499641',
    gold: '113.457075',
    btc: '141.7560659',
    eth: '103.9577446',
    jpy: '109.6504914',
    gold_crude_corr: '-0.07439548204',
    crude_btc_corr: '0.2962056816',
    crude_eth_corr: '-0.3008455356',
    crude_jpy_corr: '0.6736044005',
    gold_btc_corr: '-0.3383927115',
    gold_eth_corr: '-0.1909037145',
    gold_jpy_corr: '-0.3971984694',
    btc_eth_corr: '-0.05977971133',
    btc_jpy_corr: '0.6813607738',
    eth_jpy_corr: '-0.4477519421',
  },
  {
    date: '2023-10-20',
    crude: '115.4343144',
    gold: '113.7471048',
    btc: '146.5099734',
    eth: '106.425596',
    jpy: '109.6944253',
    gold_crude_corr: '-0.03936872246',
    crude_btc_corr: '0.3245943943',
    crude_eth_corr: '-0.2927473105',
    crude_jpy_corr: '0.6725985262',
    gold_btc_corr: '-0.2010602661',
    gold_eth_corr: '-0.1961234234',
    gold_jpy_corr: '-0.348070123',
    btc_eth_corr: '-0.07019553566',
    btc_jpy_corr: '0.6803747661',
    eth_jpy_corr: '-0.4416182568',
  },
  {
    date: '2023-10-21',
    crude: '115.4343144',
    gold: '113.7471048',
    btc: '147.672178',
    eth: '108.0596226',
    jpy: '109.6944253',
    gold_crude_corr: '-0.009504594513',
    crude_btc_corr: '0.3496147195',
    crude_eth_corr: '-0.2667724621',
    crude_jpy_corr: '0.6702189975',
    gold_btc_corr: '-0.08809818302',
    gold_eth_corr: '-0.1823733978',
    gold_jpy_corr: '-0.3063897646',
    btc_eth_corr: '-0.04688458764',
    btc_jpy_corr: '0.6800037845',
    eth_jpy_corr: '-0.4192552993',
  },
  {
    date: '2023-10-22',
    crude: '115.4343144',
    gold: '113.7471048',
    btc: '148.044756',
    eth: '110.322912',
    jpy: '109.6944253',
    gold_crude_corr: '0.01591494624',
    crude_btc_corr: '0.3726671568',
    crude_eth_corr: '-0.2119239072',
    crude_jpy_corr: '0.6664030541',
    gold_btc_corr: '0.001653747219',
    gold_eth_corr: '-0.1427675048',
    gold_jpy_corr: '-0.2710785398',
    btc_eth_corr: '0.01633803925',
    btc_jpy_corr: '0.6827741077',
    eth_jpy_corr: '-0.3700768427',
  },
  {
    date: '2023-10-23',
    crude: '113.1371141',
    gold: '113.399069',
    btc: '163.3080083',
    eth: '117.0846787',
    jpy: '109.751544',
    gold_crude_corr: '0.02920644788',
    crude_btc_corr: '0.3521481508',
    crude_eth_corr: '-0.1341439504',
    crude_jpy_corr: '0.6573282797',
    gold_btc_corr: '0.117284406',
    gold_eth_corr: '-0.04599823487',
    gold_jpy_corr: '-0.2438474736',
    btc_eth_corr: '0.2585831051',
    btc_jpy_corr: '0.6409297835',
    eth_jpy_corr: '-0.2508012005',
  },
  {
    date: '2023-10-24',
    crude: '111.5936827',
    gold: '113.3410741',
    btc: '167.3321553',
    eth: '118.3484422',
    jpy: '109.6702572',
    gold_crude_corr: '0.03495678087',
    crude_btc_corr: '0.3190397453',
    crude_eth_corr: '-0.09425396328',
    crude_jpy_corr: '0.6481326312',
    gold_btc_corr: '0.1987554942',
    gold_eth_corr: '0.03590436457',
    gold_jpy_corr: '-0.2145056505',
    btc_eth_corr: '0.4246814571',
    btc_jpy_corr: '0.6095943854',
    eth_jpy_corr: '-0.1624191743',
  },
  {
    date: '2023-10-25',
    crude: '109.3682699',
    gold: '113.8051108',
    btc: '170.3000348',
    eth: '118.5447625',
    jpy: '109.7325044',
    gold_crude_corr: '0.04209261801',
    crude_btc_corr: '0.2738694243',
    crude_eth_corr: '-0.05821716101',
    crude_jpy_corr: '0.6245524418',
    gold_btc_corr: '0.2739040863',
    gold_eth_corr: '0.1083609582',
    gold_jpy_corr: '-0.174056939',
    btc_eth_corr: '0.5421373652',
    btc_jpy_corr: '0.6000204187',
    eth_jpy_corr: '-0.06707716087',
  },
  {
    date: '2023-10-26',
    crude: '111.1270639',
    gold: '114.0371236',
    btc: '168.5913895',
    eth: '119.648484',
    jpy: '109.911179',
    gold_crude_corr: '0.05854011902',
    crude_btc_corr: '0.257875814',
    crude_eth_corr: '-0.01808342036',
    crude_jpy_corr: '0.5884951305',
    gold_btc_corr: '0.3344166968',
    gold_eth_corr: '0.1775157856',
    gold_jpy_corr: '-0.1319340417',
    btc_eth_corr: '0.6249716982',
    btc_jpy_corr: '0.61565846',
    eth_jpy_corr: '0.02598880434',
  },
  {
    date: '2023-10-27',
    crude: '111.0911701',
    gold: '115.3132217',
    btc: '167.3729916',
    eth: '118.0571396',
    jpy: '110.1074181',
    gold_crude_corr: '0.07206970367',
    crude_btc_corr: '0.2426037581',
    crude_eth_corr: '0.008127608975',
    crude_jpy_corr: '0.5647489065',
    gold_btc_corr: '0.3993830128',
    gold_eth_corr: '0.2437803378',
    gold_jpy_corr: '-0.07436199291',
    btc_eth_corr: '0.6737316546',
    btc_jpy_corr: '0.6307001238',
    eth_jpy_corr: '0.09456896284',
  },
  {
    date: '2023-10-28',
    crude: '111.0911701',
    gold: '115.3132217',
    btc: '168.2603226',
    eth: '117.829844',
    jpy: '110.1074181',
    gold_crude_corr: '0.08367803138',
    crude_btc_corr: '0.2263903252',
    crude_eth_corr: '0.0269930713',
    crude_jpy_corr: '0.5381423148',
    gold_btc_corr: '0.4520034499',
    gold_eth_corr: '0.2974823325',
    gold_jpy_corr: '-0.02391706236',
    btc_eth_corr: '0.7100544642',
    btc_jpy_corr: '0.6439240498',
    eth_jpy_corr: '0.1505631556',
  },
  {
    date: '2023-10-29',
    crude: '111.0911701',
    gold: '115.3132217',
    btc: '170.4760472',
    eth: '119.0851876',
    jpy: '110.1074181',
    gold_crude_corr: '0.09362678162',
    crude_btc_corr: '0.2098746525',
    crude_eth_corr: '0.05125841004',
    crude_jpy_corr: '0.5078495807',
    gold_btc_corr: '0.4961830259',
    gold_eth_corr: '0.3480655692',
    gold_jpy_corr: '0.02068664204',
    btc_eth_corr: '0.7468159043',
    btc_jpy_corr: '0.655952636',
    eth_jpy_corr: '0.2093665542',
  },
  {
    date: '2023-10-30',
    crude: '110.5886576',
    gold: '114.6751782',
    btc: '170.297779',
    eth: '120.0496901',
    jpy: '109.643899',
    gold_crude_corr: '0.1020290422',
    crude_btc_corr: '0.1883072952',
    crude_eth_corr: '0.06426394532',
    crude_jpy_corr: '0.4846556843',
    gold_btc_corr: '0.5275517756',
    gold_eth_corr: '0.3866377243',
    gold_jpy_corr: '0.04667990039',
    btc_eth_corr: '0.7775857616',
    btc_jpy_corr: '0.6528066238',
    eth_jpy_corr: '0.2433125563',
  },
  {
    date: '2023-10-31',
    crude: '109.0452261',
    gold: '114.0951296',
    btc: '171.1142538',
    eth: '120.4721887',
    jpy: '109.1884248',
    gold_crude_corr: '0.1166475121',
    crude_btc_corr: '0.173656624',
    crude_eth_corr: '0.1057982935',
    crude_jpy_corr: '0.4546505157',
    gold_btc_corr: '0.5482625389',
    gold_eth_corr: '0.4124196642',
    gold_jpy_corr: '0.06691506501',
    btc_eth_corr: '0.8102079012',
    btc_jpy_corr: '0.6472034664',
    eth_jpy_corr: '0.2863817458',
  },
  {
    date: '2023-11-01',
    crude: '108.040201',
    gold: '113.7471048',
    btc: '174.9122396',
    eth: '122.5036972',
    jpy: '110.7869646',
    gold_crude_corr: '0.126733272',
    crude_btc_corr: '0.1364101905',
    crude_eth_corr: '0.1080404993',
    crude_jpy_corr: '0.3823947544',
    gold_btc_corr: '0.5654481493',
    gold_eth_corr: '0.431537045',
    gold_jpy_corr: '0.1201987272',
    btc_eth_corr: '0.8394854279',
    btc_jpy_corr: '0.6849174617',
    eth_jpy_corr: '0.3818139431',
  },
  {
    date: '2023-11-02',
    crude: '108.183776',
    gold: '114.0951296',
    btc: '172.4492029',
    eth: '119.4217713',
    jpy: '110.2604608',
    gold_crude_corr: '0.1342102286',
    crude_btc_corr: '0.08579832138',
    crude_eth_corr: '0.08990591101',
    crude_jpy_corr: '0.3177199128',
    gold_btc_corr: '0.5900502254',
    gold_eth_corr: '0.453726528',
    gold_jpy_corr: '0.1630659364',
    btc_eth_corr: '0.8544285002',
    btc_jpy_corr: '0.6969975819',
    eth_jpy_corr: '0.4201949479',
  },
  {
    date: '2023-11-03',
    crude: '108.183776',
    gold: '114.5011603',
    btc: '171.4328269',
    eth: '121.5556459',
    jpy: '110.2033421',
    gold_crude_corr: '0.1339928',
    crude_btc_corr: '0.03816720571',
    crude_eth_corr: '0.05766147329',
    crude_jpy_corr: '0.2462083994',
    gold_btc_corr: '0.6162561271',
    gold_eth_corr: '0.4807970462',
    gold_jpy_corr: '0.2129187672',
    btc_eth_corr: '0.8670034694',
    btc_jpy_corr: '0.7118639339',
    eth_jpy_corr: '0.4568900869',
  },
  {
    date: '2023-11-04',
    crude: '108.183776',
    gold: '114.5011603',
    btc: '173.159731',
    eth: '123.2073056',
    jpy: '110.2033421',
    gold_crude_corr: '0.1334296677',
    crude_btc_corr: '-0.01156907696',
    crude_eth_corr: '0.02469272899',
    crude_jpy_corr: '0.1639595313',
    gold_btc_corr: '0.6402650972',
    gold_eth_corr: '0.5052523524',
    gold_jpy_corr: '0.264068268',
    btc_eth_corr: '0.8795456825',
    btc_jpy_corr: '0.7285757034',
    eth_jpy_corr: '0.4982498037',
  },
  {
    date: '2023-11-05',
    crude: '108.183776',
    gold: '114.5011603',
    btc: '172.9976391',
    eth: '125.6253665',
    jpy: '110.2033421',
    gold_crude_corr: '0.1325151096',
    crude_btc_corr: '-0.06227682379',
    crude_eth_corr: '-0.01369463372',
    crude_jpy_corr: '0.06749500414',
    gold_btc_corr: '0.6621072867',
    gold_eth_corr: '0.5275199222',
    gold_jpy_corr: '0.31784572',
    btc_eth_corr: '0.8884193483',
    btc_jpy_corr: '0.7487272718',
    eth_jpy_corr: '0.540279695',
  },
  {
    date: '2023-11-06',
    crude: '106.8198134',
    gold: '113.6890988',
    btc: '172.9384863',
    eth: '126.0020578',
    jpy: '109.4359389',
    gold_crude_corr: '0.1207105896',
    crude_btc_corr: '-0.1162529681',
    crude_eth_corr: '-0.06657377411',
    crude_jpy_corr: '0.006891409351',
    gold_btc_corr: '0.6787809053',
    gold_eth_corr: '0.5403591216',
    gold_jpy_corr: '0.3511816312',
    btc_eth_corr: '0.8956145788',
    btc_jpy_corr: '0.7446820507',
    eth_jpy_corr: '0.5433043396',
  },
  {
    date: '2023-11-07',
    crude: '106.8198134',
    gold: '113.1670562',
    btc: '174.9433777',
    eth: '125.2252129',
    jpy: '109.8313559',
    gold_crude_corr: '0.1049961274',
    crude_btc_corr: '-0.1653780142',
    crude_eth_corr: '-0.1107817813',
    crude_jpy_corr: '-0.04772583701',
    gold_btc_corr: '0.6865188906',
    gold_eth_corr: '0.5464589394',
    gold_jpy_corr: '0.3737456237',
    btc_eth_corr: '0.904223968',
    btc_jpy_corr: '0.7458073574',
    eth_jpy_corr: '0.5597362044',
  },
  {
    date: '2023-11-08',
    crude: '103.1945441',
    gold: '112.0649649',
    btc: '175.9883661',
    eth: '125.3046753',
    jpy: '110.1293851',
    gold_crude_corr: '0.08289995581',
    crude_btc_corr: '-0.2321803919',
    crude_eth_corr: '-0.1809387309',
    crude_jpy_corr: '-0.1043671375',
    gold_btc_corr: '0.6834695922',
    gold_eth_corr: '0.5417047546',
    gold_jpy_corr: '0.3808540117',
    btc_eth_corr: '0.9121362501',
    btc_jpy_corr: '0.7485684255',
    eth_jpy_corr: '0.5761064796',
  },
  {
    date: '2023-11-09',
    crude: '101.4357502',
    gold: '112.4709956',
    btc: '181.1110051',
    eth: '140.6410116',
    jpy: '110.4720858',
    gold_crude_corr: '0.05356260461',
    crude_btc_corr: '-0.3023964074',
    crude_eth_corr: '-0.3046320494',
    crude_jpy_corr: '-0.169756644',
    gold_btc_corr: '0.680601903',
    gold_eth_corr: '0.510740167',
    gold_jpy_corr: '0.3900859274',
    btc_eth_corr: '0.9005184995',
    btc_jpy_corr: '0.757379757',
    eth_jpy_corr: '0.5947367413',
  },
  {
    date: '2023-11-10',
    crude: '102.3689878',
    gold: '111.2529035',
    btc: '184.1753839',
    eth: '137.8374753',
    jpy: '110.8272337',
    gold_crude_corr: '0.04364331543',
    crude_btc_corr: '-0.3661920216',
    crude_eth_corr: '-0.3809667403',
    crude_jpy_corr: '-0.2444560837',
    gold_btc_corr: '0.6659702656',
    gold_eth_corr: '0.4800289627',
    gold_jpy_corr: '0.3895004767',
    btc_eth_corr: '0.9054197679',
    btc_jpy_corr: '0.7697589524',
    eth_jpy_corr: '0.6300743584',
  },
  {
    date: '2023-11-11',
    crude: '102.3689878',
    gold: '111.2529035',
    btc: '183.3070828',
    eth: '136.1412146',
    jpy: '110.8272337',
    gold_crude_corr: '0.03467564781',
    crude_btc_corr: '-0.4204667681',
    crude_eth_corr: '-0.4392981521',
    crude_jpy_corr: '-0.3111158656',
    gold_btc_corr: '0.6546393532',
    gold_eth_corr: '0.4601948044',
    gold_jpy_corr: '0.3896564638',
    btc_eth_corr: '0.9115471881',
    btc_jpy_corr: '0.779958',
    eth_jpy_corr: '0.6597493127',
  },
  {
    date: '2023-11-12',
    crude: '102.3689878',
    gold: '111.2529035',
    btc: '182.8947868',
    eth: '135.6420143',
    jpy: '110.8272337',
    gold_crude_corr: '0.02652273125',
    crude_btc_corr: '-0.4680722341',
    crude_eth_corr: '-0.4885561025',
    crude_jpy_corr: '-0.3715038371',
    gold_btc_corr: '0.6456752873',
    gold_eth_corr: '0.445421882',
    gold_jpy_corr: '0.3905008254',
    btc_eth_corr: '0.916288488',
    btc_jpy_corr: '0.7883180708',
    eth_jpy_corr: '0.682836096',
  },
  {
    date: '2023-11-13',
    crude: '103.2663317',
    gold: '112.0069589',
    btc: '180.1693992',
    eth: '136.3104375',
    jpy: '110.9151016',
    gold_crude_corr: '0.01398166618',
    crude_btc_corr: '-0.5068912034',
    crude_eth_corr: '-0.5311223083',
    crude_jpy_corr: '-0.4263938308',
    gold_btc_corr: '0.6490890829',
    gold_eth_corr: '0.4439686844',
    gold_jpy_corr: '0.4017827836',
    btc_eth_corr: '0.9163332075',
    btc_jpy_corr: '0.7933556748',
    eth_jpy_corr: '0.6979499376',
  },
  {
    date: '2023-11-14',
    crude: '105.8865757',
    gold: '112.8190314',
    btc: '175.4077313',
    eth: '131.2558124',
    jpy: '111.0447135',
    gold_crude_corr: '0.002106536294',
    crude_btc_corr: '-0.5253482309',
    crude_eth_corr: '-0.5468708172',
    crude_jpy_corr: '-0.4660735383',
    gold_btc_corr: '0.6541225147',
    gold_eth_corr: '0.4496954303',
    gold_jpy_corr: '0.417560588',
    btc_eth_corr: '0.9179846537',
    btc_jpy_corr: '0.8008961461',
    eth_jpy_corr: '0.719127848',
  },
  {
    date: '2023-11-15',
    crude: '105.3840632',
    gold: '112.5870076',
    btc: '186.9720904',
    eth: '136.6515387',
    jpy: '110.1945598',
    gold_crude_corr: '-0.006334093705',
    crude_btc_corr: '-0.5373187182',
    crude_eth_corr: '-0.5576377591',
    crude_jpy_corr: '-0.4775933668',
    gold_btc_corr: '0.651913752',
    gold_eth_corr: '0.4500466161',
    gold_jpy_corr: '0.425346662',
    btc_eth_corr: '0.9225794242',
    btc_jpy_corr: '0.7997122335',
    eth_jpy_corr: '0.7219867792',
  },
  {
    date: '2023-11-16',
    crude: '103.0868629',
    gold: '113.8631057',
    btc: '178.4537743',
    eth: '130.050664',
    jpy: '110.7291085',
    gold_crude_corr: '-0.0288743635',
    crude_btc_corr: '-0.5519514902',
    crude_eth_corr: '-0.5728984462',
    crude_jpy_corr: '-0.4995054674',
    gold_btc_corr: '0.6589525673',
    gold_eth_corr: '0.4603908143',
    gold_jpy_corr: '0.4407456426',
    btc_eth_corr: '0.925461381',
    btc_jpy_corr: '0.8060208264',
    eth_jpy_corr: '0.7369519716',
  },
  {
    date: '2023-11-17',
    crude: '99.06676238',
    gold: '113.8051108',
    btc: '180.6349867',
    eth: '130.077138',
    jpy: '110.3556585',
    gold_crude_corr: '-0.06180039704',
    crude_btc_corr: '-0.5662275002',
    crude_eth_corr: '-0.5897112768',
    crude_jpy_corr: '-0.5007979398',
    gold_btc_corr: '0.6683612225',
    gold_eth_corr: '0.4711527446',
    gold_jpy_corr: '0.4585207893',
    btc_eth_corr: '0.9289186661',
    btc_jpy_corr: '0.8088147377',
    eth_jpy_corr: '0.7486180367',
  },
  {
    date: '2023-11-18',
    crude: '99.06676238',
    gold: '113.8051108',
    btc: '180.580789',
    eth: '130.2100665',
    jpy: '110.3556585',
    gold_crude_corr: '-0.09044789025',
    crude_btc_corr: '-0.5779328804',
    crude_eth_corr: '-0.6040006559',
    crude_jpy_corr: '-0.5012186112',
    gold_btc_corr: '0.6774956214',
    gold_eth_corr: '0.4812069429',
    gold_jpy_corr: '0.476669779',
    btc_eth_corr: '0.9318970384',
    btc_jpy_corr: '0.8114973614',
    eth_jpy_corr: '0.7597453319',
  },
  {
    date: '2023-11-19',
    crude: '99.06676238',
    gold: '113.8051108',
    btc: '184.5336171',
    eth: '133.5208505',
    jpy: '110.3556585',
    gold_crude_corr: '-0.1156173954',
    crude_btc_corr: '-0.591516148',
    crude_eth_corr: '-0.6213749101',
    crude_jpy_corr: '-0.5006981521',
    gold_btc_corr: '0.686058791',
    gold_eth_corr: '0.4914015587',
    gold_jpy_corr: '0.4954884631',
    btc_eth_corr: '0.9348506146',
    btc_jpy_corr: '0.8136542641',
    eth_jpy_corr: '0.7689773788',
  },
  {
    date: '2023-11-20',
    crude: '102.4048816',
    gold: '113.6310929',
    btc: '184.9798663',
    eth: '134.1200527',
    jpy: '109.7969417',
    gold_crude_corr: '-0.133120712',
    crude_btc_corr: '-0.598138887',
    crude_eth_corr: '-0.6317858304',
    crude_jpy_corr: '-0.485479865',
    gold_btc_corr: '0.6962467873',
    gold_eth_corr: '0.5015033445',
    gold_jpy_corr: '0.5093712711',
    btc_eth_corr: '0.9379178346',
    btc_jpy_corr: '0.8019804984',
    eth_jpy_corr: '0.7621143454',
  },
  {
    date: '2023-11-21',
    crude: '102.6202441',
    gold: '114.849185',
    btc: '176.7708686',
    eth: '128.4712045',
    jpy: '108.6399652',
    gold_crude_corr: '-0.1553202095',
    crude_btc_corr: '-0.6001358244',
    crude_eth_corr: '-0.6371751637',
    crude_jpy_corr: '-0.4285397958',
    gold_btc_corr: '0.7074200692',
    gold_eth_corr: '0.5130805711',
    gold_jpy_corr: '0.4892611703',
    btc_eth_corr: '0.9394136681',
    btc_jpy_corr: '0.7614676279',
    eth_jpy_corr: '0.7286191112',
  },
  {
    date: '2023-11-22',
    crude: '103.4816942',
    gold: '114.3851483',
    btc: '184.7596434',
    eth: '136.9179462',
    jpy: '108.4861851',
    gold_crude_corr: '-0.1709410074',
    crude_btc_corr: '-0.6040745088',
    crude_eth_corr: '-0.6421751701',
    crude_jpy_corr: '-0.3777891567',
    gold_btc_corr: '0.7190213302',
    gold_eth_corr: '0.5267391946',
    gold_jpy_corr: '0.4665336201',
    btc_eth_corr: '0.9406268411',
    btc_jpy_corr: '0.6964438688',
    eth_jpy_corr: '0.6536159102',
  },
  {
    date: '2023-11-23',
    crude: '103.4816942',
    gold: '114.3851483',
    btc: '184.055208',
    eth: '136.7710683',
    jpy: '109.443995',
    gold_crude_corr: '-0.1824136188',
    crude_btc_corr: '-0.607226562',
    crude_eth_corr: '-0.6457721929',
    crude_jpy_corr: '-0.359363903',
    gold_btc_corr: '0.7280496456',
    gold_eth_corr: '0.5376670959',
    gold_jpy_corr: '0.4610887587',
    btc_eth_corr: '0.940938067',
    btc_jpy_corr: '0.6723110571',
    eth_jpy_corr: '0.6239121061',
  },
  {
    date: '2023-11-24',
    crude: '102.4048816',
    gold: '115.0232029',
    btc: '186.1808731',
    eth: '138.0273106',
    jpy: '109.572132',
    gold_crude_corr: '-0.1996403829',
    crude_btc_corr: '-0.6098327193',
    crude_eth_corr: '-0.6506868588',
    crude_jpy_corr: '-0.3313899405',
    gold_btc_corr: '0.7401078288',
    gold_eth_corr: '0.5522131341',
    gold_jpy_corr: '0.4663656317',
    btc_eth_corr: '0.9416465534',
    btc_jpy_corr: '0.6497892236',
    eth_jpy_corr: '0.5995500303',
  },
  {
    date: '2023-11-25',
    crude: '102.4048816',
    gold: '115.0232029',
    btc: '186.5585219',
    eth: '138.2435875',
    jpy: '109.572132',
    gold_crude_corr: '-0.2153133632',
    crude_btc_corr: '-0.611328462',
    crude_eth_corr: '-0.654518946',
    crude_jpy_corr: '-0.3000900087',
    gold_btc_corr: '0.7518078022',
    gold_eth_corr: '0.5655892742',
    gold_jpy_corr: '0.4725053818',
    btc_eth_corr: '0.9423301919',
    btc_jpy_corr: '0.6255009507',
    eth_jpy_corr: '0.5744542256',
  },
  {
    date: '2023-11-26',
    crude: '102.4048816',
    gold: '115.0232029',
    btc: '184.9905477',
    eth: '136.8423942',
    jpy: '109.572132',
    gold_crude_corr: '-0.2295733105',
    crude_btc_corr: '-0.6107761816',
    crude_eth_corr: '-0.6565257993',
    crude_jpy_corr: '-0.2646437053',
    gold_btc_corr: '0.7641302176',
    gold_eth_corr: '0.5780842823',
    gold_jpy_corr: '0.4798794452',
    btc_eth_corr: '0.9427002237',
    btc_jpy_corr: '0.5983736943',
    eth_jpy_corr: '0.548216792',
  },
  {
    date: '2023-11-27',
    crude: '100.2153625',
    gold: '115.7192635',
    btc: '183.8802174',
    eth: '134.4634856',
    jpy: '109.5252706',
    gold_crude_corr: '-0.2489700307',
    crude_btc_corr: '-0.6127070314',
    crude_eth_corr: '-0.66054548',
    crude_jpy_corr: '-0.2315991811',
    gold_btc_corr: '0.7738541844',
    gold_eth_corr: '0.5880845224',
    gold_jpy_corr: '0.4681777062',
    btc_eth_corr: '0.9432529191',
    btc_jpy_corr: '0.5706665957',
    eth_jpy_corr: '0.5224394633',
  },
  {
    date: '2023-11-28',
    crude: '99.85642498',
    gold: '117.2853804',
    btc: '186.7277861',
    eth: '135.9173274',
    jpy: '108.7058662',
    gold_crude_corr: '-0.2720832012',
    crude_btc_corr: '-0.6164314629',
    crude_eth_corr: '-0.6656929951',
    crude_jpy_corr: '-0.1659174631',
    gold_btc_corr: '0.7810460887',
    gold_eth_corr: '0.5981355981',
    gold_jpy_corr: '0.400292519',
    btc_eth_corr: '0.9442784525',
    btc_jpy_corr: '0.5059829311',
    eth_jpy_corr: '0.4586374695',
  },
  {
    date: '2023-11-29',
    crude: '100.9332376',
    gold: '117.5174043',
    btc: '186.8630587',
    eth: '134.6300773',
    jpy: '107.6887588',
    gold_crude_corr: '-0.2785201549',
    crude_btc_corr: '-0.6123503796',
    crude_eth_corr: '-0.6657107601',
    crude_jpy_corr: '-0.07045467607',
    gold_btc_corr: '0.7826694505',
    gold_eth_corr: '0.6020496777',
    gold_jpy_corr: '0.2747027466',
    btc_eth_corr: '0.945139358',
    btc_jpy_corr: '0.39317529',
    eth_jpy_corr: '0.3531291057',
  },
  {
    date: '2023-11-30',
    crude: '102.8715004',
    gold: '116.9953616',
    btc: '186.1436808',
    eth: '136.1307545',
    jpy: '107.671183',
    gold_crude_corr: '-0.2631893711',
    crude_btc_corr: '-0.6025688448',
    crude_eth_corr: '-0.6696627823',
    crude_jpy_corr: '-0.01710541956',
    gold_btc_corr: '0.7820091061',
    gold_eth_corr: '0.60744061',
    gold_jpy_corr: '0.1854771491',
    btc_eth_corr: '0.9474279949',
    btc_jpy_corr: '0.310337223',
    eth_jpy_corr: '0.2666618792',
  },
  {
    date: '2023-12-01',
    crude: '100.8614501',
    gold: '119.0255263',
    btc: '190.9610696',
    eth: '138.4244363',
    jpy: '108.374149',
    gold_crude_corr: '-0.2599448539',
    crude_btc_corr: '-0.5982661519',
    crude_eth_corr: '-0.6760834667',
    crude_jpy_corr: '0.03069596',
    gold_btc_corr: '0.7810168995',
    gold_eth_corr: '0.6182051176',
    gold_jpy_corr: '0.1144490624',
    btc_eth_corr: '0.9512957228',
    btc_jpy_corr: '0.255697497',
    eth_jpy_corr: '0.2127213991',
  },
  {
    date: '2023-12-02',
    crude: '100.8614501',
    gold: '119.0255263',
    btc: '194.8484402',
    eth: '143.6350148',
    jpy: '108.374149',
    gold_crude_corr: '-0.2528029458',
    crude_btc_corr: '-0.5935680188',
    crude_eth_corr: '-0.6833683018',
    crude_jpy_corr: '0.07541583074',
    gold_btc_corr: '0.78150316',
    gold_eth_corr: '0.6333695566',
    gold_jpy_corr: '0.05043269105',
    btc_eth_corr: '0.9554160093',
    btc_jpy_corr: '0.1998972623',
    eth_jpy_corr: '0.1515446538',
  },
  {
    date: '2023-12-03',
    crude: '100.8614501',
    gold: '119.0255263',
    btc: '197.3265157',
    eth: '145.4912204',
    jpy: '108.374149',
    gold_crude_corr: '-0.2415615052',
    crude_btc_corr: '-0.5914419327',
    crude_eth_corr: '-0.6970242716',
    crude_jpy_corr: '0.1176735586',
    gold_btc_corr: '0.7843970873',
    gold_eth_corr: '0.6544684457',
    gold_jpy_corr: '-0.007853307743',
    btc_eth_corr: '0.9610694358',
    btc_jpy_corr: '0.1459050627',
    eth_jpy_corr: '0.09437721472',
  },
  {
    date: '2023-12-04',
    crude: '97.05671213',
    gold: '116.473319',
    btc: '207.2066001',
    eth: '148.7757895',
    jpy: '107.1981441',
    gold_crude_corr: '-0.2230089543',
    crude_btc_corr: '-0.6015810556',
    crude_eth_corr: '-0.7123113845',
    crude_jpy_corr: '0.2048206992',
    gold_btc_corr: '0.7786123131',
    gold_eth_corr: '0.6588131348',
    gold_jpy_corr: '-0.06210226991',
    btc_eth_corr: '0.9649445392',
    btc_jpy_corr: '0.03875260736',
    eth_jpy_corr: '-0.008199356541',
  },
  {
    date: '2023-12-05',
    crude: '97.12849964',
    gold: '116.0092823',
    btc: '217.5745604',
    eth: '152.1334333',
    jpy: '107.8820706',
    gold_crude_corr: '-0.2127623242',
    crude_btc_corr: '-0.6186057797',
    crude_eth_corr: '-0.7256244793',
    crude_jpy_corr: '0.2511981286',
    gold_btc_corr: '0.7647751809',
    gold_eth_corr: '0.6589467796',
    gold_jpy_corr: '-0.08479860798',
    btc_eth_corr: '0.9687141706',
    btc_jpy_corr: '-0.03334931097',
    eth_jpy_corr: '-0.07175949564',
  },
  {
    date: '2023-12-06',
    crude: '95.83632448',
    gold: '116.473319',
    btc: '215.9249908',
    eth: '148.0094694',
    jpy: '107.7780905',
    gold_crude_corr: '-0.2000378255',
    crude_btc_corr: '-0.6346341689',
    crude_eth_corr: '-0.7354394291',
    crude_jpy_corr: '0.3115939815',
    gold_btc_corr: '0.7580709688',
    gold_eth_corr: '0.6630446398',
    gold_jpy_corr: '-0.132420741',
    btc_eth_corr: '0.9697460864',
    btc_jpy_corr: '-0.1086120006',
    eth_jpy_corr: '-0.1295806101',
  },
  {
    date: '2023-12-07',
    crude: '92.17516152',
    gold: '116.6473369',
    btc: '213.685204',
    eth: '156.3606862',
    jpy: '107.7773642',
    gold_crude_corr: '-0.2352401392',
    crude_btc_corr: '-0.669487342',
    crude_eth_corr: '-0.7635652174',
    crude_jpy_corr: '0.3683100504',
    gold_btc_corr: '0.7525177146',
    gold_eth_corr: '0.6622362659',
    gold_jpy_corr: '-0.1896360057',
    btc_eth_corr: '0.971323155',
    btc_jpy_corr: '-0.1771704538',
    eth_jpy_corr: '-0.1999973478',
  },
  {
    date: '2023-12-08',
    crude: '91.81622398',
    gold: '115.1392149',
    btc: '217.9988104',
    eth: '156.4371126',
    jpy: '105.6494044',
    gold_crude_corr: '-0.2706224904',
    crude_btc_corr: '-0.7132062051',
    crude_eth_corr: '-0.793979365',
    crude_jpy_corr: '0.4574973283',
    gold_btc_corr: '0.7364394996',
    gold_eth_corr: '0.6538004402',
    gold_jpy_corr: '-0.2461253708',
    btc_eth_corr: '0.9737509086',
    btc_jpy_corr: '-0.2963885995',
    eth_jpy_corr: '-0.3118681772',
  },
  {
    date: '2023-12-09',
    crude: '91.81622398',
    gold: '115.1392149',
    btc: '215.8239991',
    eth: '155.2727114',
    jpy: '105.6494044',
    gold_crude_corr: '-0.3070806464',
    crude_btc_corr: '-0.7499909002',
    crude_eth_corr: '-0.8200143487',
    crude_jpy_corr: '0.5221744208',
    gold_btc_corr: '0.7229690495',
    gold_eth_corr: '0.6466985586',
    gold_jpy_corr: '-0.3000650648',
    btc_eth_corr: '0.9754717939',
    btc_jpy_corr: '-0.3840125841',
    eth_jpy_corr: '-0.3939775789',
  },
  {
    date: '2023-12-10',
    crude: '91.81622398',
    gold: '115.1392149',
    btc: '216.0891264',
    eth: '156.0213175',
    jpy: '105.6494044',
    gold_crude_corr: '-0.3469256955',
    crude_btc_corr: '-0.7835400665',
    crude_eth_corr: '-0.8436612789',
    crude_jpy_corr: '0.5714940422',
    gold_btc_corr: '0.710458144',
    gold_eth_corr: '0.6414318357',
    gold_jpy_corr: '-0.3560686672',
    btc_eth_corr: '0.9770909863',
    btc_jpy_corr: '-0.4561468658',
    eth_jpy_corr: '-0.4614850631',
  },
  {
    date: '2023-12-11',
    crude: '94.04163676',
    gold: '113.8051108',
    btc: '203.5725186',
    eth: '147.5397387',
    jpy: '106.165651',
    gold_crude_corr: '-0.3634939957',
    crude_btc_corr: '-0.8104410577',
    crude_eth_corr: '-0.8651549213',
    crude_jpy_corr: '0.5993108942',
    gold_btc_corr: '0.6857519361',
    gold_eth_corr: '0.6172861875',
    gold_jpy_corr: '-0.3617827428',
    btc_eth_corr: '0.9771532761',
    btc_jpy_corr: '-0.4899198376',
    eth_jpy_corr: '-0.4938185864',
  },
  {
    date: '2023-12-12',
    crude: '94.25699928',
    gold: '113.7471048',
    btc: '204.5912275',
    eth: '146.0447773',
    jpy: '106.9726082',
    gold_crude_corr: '-0.3537436909',
    crude_btc_corr: '-0.8227565134',
    crude_eth_corr: '-0.8729140026',
    crude_jpy_corr: '0.6255540228',
    gold_btc_corr: '0.6555479102',
    gold_eth_corr: '0.5895783316',
    gold_jpy_corr: '-0.4022497661',
    btc_eth_corr: '0.9770029566',
    btc_jpy_corr: '-0.5357304356',
    eth_jpy_corr: '-0.5330499325',
  },
  {
    date: '2023-12-13',
    crude: '90.30868629',
    gold: '116.2993011',
    btc: '211.7013862',
    eth: '149.9319813',
    jpy: '106.3831309',
    gold_crude_corr: '-0.3673603495',
    crude_btc_corr: '-0.836862424',
    crude_eth_corr: '-0.8785136299',
    crude_jpy_corr: '0.6593862942',
    gold_btc_corr: '0.6354480919',
    gold_eth_corr: '0.574629514',
    gold_jpy_corr: '-0.4602462336',
    btc_eth_corr: '0.9773766187',
    btc_jpy_corr: '-0.5855477208',
    eth_jpy_corr: '-0.5725206145',
  },
  {
    date: '2023-12-14',
    crude: '89.48312994',
    gold: '116.9953616',
    btc: '212.358989',
    eth: '153.6414293',
    jpy: '104.5473454',
    gold_crude_corr: '-0.4149610761',
    crude_btc_corr: '-0.8635801848',
    crude_eth_corr: '-0.8984431346',
    crude_jpy_corr: '0.6922170659',
    gold_btc_corr: '0.6142530728',
    gold_eth_corr: '0.5583984653',
    gold_jpy_corr: '-0.5255231818',
    btc_eth_corr: '0.9775041706',
    btc_jpy_corr: '-0.6255102679',
    eth_jpy_corr: '-0.6101896704',
  },
  {
    date: '2023-12-15',
    crude: '92.53409907',
    gold: '115.9512763',
    btc: '206.9581312',
    eth: '147.192112',
    jpy: '104.1856164',
    gold_crude_corr: '-0.4218845722',
    crude_btc_corr: '-0.8828163331',
    crude_eth_corr: '-0.9096948674',
    crude_jpy_corr: '0.7045716651',
    gold_btc_corr: '0.5977694082',
    gold_eth_corr: '0.5425714878',
    gold_jpy_corr: '-0.525021249',
    btc_eth_corr: '0.9773662941',
    btc_jpy_corr: '-0.6420656722',
    eth_jpy_corr: '-0.6179470475',
  },
  {
    date: '2023-12-16',
    crude: '92.53409907',
    gold: '115.9512763',
    btc: '208.4900122',
    eth: '147.6954412',
    jpy: '104.1856164',
    gold_crude_corr: '-0.4277989698',
    crude_btc_corr: '-0.9045853063',
    crude_eth_corr: '-0.9220008702',
    crude_jpy_corr: '0.7159472856',
    gold_btc_corr: '0.5780536555',
    gold_eth_corr: '0.5240444581',
    gold_jpy_corr: '-0.5269606523',
    btc_eth_corr: '0.9772829928',
    btc_jpy_corr: '-0.6634852109',
    eth_jpy_corr: '-0.6288721778',
  },
  {
    date: '2023-12-17',
    crude: '92.53409907',
    gold: '115.9512763',
    btc: '204.1689249',
    eth: '145.6762305',
    jpy: '104.1856164',
    gold_crude_corr: '-0.4328062595',
    crude_btc_corr: '-0.9256559017',
    crude_eth_corr: '-0.9339989935',
    crude_jpy_corr: '0.7262175905',
    gold_btc_corr: '0.5545615171',
    gold_eth_corr: '0.5015930918',
    gold_jpy_corr: '-0.5303892239',
    btc_eth_corr: '0.9769622873',
    btc_jpy_corr: '-0.6794958592',
    eth_jpy_corr: '-0.6365636267',
  },
  {
    date: '2023-12-18',
    crude: '92.85714286',
    gold: '116.357307',
    btc: '210.3825171',
    eth: '147.0552246',
    jpy: '104.2105108',
    gold_crude_corr: '-0.4249042924',
    crude_btc_corr: '-0.9315052292',
    crude_eth_corr: '-0.9354633009',
    crude_jpy_corr: '0.7375636971',
    gold_btc_corr: '0.5289033308',
    gold_eth_corr: '0.4765641075',
    gold_jpy_corr: '-0.5483426219',
    btc_eth_corr: '0.9757364418',
    btc_jpy_corr: '-0.7027470954',
    eth_jpy_corr: '-0.6483844259',
  },
  {
    date: '2023-12-19',
    crude: '94.47236181',
    gold: '117.1693795',
    btc: '208.6401115',
    eth: '144.4420579',
    jpy: '104.5100038',
    gold_crude_corr: '-0.4227627695',
    crude_btc_corr: '-0.9407974437',
    crude_eth_corr: '-0.9414355019',
    crude_jpy_corr: '0.7432353188',
    gold_btc_corr: '0.5033528712',
    gold_eth_corr: '0.444611067',
    gold_jpy_corr: '-0.5768297816',
    btc_eth_corr: '0.9729802773',
    btc_jpy_corr: '-0.7260320447',
    eth_jpy_corr: '-0.6598170893',
  },
  {
    date: '2023-12-20',
    crude: '95.76453697',
    gold: '116.5893309',
    btc: '215.4600588',
    eth: '146.0363736',
    jpy: '105.3645598',
    gold_crude_corr: '-0.4112896983',
    crude_btc_corr: '-0.9415126472',
    crude_eth_corr: '-0.9430150124',
    crude_jpy_corr: '0.750224669',
    gold_btc_corr: '0.4902975354',
    gold_eth_corr: '0.4254690305',
    gold_jpy_corr: '-0.5867063416',
    btc_eth_corr: '0.9679968817',
    btc_jpy_corr: '-0.7536426106',
    eth_jpy_corr: '-0.6758618317',
  },
  {
    date: '2023-12-21',
    crude: '95.11844939',
    gold: '117.4013923',
    btc: '216.5306518',
    eth: '148.5321643',
    jpy: '105.0965425',
    gold_crude_corr: '-0.4160556313',
    crude_btc_corr: '-0.9425047159',
    crude_eth_corr: '-0.9456094048',
    crude_jpy_corr: '0.7577458996',
    gold_btc_corr: '0.5056340311',
    gold_eth_corr: '0.4324614986',
    gold_jpy_corr: '-0.6006557954',
    btc_eth_corr: '0.9638697989',
    btc_jpy_corr: '-0.781717553',
    eth_jpy_corr: '-0.695998909',
  },
  {
    date: '2023-12-22',
    crude: '94.90308686',
    gold: '117.981441',
    btc: '217.1661398',
    eth: '154.3010602',
    jpy: '104.0325737',
    gold_crude_corr: '-0.4259948146',
    crude_btc_corr: '-0.9376728356',
    crude_eth_corr: '-0.9417059521',
    crude_jpy_corr: '0.7671185492',
    gold_btc_corr: '0.5276828472',
    gold_eth_corr: '0.4530821225',
    gold_jpy_corr: '-0.6206581429',
    btc_eth_corr: '0.9622926816',
    btc_jpy_corr: '-0.8060470904',
    eth_jpy_corr: '-0.7202534635',
  },
  {
    date: '2023-12-23',
    crude: '94.90308686',
    gold: '117.981441',
    btc: '215.890922',
    eth: '153.1418405',
    jpy: '104.0325737',
    gold_crude_corr: '-0.4348691393',
    crude_btc_corr: '-0.9326178682',
    crude_eth_corr: '-0.9375616725',
    crude_jpy_corr: '0.7779169769',
    gold_btc_corr: '0.5472971429',
    gold_eth_corr: '0.4701700775',
    gold_jpy_corr: '-0.6376856878',
    btc_eth_corr: '0.9603934504',
    btc_jpy_corr: '-0.8291728',
    eth_jpy_corr: '-0.7413275239',
  },
  {
    date: '2023-12-24',
    crude: '94.90308686',
    gold: '117.981441',
    btc: '212.3202158',
    eth: '150.2664763',
    jpy: '104.0325737',
    gold_crude_corr: '-0.4428935778',
    crude_btc_corr: '-0.9277664799',
    crude_eth_corr: '-0.9336780673',
    crude_jpy_corr: '0.7904191627',
    gold_btc_corr: '0.5638028273',
    gold_eth_corr: '0.481451753',
    gold_jpy_corr: '-0.6522784758',
    btc_eth_corr: '0.9582458765',
    btc_jpy_corr: '-0.8512211491',
    eth_jpy_corr: '-0.7563850546',
  },
  {
    date: '2023-12-25',
    crude: '94.54414932',
    gold: '117.981441',
    btc: '215.2670216',
    eth: '150.7220876',
    jpy: '104.2317403',
    gold_crude_corr: '-0.4449483763',
    crude_btc_corr: '-0.9253702924',
    crude_eth_corr: '-0.931787282',
    crude_jpy_corr: '0.7993696761',
    gold_btc_corr: '0.5719171353',
    gold_eth_corr: '0.4861775745',
    gold_jpy_corr: '-0.6639541036',
    btc_eth_corr: '0.9570258507',
    btc_jpy_corr: '-0.8603744175',
    eth_jpy_corr: '-0.7646309037',
  },
  {
    date: '2023-12-26',
    crude: '94.4005743',
    gold: '118.7935024',
    btc: '209.8734519',
    eth: '147.9964672',
    jpy: '104.1497273',
    gold_crude_corr: '-0.4473191467',
    crude_btc_corr: '-0.9246402739',
    crude_eth_corr: '-0.9296519751',
    crude_jpy_corr: '0.8064766004',
    gold_btc_corr: '0.5754363668',
    gold_eth_corr: '0.486081893',
    gold_jpy_corr: '-0.6783279822',
    btc_eth_corr: '0.9562509385',
    btc_jpy_corr: '-0.8640784179',
    eth_jpy_corr: '-0.7687789678',
  },
  {
    date: '2023-12-27',
    crude: '96.59009332',
    gold: '119.431557',
    btc: '214.4265231',
    eth: '157.7640979',
    jpy: '104.318882',
    gold_crude_corr: '-0.444283857',
    crude_btc_corr: '-0.920080819',
    crude_eth_corr: '-0.9198297054',
    crude_jpy_corr: '0.8046401057',
    gold_btc_corr: '0.5867748985',
    gold_eth_corr: '0.5083943908',
    gold_jpy_corr: '-0.6913360618',
    btc_eth_corr: '0.9564953581',
    btc_jpy_corr: '-0.8687677303',
    eth_jpy_corr: '-0.7806296744',
  },
  {
    date: '2023-12-28',
    crude: '94.47236181',
    gold: '118.7354964',
    btc: '210.4038222',
    eth: '155.6965701',
    jpy: '103.5412216',
    gold_crude_corr: '-0.4517762395',
    crude_btc_corr: '-0.9191785824',
    crude_eth_corr: '-0.9160459981',
    crude_jpy_corr: '0.8077669378',
    gold_btc_corr: '0.5937708888',
    gold_eth_corr: '0.5237386362',
    gold_jpy_corr: '-0.7038139793',
    btc_eth_corr: '0.9549405117',
    btc_jpy_corr: '-0.869136222',
    eth_jpy_corr: '-0.7894972217',
  },
  {
    date: '2023-12-29',
    crude: '92.17516152',
    gold: '118.5034836',
    btc: '207.7954677',
    eth: '152.5876683',
    jpy: '103.5639148',
    gold_crude_corr: '-0.4808856169',
    crude_btc_corr: '-0.9162772784',
    crude_eth_corr: '-0.9132921066',
    crude_jpy_corr: '0.8151393122',
    gold_btc_corr: '0.6109285396',
    gold_eth_corr: '0.5499518432',
    gold_jpy_corr: '-0.7194934058',
    btc_eth_corr: '0.953573638',
    btc_jpy_corr: '-0.8671729577',
    eth_jpy_corr: '-0.7947113108',
  },
  {
    date: '2023-12-30',
    crude: '92.17516152',
    gold: '118.5034836',
    btc: '208.0792779',
    eth: '152.0156201',
    jpy: '103.5639148',
    gold_crude_corr: '-0.5095524665',
    crude_btc_corr: '-0.9136243513',
    crude_eth_corr: '-0.9096209342',
    crude_jpy_corr: '0.822703264',
    gold_btc_corr: '0.6274015572',
    gold_eth_corr: '0.575819878',
    gold_jpy_corr: '-0.7337959252',
    btc_eth_corr: '0.9527782877',
    btc_jpy_corr: '-0.865489307',
    eth_jpy_corr: '-0.7996864728',
  },
  {
    date: '2023-12-31',
    crude: '92.17516152',
    gold: '118.5034836',
    btc: '208.613755',
    eth: '151.3129832',
    jpy: '103.5639148',
    gold_crude_corr: '-0.5382738751',
    crude_btc_corr: '-0.9123781867',
    crude_eth_corr: '-0.9053612228',
    crude_jpy_corr: '0.8306763264',
    gold_btc_corr: '0.6422702658',
    gold_eth_corr: '0.5993695221',
    gold_jpy_corr: '-0.7469451207',
    btc_eth_corr: '0.9526691292',
    btc_jpy_corr: '-0.8636822203',
    eth_jpy_corr: '-0.8030542003',
  },
  {
    date: '2024-01-01',
    crude: '92.17516152',
    gold: '118.5034836',
    btc: '218.0024159',
    eth: '156.0123795',
    jpy: '103.2138956',
    gold_crude_corr: '-0.5568425748',
    crude_btc_corr: '-0.9122563403',
    crude_eth_corr: '-0.9019131047',
    crude_jpy_corr: '0.8417495356',
    gold_btc_corr: '0.6563912223',
    gold_eth_corr: '0.617877068',
    gold_jpy_corr: '-0.7550637039',
    btc_eth_corr: '0.9530389911',
    btc_jpy_corr: '-0.8696401732',
    eth_jpy_corr: '-0.813581928',
  },
  {
    date: '2024-01-02',
    crude: '92.17516152',
    gold: '118.2714597',
    btc: '221.9048638',
    eth: '156.2450751',
    jpy: '103.3413175',
    gold_crude_corr: '-0.5646604044',
    crude_btc_corr: '-0.9106593131',
    crude_eth_corr: '-0.89852004',
    crude_jpy_corr: '0.8532127948',
    gold_btc_corr: '0.664313709',
    gold_eth_corr: '0.6280107106',
    gold_jpy_corr: '-0.7599703378',
    btc_eth_corr: '0.953494373',
    btc_jpy_corr: '-0.8784651357',
    eth_jpy_corr: '-0.8276171631',
  },
  {
    date: '2024-01-03',
    crude: '92.17516152',
    gold: '117.2853804',
    btc: '211.4912857',
    eth: '146.623367',
    jpy: '104.0948098',
    gold_crude_corr: '-0.5629623343',
    crude_btc_corr: '-0.9096500635',
    crude_eth_corr: '-0.8903194048',
    crude_jpy_corr: '0.8531841277',
    gold_btc_corr: '0.6628135256',
    gold_eth_corr: '0.6250976626',
    gold_jpy_corr: '-0.7587206632',
    btc_eth_corr: '0.9522322583',
    btc_jpy_corr: '-0.8767209199',
    eth_jpy_corr: '-0.8209629743',
  },
  {
    date: '2024-01-04',
    crude: '94.86719311',
    gold: '117.4013923',
    btc: '218.0645572',
    eth: '150.488388',
    jpy: '104.7128353',
    gold_crude_corr: '-0.5627901081',
    crude_btc_corr: '-0.9043059919',
    crude_eth_corr: '-0.8844267582',
    crude_jpy_corr: '0.8532169095',
    gold_btc_corr: '0.6641347034',
    gold_eth_corr: '0.630642508',
    gold_jpy_corr: '-0.7590711532',
    btc_eth_corr: '0.9523088868',
    btc_jpy_corr: '-0.8765509457',
    eth_jpy_corr: '-0.8246674168',
  },
  {
    date: '2024-01-05',
    crude: '94.75951184',
    gold: '117.4013923',
    btc: '217.9795105',
    eth: '150.4624646',
    jpy: '106.006016',
    gold_crude_corr: '-0.5672257644',
    crude_btc_corr: '-0.8983480991',
    crude_eth_corr: '-0.8774098803',
    crude_jpy_corr: '0.8535835595',
    gold_btc_corr: '0.6691161392',
    gold_eth_corr: '0.6397212288',
    gold_jpy_corr: '-0.7593469392',
    btc_eth_corr: '0.950419164',
    btc_jpy_corr: '-0.871810674',
    eth_jpy_corr: '-0.8254249406',
  },
  {
    date: '2024-01-06',
    crude: '94.75951184',
    gold: '117.4013923',
    btc: '217.1231634',
    eth: '148.6702661',
    jpy: '106.006016',
    gold_crude_corr: '-0.5719678007',
    crude_btc_corr: '-0.8932551467',
    crude_eth_corr: '-0.8693637677',
    crude_jpy_corr: '0.8542979739',
    gold_btc_corr: '0.6732032708',
    gold_eth_corr: '0.6467349936',
    gold_jpy_corr: '-0.7594670971',
    btc_eth_corr: '0.9472963321',
    btc_jpy_corr: '-0.8669458953',
    eth_jpy_corr: '-0.8252214446',
  },
  {
    date: '2024-01-07',
    crude: '94.75951184',
    gold: '117.4013923',
    btc: '216.8956332',
    eth: '147.4261354',
    jpy: '106.006016',
    gold_crude_corr: '-0.5772351325',
    crude_btc_corr: '-0.8876809363',
    crude_eth_corr: '-0.8610210112',
    crude_jpy_corr: '0.8555400097',
    gold_btc_corr: '0.6773680865',
    gold_eth_corr: '0.6506478397',
    gold_jpy_corr: '-0.7594354192',
    btc_eth_corr: '0.9415867681',
    btc_jpy_corr: '-0.8620729705',
    eth_jpy_corr: '-0.8230216872',
  },
  {
    date: '2024-01-08',
    crude: '94.75951184',
    gold: '116.473319',
    btc: '231.838394',
    eth: '154.7326749',
    jpy: '105.9701381',
    gold_crude_corr: '-0.5689586424',
    crude_btc_corr: '-0.8685659864',
    crude_eth_corr: '-0.8507028069',
    crude_jpy_corr: '0.8598407324',
    gold_btc_corr: '0.6572717632',
    gold_eth_corr: '0.6418059012',
    gold_jpy_corr: '-0.7562057093',
    btc_eth_corr: '0.9357930815',
    btc_jpy_corr: '-0.8472295939',
    eth_jpy_corr: '-0.8226924089',
  },
  {
    date: '2024-01-09',
    crude: '91.6367552',
    gold: '116.473319',
    btc: '227.737838',
    eth: '155.5149118',
    jpy: '105.6142528',
    gold_crude_corr: '-0.5512242916',
    crude_btc_corr: '-0.8652146882',
    crude_eth_corr: '-0.8432980735',
    crude_jpy_corr: '0.8597701316',
    gold_btc_corr: '0.6374896571',
    gold_eth_corr: '0.626482588',
    gold_jpy_corr: '-0.7508666567',
    btc_eth_corr: '0.9346610562',
    btc_jpy_corr: '-0.8378595479',
    eth_jpy_corr: '-0.8218146541',
  },
  {
    date: '2024-01-10',
    crude: '94.43646805',
    gold: '116.2413062',
    btc: '230.1467542',
    eth: '171.2516851',
    jpy: '105.7592393',
    gold_crude_corr: '-0.533185863',
    crude_btc_corr: '-0.852727268',
    crude_eth_corr: '-0.8064669127',
    crude_jpy_corr: '0.8564338461',
    gold_btc_corr: '0.6063607027',
    gold_eth_corr: '0.5641351363',
    gold_jpy_corr: '-0.743005925',
    btc_eth_corr: '0.9285969297',
    btc_jpy_corr: '-0.8235136459',
    eth_jpy_corr: '-0.7826156102',
  },
  {
    date: '2024-01-11',
    crude: '94.005743',
    gold: '116.473319',
    btc: '228.8674296',
    eth: '173.7398169',
    jpy: '106.7155743',
    gold_crude_corr: '-0.5220005229',
    crude_btc_corr: '-0.8459380225',
    crude_eth_corr: '-0.77716838',
    crude_jpy_corr: '0.8518103926',
    gold_btc_corr: '0.5835214495',
    gold_eth_corr: '0.5325981594',
    gold_jpy_corr: '-0.7331266314',
    btc_eth_corr: '0.9250291002',
    btc_jpy_corr: '-0.801242529',
    eth_jpy_corr: '-0.7318948394',
  },
  {
    date: '2024-01-12',
    crude: '96.26704953',
    gold: '117.6334162',
    btc: '211.5159262',
    eth: '167.4286338',
    jpy: '106.2117861',
    gold_crude_corr: '-0.5026316633',
    crude_btc_corr: '-0.8411571186',
    crude_eth_corr: '-0.7493443558',
    crude_jpy_corr: '0.8476983368',
    gold_btc_corr: '0.5724795914',
    gold_eth_corr: '0.5305573583',
    gold_jpy_corr: '-0.7187120638',
    btc_eth_corr: '0.9060263324',
    btc_jpy_corr: '-0.7957716861',
    eth_jpy_corr: '-0.7098232782',
  },
  {
    date: '2024-01-13',
    crude: '96.26704953',
    gold: '117.6334162',
    btc: '211.4626926',
    eth: '170.8865387',
    jpy: '106.2117861',
    gold_crude_corr: '-0.4806462448',
    crude_btc_corr: '-0.8357866198',
    crude_eth_corr: '-0.7141760742',
    crude_jpy_corr: '0.8431943792',
    gold_btc_corr: '0.5581705277',
    gold_eth_corr: '0.5211700962',
    gold_jpy_corr: '-0.7020604917',
    btc_eth_corr: '0.8804448846',
    btc_jpy_corr: '-0.789081553',
    eth_jpy_corr: '-0.6796366496',
  },
  {
    date: '2024-01-14',
    crude: '96.26704953',
    gold: '117.6334162',
    btc: '206.2992559',
    eth: '163.9653371',
    jpy: '106.2117861',
    gold_crude_corr: '-0.4554302053',
    crude_btc_corr: '-0.830795472',
    crude_eth_corr: '-0.6946163147',
    crude_jpy_corr: '0.8382500631',
    gold_btc_corr: '0.537953867',
    gold_eth_corr: '0.5139978434',
    gold_jpy_corr: '-0.6826183294',
    btc_eth_corr: '0.8628953885',
    btc_jpy_corr: '-0.7816057352',
    eth_jpy_corr: '-0.6621734974',
  },
  {
    date: '2024-01-15',
    crude: '94.86719311',
    gold: '117.6334162',
    btc: '209.8318251',
    eth: '166.5600449',
    jpy: '106.2872069',
    gold_crude_corr: '-0.4238470763',
    crude_btc_corr: '-0.8239080017',
    crude_eth_corr: '-0.6800401204',
    crude_jpy_corr: '0.8303379538',
    gold_btc_corr: '0.5115016106',
    gold_eth_corr: '0.5057720476',
    gold_jpy_corr: '-0.6585005668',
    btc_eth_corr: '0.847877176',
    btc_jpy_corr: '-0.7709134236',
    eth_jpy_corr: '-0.6409660353',
  },
  {
    date: '2024-01-16',
    crude: '95.36970567',
    gold: '116.5313249',
    btc: '213.0054477',
    eth: '171.622272',
    jpy: '106.7595195',
    gold_crude_corr: '-0.3742303439',
    crude_btc_corr: '-0.8104766537',
    crude_eth_corr: '-0.6458441449',
    crude_jpy_corr: '0.8165802058',
    gold_btc_corr: '0.4744602748',
    gold_eth_corr: '0.4603188452',
    gold_jpy_corr: '-0.6332198739',
    btc_eth_corr: '0.825569715',
    btc_jpy_corr: '-0.752385683',
    eth_jpy_corr: '-0.5945764742',
  },
  {
    date: '2024-01-17',
    crude: '95.65685571',
    gold: '115.2552268',
    btc: '210.97044',
    eth: '167.6879006',
    jpy: '107.8227619',
    gold_crude_corr: '-0.3093925604',
    crude_btc_corr: '-0.8082231583',
    crude_eth_corr: '-0.626870548',
    crude_jpy_corr: '0.8027607363',
    gold_btc_corr: '0.443995516',
    gold_eth_corr: '0.4065349129',
    gold_jpy_corr: '-0.6132757185',
    btc_eth_corr: '0.8111701562',
    btc_jpy_corr: '-0.7373771647',
    eth_jpy_corr: '-0.5499297377',
  },
  {
    date: '2024-01-18',
    crude: '96.62598708',
    gold: '116.1833002',
    btc: '203.6624818',
    eth: '163.6189734',
    jpy: '108.4612907',
    gold_crude_corr: '-0.2725090502',
    crude_btc_corr: '-0.7967355305',
    crude_eth_corr: '-0.5945756805',
    crude_jpy_corr: '0.7867068946',
    gold_btc_corr: '0.4130317017',
    gold_eth_corr: '0.3648529312',
    gold_jpy_corr: '-0.5902632148',
    btc_eth_corr: '0.7854621554',
    btc_jpy_corr: '-0.7170349107',
    eth_jpy_corr: '-0.4913782439',
  },
  {
    date: '2024-01-19',
    crude: '98.56424982',
    gold: '116.5313249',
    btc: '205.4213531',
    eth: '165.1098868',
    jpy: '108.4686204',
    gold_crude_corr: '-0.2563473701',
    crude_btc_corr: '-0.7964672104',
    crude_eth_corr: '-0.5614959989',
    crude_jpy_corr: '0.7905187137',
    gold_btc_corr: '0.3790056737',
    gold_eth_corr: '0.3217629647',
    gold_jpy_corr: '-0.5625660166',
    btc_eth_corr: '0.7587353501',
    btc_jpy_corr: '-0.6954516856',
    eth_jpy_corr: '-0.4271308862',
  },
  {
    date: '2024-01-20',
    crude: '98.56424982',
    gold: '116.5313249',
    btc: '205.6542239',
    eth: '163.7894431',
    jpy: '108.4686204',
    gold_crude_corr: '-0.2394516621',
    crude_btc_corr: '-0.7972911217',
    crude_eth_corr: '-0.5316215288',
    crude_jpy_corr: '0.7949585334',
    gold_btc_corr: '0.3394922039',
    gold_eth_corr: '0.2744071896',
    gold_jpy_corr: '-0.5316542119',
    btc_eth_corr: '0.7322036226',
    btc_jpy_corr: '-0.6711189841',
    eth_jpy_corr: '-0.3614723891',
  },
  {
    date: '2024-01-21',
    crude: '98.56424982',
    gold: '116.5313249',
    btc: '205.0629077',
    eth: '162.7497691',
    jpy: '108.4686204',
    gold_crude_corr: '-0.2216972628',
    crude_btc_corr: '-0.796435856',
    crude_eth_corr: '-0.5035253178',
    crude_jpy_corr: '0.800119722',
    gold_btc_corr: '0.3008631864',
    gold_eth_corr: '0.2292787762',
    gold_jpy_corr: '-0.4968731926',
    btc_eth_corr: '0.7087619933',
    btc_jpy_corr: '-0.6485732849',
    eth_jpy_corr: '-0.2998498708',
  },
  {
    date: '2024-01-22',
    crude: '96.87724336',
    gold: '116.0672883',
    btc: '195.0016244',
    eth: '153.2598965',
    jpy: '108.4634918',
    gold_crude_corr: '-0.1716574686',
    crude_btc_corr: '-0.7833395252',
    crude_eth_corr: '-0.4784436712',
    crude_jpy_corr: '0.7858094831',
    gold_btc_corr: '0.2601565725',
    gold_eth_corr: '0.1800635956',
    gold_jpy_corr: '-0.4670878202',
    btc_eth_corr: '0.6858542241',
    btc_jpy_corr: '-0.6364314859',
    eth_jpy_corr: '-0.2591819241',
  },
  {
    date: '2024-01-23',
    crude: '99.42569993',
    gold: '116.473319',
    btc: '196.6708409',
    eth: '148.6080078',
    jpy: '108.4788665',
    gold_crude_corr: '-0.1370907519',
    crude_btc_corr: '-0.7718262377',
    crude_eth_corr: '-0.4419640232',
    crude_jpy_corr: '0.7844881525',
    gold_btc_corr: '0.219484176',
    gold_eth_corr: '0.1368568587',
    gold_jpy_corr: '-0.4514042463',
    btc_eth_corr: '0.6549710172',
    btc_jpy_corr: '-0.6326245157',
    eth_jpy_corr: '-0.2299903238',
  },
  {
    date: '2024-01-24',
    crude: '98.95908112',
    gold: '115.6032516',
    btc: '197.8136011',
    eth: '148.1355082',
    jpy: '108.5586896',
    gold_crude_corr: '-0.09094790683',
    crude_btc_corr: '-0.758817853',
    crude_eth_corr: '-0.4148409542',
    crude_jpy_corr: '0.7862358303',
    gold_btc_corr: '0.1832330169',
    gold_eth_corr: '0.101762498',
    gold_jpy_corr: '-0.4439444587',
    btc_eth_corr: '0.6397002445',
    btc_jpy_corr: '-0.628143836',
    eth_jpy_corr: '-0.2128037936',
  },
  {
    date: '2024-01-25',
    crude: '100.3589375',
    gold: '116.0672883',
    btc: '197.1064664',
    eth: '147.0841922',
    jpy: '108.0753935',
    gold_crude_corr: '-0.03721163972',
    crude_btc_corr: '-0.7441241066',
    crude_eth_corr: '-0.3857683508',
    crude_jpy_corr: '0.7788743538',
    gold_btc_corr: '0.1371393351',
    gold_eth_corr: '0.06183093404',
    gold_jpy_corr: '-0.4201252986',
    btc_eth_corr: '0.6233138865',
    btc_jpy_corr: '-0.6134857472',
    eth_jpy_corr: '-0.1849424395',
  },
  {
    date: '2024-01-26',
    crude: '102.045944',
    gold: '115.9512763',
    btc: '206.4009417',
    eth: '150.366462',
    jpy: '108.1720549',
    gold_crude_corr: '-0.01259168032',
    crude_btc_corr: '-0.7105928629',
    crude_eth_corr: '-0.3507225111',
    crude_jpy_corr: '0.7685001616',
    gold_btc_corr: '0.09779178197',
    gold_eth_corr: '0.03028510759',
    gold_jpy_corr: '-0.4028001701',
    btc_eth_corr: '0.6063824499',
    btc_jpy_corr: '-0.589080469',
    eth_jpy_corr: '-0.1514205303',
  },
  {
    date: '2024-01-27',
    crude: '102.045944',
    gold: '115.9512763',
    btc: '207.8974042',
    eth: '150.4119778',
    jpy: '108.1720549',
    gold_crude_corr: '0.01289715407',
    crude_btc_corr: '-0.6719648521',
    crude_eth_corr: '-0.3147292494',
    crude_jpy_corr: '0.7579099526',
    gold_btc_corr: '0.05316006261',
    gold_eth_corr: '-0.003281393283',
    gold_jpy_corr: '-0.3842318801',
    btc_eth_corr: '0.5876556535',
    btc_jpy_corr: '-0.5603284674',
    eth_jpy_corr: '-0.1155680801',
  },
  {
    date: '2024-01-28',
    crude: '102.045944',
    gold: '115.9512763',
    btc: '207.4805194',
    eth: '149.7038357',
    jpy: '108.1720549',
    gold_crude_corr: '0.0393189587',
    crude_btc_corr: '-0.6312689358',
    crude_eth_corr: '-0.2756400424',
    crude_jpy_corr: '0.7470927815',
    gold_btc_corr: '-0.00004716119968',
    gold_eth_corr: '-0.04265118299',
    gold_jpy_corr: '-0.3642832856',
    btc_eth_corr: '0.5622511198',
    btc_jpy_corr: '-0.5274464823',
    eth_jpy_corr: '-0.07352392299',
  },
  {
    date: '2024-01-29',
    crude: '104.1277818',
    gold: '116.7633377',
    btc: '213.6633976',
    eth: '153.6736029',
    jpy: '108.4774028',
    gold_crude_corr: '0.05394814047',
    crude_btc_corr: '-0.5684175569',
    crude_eth_corr: '-0.2262499607',
    crude_jpy_corr: '0.7426116785',
    gold_btc_corr: '-0.0430027362',
    gold_eth_corr: '-0.07832519789',
    gold_jpy_corr: '-0.3453865165',
    btc_eth_corr: '0.5240758077',
    btc_jpy_corr: '-0.4751699227',
    eth_jpy_corr: '-0.01127398182',
  },
  {
    date: '2024-01-30',
    crude: '102.333094',
    gold: '116.9953616',
    btc: '212.0067521',
    eth: '155.4927935',
    jpy: '107.9853244',
    gold_crude_corr: '0.04912892247',
    crude_btc_corr: '-0.5315502326',
    crude_eth_corr: '-0.1819877396',
    crude_jpy_corr: '0.739409544',
    gold_btc_corr: '-0.03317489872',
    gold_eth_corr: '-0.07107733225',
    gold_jpy_corr: '-0.3542654639',
    btc_eth_corr: '0.4861882611',
    btc_jpy_corr: '-0.4408290924',
    eth_jpy_corr: '0.04027172707',
  },
  {
    date: '2024-01-31',
    crude: '102.5484566',
    gold: '116.8793497',
    btc: '210.1804759',
    eth: '151.3841634',
    jpy: '107.9128311',
    gold_crude_corr: '0.03601292397',
    crude_btc_corr: '-0.4921108441',
    crude_eth_corr: '-0.1402843799',
    crude_jpy_corr: '0.7390287516',
    gold_btc_corr: '-0.01400291936',
    gold_eth_corr: '-0.05577208097',
    gold_jpy_corr: '-0.3616332214',
    btc_eth_corr: '0.4350638229',
    btc_jpy_corr: '-0.4278638115',
    eth_jpy_corr: '0.070215439',
  },
  {
    date: '2024-02-01',
    crude: '100.28715',
    gold: '118.0394469',
    btc: '212.6146689',
    eth: '152.7955254',
    jpy: '107.6396962',
    gold_crude_corr: '0.05370018404',
    crude_btc_corr: '-0.4330912314',
    crude_eth_corr: '-0.07656489181',
    crude_jpy_corr: '0.7433673488',
    gold_btc_corr: '-0.008801355647',
    gold_eth_corr: '-0.05710591621',
    gold_jpy_corr: '-0.3478723387',
    btc_eth_corr: '0.3746877721',
    btc_jpy_corr: '-0.4135979672',
    eth_jpy_corr: '0.1025295691',
  },
  {
    date: '2024-02-02',
    crude: '97.20028715',
    gold: '116.9373557',
    btc: '213.1580343',
    eth: '153.0749674',
    jpy: '107.2406144',
    gold_crude_corr: '0.01320252611',
    crude_btc_corr: '-0.3986446742',
    crude_eth_corr: '-0.03123152731',
    crude_jpy_corr: '0.7366954292',
    gold_btc_corr: '0.06413437763',
    gold_eth_corr: '-0.00196954925',
    gold_jpy_corr: '-0.3958186601',
    btc_eth_corr: '0.321204852',
    btc_jpy_corr: '-0.3840449974',
    eth_jpy_corr: '0.1478902962',
  },
  {
    date: '2024-02-03',
    crude: '97.20028715',
    gold: '116.9373557',
    btc: '212.2024114',
    eth: '152.279113',
    jpy: '107.2406144',
    gold_crude_corr: '-0.03188241808',
    crude_btc_corr: '-0.3653432382',
    crude_eth_corr: '0.001175497276',
    crude_jpy_corr: '0.7296332854',
    gold_btc_corr: '0.13403052',
    gold_eth_corr: '0.03958354223',
    gold_jpy_corr: '-0.448351845',
    btc_eth_corr: '0.2881795833',
    btc_jpy_corr: '-0.355958504',
    eth_jpy_corr: '0.1784794259',
  },
  {
    date: '2024-02-04',
    crude: '97.20028715',
    gold: '116.9373557',
    btc: '210.185296',
    eth: '151.8485346',
    jpy: '107.2406144',
    gold_crude_corr: '-0.08237691896',
    crude_btc_corr: '-0.3343616759',
    crude_eth_corr: '0.02927596279',
    crude_jpy_corr: '0.7221438742',
    gold_btc_corr: '0.2030085005',
    gold_eth_corr: '0.0775787847',
    gold_jpy_corr: '-0.5062336858',
    btc_eth_corr: '0.2634808445',
    btc_jpy_corr: '-0.3311321247',
    eth_jpy_corr: '0.2039681531',
  },
  {
    date: '2024-02-05',
    crude: '97.27207466',
    gold: '116.2993011',
    btc: '210.5559074',
    eth: '152.4681711',
    jpy: '108.7417553',
    gold_crude_corr: '-0.08385481143',
    crude_btc_corr: '-0.3320835371',
    crude_eth_corr: '0.03274291372',
    crude_jpy_corr: '0.7187104722',
    gold_btc_corr: '0.2014742046',
    gold_eth_corr: '0.07581425199',
    gold_jpy_corr: '-0.5063169884',
    btc_eth_corr: '0.2583570672',
    btc_jpy_corr: '-0.3249568022',
    eth_jpy_corr: '0.2032390111',
  },
  {
    date: '2024-02-06',
    crude: '97.73869347',
    gold: '116.9373557',
    btc: '212.65859',
    eth: '157.3304753',
    jpy: '108.8435341',
    gold_crude_corr: '-0.07767326453',
    crude_btc_corr: '-0.3378998056',
    crude_eth_corr: '0.03922081286',
    crude_jpy_corr: '0.7181853266',
    gold_btc_corr: '0.2130265316',
    gold_eth_corr: '0.07311588116',
    gold_jpy_corr: '-0.4889924499',
    btc_eth_corr: '0.2641230876',
    btc_jpy_corr: '-0.3327036605',
    eth_jpy_corr: '0.2158134418',
  },
  {
    date: '2024-02-07',
    crude: '97.98994975',
    gold: '116.8793497',
    btc: '218.7471862',
    eth: '160.7489526',
    jpy: '108.2416096',
    gold_crude_corr: '-0.0767939766',
    crude_btc_corr: '-0.3244222976',
    crude_eth_corr: '0.04990918766',
    crude_jpy_corr: '0.7247374628',
    gold_btc_corr: '0.2156764949',
    gold_eth_corr: '0.06878125053',
    gold_jpy_corr: '-0.4818275787',
    btc_eth_corr: '0.2844131254',
    btc_jpy_corr: '-0.3198683661',
    eth_jpy_corr: '0.2443794118',
  },
  {
    date: '2024-02-08',
    crude: '99.28212491',
    gold: '116.7633377',
    btc: '223.6008032',
    eth: '160.4943653',
    jpy: '108.43786',
    gold_crude_corr: '-0.08139115826',
    crude_btc_corr: '-0.2860861512',
    crude_eth_corr: '0.06794376725',
    crude_jpy_corr: '0.7577340874',
    gold_btc_corr: '0.2100988019',
    gold_eth_corr: '0.06813202435',
    gold_jpy_corr: '-0.4777678989',
    btc_eth_corr: '0.2970391568',
    btc_jpy_corr: '-0.2825419736',
    eth_jpy_corr: '0.2546724948',
  },
  {
    date: '2024-02-09',
    crude: '102.2613065',
    gold: '116.2993011',
    btc: '232.7105319',
    eth: '164.9783751',
    jpy: '109.2968072',
    gold_crude_corr: '-0.1272796247',
    crude_btc_corr: '-0.1703164263',
    crude_eth_corr: '0.1129021198',
    crude_jpy_corr: '0.7736415139',
    gold_btc_corr: '0.1997771741',
    gold_eth_corr: '0.062774956',
    gold_jpy_corr: '-0.4965921784',
    btc_eth_corr: '0.3330603153',
    btc_jpy_corr: '-0.1857376566',
    eth_jpy_corr: '0.2841447892',
  },
  {
    date: '2024-02-10',
    crude: '102.2613065',
    gold: '116.2993011',
    btc: '235.7908751',
    eth: '165.8878328',
    jpy: '109.2968072',
    gold_crude_corr: '-0.1754452551',
    crude_btc_corr: '-0.06598138507',
    crude_eth_corr: '0.1535805771',
    crude_jpy_corr: '0.7879290061',
    gold_btc_corr: '0.1768767591',
    gold_eth_corr: '0.05163385045',
    gold_jpy_corr: '-0.5177511696',
    btc_eth_corr: '0.3719586166',
    btc_jpy_corr: '-0.09436108518',
    eth_jpy_corr: '0.3121234092',
  },
  {
    date: '2024-02-11',
    crude: '102.2613065',
    gold: '116.2993011',
    btc: '238.37054',
    eth: '166.3084693',
    jpy: '109.2968072',
    gold_crude_corr: '-0.2264363996',
    crude_btc_corr: '0.02832718463',
    crude_eth_corr: '0.1934696917',
    crude_jpy_corr: '0.8009215688',
    gold_btc_corr: '0.1521797174',
    gold_eth_corr: '0.04184844529',
    gold_jpy_corr: '-0.5413543334',
    btc_eth_corr: '0.4079081274',
    btc_jpy_corr: '-0.0117150087',
    eth_jpy_corr: '0.338751912',
  },
  {
    date: '2024-02-12',
    crude: '102.2613065',
    gold: '116.0092823',
    btc: '246.585264',
    eth: '176.2930245',
    jpy: '109.2294425',
    gold_crude_corr: '-0.29546455',
    crude_btc_corr: '0.1072375098',
    crude_eth_corr: '0.2410150168',
    crude_jpy_corr: '0.8103448556',
    gold_btc_corr: '0.04832942844',
    gold_eth_corr: '-0.04466510263',
    gold_jpy_corr: '-0.5963634483',
    btc_eth_corr: '0.4804760603',
    btc_jpy_corr: '0.06876344496',
    eth_jpy_corr: '0.3770586057',
  },
  {
    date: '2024-02-13',
    crude: '103.3740129',
    gold: '114.4431543',
    btc: '245.5202046',
    eth: '175.2364623',
    jpy: '109.4037147',
    gold_crude_corr: '-0.4103636558',
    crude_btc_corr: '0.1808342083',
    crude_eth_corr: '0.2869705302',
    crude_jpy_corr: '0.8239477497',
    gold_btc_corr: '-0.1264955595',
    gold_eth_corr: '-0.204893311',
    gold_jpy_corr: '-0.6540442102',
    btc_eth_corr: '0.5285738556',
    btc_jpy_corr: '0.1402907092',
    eth_jpy_corr: '0.4202332599',
  },
  {
    date: '2024-02-14',
    crude: '104.4149318',
    gold: '114.2691475',
    btc: '255.8077263',
    eth: '184.2375623',
    jpy: '110.3695694',
    gold_crude_corr: '-0.490942493',
    crude_btc_corr: '0.2730586974',
    crude_eth_corr: '0.3480281893',
    crude_jpy_corr: '0.8539497438',
    gold_btc_corr: '-0.2579802062',
    gold_eth_corr: '-0.3171174481',
    gold_jpy_corr: '-0.6819375631',
    btc_eth_corr: '0.6094420793',
    btc_jpy_corr: '0.2303370033',
    eth_jpy_corr: '0.4719642055',
  },
  {
    date: '2024-02-15',
    crude: '102.4407753',
    gold: '115.1392149',
    btc: '256.3598451',
    eth: '187.3200064',
    jpy: '110.2377675',
    gold_crude_corr: '-0.5258979933',
    crude_btc_corr: '0.3254337694',
    crude_eth_corr: '0.3914141846',
    crude_jpy_corr: '0.8630040106',
    gold_btc_corr: '-0.3175810267',
    gold_eth_corr: '-0.3694970893',
    gold_jpy_corr: '-0.7013215568',
    btc_eth_corr: '0.6712780163',
    btc_jpy_corr: '0.2952566196',
    eth_jpy_corr: '0.5105961233',
  },
  {
    date: '2024-02-16',
    crude: '104.2713568',
    gold: '115.5452456',
    btc: '257.453864',
    eth: '185.9479587',
    jpy: '109.7998691',
    gold_crude_corr: '-0.5707513622',
    crude_btc_corr: '0.3780749698',
    crude_eth_corr: '0.4303698477',
    crude_jpy_corr: '0.8650312357',
    gold_btc_corr: '-0.3642796856',
    gold_eth_corr: '-0.4179969776',
    gold_jpy_corr: '-0.7396591204',
    btc_eth_corr: '0.7115356926',
    btc_jpy_corr: '0.3331368707',
    eth_jpy_corr: '0.5278842281',
  },
  {
    date: '2024-02-17',
    crude: '104.2713568',
    gold: '115.5452456',
    btc: '254.9997349',
    eth: '184.8192286',
    jpy: '109.7998691',
    gold_crude_corr: '-0.6143957696',
    crude_btc_corr: '0.4196615442',
    crude_eth_corr: '0.4609724144',
    crude_jpy_corr: '0.8665356856',
    gold_btc_corr: '-0.4038373693',
    gold_eth_corr: '-0.4614704106',
    gold_jpy_corr: '-0.7778357666',
    btc_eth_corr: '0.7396709209',
    btc_jpy_corr: '0.3642575282',
    eth_jpy_corr: '0.5424595746',
  },
  {
    date: '2024-02-18',
    crude: '104.2713568',
    gold: '115.5452456',
    btc: '257.2679991',
    eth: '190.942487',
    jpy: '109.7998691',
    gold_crude_corr: '-0.657208656',
    crude_btc_corr: '0.4510171137',
    crude_eth_corr: '0.4883242674',
    crude_jpy_corr: '0.8675457192',
    gold_btc_corr: '-0.4460429622',
    gold_eth_corr: '-0.5060119716',
    gold_jpy_corr: '-0.8160738533',
    btc_eth_corr: '0.7660014287',
    btc_jpy_corr: '0.3868490188',
    eth_jpy_corr: '0.5524893031',
  },
  {
    date: '2024-02-19',
    crude: '104.3072505',
    gold: '115.5452456',
    btc: '255.5730238',
    eth: '195.2253756',
    jpy: '109.9060503',
    gold_crude_corr: '-0.6865975503',
    crude_btc_corr: '0.4819777511',
    crude_eth_corr: '0.5142323407',
    crude_jpy_corr: '0.868740726',
    gold_btc_corr: '-0.4725958236',
    gold_eth_corr: '-0.5351640459',
    gold_jpy_corr: '-0.8429278057',
    btc_eth_corr: '0.7888162146',
    btc_jpy_corr: '0.4123537913',
    eth_jpy_corr: '0.5626559537',
  },
  {
    date: '2024-02-20',
    crude: '105.4199569',
    gold: '116.2993011',
    btc: '258.0692231',
    eth: '199.8632421',
    jpy: '110.037126',
    gold_crude_corr: '-0.6807408931',
    crude_btc_corr: '0.5147672305',
    crude_eth_corr: '0.5474696879',
    crude_jpy_corr: '0.8713450351',
    gold_btc_corr: '-0.4688984375',
    gold_eth_corr: '-0.5196775584',
    gold_jpy_corr: '-0.8433960614',
    btc_eth_corr: '0.8089985239',
    btc_jpy_corr: '0.4351967199',
    eth_jpy_corr: '0.5709994759',
  },
  {
    date: '2024-02-21',
    crude: '103.6970567',
    gold: '116.2993011',
    btc: '255.8693471',
    eth: '197.001544',
    jpy: '109.7947405',
    gold_crude_corr: '-0.6837203675',
    crude_btc_corr: '0.5357002668',
    crude_eth_corr: '0.5655938645',
    crude_jpy_corr: '0.8744770946',
    gold_btc_corr: '-0.4698032542',
    gold_eth_corr: '-0.520302549',
    gold_jpy_corr: '-0.8487507448',
    btc_eth_corr: '0.8275423704',
    btc_jpy_corr: '0.4561110054',
    eth_jpy_corr: '0.5797725223',
  },
  {
    date: '2024-02-22',
    crude: '104.8456569',
    gold: '116.2993011',
    btc: '253.2325923',
    eth: '197.0447767',
    jpy: '110.0993621',
    gold_crude_corr: '-0.67861484',
    crude_btc_corr: '0.558369143',
    crude_eth_corr: '0.5866077015',
    crude_jpy_corr: '0.8773788824',
    gold_btc_corr: '-0.4700429422',
    gold_eth_corr: '-0.5101723623',
    gold_jpy_corr: '-0.8451742516',
    btc_eth_corr: '0.8402475825',
    btc_jpy_corr: '0.477460303',
    eth_jpy_corr: '0.5907950285',
  },
  {
    date: '2024-02-23',
    crude: '104.8456569',
    gold: '116.9373557',
    btc: '250.4042464',
    eth: '193.7718172',
    jpy: '110.1930961',
    gold_crude_corr: '-0.657330384',
    crude_btc_corr: '0.5771570876',
    crude_eth_corr: '0.6050501765',
    crude_jpy_corr: '0.8821024824',
    gold_btc_corr: '-0.4580335688',
    gold_eth_corr: '-0.4841071576',
    gold_jpy_corr: '-0.8267059304',
    btc_eth_corr: '0.8478072738',
    btc_jpy_corr: '0.4994824185',
    eth_jpy_corr: '0.6056714439',
  },
  {
    date: '2024-02-24',
    crude: '104.8456569',
    gold: '116.9373557',
    btc: '254.5461592',
    eth: '198.4626641',
    jpy: '110.1930961',
    gold_crude_corr: '-0.636576315',
    crude_btc_corr: '0.5950210319',
    crude_eth_corr: '0.6212509643',
    crude_jpy_corr: '0.8866447658',
    gold_btc_corr: '-0.4420727672',
    gold_eth_corr: '-0.4532714967',
    gold_jpy_corr: '-0.808286119',
    btc_eth_corr: '0.85612304',
    btc_jpy_corr: '0.5199694849',
    eth_jpy_corr: '0.6175947862',
  },
  {
    date: '2024-02-25',
    crude: '104.8456569',
    gold: '116.9373557',
    btc: '255.3464383',
    eth: '206.4420147',
    jpy: '110.1930961',
    gold_crude_corr: '-0.6161461539',
    crude_btc_corr: '0.6091701778',
    crude_eth_corr: '0.6336904652',
    crude_jpy_corr: '0.891086133',
    gold_btc_corr: '-0.4231086474',
    gold_eth_corr: '-0.4132653443',
    gold_jpy_corr: '-0.7897588073',
    btc_eth_corr: '0.8607431222',
    btc_jpy_corr: '0.5349162334',
    eth_jpy_corr: '0.6234912121',
  },
  {
    date: '2024-02-26',
    crude: '102.7279253',
    gold: '116.7053428',
    btc: '269.1132763',
    eth: '210.83896',
    jpy: '110.2040796',
    gold_crude_corr: '-0.6055670759',
    crude_btc_corr: '0.6127740944',
    crude_eth_corr: '0.62804554',
    crude_jpy_corr: '0.8921526236',
    gold_btc_corr: '-0.4044512594',
    gold_eth_corr: '-0.3824555701',
    gold_jpy_corr: '-0.7754348994',
    btc_eth_corr: '0.8742747366',
    btc_jpy_corr: '0.5529872236',
    eth_jpy_corr: '0.6279322545',
  },
  {
    date: '2024-02-27',
    crude: '104.4508256',
    gold: '116.5893309',
    btc: '281.7636528',
    eth: '215.1847871',
    jpy: '110.2538684',
    gold_crude_corr: '-0.5910662841',
    crude_btc_corr: '0.6209462421',
    crude_eth_corr: '0.632744515',
    crude_jpy_corr: '0.8945926666',
    gold_btc_corr: '-0.3783019126',
    gold_eth_corr: '-0.3481312057',
    gold_jpy_corr: '-0.7569202455',
    btc_eth_corr: '0.8886513226',
    btc_jpy_corr: '0.5633327165',
    eth_jpy_corr: '0.6289275161',
  },
  {
    date: '2024-02-28',
    crude: '105.5994257',
    gold: '116.8213437',
    btc: '308.5129754',
    eth: '224.5485076',
    jpy: '110.2018784',
    gold_crude_corr: '-0.5904430025',
    crude_btc_corr: '0.6300600557',
    crude_eth_corr: '0.6466410051',
    crude_jpy_corr: '0.903478274',
    gold_btc_corr: '-0.3328007111',
    gold_eth_corr: '-0.31657158',
    gold_jpy_corr: '-0.7356389096',
    btc_eth_corr: '0.9040346285',
    btc_jpy_corr: '0.5660623748',
    eth_jpy_corr: '0.6347899609',
  },
  {
    date: '2024-02-29',
    crude: '104.9892319',
    gold: '117.4013923',
    btc: '302.064777',
    eth: '221.6446292',
    jpy: '110.3197806',
    gold_crude_corr: '-0.5515225521',
    crude_btc_corr: '0.6359895285',
    crude_eth_corr: '0.6550625691',
    crude_jpy_corr: '0.9098208107',
    gold_btc_corr: '-0.2586155663',
    gold_eth_corr: '-0.2547802119',
    gold_jpy_corr: '-0.6863082262',
    btc_eth_corr: '0.9142582978',
    btc_jpy_corr: '0.57596778',
    eth_jpy_corr: '0.6465349147',
  },
  {
    date: '2024-03-01',
    crude: '105.3122757',
    gold: '119.6635698',
    btc: '308.196311',
    eth: '227.8215318',
    jpy: '109.9089778',
    gold_crude_corr: '-0.405774247',
    crude_btc_corr: '0.6426695927',
    crude_eth_corr: '0.6627305568',
    crude_jpy_corr: '0.9075116896',
    gold_btc_corr: '-0.05760101021',
    gold_eth_corr: '-0.06916571525',
    gold_jpy_corr: '-0.5517454154',
    btc_eth_corr: '0.9238454555',
    btc_jpy_corr: '0.5749756114',
    eth_jpy_corr: '0.6464374147',
  },
  {
    date: '2024-03-02',
    crude: '105.3122757',
    gold: '119.6635698',
    btc: '306.1687456',
    eth: '226.9590634',
    jpy: '109.9089778',
    gold_crude_corr: '-0.2758299817',
    crude_btc_corr: '0.6505145343',
    crude_eth_corr: '0.670051587',
    crude_jpy_corr: '0.905105846',
    gold_btc_corr: '0.09710671905',
    gold_eth_corr: '0.07915519156',
    gold_jpy_corr: '-0.4289506904',
    btc_eth_corr: '0.9309096219',
    btc_jpy_corr: '0.5776442455',
    eth_jpy_corr: '0.6485820311',
  },
  {
    date: '2024-03-03',
    crude: '105.3122757',
    gold: '119.6635698',
    btc: '311.7833672',
    eth: '231.5315951',
    jpy: '109.9089778',
    gold_crude_corr: '-0.1577921839',
    crude_btc_corr: '0.6575544926',
    crude_eth_corr: '0.6755282084',
    crude_jpy_corr: '0.9026598997',
    gold_btc_corr: '0.2295418731',
    gold_eth_corr: '0.2101414735',
    gold_jpy_corr: '-0.3140765277',
    btc_eth_corr: '0.9374584042',
    btc_jpy_corr: '0.5813254461',
    eth_jpy_corr: '0.6501652069',
  },
  {
    date: '2024-03-04',
    crude: '106.7480258',
    gold: '121.5777225',
    btc: '337.2672665',
    eth: '240.7796235',
    jpy: '109.9162964',
    gold_crude_corr: '0.01057483513',
    crude_btc_corr: '0.6761783472',
    crude_eth_corr: '0.6918278408',
    crude_jpy_corr: '0.9006171191',
    gold_btc_corr: '0.4018147382',
    gold_eth_corr: '0.3652208831',
    gold_jpy_corr: '-0.1577656938',
    btc_eth_corr: '0.943993561',
    btc_jpy_corr: '0.5936812594',
    eth_jpy_corr: '0.6610837021',
  },
  {
    date: '2024-03-05',
    crude: '105.4558507',
    gold: '122.2737831',
    btc: '314.9118347',
    eth: '235.7743113',
    jpy: '110.1806489',
    gold_crude_corr: '0.1254862002',
    crude_btc_corr: '0.6939923196',
    crude_eth_corr: '0.7014937501',
    crude_jpy_corr: '0.9016541782',
    gold_btc_corr: '0.4912399752',
    gold_eth_corr: '0.467640828',
    gold_jpy_corr: '-0.01877686839',
    btc_eth_corr: '0.9493272081',
    btc_jpy_corr: '0.6284379769',
    eth_jpy_corr: '0.6837119443',
  },
  {
    date: '2024-03-06',
    crude: '104.4508256',
    gold: '123.3178684',
    btc: '326.29191',
    eth: '253.3008219',
    jpy: '109.8752899',
    gold_crude_corr: '0.18452322',
    crude_btc_corr: '0.6956645264',
    crude_eth_corr: '0.690447917',
    crude_jpy_corr: '0.8980348318',
    gold_btc_corr: '0.5663535089',
    gold_eth_corr: '0.5647690344',
    gold_jpy_corr: '0.05622436132',
    btc_eth_corr: '0.9545580481',
    btc_jpy_corr: '0.6386078547',
    eth_jpy_corr: '0.676950454',
  },
  {
    date: '2024-03-07',
    crude: '104.6302943',
    gold: '123.955923',
    btc: '330.3327732',
    eth: '256.9566095',
    jpy: '109.3041258',
    gold_crude_corr: '0.2306888958',
    crude_btc_corr: '0.6963829253',
    crude_eth_corr: '0.6821152043',
    crude_jpy_corr: '0.900365627',
    gold_btc_corr: '0.6237903082',
    gold_eth_corr: '0.6372436608',
    gold_jpy_corr: '0.0950169856',
    btc_eth_corr: '0.9594690924',
    btc_jpy_corr: '0.6390591338',
    eth_jpy_corr: '0.6589158635',
  },
  {
    date: '2024-03-08',
    crude: '104.7379756',
    gold: '125.0580032',
    btc: '337.1176106',
    eth: '258.1314059',
    jpy: '108.278225',
    gold_crude_corr: '0.2698243581',
    crude_btc_corr: '0.697003062',
    crude_eth_corr: '0.6759867628',
    crude_jpy_corr: '0.8854898291',
    gold_btc_corr: '0.671473633',
    gold_eth_corr: '0.6906968436',
    gold_jpy_corr: '0.07711350873',
    btc_eth_corr: '0.9641178564',
    btc_jpy_corr: '0.6009659913',
    eth_jpy_corr: '0.609083504',
  },
  {
    date: '2024-03-09',
    crude: '104.7379756',
    gold: '125.0580032',
    btc: '338.0987995',
    eth: '259.680562',
    jpy: '108.278225',
    gold_crude_corr: '0.3023443664',
    crude_btc_corr: '0.6979114011',
    crude_eth_corr: '0.6696667341',
    crude_jpy_corr: '0.8696414526',
    gold_btc_corr: '0.7083282163',
    gold_eth_corr: '0.7327090118',
    gold_jpy_corr: '0.06122098812',
    btc_eth_corr: '0.9681123388',
    btc_jpy_corr: '0.5653874368',
    eth_jpy_corr: '0.5610240062',
  },
  {
    date: '2024-03-10',
    crude: '104.7379756',
    gold: '125.0580032',
    btc: '340.6699039',
    eth: '257.4106178',
    jpy: '108.278225',
    gold_crude_corr: '0.3303889737',
    crude_btc_corr: '0.698840546',
    crude_eth_corr: '0.6641196111',
    crude_jpy_corr: '0.8525545453',
    gold_btc_corr: '0.738580251',
    gold_eth_corr: '0.7645137247',
    gold_jpy_corr: '0.04567093085',
    btc_eth_corr: '0.9717459899',
    btc_jpy_corr: '0.5307761583',
    eth_jpy_corr: '0.5163760709',
  },
  {
    date: '2024-03-11',
    crude: '101.8664752',
    gold: '125.2900271',
    btc: '355.9912967',
    eth: '269.6969994',
    jpy: '107.4771338',
    gold_crude_corr: '0.3129515606',
    crude_btc_corr: '0.6792176249',
    crude_eth_corr: '0.6336979043',
    crude_jpy_corr: '0.8365448805',
    gold_btc_corr: '0.7662416859',
    gold_eth_corr: '0.7901743865',
    gold_jpy_corr: '-0.01099000403',
    btc_eth_corr: '0.9765758447',
    btc_jpy_corr: '0.473327424',
    eth_jpy_corr: '0.4434420245',
  },
  {
    date: '2024-03-12',
    crude: '103.3740129',
    gold: '123.897917',
    btc: '352.8194479',
    eth: '263.981861',
    jpy: '107.4463733',
    gold_crude_corr: '0.3202286427',
    crude_btc_corr: '0.6903980817',
    crude_eth_corr: '0.629789438',
    crude_jpy_corr: '0.8015974684',
    gold_btc_corr: '0.7843943334',
    gold_eth_corr: '0.8066598169',
    gold_jpy_corr: '-0.06241057321',
    btc_eth_corr: '0.9795919107',
    btc_jpy_corr: '0.4205079598',
    eth_jpy_corr: '0.3781872859',
  },
  {
    date: '2024-03-13',
    crude: '103.3022254',
    gold: '124.6519725',
    btc: '360.7276878',
    eth: '265.7184399',
    jpy: '108.0958967',
    gold_crude_corr: '0.3189489219',
    crude_btc_corr: '0.6885715114',
    crude_eth_corr: '0.6299904089',
    crude_jpy_corr: '0.7787583985',
    gold_btc_corr: '0.8021391332',
    gold_eth_corr: '0.8208388779',
    gold_jpy_corr: '-0.1021948432',
    btc_eth_corr: '0.9808652659',
    btc_jpy_corr: '0.3869475565',
    eth_jpy_corr: '0.3467544197',
  },
  {
    date: '2024-03-14',
    crude: '105.850682',
    gold: '124.2459418',
    btc: '352.4014063',
    eth: '257.5397654',
    jpy: '108.0973605',
    gold_crude_corr: '0.3423918573',
    crude_btc_corr: '0.7097624977',
    crude_eth_corr: '0.6530614496',
    crude_jpy_corr: '0.7437767203',
    gold_btc_corr: '0.8146504088',
    gold_eth_corr: '0.8304738957',
    gold_jpy_corr: '-0.1322213773',
    btc_eth_corr: '0.9816132093',
    btc_jpy_corr: '0.3527142257',
    eth_jpy_corr: '0.3181044475',
  },
  {
    date: '2024-03-15',
    crude: '107.5735822',
    gold: '123.839911',
    btc: '342.5651852',
    eth: '247.729323',
    jpy: '108.5916401',
    gold_crude_corr: '0.3807869673',
    crude_btc_corr: '0.7208285316',
    crude_eth_corr: '0.6671405948',
    crude_jpy_corr: '0.7078099785',
    gold_btc_corr: '0.8271893001',
    gold_eth_corr: '0.8383056584',
    gold_jpy_corr: '-0.142544946',
    btc_eth_corr: '0.9821751013',
    btc_jpy_corr: '0.3254213846',
    eth_jpy_corr: '0.3003033587',
  },
  {
    date: '2024-03-16',
    crude: '107.5735822',
    gold: '123.839911',
    btc: '322.3842755',
    eth: '233.6450596',
    jpy: '108.5916401',
    gold_crude_corr: '0.4155321246',
    crude_btc_corr: '0.7271121157',
    crude_eth_corr: '0.6774493622',
    crude_jpy_corr: '0.6691670606',
    gold_btc_corr: '0.8347934009',
    gold_eth_corr: '0.8405397934',
    gold_jpy_corr: '-0.1563363798',
    btc_eth_corr: '0.9829533159',
    btc_jpy_corr: '0.3001404342',
    eth_jpy_corr: '0.2874242091',
  },
  {
    date: '2024-03-17',
    crude: '107.5735822',
    gold: '123.839911',
    btc: '337.5644574',
    eth: '241.5741178',
    jpy: '108.5916401',
    gold_crude_corr: '0.4474006205',
    crude_btc_corr: '0.733661712',
    crude_eth_corr: '0.686179299',
    crude_jpy_corr: '0.6267399608',
    gold_btc_corr: '0.8455175319',
    gold_eth_corr: '0.8462382784',
    gold_jpy_corr: '-0.1741422042',
    btc_eth_corr: '0.9831659904',
    btc_jpy_corr: '0.2651995507',
    eth_jpy_corr: '0.2643164872',
  },
  {
    date: '2024-03-18',
    crude: '109.0452261',
    gold: '124.0719239',
    btc: '333.4083348',
    eth: '233.3217371',
    jpy: '109.0741987',
    gold_crude_corr: '0.4894851656',
    crude_btc_corr: '0.7443787641',
    crude_eth_corr: '0.6964853229',
    crude_jpy_corr: '0.5757886503',
    gold_btc_corr: '0.8540841355',
    gold_eth_corr: '0.8481557219',
    gold_jpy_corr: '-0.1824762055',
    btc_eth_corr: '0.9826675339',
    btc_jpy_corr: '0.240927785',
    eth_jpy_corr: '0.250997145',
  },
  {
    date: '2024-03-19',
    crude: '110.8040201',
    gold: '123.897917',
    btc: '305.5908872',
    eth: '209.4212831',
    jpy: '109.212593',
    gold_crude_corr: '0.5200115256',
    crude_btc_corr: '0.7384067347',
    crude_eth_corr: '0.6849182606',
    crude_jpy_corr: '0.5240931322',
    gold_btc_corr: '0.8527504152',
    gold_eth_corr: '0.8382593524',
    gold_jpy_corr: '-0.1991205007',
    btc_eth_corr: '0.9818290167',
    btc_jpy_corr: '0.2208068737',
    eth_jpy_corr: '0.2418113282',
  },
  {
    date: '2024-03-20',
    crude: '110.8040201',
    gold: '125.348033',
    btc: '335.2102981',
    eth: '233.0171813',
    jpy: '110.5973066',
    gold_crude_corr: '0.5454292083',
    crude_btc_corr: '0.7449017658',
    crude_eth_corr: '0.6891294987',
    crude_jpy_corr: '0.5362410032',
    gold_btc_corr: '0.8542789039',
    gold_eth_corr: '0.834794434',
    gold_jpy_corr: '-0.1572870477',
    btc_eth_corr: '0.9813379455',
    btc_jpy_corr: '0.2342843682',
    eth_jpy_corr: '0.250329883',
  },
  {
    date: '2024-03-21',
    crude: '111.1270639',
    gold: '125.2900271',
    btc: '323.2543311',
    eth: '231.6640782',
    jpy: '110.3959276',
    gold_crude_corr: '0.5726492847',
    crude_btc_corr: '0.7401913365',
    crude_eth_corr: '0.6861129666',
    crude_jpy_corr: '0.5566467851',
    gold_btc_corr: '0.8545110959',
    gold_eth_corr: '0.831408913',
    gold_jpy_corr: '-0.1156837986',
    btc_eth_corr: '0.9818463681',
    btc_jpy_corr: '0.2454640427',
    eth_jpy_corr: '0.2592025705',
  },
  {
    date: '2024-03-22',
    crude: '110.1220388',
    gold: '124.3039477',
    btc: '314.801087',
    eth: '221.0986826',
    jpy: '111.0351938',
    gold_crude_corr: '0.5887298602',
    crude_btc_corr: '0.7344192372',
    crude_eth_corr: '0.6777114167',
    crude_jpy_corr: '0.5811456758',
    gold_btc_corr: '0.8553772939',
    gold_eth_corr: '0.8270699298',
    gold_jpy_corr: '-0.0681893926',
    btc_eth_corr: '0.9819165829',
    btc_jpy_corr: '0.2569569948',
    eth_jpy_corr: '0.2634090954',
  },
  {
    date: '2024-03-23',
    crude: '110.1220388',
    gold: '124.3039477',
    btc: '316.2001055',
    eth: '221.2914163',
    jpy: '111.0351938',
    gold_crude_corr: '0.6026794605',
    crude_btc_corr: '0.7289483254',
    crude_eth_corr: '0.6692110661',
    crude_jpy_corr: '0.602068674',
    gold_btc_corr: '0.8564986034',
    gold_eth_corr: '0.8230329424',
    gold_jpy_corr: '-0.02749455069',
    btc_eth_corr: '0.9817573551',
    btc_jpy_corr: '0.2669621797',
    eth_jpy_corr: '0.2663170254',
  },
  {
    date: '2024-03-24',
    crude: '110.1220388',
    gold: '124.3039477',
    btc: '331.8564021',
    eth: '229.1202944',
    jpy: '111.0351938',
    gold_crude_corr: '0.6148225241',
    crude_btc_corr: '0.7280051347',
    crude_eth_corr: '0.6647113675',
    crude_jpy_corr: '0.6201040706',
    gold_btc_corr: '0.8601843055',
    gold_eth_corr: '0.8218937295',
    gold_jpy_corr: '0.007525543902',
    btc_eth_corr: '0.9811461918',
    btc_jpy_corr: '0.2821251603',
    eth_jpy_corr: '0.2730727124',
  },
  {
    date: '2024-03-25',
    crude: '110.9475951',
    gold: '124.7099785',
    btc: '345.3047634',
    eth: '238.1565635',
    jpy: '110.7950094',
    gold_crude_corr: '0.6279600254',
    crude_btc_corr: '0.7264228006',
    crude_eth_corr: '0.6595863175',
    crude_jpy_corr: '0.6376426473',
    gold_btc_corr: '0.8649542952',
    gold_eth_corr: '0.8223579681',
    gold_jpy_corr: '0.03422848247',
    btc_eth_corr: '0.9801976608',
    btc_jpy_corr: '0.296200674',
    eth_jpy_corr: '0.2804620944',
  },
  {
    date: '2024-03-26',
    crude: '112.132089',
    gold: '125.0580032',
    btc: '345.4480181',
    eth: '237.9324658',
    jpy: '110.8448095',
    gold_crude_corr: '0.6409060618',
    crude_btc_corr: '0.7266334634',
    crude_eth_corr: '0.6521957988',
    crude_jpy_corr: '0.6512846',
    gold_btc_corr: '0.8707326549',
    gold_eth_corr: '0.8238273449',
    gold_jpy_corr: '0.06151696542',
    btc_eth_corr: '0.978688138',
    btc_jpy_corr: '0.3089695649',
    eth_jpy_corr: '0.2859530079',
  },
  {
    date: '2024-03-27',
    crude: '110.6245513',
    gold: '125.9280706',
    btc: '342.8197275',
    eth: '232.1365616',
    jpy: '110.959773',
    gold_crude_corr: '0.6501585905',
    crude_btc_corr: '0.7250429325',
    crude_eth_corr: '0.6426867945',
    crude_jpy_corr: '0.664424494',
    gold_btc_corr: '0.8727263416',
    gold_eth_corr: '0.8189949746',
    gold_jpy_corr: '0.09109055093',
    btc_eth_corr: '0.9763026792',
    btc_jpy_corr: '0.3215334795',
    eth_jpy_corr: '0.2886944644',
  },
  {
    date: '2024-03-28',
    crude: '111.5577889',
    gold: '127.5522045',
    btc: '349.1850194',
    eth: '236.1940896',
    jpy: '110.781836',
    gold_crude_corr: '0.6657848854',
    crude_btc_corr: '0.7279440078',
    crude_eth_corr: '0.6369289799',
    crude_jpy_corr: '0.6700450678',
    gold_btc_corr: '0.8755778887',
    gold_eth_corr: '0.8132900612',
    gold_jpy_corr: '0.115513293',
    btc_eth_corr: '0.9737877504',
    btc_jpy_corr: '0.3224869861',
    eth_jpy_corr: '0.2818972518',
  },
  {
    date: '2024-03-29',
    crude: '113.0653266',
    gold: '127.5522045',
    btc: '344.9790757',
    eth: '232.9119331',
    jpy: '110.8938721',
    gold_crude_corr: '0.684227345',
    crude_btc_corr: '0.7331611102',
    crude_eth_corr: '0.6321605258',
    crude_jpy_corr: '0.6798944215',
    gold_btc_corr: '0.8759803684',
    gold_eth_corr: '0.8056879584',
    gold_jpy_corr: '0.1389589354',
    btc_eth_corr: '0.9712174873',
    btc_jpy_corr: '0.3245626331',
    eth_jpy_corr: '0.2746546011',
  },
  {
    date: '2024-03-30',
    crude: '113.0653266',
    gold: '127.5522045',
    btc: '343.7573423',
    eth: '232.6558076',
    jpy: '110.8938721',
    gold_crude_corr: '0.6996701699',
    crude_btc_corr: '0.7375597278',
    crude_eth_corr: '0.6276817495',
    crude_jpy_corr: '0.6883944187',
    gold_btc_corr: '0.8760634416',
    gold_eth_corr: '0.7984745777',
    gold_jpy_corr: '0.1582323042',
    btc_eth_corr: '0.9687234991',
    btc_jpy_corr: '0.3243186693',
    eth_jpy_corr: '0.265263964',
  },
  {
    date: '2024-03-31',
    crude: '113.0653266',
    gold: '127.5522045',
    btc: '352.090719',
    eth: '241.9351352',
    jpy: '110.8938721',
    gold_crude_corr: '0.7126985335',
    crude_btc_corr: '0.7446549701',
    crude_eth_corr: '0.6295458703',
    crude_jpy_corr: '0.6957174848',
    gold_btc_corr: '0.8780927647',
    gold_eth_corr: '0.7962616288',
    gold_jpy_corr: '0.1738342718',
    btc_eth_corr: '0.9669345297',
    btc_jpy_corr: '0.3242036628',
    eth_jpy_corr: '0.258000669',
  },
  {
    date: '2024-04-01',
    crude: '113.4242642',
    gold: '128.8863086',
    btc: '344.0379133',
    eth: '232.4625233',
    jpy: '110.8631116',
    gold_crude_corr: '0.731187394',
    crude_btc_corr: '0.7564279988',
    crude_eth_corr: '0.6340774227',
    crude_jpy_corr: '0.7058270026',
    gold_btc_corr: '0.8773778709',
    gold_eth_corr: '0.7876798315',
    gold_jpy_corr: '0.1957532196',
    btc_eth_corr: '0.9643342384',
    btc_jpy_corr: '0.3256443195',
    eth_jpy_corr: '0.2508113904',
  },
  {
    date: '2024-04-02',
    crude: '114.2857143',
    gold: '130.8004613',
    btc: '323.0350916',
    eth: '217.3545513',
    jpy: '110.9971147',
    gold_crude_corr: '0.7485329538',
    crude_btc_corr: '0.749805966',
    crude_eth_corr: '0.6153761856',
    crude_jpy_corr: '0.7135706213',
    gold_btc_corr: '0.862918155',
    gold_eth_corr: '0.7600676117',
    gold_jpy_corr: '0.2196612674',
    btc_eth_corr: '0.9611588357',
    btc_jpy_corr: '0.3105038369',
    eth_jpy_corr: '0.2248648605',
  },
  {
    date: '2024-04-03',
    crude: '115.936827',
    gold: '131.9605586',
    btc: '325.670034',
    eth: '219.6232649',
    jpy: '110.9670916',
    gold_crude_corr: '0.7682520494',
    crude_btc_corr: '0.7414807142',
    crude_eth_corr: '0.5951254349',
    crude_jpy_corr: '0.7196045451',
    gold_btc_corr: '0.8474264917',
    gold_eth_corr: '0.7320221844',
    gold_jpy_corr: '0.2401665239',
    btc_eth_corr: '0.9577083141',
    btc_jpy_corr: '0.2916117734',
    eth_jpy_corr: '0.1936026492',
  },
  {
    date: '2024-04-04',
    crude: '116.7264896',
    gold: '131.264498',
    btc: '338.147965',
    eth: '220.8567737',
    jpy: '110.9971147',
    gold_crude_corr: '0.7853150164',
    crude_btc_corr: '0.7311183613',
    crude_eth_corr: '0.5647115228',
    crude_jpy_corr: '0.7184053605',
    gold_btc_corr: '0.8472827132',
    gold_eth_corr: '0.7159640058',
    gold_jpy_corr: '0.2601914028',
    btc_eth_corr: '0.952097421',
    btc_jpy_corr: '0.2696186592',
    eth_jpy_corr: '0.1533952449',
  },
  {
    date: '2024-04-05',
    crude: '117.9109835',
    gold: '133.4106746',
    btc: '334.8350209',
    eth: '220.1169275',
    jpy: '110.7855008',
    gold_crude_corr: '0.8061707988',
    crude_btc_corr: '0.7093399593',
    crude_eth_corr: '0.5189241866',
    crude_jpy_corr: '0.6998669347',
    gold_btc_corr: '0.8359835953',
    gold_eth_corr: '0.6869449309',
    gold_jpy_corr: '0.2669371783',
    btc_eth_corr: '0.9464862484',
    btc_jpy_corr: '0.2327768999',
    eth_jpy_corr: '0.09651639459',
  },
  {
    date: '2024-04-06',
    crude: '117.9109835',
    gold: '133.4106746',
    btc: '340.059442',
    eth: '222.4580195',
    jpy: '110.7855008',
    gold_crude_corr: '0.8239608704',
    crude_btc_corr: '0.6904646408',
    crude_eth_corr: '0.4741474738',
    crude_jpy_corr: '0.68070652',
    gold_btc_corr: '0.8304651365',
    gold_eth_corr: '0.6635400394',
    gold_jpy_corr: '0.2710066576',
    btc_eth_corr: '0.939869785',
    btc_jpy_corr: '0.1890219646',
    eth_jpy_corr: '0.02884772699',
  },
  {
    date: '2024-04-07',
    crude: '117.9109835',
    gold: '133.4106746',
    btc: '342.3617365',
    eth: '229.0445643',
    jpy: '110.7855008',
    gold_crude_corr: '0.8395589255',
    crude_btc_corr: '0.6715768407',
    crude_eth_corr: '0.4336828304',
    crude_jpy_corr: '0.6601903439',
    gold_btc_corr: '0.8286578755',
    gold_eth_corr: '0.6489183434',
    gold_jpy_corr: '0.2725510625',
    btc_eth_corr: '0.9339894159',
    btc_jpy_corr: '0.1343183742',
    eth_jpy_corr: '-0.05061206062',
  },
  {
    date: '2024-04-08',
    crude: '116.6547021',
    gold: '134.280742',
    btc: '353.5601694',
    eth: '245.0812301',
    jpy: '111.0769378',
    gold_crude_corr: '0.8505165265',
    crude_btc_corr: '0.6581938933',
    crude_eth_corr: '0.4060960666',
    crude_jpy_corr: '0.6636275177',
    gold_btc_corr: '0.82951792',
    gold_eth_corr: '0.6454477719',
    gold_jpy_corr: '0.2898623905',
    btc_eth_corr: '0.9297409776',
    btc_jpy_corr: '0.1239407025',
    eth_jpy_corr: '-0.07256250633',
  },
  {
    date: '2024-04-09',
    crude: '117.982771',
    gold: '135.0348086',
    btc: '341.2583858',
    eth: '232.4713642',
    jpy: '111.1889739',
    gold_crude_corr: '0.8639152749',
    crude_btc_corr: '0.6364695431',
    crude_eth_corr: '0.3657432094',
    crude_jpy_corr: '0.6694497229',
    gold_btc_corr: '0.8260977561',
    gold_eth_corr: '0.6308482924',
    gold_jpy_corr: '0.3106061172',
    btc_eth_corr: '0.9235685925',
    btc_jpy_corr: '0.1099132994',
    eth_jpy_corr: '-0.100798938',
  },
  {
    date: '2024-04-10',
    crude: '116.5829146',
    gold: '133.7586994',
    btc: '348.4097471',
    eth: '235.0296723',
    jpy: '111.1406376',
    gold_crude_corr: '0.8735275511',
    crude_btc_corr: '0.6194706131',
    crude_eth_corr: '0.3270398498',
    crude_jpy_corr: '0.662860869',
    gold_btc_corr: '0.8287513772',
    gold_eth_corr: '0.6214491937',
    gold_jpy_corr: '0.3189027138',
    btc_eth_corr: '0.9167061648',
    btc_jpy_corr: '0.07664735257',
    eth_jpy_corr: '-0.1540172853',
  },
  {
    date: '2024-04-11',
    crude: '118.8083274',
    gold: '136.2529007',
    btc: '345.8072154',
    eth: '232.4769666',
    jpy: '112.009842',
    gold_crude_corr: '0.8830883249',
    crude_btc_corr: '0.6018062749',
    crude_eth_corr: '0.2852917692',
    crude_jpy_corr: '0.6729026039',
    gold_btc_corr: '0.8248743272',
    gold_eth_corr: '0.6040890041',
    gold_jpy_corr: '0.3519329458',
    btc_eth_corr: '0.9087851028',
    btc_jpy_corr: '0.05634800879',
    eth_jpy_corr: '-0.2012151639',
  },
  {
    date: '2024-04-12',
    crude: '118.8083274',
    gold: '134.5127659',
    btc: '331.6673367',
    eth: '215.0863395',
    jpy: '112.1767956',
    gold_crude_corr: '0.8872862165',
    crude_btc_corr: '0.585561462',
    crude_eth_corr: '0.2370416953',
    crude_jpy_corr: '0.6925693764',
    gold_btc_corr: '0.8150248075',
    gold_eth_corr: '0.5682444566',
    gold_jpy_corr: '0.3840691602',
    btc_eth_corr: '0.8970746968',
    btc_jpy_corr: '0.05243663895',
    eth_jpy_corr: '-0.2380825557',
  },
  {
    date: '2024-04-13',
    crude: '118.8083274',
    gold: '134.5127659',
    btc: '315.0119009',
    eth: '199.2926512',
    jpy: '112.1767956',
    gold_crude_corr: '0.8907078926',
    crude_btc_corr: '0.5563258491',
    crude_eth_corr: '0.1641787514',
    crude_jpy_corr: '0.7090327351',
    gold_btc_corr: '0.7945958704',
    gold_eth_corr: '0.5092677112',
    gold_jpy_corr: '0.4109938719',
    btc_eth_corr: '0.8815057889',
    btc_jpy_corr: '0.03212122363',
    eth_jpy_corr: '-0.2984707194',
  },
  {
    date: '2024-04-14',
    crude: '118.8083274',
    gold: '134.5127659',
    btc: '324.4751391',
    eth: '209.3764312',
    jpy: '112.1767956',
    gold_crude_corr: '0.893467281',
    crude_btc_corr: '0.5338742147',
    crude_eth_corr: '0.1009681891',
    crude_jpy_corr: '0.7229460027',
    gold_btc_corr: '0.7804734735',
    gold_eth_corr: '0.4622291359',
    gold_jpy_corr: '0.4336627897',
    btc_eth_corr: '0.8663488335',
    btc_jpy_corr: '0.01752400662',
    eth_jpy_corr: '-0.3525694047',
  },
  {
    date: '2024-04-15',
    crude: '118.9519024',
    gold: '137.0649621',
    btc: '313.0609565',
    eth: '205.7060384',
    jpy: '112.243434',
    gold_crude_corr: '0.8968355738',
    crude_btc_corr: '0.5016022577',
    crude_eth_corr: '0.03475563719',
    crude_jpy_corr: '0.7347748539',
    gold_btc_corr: '0.7477098546',
    gold_eth_corr: '0.3973950193',
    gold_jpy_corr: '0.4591057884',
    btc_eth_corr: '0.8534772515',
    btc_jpy_corr: '-0.008869459595',
    eth_jpy_corr: '-0.4083184346',
  },
  {
    date: '2024-04-16',
    crude: '120.2081838',
    gold: '137.2389801',
    btc: '314.9644707',
    eth: '204.5997667',
    jpy: '112.9193044',
    gold_crude_corr: '0.9018582506',
    crude_btc_corr: '0.4708265147',
    crude_eth_corr: '-0.03398898556',
    crude_jpy_corr: '0.7512667586',
    gold_btc_corr: '0.7126926016',
    gold_eth_corr: '0.3209814949',
    gold_jpy_corr: '0.4914839741',
    btc_eth_corr: '0.8370393348',
    btc_jpy_corr: '-0.03184532214',
    eth_jpy_corr: '-0.4651489641',
  },
  {
    date: '2024-04-17',
    crude: '119.0236899',
    gold: '136.2529007',
    btc: '302.4512948',
    eth: '197.9547363',
    jpy: '113.2795809',
    gold_crude_corr: '0.9074052539',
    crude_btc_corr: '0.4365487031',
    crude_eth_corr: '-0.09683712016',
    crude_jpy_corr: '0.7702867845',
    gold_btc_corr: '0.6692489746',
    gold_eth_corr: '0.2401253324',
    gold_jpy_corr: '0.5364677801',
    btc_eth_corr: '0.8243759563',
    btc_jpy_corr: '-0.04482901314',
    eth_jpy_corr: '-0.4967142639',
  },
  {
    date: '2024-04-18',
    crude: '116.4752333',
    gold: '136.7169374',
    btc: '313.4881179',
    eth: '203.3467627',
    jpy: '113.0188933',
    gold_crude_corr: '0.9056047717',
    crude_btc_corr: '0.4079096146',
    crude_eth_corr: '-0.1572922817',
    crude_jpy_corr: '0.7832947526',
    gold_btc_corr: '0.6348248944',
    gold_eth_corr: '0.1691991171',
    gold_jpy_corr: '0.5720298741',
    btc_eth_corr: '0.8092190432',
    btc_jpy_corr: '-0.04972223947',
    eth_jpy_corr: '-0.5240182686',
  },
  {
    date: '2024-04-19',
    crude: '117.7315147',
    gold: '137.1229681',
    btc: '315.1209712',
    eth: '202.8991503',
    jpy: '113.2151436',
    gold_crude_corr: '0.9075266464',
    crude_btc_corr: '0.3833617353',
    crude_eth_corr: '-0.2153633686',
    crude_jpy_corr: '0.7900981324',
    gold_btc_corr: '0.6002835606',
    gold_eth_corr: '0.09411672433',
    gold_jpy_corr: '0.5980504544',
    btc_eth_corr: '0.7913915331',
    btc_jpy_corr: '-0.06542570614',
    eth_jpy_corr: '-0.5660416255',
  },
  {
    date: '2024-04-20',
    crude: '117.7315147',
    gold: '137.1229681',
    btc: '320.8014746',
    eth: '209.4218822',
    jpy: '113.2151436',
    gold_crude_corr: '0.9092108695',
    crude_btc_corr: '0.3602840616',
    crude_eth_corr: '-0.2727455945',
    crude_jpy_corr: '0.7964021582',
    gold_btc_corr: '0.5683804608',
    gold_eth_corr: '0.02305181852',
    gold_jpy_corr: '0.6209813342',
    btc_eth_corr: '0.7716339837',
    btc_jpy_corr: '-0.0785258875',
    eth_jpy_corr: '-0.604719907',
  },
  {
    date: '2024-04-21',
    crude: '117.7315147',
    gold: '137.1229681',
    btc: '320.4668408',
    eth: '208.7361984',
    jpy: '113.2151436',
    gold_crude_corr: '0.9106887176',
    crude_btc_corr: '0.334794237',
    crude_eth_corr: '-0.3270374354',
    crude_jpy_corr: '0.8022477228',
    gold_btc_corr: '0.5339431607',
    gold_eth_corr: '-0.04612744516',
    gold_jpy_corr: '0.6414851',
    btc_eth_corr: '0.7528207429',
    btc_jpy_corr: '-0.09461461444',
    eth_jpy_corr: '-0.640072419',
  },
  {
    date: '2024-04-22',
    crude: '114.8600144',
    gold: '133.7586994',
    btc: '329.8993843',
    eth: '212.341726',
    jpy: '113.2371106',
    gold_crude_corr: '0.9104035631',
    crude_btc_corr: '0.3126357182',
    crude_eth_corr: '-0.3725353167',
    crude_jpy_corr: '0.7997121342',
    gold_btc_corr: '0.5066944598',
    gold_eth_corr: '-0.1038147901',
    gold_jpy_corr: '0.6536579173',
    btc_eth_corr: '0.7328606634',
    btc_jpy_corr: '-0.1021373803',
    eth_jpy_corr: '-0.6662611672',
  },
  {
    date: '2024-04-23',
    crude: '116.0086145',
    gold: '133.4106746',
    btc: '327.7749725',
    eth: '213.552744',
    jpy: '113.3637951',
    gold_crude_corr: '0.9125030207',
    crude_btc_corr: '0.2931755596',
    crude_eth_corr: '-0.4093420038',
    crude_jpy_corr: '0.8003155961',
    gold_btc_corr: '0.4776110293',
    gold_eth_corr: '-0.1542943578',
    gold_jpy_corr: '0.6648922708',
    btc_eth_corr: '0.7180338967',
    btc_jpy_corr: '-0.1111465731',
    eth_jpy_corr: '-0.6848542727',
  },
  {
    date: '2024-04-24',
    crude: '117.5879397',
    gold: '133.1786507',
    btc: '317.2598049',
    eth: '208.2398804',
    jpy: '113.3132688',
    gold_crude_corr: '0.9116420993',
    crude_btc_corr: '0.2503549337',
    crude_eth_corr: '-0.467480386',
    crude_jpy_corr: '0.8058492686',
    gold_btc_corr: '0.4366142999',
    gold_eth_corr: '-0.216603008',
    gold_jpy_corr: '0.6719259481',
    btc_eth_corr: '0.702296708',
    btc_jpy_corr: '-0.1441328058',
    eth_jpy_corr: '-0.7196006959',
  },
  {
    date: '2024-04-25',
    crude: '117.5161522',
    gold: '133.9907232',
    btc: '318.2707052',
    eth: '209.3477551',
    jpy: '113.6903837',
    gold_crude_corr: '0.9130871158',
    crude_btc_corr: '0.2089631357',
    crude_eth_corr: '-0.520839254',
    crude_jpy_corr: '0.8105577921',
    gold_btc_corr: '0.3863088473',
    gold_eth_corr: '-0.2845381359',
    gold_jpy_corr: '0.6846303124',
    btc_eth_corr: '0.6866657948',
    btc_jpy_corr: '-0.1748852228',
    eth_jpy_corr: '-0.7476803369',
  },
  {
    date: '2024-04-26',
    crude: '119.4544149',
    gold: '134.396754',
    btc: '314.6853843',
    eth: '207.6005057',
    jpy: '113.9378866',
    gold_crude_corr: '0.9136474607',
    crude_btc_corr: '0.1513409032',
    crude_eth_corr: '-0.5864819219',
    crude_jpy_corr: '0.8200767181',
    gold_btc_corr: '0.3242787927',
    gold_eth_corr: '-0.3629325552',
    gold_jpy_corr: '0.6978965086',
    btc_eth_corr: '0.667468813',
    btc_jpy_corr: '-0.2181058244',
    eth_jpy_corr: '-0.7817928546',
  },
  {
    date: '2024-04-27',
    crude: '119.4544149',
    gold: '134.396754',
    btc: '313.0260587',
    eth: '215.6920833',
    jpy: '113.9378866',
    gold_crude_corr: '0.9142506463',
    crude_btc_corr: '0.08153059731',
    crude_eth_corr: '-0.6409437901',
    crude_jpy_corr: '0.8289638054',
    gold_btc_corr: '0.2475264938',
    gold_eth_corr: '-0.4331961055',
    gold_jpy_corr: '0.7111990557',
    btc_eth_corr: '0.6569962379',
    btc_jpy_corr: '-0.2730260188',
    eth_jpy_corr: '-0.8031718615',
  },
  {
    date: '2024-04-28',
    crude: '119.4544149',
    gold: '134.396754',
    btc: '311.5161383',
    eth: '216.3955298',
    jpy: '113.9378866',
    gold_crude_corr: '0.9149364851',
    crude_btc_corr: '-0.007472953407',
    crude_eth_corr: '-0.6830641912',
    crude_jpy_corr: '0.8373437544',
    gold_btc_corr: '0.1477712216',
    gold_eth_corr: '-0.4918572999',
    gold_jpy_corr: '0.7247561043',
    btc_eth_corr: '0.6706107396',
    btc_jpy_corr: '-0.3472482839',
    eth_jpy_corr: '-0.8158145195',
  },
  {
    date: '2024-04-29',
    crude: '119.4544149',
    gold: '134.1067352',
    btc: '315.1088823',
    eth: '213.2554421',
    jpy: '115.86154',
    gold_crude_corr: '0.9119603371',
    crude_btc_corr: '-0.1210922419',
    crude_eth_corr: '-0.7337934239',
    crude_jpy_corr: '0.8401795082',
    gold_btc_corr: '0.03880540427',
    gold_eth_corr: '-0.5483898632',
    gold_jpy_corr: '0.7270598114',
    btc_eth_corr: '0.6899629498',
    btc_jpy_corr: '-0.4152211298',
    eth_jpy_corr: '-0.8115075392',
  },
  {
    date: '2024-04-30',
    crude: '118.0545585',
    gold: '131.5545279',
    btc: '299.2931738',
    eth: '199.7825411',
    jpy: '114.4629155',
    gold_crude_corr: '0.9098407468',
    crude_btc_corr: '-0.2253111024',
    crude_eth_corr: '-0.7694029267',
    crude_jpy_corr: '0.8452327798',
    gold_btc_corr: '-0.07436376609',
    gold_eth_corr: '-0.5943402115',
    gold_jpy_corr: '0.7326141701',
    btc_eth_corr: '0.7209371754',
    btc_jpy_corr: '-0.4961421315',
    eth_jpy_corr: '-0.8285952074',
  },
  {
    date: '2024-05-01',
    crude: '117.0495334',
    gold: '132.5986132',
    btc: '287.5318636',
    eth: '196.963687',
    jpy: '115.4939449',
    gold_crude_corr: '0.9125253886',
    crude_btc_corr: '-0.2753015689',
    crude_eth_corr: '-0.7827305373',
    crude_jpy_corr: '0.8372998759',
    gold_btc_corr: '-0.1439328069',
    gold_eth_corr: '-0.6246639661',
    gold_jpy_corr: '0.7354359182',
    btc_eth_corr: '0.7409968135',
    btc_jpy_corr: '-0.5577703376',
    eth_jpy_corr: '-0.8392234514',
  },
  {
    date: '2024-05-02',
    crude: '113.4242642',
    gold: '132.2505774',
    btc: '291.8231816',
    eth: '198.1829468',
    jpy: '114.0162348',
    gold_crude_corr: '0.9111727622',
    crude_btc_corr: '-0.3146840291',
    crude_eth_corr: '-0.7901573934',
    crude_jpy_corr: '0.8307499823',
    gold_btc_corr: '-0.2223568747',
    gold_eth_corr: '-0.6596321326',
    gold_jpy_corr: '0.7483356308',
    btc_eth_corr: '0.760417306',
    btc_jpy_corr: '-0.6004482927',
    eth_jpy_corr: '-0.8487410083',
  },
  {
    date: '2024-05-03',
    crude: '113.4242642',
    gold: '132.1345765',
    btc: '310.413501',
    eth: '205.8348136',
    jpy: '112.0691506',
    gold_crude_corr: '0.9077990648',
    crude_btc_corr: '-0.3494014958',
    crude_eth_corr: '-0.7952434444',
    crude_jpy_corr: '0.8313219235',
    gold_btc_corr: '-0.2739988671',
    gold_eth_corr: '-0.6776099141',
    gold_jpy_corr: '0.7530250923',
    btc_eth_corr: '0.7748986986',
    btc_jpy_corr: '-0.623356521',
    eth_jpy_corr: '-0.8492646573',
  },
  {
    date: '2024-05-04',
    crude: '113.4242642',
    gold: '132.1345765',
    btc: '315.357409',
    eth: '206.7656124',
    jpy: '112.0691506',
    gold_crude_corr: '0.9042465744',
    crude_btc_corr: '-0.3910585088',
    crude_eth_corr: '-0.8023768386',
    crude_jpy_corr: '0.831970737',
    gold_btc_corr: '-0.333339674',
    gold_eth_corr: '-0.6984782088',
    gold_jpy_corr: '0.7584382062',
    btc_eth_corr: '0.7892260557',
    btc_jpy_corr: '-0.6506437931',
    eth_jpy_corr: '-0.8506244491',
  },
  {
    date: '2024-05-05',
    crude: '113.4242642',
    gold: '132.1345765',
    btc: '316.0467477',
    eth: '208.0703498',
    jpy: '112.0691506',
    gold_crude_corr: '0.9005115463',
    crude_btc_corr: '-0.4247990745',
    crude_eth_corr: '-0.8056194118',
    crude_jpy_corr: '0.8327228194',
    gold_btc_corr: '-0.3848202602',
    gold_eth_corr: '-0.7140438375',
    gold_jpy_corr: '0.7648034754',
    btc_eth_corr: '0.8030428535',
    btc_jpy_corr: '-0.6709931821',
    eth_jpy_corr: '-0.8503231698',
  },
  {
    date: '2024-05-06',
    crude: '113.4242642',
    gold: '133.5266866',
    btc: '311.7566058',
    eth: '203.127976',
    jpy: '112.4506567',
    gold_crude_corr: '0.8952228127',
    crude_btc_corr: '-0.4174883286',
    crude_eth_corr: '-0.7958823009',
    crude_jpy_corr: '0.8304152954',
    gold_btc_corr: '-0.3872230849',
    gold_eth_corr: '-0.7159167464',
    gold_jpy_corr: '0.7681819476',
    btc_eth_corr: '0.8057186126',
    btc_jpy_corr: '-0.671348771',
    eth_jpy_corr: '-0.848396916',
  },
  {
    date: '2024-05-07',
    crude: '111.7372577',
    gold: '132.888632',
    btc: '307.6740193',
    eth: '199.4038581',
    jpy: '112.9420088',
    gold_crude_corr: '0.8849794454',
    crude_btc_corr: '-0.4358532578',
    crude_eth_corr: '-0.7811479163',
    crude_jpy_corr: '0.8248288694',
    gold_btc_corr: '-0.4246265782',
    gold_eth_corr: '-0.7191335746',
    gold_jpy_corr: '0.7729057419',
    btc_eth_corr: '0.8236022358',
    btc_jpy_corr: '-0.6885076857',
    eth_jpy_corr: '-0.8485204463',
  },
  {
    date: '2024-05-08',
    crude: '111.5218952',
    gold: '132.5406072',
    btc: '302.01324',
    eth: '197.220525',
    jpy: '113.3161963',
    gold_crude_corr: '0.8750173634',
    crude_btc_corr: '-0.4316850805',
    crude_eth_corr: '-0.7506966032',
    crude_jpy_corr: '0.8146210113',
    gold_btc_corr: '-0.4374063647',
    gold_eth_corr: '-0.7073777541',
    gold_jpy_corr: '0.7721137876',
    btc_eth_corr: '0.8459219909',
    btc_jpy_corr: '-0.6986758507',
    eth_jpy_corr: '-0.8512842066',
  },
  {
    date: '2024-05-09',
    crude: '113.3883704',
    gold: '134.5707719',
    btc: '311.2038507',
    eth: '201.3566167',
    jpy: '113.8075484',
    gold_crude_corr: '0.8650215721',
    crude_btc_corr: '-0.4349855176',
    crude_eth_corr: '-0.729369058',
    crude_jpy_corr: '0.8022865539',
    gold_btc_corr: '-0.4485323068',
    gold_eth_corr: '-0.7009739725',
    gold_jpy_corr: '0.7704558472',
    btc_eth_corr: '0.8641165838',
    btc_jpy_corr: '-0.7078858992',
    eth_jpy_corr: '-0.8527918866',
  },
  {
    date: '2024-05-10',
    crude: '114.2139268',
    gold: '135.6728521',
    btc: '300.0627776',
    eth: '192.9847713',
    jpy: '113.7855814',
    gold_crude_corr: '0.8567422094',
    crude_btc_corr: '-0.42510489',
    crude_eth_corr: '-0.7025863152',
    crude_jpy_corr: '0.7859314304',
    gold_btc_corr: '-0.4580920912',
    gold_eth_corr: '-0.6994694541',
    gold_jpy_corr: '0.7689012521',
    btc_eth_corr: '0.8780293152',
    btc_jpy_corr: '-0.7173464221',
    eth_jpy_corr: '-0.8489733171',
  },
  {
    date: '2024-05-11',
    crude: '114.2139268',
    gold: '135.6728521',
    btc: '300.0673856',
    eth: '193.1048676',
    jpy: '113.7855814',
    gold_crude_corr: '0.8482167479',
    crude_btc_corr: '-0.4130304725',
    crude_eth_corr: '-0.6723658301',
    crude_jpy_corr: '0.7678674691',
    gold_btc_corr: '-0.4653664373',
    gold_eth_corr: '-0.6971205882',
    gold_jpy_corr: '0.7666495649',
    btc_eth_corr: '0.8924475876',
    btc_jpy_corr: '-0.7254039566',
    eth_jpy_corr: '-0.8442436097',
  },
  {
    date: '2024-05-12',
    crude: '114.2139268',
    gold: '135.6728521',
    btc: '303.2987922',
    eth: '194.2389735',
    jpy: '113.7855814',
    gold_crude_corr: '0.839384147',
    crude_btc_corr: '-0.3973136302',
    crude_eth_corr: '-0.6407877391',
    crude_jpy_corr: '0.7476407426',
    gold_btc_corr: '-0.4680322677',
    gold_eth_corr: '-0.6935010044',
    gold_jpy_corr: '0.763660212',
    btc_eth_corr: '0.9017776214',
    btc_jpy_corr: '-0.7301522826',
    eth_jpy_corr: '-0.8387899771',
  },
  {
    date: '2024-05-13',
    crude: '111.7731515',
    gold: '134.2227361',
    btc: '310.4708222',
    eth: '195.6090457',
    jpy: '114.0997116',
    gold_crude_corr: '0.8358937333',
    crude_btc_corr: '-0.3396540474',
    crude_eth_corr: '-0.5689132178',
    crude_jpy_corr: '0.6984739026',
    gold_btc_corr: '-0.4544340854',
    gold_eth_corr: '-0.6902182488',
    gold_jpy_corr: '0.7601187642',
    btc_eth_corr: '0.9017944923',
    btc_jpy_corr: '-0.7180487325',
    eth_jpy_corr: '-0.8274516146',
  },
  {
    date: '2024-05-14',
    crude: '113.747308',
    gold: '135.3248273',
    btc: '303.8140658',
    eth: '191.0857378',
    jpy: '114.4036069',
    gold_crude_corr: '0.8241570569',
    crude_btc_corr: '-0.2873431897',
    crude_eth_corr: '-0.5017637319',
    crude_jpy_corr: '0.650303435',
    gold_btc_corr: '-0.4400023863',
    gold_eth_corr: '-0.6783244549',
    gold_jpy_corr: '0.7517832505',
    btc_eth_corr: '0.904117938',
    btc_jpy_corr: '-0.711124368',
    eth_jpy_corr: '-0.8152372013',
  },
  {
    date: '2024-05-15',
    crude: '113.0653266',
    gold: '137.0649621',
    btc: '327.085036',
    eth: '201.4253357',
    jpy: '114.5976561',
    gold_crude_corr: '0.8056236584',
    crude_btc_corr: '-0.2115382292',
    crude_eth_corr: '-0.4172129435',
    crude_jpy_corr: '0.5928651302',
    gold_btc_corr: '-0.4014244796',
    gold_eth_corr: '-0.6709194337',
    gold_jpy_corr: '0.748061599',
    btc_eth_corr: '0.8880098983',
    btc_jpy_corr: '-0.6710441748',
    eth_jpy_corr: '-0.8010724683',
  },
  {
    date: '2024-05-16',
    crude: '111.9526202',
    gold: '136.5429306',
    btc: '321.9719602',
    eth: '195.3285998',
    jpy: '112.9749593',
    gold_crude_corr: '0.7708779018',
    crude_btc_corr: '-0.1530955326',
    crude_eth_corr: '-0.3297734333',
    crude_jpy_corr: '0.5428293501',
    gold_btc_corr: '-0.3700827446',
    gold_eth_corr: '-0.6594487421',
    gold_jpy_corr: '0.7345228911',
    btc_eth_corr: '0.8738227221',
    btc_jpy_corr: '-0.6497422851',
    eth_jpy_corr: '-0.7792278747',
  },
  {
    date: '2024-05-17',
    crude: '114.0703518',
    gold: '138.7471021',
    btc: '330.9566158',
    eth: '205.2098337',
    jpy: '113.7782516',
    gold_crude_corr: '0.7361016234',
    crude_btc_corr: '-0.1201003491',
    crude_eth_corr: '-0.2770966054',
    crude_jpy_corr: '0.5007772572',
    gold_btc_corr: '-0.3283653076',
    gold_eth_corr: '-0.6396527248',
    gold_jpy_corr: '0.7221946273',
    btc_eth_corr: '0.8642600916',
    btc_jpy_corr: '-0.6259075995',
    eth_jpy_corr: '-0.7643348397',
  },
  {
    date: '2024-05-18',
    crude: '114.0703518',
    gold: '138.7471021',
    btc: '330.4083917',
    eth: '207.1219342',
    jpy: '113.7782516',
    gold_crude_corr: '0.698048626',
    crude_btc_corr: '-0.1279646023',
    crude_eth_corr: '-0.2436784885',
    crude_jpy_corr: '0.4509341687',
    gold_btc_corr: '-0.3215134742',
    gold_eth_corr: '-0.6273181984',
    gold_jpy_corr: '0.7076073483',
    btc_eth_corr: '0.8689183677',
    btc_jpy_corr: '-0.6418462891',
    eth_jpy_corr: '-0.7608826949',
  },
  {
    date: '2024-05-19',
    crude: '114.0703518',
    gold: '138.7471021',
    btc: '327.1387132',
    eth: '203.7324568',
    jpy: '113.7782516',
    gold_crude_corr: '0.6555500117',
    crude_btc_corr: '-0.1008323299',
    crude_eth_corr: '-0.1856695923',
    crude_jpy_corr: '0.3907102972',
    gold_btc_corr: '-0.2927854861',
    gold_eth_corr: '-0.6086034935',
    gold_jpy_corr: '0.6901728659',
    btc_eth_corr: '0.8645147044',
    btc_jpy_corr: '-0.6326101919',
    eth_jpy_corr: '-0.7477495795',
  },
  {
    date: '2024-05-20',
    crude: '115.1471644',
    gold: '139.3271507',
    btc: '352.6561028',
    eth: '242.9962312',
    jpy: '114.0528502',
    gold_crude_corr: '0.6204414462',
    crude_btc_corr: '-0.08021220056',
    crude_eth_corr: '-0.1454015168',
    crude_jpy_corr: '0.3428052964',
    gold_btc_corr: '-0.2229337163',
    gold_eth_corr: '-0.5137872772',
    gold_jpy_corr: '0.6747550158',
    btc_eth_corr: '0.8732477776',
    btc_jpy_corr: '-0.5825284685',
    eth_jpy_corr: '-0.6763486898',
  },
  {
    date: '2024-05-21',
    crude: '114.2857143',
    gold: '139.1531328',
    btc: '346.1819528',
    eth: '251.3168774',
    jpy: '114.503922',
    gold_crude_corr: '0.5846438333',
    crude_btc_corr: '-0.1129007273',
    crude_eth_corr: '-0.1594380368',
    crude_jpy_corr: '0.2971210332',
    gold_btc_corr: '-0.2304131363',
    gold_eth_corr: '-0.4535158372',
    gold_jpy_corr: '0.6596959455',
    btc_eth_corr: '0.8755589094',
    btc_jpy_corr: '-0.6047705948',
    eth_jpy_corr: '-0.6225677404',
  },
  {
    date: '2024-05-22',
    crude: '113.0294329',
    gold: '136.6589314',
    btc: '341.1760578',
    eth: '247.8618062',
    jpy: '114.4167915',
    gold_crude_corr: '0.5494620986',
    crude_btc_corr: '-0.1097275595',
    crude_eth_corr: '-0.1546863531',
    crude_jpy_corr: '0.2530380041',
    gold_btc_corr: '-0.1988043592',
    gold_eth_corr: '-0.3884116782',
    gold_jpy_corr: '0.6518595248',
    btc_eth_corr: '0.8722226939',
    btc_jpy_corr: '-0.5664671637',
    eth_jpy_corr: '-0.5409542006',
  },
  {
    date: '2024-05-23',
    crude: '111.844939',
    gold: '133.8747113',
    btc: '335.2887316',
    eth: '250.4954396',
    jpy: '114.7484975',
    gold_crude_corr: '0.5211593718',
    crude_btc_corr: '-0.1235291473',
    crude_eth_corr: '-0.168190749',
    crude_jpy_corr: '0.1916319321',
    gold_btc_corr: '-0.2044075491',
    gold_eth_corr: '-0.3509153547',
    gold_jpy_corr: '0.6269483647',
    btc_eth_corr: '0.8675244265',
    btc_jpy_corr: '-0.5512595801',
    eth_jpy_corr: '-0.4500372242',
  },
  {
    date: '2024-05-24',
    crude: '111.8808327',
    gold: '134.0487292',
    btc: '338.2331466',
    eth: '247.179798',
    jpy: '114.9454853',
    gold_crude_corr: '0.4799099887',
    crude_btc_corr: '-0.1570464849',
    crude_eth_corr: '-0.1916637376',
    crude_jpy_corr: '0.129821725',
    gold_btc_corr: '-0.2332135905',
    gold_eth_corr: '-0.3388531372',
    gold_jpy_corr: '0.6090808732',
    btc_eth_corr: '0.8690625275',
    btc_jpy_corr: '-0.5350381525',
    eth_jpy_corr: '-0.3810133438',
  },
  {
    date: '2024-05-25',
    crude: '111.8808327',
    gold: '134.0487292',
    btc: '341.8848891',
    eth: '248.6589236',
    jpy: '114.9454853',
    gold_crude_corr: '0.4337484452',
    crude_btc_corr: '-0.1929798737',
    crude_eth_corr: '-0.216312189',
    crude_jpy_corr: '0.06657035305',
    gold_btc_corr: '-0.2629112173',
    gold_eth_corr: '-0.3296897298',
    gold_jpy_corr: '0.5901912079',
    btc_eth_corr: '0.8726673856',
    btc_jpy_corr: '-0.5135030918',
    eth_jpy_corr: '-0.3167044319',
  },
  {
    date: '2024-05-26',
    crude: '111.8808327',
    gold: '134.0487292',
    btc: '338.1936214',
    eth: '253.7432691',
    jpy: '114.9454853',
    gold_crude_corr: '0.3813083528',
    crude_btc_corr: '-0.1985604618',
    crude_eth_corr: '-0.2326036373',
    crude_jpy_corr: '0.001355279521',
    gold_btc_corr: '-0.2584189568',
    gold_eth_corr: '-0.3034633894',
    gold_jpy_corr: '0.569985107',
    btc_eth_corr: '0.8639046086',
    btc_jpy_corr: '-0.482968357',
    eth_jpy_corr: '-0.2426900933',
  },
  {
    date: '2024-05-27',
    crude: '113.3165829',
    gold: '134.0487292',
    btc: '342.5196831',
    eth: '258.1278113',
    jpy: '114.8700534',
    gold_crude_corr: '0.337319739',
    crude_btc_corr: '-0.1835050406',
    crude_eth_corr: '-0.225646047',
    crude_jpy_corr: '-0.05198926613',
    gold_btc_corr: '-0.2188208803',
    gold_eth_corr: '-0.2573204583',
    gold_jpy_corr: '0.5422204929',
    btc_eth_corr: '0.8555758197',
    btc_jpy_corr: '-0.4345065787',
    eth_jpy_corr: '-0.1599990804',
  },
  {
    date: '2024-05-28',
    crude: '114.1421393',
    gold: '135.3828333',
    btc: '337.0984843',
    eth: '254.695587',
    jpy: '114.8517513',
    gold_crude_corr: '0.3030808535',
    crude_btc_corr: '-0.1684330239',
    crude_eth_corr: '-0.215695555',
    crude_jpy_corr: '-0.08994004856',
    gold_btc_corr: '-0.1704125224',
    gold_eth_corr: '-0.2001335564',
    gold_jpy_corr: '0.5204273611',
    btc_eth_corr: '0.8445958879',
    btc_jpy_corr: '-0.3951576035',
    eth_jpy_corr: '-0.08910094973',
  },
  {
    date: '2024-05-29',
    crude: '116.6188083',
    gold: '134.1067352',
    btc: '333.5539418',
    eth: '249.5847838',
    jpy: '115.1570992',
    gold_crude_corr: '0.2445806798',
    crude_btc_corr: '-0.138068658',
    crude_eth_corr: '-0.1818054027',
    crude_jpy_corr: '-0.1226236556',
    gold_btc_corr: '-0.1314592633',
    gold_eth_corr: '-0.1735991559',
    gold_jpy_corr: '0.4868471401',
    btc_eth_corr: '0.8362334692',
    btc_jpy_corr: '-0.3601122093',
    eth_jpy_corr: '-0.0300771723',
  },
  {
    date: '2024-05-30',
    crude: '115.2548457',
    gold: '134.396754',
    btc: '337.4379382',
    eth: '248.5006141',
    jpy: '115.4104682',
    gold_crude_corr: '0.1991988215',
    crude_btc_corr: '-0.106842169',
    crude_eth_corr: '-0.1614515642',
    crude_jpy_corr: '-0.1660062386',
    gold_btc_corr: '-0.07742942878',
    gold_eth_corr: '-0.1394406205',
    gold_jpy_corr: '0.447136112',
    btc_eth_corr: '0.8331395463',
    btc_jpy_corr: '-0.3059992627',
    eth_jpy_corr: '0.03569613923',
  },
  {
    date: '2024-05-31',
    crude: '113.2089017',
    gold: '133.6426985',
    btc: '333.1261056',
    eth: '249.3745465',
    jpy: '114.9308369',
    gold_crude_corr: '0.1733801572',
    crude_btc_corr: '-0.09649223412',
    crude_eth_corr: '-0.1701605421',
    crude_jpy_corr: '-0.2091192162',
    gold_btc_corr: '-0.02908792062',
    gold_eth_corr: '-0.118934129',
    gold_jpy_corr: '0.4009668551',
    btc_eth_corr: '0.8267979969',
    btc_jpy_corr: '-0.2687778098',
    eth_jpy_corr: '0.08635133113',
  },
  {
    date: '2024-06-01',
    crude: '113.2089017',
    gold: '133.6426985',
    btc: '334.1898925',
    eth: '252.9010731',
    jpy: '114.9308369',
    gold_crude_corr: '0.1450117482',
    crude_btc_corr: '-0.08781848115',
    crude_eth_corr: '-0.180551051',
    crude_jpy_corr: '-0.2536268498',
    gold_btc_corr: '0.0235255522',
    gold_eth_corr: '-0.09969109166',
    gold_jpy_corr: '0.3466454248',
    btc_eth_corr: '0.8205233532',
    btc_jpy_corr: '-0.2298691668',
    eth_jpy_corr: '0.1377348298',
  },
  {
    date: '2024-06-02',
    crude: '113.2089017',
    gold: '133.6426985',
    btc: '334.4103467',
    eth: '250.7586573',
    jpy: '114.9308369',
    gold_crude_corr: '0.1131523718',
    crude_btc_corr: '-0.07211607371',
    crude_eth_corr: '-0.184102741',
    crude_jpy_corr: '-0.2998834087',
    gold_btc_corr: '0.1157406709',
    gold_eth_corr: '-0.05666884735',
    gold_jpy_corr: '0.2810256544',
    btc_eth_corr: '0.8141076525',
    btc_jpy_corr: '-0.1762614494',
    eth_jpy_corr: '0.1998472301',
  },
  {
    date: '2024-06-03',
    crude: '112.6704953',
    gold: '134.7447787',
    btc: '339.608662',
    eth: '249.7965269',
    jpy: '115.0970531',
    gold_crude_corr: '0.08198018022',
    crude_btc_corr: '-0.07325767328',
    crude_eth_corr: '-0.1984156579',
    crude_jpy_corr: '-0.3479870823',
    gold_btc_corr: '0.1842959594',
    gold_eth_corr: '-0.02714054691',
    gold_jpy_corr: '0.2204334642',
    btc_eth_corr: '0.8167351736',
    btc_jpy_corr: '-0.1217633325',
    eth_jpy_corr: '0.2495551035',
  },
  {
    date: '2024-06-04',
    crude: '106.9633884',
    gold: '133.5846925',
    btc: '348.3104521',
    eth: '252.8557678',
    jpy: '114.2886433',
    gold_crude_corr: '0.093068774',
    crude_btc_corr: '-0.1411819262',
    crude_eth_corr: '-0.2581924421',
    crude_jpy_corr: '-0.3719957656',
    gold_btc_corr: '0.1698876674',
    gold_eth_corr: '-0.04471222531',
    gold_jpy_corr: '0.1678667618',
    btc_eth_corr: '0.8238412942',
    btc_jpy_corr: '-0.1083620188',
    eth_jpy_corr: '0.2641539681',
  },
  {
    date: '2024-06-05',
    crude: '106.3890883',
    gold: '135.1508094',
    btc: '350.8526742',
    eth: '256.2876358',
    jpy: '113.5915211',
    gold_crude_corr: '0.08289427278',
    crude_btc_corr: '-0.2055681862',
    crude_eth_corr: '-0.3144858645',
    crude_jpy_corr: '-0.3525719401',
    gold_btc_corr: '0.1761906361',
    gold_eth_corr: '-0.03864780742',
    gold_jpy_corr: '0.1310252714',
    btc_eth_corr: '0.8316042844',
    btc_jpy_corr: '-0.1044527511',
    eth_jpy_corr: '0.2680572811',
  },
  {
    date: '2024-06-06',
    crude: '108.0760948',
    gold: '136.1368888',
    btc: '349.2452905',
    eth: '252.7954201',
    jpy: '114.1809984',
    gold_crude_corr: '0.07140925792',
    crude_btc_corr: '-0.2555221598',
    crude_eth_corr: '-0.3506717527',
    crude_jpy_corr: '-0.3511305573',
    gold_btc_corr: '0.220129369',
    gold_eth_corr: '-0.02099122431',
    gold_jpy_corr: '0.0835368378',
    btc_eth_corr: '0.8415134876',
    btc_jpy_corr: '-0.06784401636',
    eth_jpy_corr: '0.2843921201',
  },
  {
    date: '2024-06-07',
    crude: '109.4759512',
    gold: '131.322504',
    btc: '342.2631742',
    eth: '243.9760594',
    jpy: '114.0111174',
    gold_crude_corr: '0.1310628956',
    crude_btc_corr: '-0.2875151362',
    crude_eth_corr: '-0.37045938',
    crude_jpy_corr: '-0.3353209753',
    gold_btc_corr: '0.1899496024',
    gold_eth_corr: '-0.05173225109',
    gold_jpy_corr: '0.04672473988',
    btc_eth_corr: '0.8469197859',
    btc_jpy_corr: '-0.04220005779',
    eth_jpy_corr: '0.2984127095',
  },
  {
    date: '2024-06-08',
    crude: '109.4759512',
    gold: '131.322504',
    btc: '342.081474',
    eth: '244.1299809',
    jpy: '114.0111174',
    gold_crude_corr: '0.1850417522',
    crude_btc_corr: '-0.3239016342',
    crude_eth_corr: '-0.3903884496',
    crude_jpy_corr: '-0.317428106',
    gold_btc_corr: '0.166337517',
    gold_eth_corr: '-0.07906222452',
    gold_jpy_corr: '0.01031405203',
    btc_eth_corr: '0.8541467246',
    btc_jpy_corr: '-0.003263835485',
    eth_jpy_corr: '0.3173828237',
  },
  {
    date: '2024-06-09',
    crude: '109.4759512',
    gold: '131.322504',
    btc: '343.7706073',
    eth: '245.7849843',
    jpy: '114.0111174',
    gold_crude_corr: '0.234279562',
    crude_btc_corr: '-0.3645165814',
    crude_eth_corr: '-0.4153086645',
    crude_jpy_corr: '-0.2969684686',
    gold_btc_corr: '0.1427211949',
    gold_eth_corr: '-0.1031846083',
    gold_jpy_corr: '-0.02639300359',
    btc_eth_corr: '0.8594703414',
    btc_jpy_corr: '0.04545469103',
    eth_jpy_corr: '0.3506168157',
  },
  {
    date: '2024-06-10',
    crude: '110.6245513',
    gold: '132.4826013',
    btc: '343.1007613',
    eth: '243.1860504',
    jpy: '114.8429578',
    gold_crude_corr: '0.2540808588',
    crude_btc_corr: '-0.4054263486',
    crude_eth_corr: '-0.4414198905',
    crude_jpy_corr: '-0.3017733482',
    gold_btc_corr: '0.1297997231',
    gold_eth_corr: '-0.1158943893',
    gold_jpy_corr: '-0.05509746368',
    btc_eth_corr: '0.8608831884',
    btc_jpy_corr: '0.1356982002',
    eth_jpy_corr: '0.422699011',
  },
  {
    date: '2024-06-11',
    crude: '113.1730079',
    gold: '132.888632',
    btc: '332.3394192',
    eth: '232.0181979',
    jpy: '114.9945367',
    gold_crude_corr: '0.2576309838',
    crude_btc_corr: '-0.431306843',
    crude_eth_corr: '-0.4562979811',
    crude_jpy_corr: '-0.2811115987',
    gold_btc_corr: '0.1205046196',
    gold_eth_corr: '-0.1232920082',
    gold_jpy_corr: '-0.06734785002',
    btc_eth_corr: '0.8620951735',
    btc_jpy_corr: '0.1913873751',
    eth_jpy_corr: '0.4679990903',
  },
  {
    date: '2024-06-12',
    crude: '114.5010768',
    gold: '133.2366567',
    btc: '336.8268594',
    eth: '236.0829152',
    jpy: '115.0421356',
    gold_crude_corr: '0.2625278856',
    crude_btc_corr: '-0.4535612678',
    crude_eth_corr: '-0.4638039474',
    crude_jpy_corr: '-0.2668096372',
    gold_btc_corr: '0.1239309654',
    gold_eth_corr: '-0.1259749694',
    gold_jpy_corr: '-0.1045669734',
    btc_eth_corr: '0.8663346481',
    btc_jpy_corr: '0.2830052537',
    eth_jpy_corr: '0.5293713521',
  },
  {
    date: '2024-06-13',
    crude: '113.9985642',
    gold: '132.1925825',
    btc: '329.4981939',
    eth: '230.0915732',
    jpy: '114.8188008',
    gold_crude_corr: '0.2499138748',
    crude_btc_corr: '-0.4967613352',
    crude_eth_corr: '-0.4825993778',
    crude_jpy_corr: '-0.2360827277',
    gold_btc_corr: '0.1015774012',
    gold_eth_corr: '-0.1389480998',
    gold_jpy_corr: '-0.1000811216',
    btc_eth_corr: '0.8701593291',
    btc_jpy_corr: '0.3409055718',
    eth_jpy_corr: '0.5635495126',
  },
  {
    date: '2024-06-14',
    crude: '115.0035894',
    gold: '133.8747113',
    btc: '325.8194971',
    eth: '230.8205059',
    jpy: '115.0589852',
    gold_crude_corr: '0.2534305548',
    crude_btc_corr: '-0.5155367107',
    crude_eth_corr: '-0.4819689072',
    crude_jpy_corr: '-0.1976846611',
    gold_btc_corr: '0.1014412726',
    gold_eth_corr: '-0.141335142',
    gold_jpy_corr: '-0.1099709235',
    btc_eth_corr: '0.8737366583',
    btc_jpy_corr: '0.3630287395',
    eth_jpy_corr: '0.5719253628',
  },
  {
    date: '2024-06-15',
    crude: '115.0035894',
    gold: '133.8747113',
    btc: '326.7074837',
    eth: '236.4763329',
    jpy: '115.0589852',
    gold_crude_corr: '0.2570314841',
    crude_btc_corr: '-0.5110396616',
    crude_eth_corr: '-0.4664074352',
    crude_jpy_corr: '-0.1552365179',
    gold_btc_corr: '0.1003106995',
    gold_eth_corr: '-0.1462860909',
    gold_jpy_corr: '-0.1200151839',
    btc_eth_corr: '0.8722013018',
    btc_jpy_corr: '0.3554993718',
    eth_jpy_corr: '0.5665342626',
  },
  {
    date: '2024-06-16',
    crude: '115.0035894',
    gold: '133.8747113',
    btc: '328.9189666',
    eth: '240.124996',
    jpy: '115.0589852',
    gold_crude_corr: '0.2607365218',
    crude_btc_corr: '-0.5184403874',
    crude_eth_corr: '-0.4563501331',
    crude_jpy_corr: '-0.1079062168',
    gold_btc_corr: '0.09854628811',
    gold_eth_corr: '-0.1506072079',
    gold_jpy_corr: '-0.1303317769',
    btc_eth_corr: '0.8732507697',
    btc_jpy_corr: '0.3687051961',
    eth_jpy_corr: '0.5735855426',
  },
  {
    date: '2024-06-17',
    crude: '114.034458',
    gold: '133.1206558',
    btc: '328.184762',
    eth: '232.883597',
    jpy: '115.3650705',
    gold_crude_corr: '0.2369974488',
    crude_btc_corr: '-0.512353918',
    crude_eth_corr: '-0.4477775802',
    crude_jpy_corr: '-0.06020868375',
    gold_btc_corr: '0.1116974947',
    gold_eth_corr: '-0.1422434151',
    gold_jpy_corr: '-0.1091424232',
    btc_eth_corr: '0.8719957728',
    btc_jpy_corr: '0.3575759829',
    eth_jpy_corr: '0.5703783584',
  },
  {
    date: '2024-06-18',
    crude: '116.9418521',
    gold: '133.7586994',
    btc: '321.5236096',
    eth: '231.0466276',
    jpy: '115.4778328',
    gold_crude_corr: '0.1950298505',
    crude_btc_corr: '-0.5099712064',
    crude_eth_corr: '-0.428614823',
    crude_jpy_corr: '0.007211225524',
    gold_btc_corr: '0.1285319833',
    gold_eth_corr: '-0.1285871953',
    gold_jpy_corr: '-0.08955449678',
    btc_eth_corr: '0.8689401033',
    btc_jpy_corr: '0.3387240267',
    eth_jpy_corr: '0.5612364801',
  },
  {
    date: '2024-06-19',
    crude: '118.4493898',
    gold: '133.7586994',
    btc: '320.632943',
    eth: '236.0649906',
    jpy: '115.6030536',
    gold_crude_corr: '0.1639045914',
    crude_btc_corr: '-0.4877848843',
    crude_eth_corr: '-0.3891797786',
    crude_jpy_corr: '0.07223699951',
    gold_btc_corr: '0.1541432968',
    gold_eth_corr: '-0.1165516912',
    gold_jpy_corr: '-0.08357409119',
    btc_eth_corr: '0.860782212',
    btc_jpy_corr: '0.3099166171',
    eth_jpy_corr: '0.5546374944',
  },
  {
    date: '2024-06-20',
    crude: '118.4493898',
    gold: '135.4408393',
    btc: '319.9831873',
    eth: '232.8642314',
    jpy: '115.6667534',
    gold_crude_corr: '0.1603802172',
    crude_btc_corr: '-0.4815945855',
    crude_eth_corr: '-0.3650081508',
    crude_jpy_corr: '0.1259544709',
    gold_btc_corr: '0.167452658',
    gold_eth_corr: '-0.09729173248',
    gold_jpy_corr: '-0.04615016341',
    btc_eth_corr: '0.8555186025',
    btc_jpy_corr: '0.28441129',
    eth_jpy_corr: '0.5441687956',
  },
  {
    date: '2024-06-21',
    crude: '119.6338837',
    gold: '133.2946627',
    btc: '316.3679044',
    eth: '233.1950993',
    jpy: '116.3792503',
    gold_crude_corr: '0.1160208568',
    crude_btc_corr: '-0.4786996322',
    crude_eth_corr: '-0.3309337825',
    crude_jpy_corr: '0.2082897643',
    gold_btc_corr: '0.1898534301',
    gold_eth_corr: '-0.08008108759',
    gold_jpy_corr: '-0.04065354091',
    btc_eth_corr: '0.8479595396',
    btc_jpy_corr: '0.2431742355',
    eth_jpy_corr: '0.5267695553',
  },
  {
    date: '2024-06-22',
    crude: '119.6338837',
    gold: '133.2946627',
    btc: '317.139764',
    eth: '231.7849355',
    jpy: '116.3792503',
    gold_crude_corr: '0.07266831999',
    crude_btc_corr: '-0.48050012',
    crude_eth_corr: '-0.3045886602',
    crude_jpy_corr: '0.2830638187',
    gold_btc_corr: '0.2035529629',
    gold_eth_corr: '-0.0671469021',
    gold_jpy_corr: '-0.03224128058',
    btc_eth_corr: '0.8437413752',
    btc_jpy_corr: '0.2130470745',
    eth_jpy_corr: '0.5115675943',
  },
  {
    date: '2024-06-23',
    crude: '119.6338837',
    gold: '133.2946627',
    btc: '311.8496346',
    eth: '226.7310473',
    jpy: '116.3792503',
    gold_crude_corr: '0.03006797495',
    crude_btc_corr: '-0.4898502725',
    crude_eth_corr: '-0.2856675676',
    crude_jpy_corr: '0.3518092247',
    gold_btc_corr: '0.2201162638',
    gold_eth_corr: '-0.05017623998',
    gold_jpy_corr: '-0.02087591014',
    btc_eth_corr: '0.8388935583',
    btc_jpy_corr: '0.1734152074',
    eth_jpy_corr: '0.4896433952',
  },
  {
    date: '2024-06-24',
    crude: '119.88514',
    gold: '133.8747113',
    btc: '297.5190323',
    eth: '222.1975384',
    jpy: '117.0602493',
    gold_crude_corr: '0.0247094138',
    crude_btc_corr: '-0.5166753901',
    crude_eth_corr: '-0.2818446359',
    crude_jpy_corr: '0.4050453748',
    gold_btc_corr: '0.2215411761',
    gold_eth_corr: '-0.05231273509',
    gold_jpy_corr: '-0.03220272651',
    btc_eth_corr: '0.8295209642',
    btc_jpy_corr: '0.09725042311',
    eth_jpy_corr: '0.4518598081',
  },
  {
    date: '2024-06-25',
    crude: '120.8542714',
    gold: '133.1206558',
    btc: '305.0571621',
    eth: '225.1669783',
    jpy: '116.9321123',
    gold_crude_corr: '0.00866520216',
    crude_btc_corr: '-0.5357825661',
    crude_eth_corr: '-0.2702656662',
    crude_jpy_corr: '0.4616900436',
    gold_btc_corr: '0.2315756299',
    gold_eth_corr: '-0.05602635787',
    gold_jpy_corr: '-0.05976787772',
    btc_eth_corr: '0.8249302714',
    btc_jpy_corr: '0.05200563348',
    eth_jpy_corr: '0.4267069085',
  },
  {
    date: '2024-06-26',
    crude: '120.4594401',
    gold: '131.9605586',
    btc: '300.1540904',
    eth: '223.4723206',
    jpy: '116.9321123',
    gold_crude_corr: '-0.01790279432',
    crude_btc_corr: '-0.5535385879',
    crude_eth_corr: '-0.2571206976',
    crude_jpy_corr: '0.5205710929',
    gold_btc_corr: '0.2500697221',
    gold_eth_corr: '-0.0607405196',
    gold_jpy_corr: '-0.1084300861',
    btc_eth_corr: '0.8135054447',
    btc_jpy_corr: '-0.008855870483',
    eth_jpy_corr: '0.3976754171',
  },
  {
    date: '2024-06-27',
    crude: '120.4594401',
    gold: '133.4106746',
    btc: '304.0707867',
    eth: '228.4679499',
    jpy: '117.6167762',
    gold_crude_corr: '-0.027778302',
    crude_btc_corr: '-0.5666085232',
    crude_eth_corr: '-0.2390457569',
    crude_jpy_corr: '0.5703926721',
    gold_btc_corr: '0.25445864',
    gold_eth_corr: '-0.06301917922',
    gold_jpy_corr: '-0.1214416314',
    btc_eth_corr: '0.8027415228',
    btc_jpy_corr: '-0.06245449353',
    eth_jpy_corr: '0.3735101981',
  },
  {
    date: '2024-06-28',
    crude: '122.9002154',
    gold: '133.4686806',
    btc: '297.7299041',
    eth: '223.7481356',
    jpy: '117.6651013',
    gold_crude_corr: '-0.04398143497',
    crude_btc_corr: '-0.5874002893',
    crude_eth_corr: '-0.2179500762',
    crude_jpy_corr: '0.6353665881',
    gold_btc_corr: '0.2601338734',
    gold_eth_corr: '-0.06094543387',
    gold_jpy_corr: '-0.1295192856',
    btc_eth_corr: '0.7899025314',
    btc_jpy_corr: '-0.1244642867',
    eth_jpy_corr: '0.3442489409',
  },
  {
    date: '2024-06-29',
    crude: '122.9002154',
    gold: '133.4686806',
    btc: '300.5297148',
    eth: '223.703818',
    jpy: '117.6651013',
    gold_crude_corr: '-0.05901588825',
    crude_btc_corr: '-0.6007329812',
    crude_eth_corr: '-0.2061133822',
    crude_jpy_corr: '0.6914852495',
    gold_btc_corr: '0.2659554799',
    gold_eth_corr: '-0.05913679274',
    gold_jpy_corr: '-0.1367031192',
    btc_eth_corr: '0.7804273636',
    btc_jpy_corr: '-0.173861958',
    eth_jpy_corr: '0.3200750158',
  },
  {
    date: '2024-06-30',
    crude: '122.9002154',
    gold: '133.4686806',
    btc: '309.369361',
    eth: '227.6779571',
    jpy: '117.6651013',
    gold_crude_corr: '-0.07302619676',
    crude_btc_corr: '-0.5984604709',
    crude_eth_corr: '-0.1892321748',
    crude_jpy_corr: '0.7408021843',
    gold_btc_corr: '0.2713896714',
    gold_eth_corr: '-0.05837707085',
    gold_jpy_corr: '-0.1431223026',
    btc_eth_corr: '0.7748685563',
    btc_jpy_corr: '-0.2028388248',
    eth_jpy_corr: '0.3051703102',
  },
  {
    date: '2024-07-01',
    crude: '122.1823403',
    gold: '133.8167053',
    btc: '310.2266529',
    eth: '228.1722187',
    jpy: '117.8262',
    gold_crude_corr: '-0.07674037163',
    crude_btc_corr: '-0.5999880911',
    crude_eth_corr: '-0.1712166871',
    crude_jpy_corr: '0.7562726036',
    gold_btc_corr: '0.2725039411',
    gold_eth_corr: '-0.05893305995',
    gold_jpy_corr: '-0.1439954507',
    btc_eth_corr: '0.7698360726',
    btc_jpy_corr: '-0.2162086717',
    eth_jpy_corr: '0.3053325745',
  },
  {
    date: '2024-07-02',
    crude: '124.7666906',
    gold: '133.7586994',
    btc: '306.1646388',
    eth: '226.5813519',
    jpy: '118.2692158',
    gold_crude_corr: '-0.06417059785',
    crude_btc_corr: '-0.5979886052',
    crude_eth_corr: '-0.1492397632',
    crude_jpy_corr: '0.7882213235',
    gold_btc_corr: '0.2508239448',
    gold_eth_corr: '-0.09018082008',
    gold_jpy_corr: '-0.1536939563',
    btc_eth_corr: '0.7573930184',
    btc_jpy_corr: '-0.2524443438',
    eth_jpy_corr: '0.2878526694',
  },
  {
    date: '2024-07-03',
    crude: '124.9820531',
    gold: '135.3248273',
    btc: '297.0082126',
    eth: '218.39463',
    jpy: '118.263361',
    gold_crude_corr: '-0.03058632571',
    crude_btc_corr: '-0.6232174795',
    crude_eth_corr: '-0.1498727433',
    crude_jpy_corr: '0.806000286',
    gold_btc_corr: '0.2111910247',
    gold_eth_corr: '-0.1171787016',
    gold_jpy_corr: '-0.1211676515',
    btc_eth_corr: '0.7424962723',
    btc_jpy_corr: '-0.2898515613',
    eth_jpy_corr: '0.273664745',
  },
  {
    date: '2024-07-04',
    crude: '124.9820531',
    gold: '135.3248273',
    btc: '281.2322221',
    eth: '202.583681',
    jpy: '118.3490389',
    gold_crude_corr: '-0.01428884951',
    crude_btc_corr: '-0.6858500695',
    crude_eth_corr: '-0.1990365432',
    crude_jpy_corr: '0.8212885183',
    gold_btc_corr: '0.1531984146',
    gold_eth_corr: '-0.1553804973',
    gold_jpy_corr: '-0.10790925',
    btc_eth_corr: '0.7357905808',
    btc_jpy_corr: '-0.3781551574',
    eth_jpy_corr: '0.2067625634',
  },
  {
    date: '2024-07-05',
    crude: '124.7307968',
    gold: '137.1229681',
    btc: '279.6758163',
    eth: '197.7472193',
    jpy: '118.1564535',
    gold_crude_corr: '0.03017535454',
    crude_btc_corr: '-0.7192985971',
    crude_eth_corr: '-0.2475451871',
    crude_jpy_corr: '0.8462057905',
    gold_btc_corr: '0.07385434408',
    gold_eth_corr: '-0.2086563016',
    gold_jpy_corr: '-0.09448271592',
    btc_eth_corr: '0.7427976941',
    btc_jpy_corr: '-0.4600076266',
    eth_jpy_corr: '0.1237608191',
  },
  {
    date: '2024-07-06',
    crude: '124.7307968',
    gold: '137.1229681',
    btc: '287.7763221',
    eth: '203.5689659',
    jpy: '118.1564535',
    gold_crude_corr: '0.06802256114',
    crude_btc_corr: '-0.7408876517',
    crude_eth_corr: '-0.2843080686',
    crude_jpy_corr: '0.8700108298',
    gold_btc_corr: '0.01997213596',
    gold_eth_corr: '-0.2543159677',
    gold_jpy_corr: '-0.08703782345',
    btc_eth_corr: '0.749685611',
    btc_jpy_corr: '-0.5189710508',
    eth_jpy_corr: '0.05383366745',
  },
  {
    date: '2024-07-07',
    crude: '124.7307968',
    gold: '137.1229681',
    btc: '275.6616759',
    eth: '194.2844245',
    jpy: '118.1564535',
    gold_crude_corr: '0.1004019776',
    crude_btc_corr: '-0.762493936',
    crude_eth_corr: '-0.3287940682',
    crude_jpy_corr: '0.8933630494',
    gold_btc_corr: '-0.04116766028',
    gold_eth_corr: '-0.3048492316',
    gold_jpy_corr: '-0.0853758132',
    btc_eth_corr: '0.7623286213',
    btc_jpy_corr: '-0.5801377487',
    eth_jpy_corr: '-0.02659336717',
  },
  {
    date: '2024-07-08',
    crude: '123.5104092',
    gold: '135.3828333',
    btc: '279.886688',
    eth: '200.2099944',
    jpy: '117.6292234',
    gold_crude_corr: '0.1062483244',
    crude_btc_corr: '-0.7800567345',
    crude_eth_corr: '-0.364215731',
    crude_jpy_corr: '0.9099129724',
    gold_btc_corr: '-0.05902139992',
    gold_eth_corr: '-0.3236787729',
    gold_jpy_corr: '-0.09129802379',
    btc_eth_corr: '0.7695769303',
    btc_jpy_corr: '-0.631170396',
    eth_jpy_corr: '-0.0939945964',
  },
  {
    date: '2024-07-09',
    crude: '123.1873654',
    gold: '135.6728521',
    btc: '286.3236458',
    eth: '203.2144739',
    jpy: '117.7895846',
    gold_crude_corr: '0.1074751039',
    crude_btc_corr: '-0.80440801',
    crude_eth_corr: '-0.4073166095',
    crude_jpy_corr: '0.9156276508',
    gold_btc_corr: '-0.08343161509',
    gold_eth_corr: '-0.35647559',
    gold_jpy_corr: '-0.09998213514',
    btc_eth_corr: '0.7748455791',
    btc_jpy_corr: '-0.6788637066',
    eth_jpy_corr: '-0.1590615825',
  },
  {
    date: '2024-07-10',
    crude: '121.6080402',
    gold: '136.0788828',
    btc: '285.0071097',
    eth: '205.7470528',
    jpy: '118.1366765',
    gold_crude_corr: '0.1056623524',
    crude_btc_corr: '-0.8317920135',
    crude_eth_corr: '-0.4501597208',
    crude_jpy_corr: '0.9157585965',
    gold_btc_corr: '-0.1199323906',
    gold_eth_corr: '-0.4008250149',
    gold_jpy_corr: '-0.1030183608',
    btc_eth_corr: '0.775826222',
    btc_jpy_corr: '-0.7296791611',
    eth_jpy_corr: '-0.2220703824',
  },
  {
    date: '2024-07-11',
    crude: '123.9052405',
    gold: '138.4570833',
    btc: '283.0447126',
    eth: '205.6218399',
    jpy: '118.3387817',
    gold_crude_corr: '0.1481262177',
    crude_btc_corr: '-0.845782393',
    crude_eth_corr: '-0.4826311555',
    crude_jpy_corr: '0.9204877461',
    gold_btc_corr: '-0.168977391',
    gold_eth_corr: '-0.4250073255',
    gold_jpy_corr: '-0.04371092164',
    btc_eth_corr: '0.7821989623',
    btc_jpy_corr: '-0.7592938192',
    eth_jpy_corr: '-0.2735884269',
  },
  {
    date: '2024-07-12',
    crude: '121.4285714',
    gold: '138.4570833',
    btc: '285.7818807',
    eth: '207.8653911',
    jpy: '115.8447017',
    gold_crude_corr: '0.1765522187',
    crude_btc_corr: '-0.8605634912',
    crude_eth_corr: '-0.5146917534',
    crude_jpy_corr: '0.9188201418',
    gold_btc_corr: '-0.2030700413',
    gold_eth_corr: '-0.437106232',
    gold_jpy_corr: '-0.02948630826',
    btc_eth_corr: '0.7836488117',
    btc_jpy_corr: '-0.7785072723',
    eth_jpy_corr: '-0.3161301926',
  },
  {
    date: '2024-07-13',
    crude: '121.4285714',
    gold: '138.4570833',
    btc: '292.3588155',
    eth: '210.7199162',
    jpy: '115.8447017',
    gold_crude_corr: '0.2024466186',
    crude_btc_corr: '-0.8761917198',
    crude_eth_corr: '-0.5473788019',
    crude_jpy_corr: '0.9173472285',
    gold_btc_corr: '-0.2271087398',
    gold_eth_corr: '-0.4473732252',
    gold_jpy_corr: '-0.01667928823',
    btc_eth_corr: '0.785408194',
    btc_jpy_corr: '-0.8030179286',
    eth_jpy_corr: '-0.3621118175',
  },
  {
    date: '2024-07-14',
    crude: '121.4285714',
    gold: '138.4570833',
    btc: '300.0381756',
    eth: '215.1555928',
    jpy: '115.8447017',
    gold_crude_corr: '0.2261317093',
    crude_btc_corr: '-0.8889183646',
    crude_eth_corr: '-0.5792100924',
    crude_jpy_corr: '0.9160683062',
    gold_btc_corr: '-0.2417949917',
    gold_eth_corr: '-0.4539322035',
    gold_jpy_corr: '-0.005145343696',
    btc_eth_corr: '0.7888858075',
    btc_jpy_corr: '-0.8280866889',
    eth_jpy_corr: '-0.4108990984',
  },
  {
    date: '2024-07-15',
    crude: '121.4285714',
    gold: '138.92112',
    btc: '320.188005',
    eth: '231.4360297',
    jpy: '115.9267147',
    gold_crude_corr: '0.2431196492',
    crude_btc_corr: '-0.8969195129',
    crude_eth_corr: '-0.618953509',
    crude_jpy_corr: '0.9105120662',
    gold_btc_corr: '-0.2368018435',
    gold_eth_corr: '-0.4569894517',
    gold_jpy_corr: '-0.00578653327',
    btc_eth_corr: '0.7973151663',
    btc_jpy_corr: '-0.8440631585',
    eth_jpy_corr: '-0.4547258215',
  },
  {
    date: '2024-07-16',
    crude: '119.7056712',
    gold: '141.7053401',
    btc: '321.308419',
    eth: '228.3825856',
    jpy: '115.8007677',
    gold_crude_corr: '0.2539619331',
    crude_btc_corr: '-0.907814644',
    crude_eth_corr: '-0.6614264601',
    crude_jpy_corr: '0.9084494405',
    gold_btc_corr: '-0.2162757086',
    gold_eth_corr: '-0.4378246778',
    gold_jpy_corr: '-0.0009835647135',
    btc_eth_corr: '0.803332596',
    btc_jpy_corr: '-0.8638943695',
    eth_jpy_corr: '-0.5035832093',
  },
  {
    date: '2024-07-17',
    crude: '117.982771',
    gold: '141.0092795',
    btc: '316.4794233',
    eth: '224.7506974',
    jpy: '115.9538103',
    gold_crude_corr: '0.2622138629',
    crude_btc_corr: '-0.9087462602',
    crude_eth_corr: '-0.6973131233',
    crude_jpy_corr: '0.9075988438',
    gold_btc_corr: '-0.2177927905',
    gold_eth_corr: '-0.417337341',
    gold_jpy_corr: '0.01482601863',
    btc_eth_corr: '0.8288201874',
    btc_jpy_corr: '-0.8647542085',
    eth_jpy_corr: '-0.5364098693',
  },
  {
    date: '2024-07-18',
    crude: '119.0595836',
    gold: '140.1392121',
    btc: '315.7650777',
    eth: '227.2381978',
    jpy: '114.0030613',
    gold_crude_corr: '0.2782402983',
    crude_btc_corr: '-0.9141610473',
    crude_eth_corr: '-0.7577012303',
    crude_jpy_corr: '0.8911321048',
    gold_btc_corr: '-0.2182135599',
    gold_eth_corr: '-0.4052405373',
    gold_jpy_corr: '-0.006857345572',
    btc_eth_corr: '0.858911203',
    btc_jpy_corr: '-0.8729928925',
    eth_jpy_corr: '-0.6216289869',
  },
  {
    date: '2024-07-19',
    crude: '118.6288586',
    gold: '137.6450108',
    btc: '329.2699503',
    eth: '232.5092697',
    jpy: '115.0750861',
    gold_crude_corr: '0.3001965843',
    crude_btc_corr: '-0.9094908473',
    crude_eth_corr: '-0.7869757957',
    crude_jpy_corr: '0.8893850057',
    gold_btc_corr: '-0.2252751314',
    gold_eth_corr: '-0.379460212',
    gold_jpy_corr: '0.01768832366',
    btc_eth_corr: '0.8881405203',
    btc_jpy_corr: '-0.8772510601',
    eth_jpy_corr: '-0.6759682496',
  },
  {
    date: '2024-07-20',
    crude: '118.6288586',
    gold: '137.6450108',
    btc: '331.5083104',
    eth: '233.4086398',
    jpy: '115.0750861',
    gold_crude_corr: '0.3229416282',
    crude_btc_corr: '-0.903786348',
    crude_eth_corr: '-0.8154630649',
    crude_jpy_corr: '0.8876568525',
    gold_btc_corr: '-0.2299273313',
    gold_eth_corr: '-0.3529837274',
    gold_jpy_corr: '0.04358780054',
    btc_eth_corr: '0.9149066203',
    btc_jpy_corr: '-0.8824932759',
    eth_jpy_corr: '-0.7310704637',
  },
  {
    date: '2024-07-21',
    crude: '118.6288586',
    gold: '137.6450108',
    btc: '336.3991004',
    eth: '234.556687',
    jpy: '115.0750861',
    gold_crude_corr: '0.3465230352',
    crude_btc_corr: '-0.8957802596',
    crude_eth_corr: '-0.8528617263',
    crude_jpy_corr: '0.8859520291',
    gold_btc_corr: '-0.2263640213',
    gold_eth_corr: '-0.3205396131',
    gold_jpy_corr: '0.07096150701',
    btc_eth_corr: '0.9436100279',
    btc_jpy_corr: '-0.8908755812',
    eth_jpy_corr: '-0.8007217774',
  },
  {
    date: '2024-07-22',
    crude: '116.2957645',
    gold: '137.6450108',
    btc: '333.5892638',
    eth: '228.1774164',
    jpy: '115.2742639',
    gold_crude_corr: '0.3611410187',
    crude_btc_corr: '-0.9024694483',
    crude_eth_corr: '-0.8511947005',
    crude_jpy_corr: '0.8901964165',
    gold_btc_corr: '-0.2666454507',
    gold_eth_corr: '-0.3496367497',
    gold_jpy_corr: '0.1029265127',
    btc_eth_corr: '0.9424636063',
    btc_jpy_corr: '-0.8878635598',
    eth_jpy_corr: '-0.797488474',
  },
  {
    date: '2024-07-23',
    crude: '114.7882268',
    gold: '138.2250594',
    btc: '325.407741',
    eth: '230.9348379',
    jpy: '114.8854392',
    gold_crude_corr: '0.370811161',
    crude_btc_corr: '-0.9044686558',
    crude_eth_corr: '-0.8497597506',
    crude_jpy_corr: '0.8905478332',
    gold_btc_corr: '-0.30189348',
    gold_eth_corr: '-0.3914520519',
    gold_jpy_corr: '0.1164764011',
    btc_eth_corr: '0.9402153219',
    btc_jpy_corr: '-0.8852113286',
    eth_jpy_corr: '-0.7900013384',
  },
  {
    date: '2024-07-24',
    crude: '111.9167265',
    gold: '137.6450108',
    btc: '322.6656949',
    eth: '221.274528',
    jpy: '114.0631074',
    gold_crude_corr: '0.3579663347',
    crude_btc_corr: '-0.8978790065',
    crude_eth_corr: '-0.8267605663',
    crude_jpy_corr: '0.8913146637',
    gold_btc_corr: '-0.3113103914',
    gold_eth_corr: '-0.4130874117',
    gold_jpy_corr: '0.103819587',
    btc_eth_corr: '0.9342816069',
    btc_jpy_corr: '-0.8743607185',
    eth_jpy_corr: '-0.759963671',
  },
  {
    date: '2024-07-25',
    crude: '110.3374013',
    gold: '135.5568512',
    btc: '324.6651686',
    eth: '210.5361043',
    jpy: '112.688651',
    gold_crude_corr: '0.3434392164',
    crude_btc_corr: '-0.8891390367',
    crude_eth_corr: '-0.7729818827',
    crude_jpy_corr: '0.8903283866',
    gold_btc_corr: '-0.3059259327',
    gold_eth_corr: '-0.4078025661',
    gold_jpy_corr: '0.08695490185',
    btc_eth_corr: '0.9164740854',
    btc_jpy_corr: '-0.8476890709',
    eth_jpy_corr: '-0.6733787536',
  },
  {
    date: '2024-07-26',
    crude: '112.7781766',
    gold: '136.9489613',
    btc: '335.2023545',
    eth: '217.269446',
    jpy: '112.5465919',
    gold_crude_corr: '0.3248224011',
    crude_btc_corr: '-0.8886556293',
    crude_eth_corr: '-0.748534178',
    crude_jpy_corr: '0.8813219347',
    gold_btc_corr: '-0.2890469396',
    gold_eth_corr: '-0.4105579771',
    gold_jpy_corr: '0.05219660246',
    btc_eth_corr: '0.8953797317',
    btc_jpy_corr: '-0.837538179',
    eth_jpy_corr: '-0.6126257301',
  },
  {
    date: '2024-07-27',
    crude: '112.7781766',
    gold: '136.9489613',
    btc: '334.7150571',
    eth: '215.3896324',
    jpy: '112.5465919',
    gold_crude_corr: '0.3062415727',
    crude_btc_corr: '-0.8876826529',
    crude_eth_corr: '-0.7211055382',
    crude_jpy_corr: '0.8748627095',
    gold_btc_corr: '-0.2720600085',
    gold_eth_corr: '-0.4134512014',
    gold_jpy_corr: '0.02109458717',
    btc_eth_corr: '0.8717114893',
    btc_jpy_corr: '-0.8302797303',
    eth_jpy_corr: '-0.5543261334',
  },
  {
    date: '2024-07-28',
    crude: '112.7781766',
    gold: '136.9489613',
    btc: '336.8993158',
    eth: '216.9718689',
    jpy: '112.5465919',
    gold_crude_corr: '0.2876717042',
    crude_btc_corr: '-0.8873741747',
    crude_eth_corr: '-0.6956297778',
    crude_jpy_corr: '0.8705173971',
    gold_btc_corr: '-0.2539290269',
    gold_eth_corr: '-0.4155580733',
    gold_jpy_corr: '-0.006917243873',
    btc_eth_corr: '0.8501041845',
    btc_jpy_corr: '-0.8265002201',
    eth_jpy_corr: '-0.5074018521',
  },
  {
    date: '2024-07-29',
    crude: '111.1988514',
    gold: '136.7749434',
    btc: '329.8116962',
    eth: '220.2266285',
    jpy: '112.870253',
    gold_crude_corr: '0.2669388105',
    crude_btc_corr: '-0.8849986245',
    crude_eth_corr: '-0.6747493516',
    crude_jpy_corr: '0.8723495755',
    gold_btc_corr: '-0.2407500464',
    gold_eth_corr: '-0.4164205045',
    gold_jpy_corr: '-0.02863540547',
    btc_eth_corr: '0.8390649125',
    btc_jpy_corr: '-0.8207256902',
    eth_jpy_corr: '-0.4780225006',
  },
  {
    date: '2024-07-30',
    crude: '109.7272075',
    gold: '138.1670534',
    btc: '326.7569191',
    eth: '217.4495985',
    jpy: '112.6461696',
    gold_crude_corr: '0.2328698249',
    crude_btc_corr: '-0.877986758',
    crude_eth_corr: '-0.6454100389',
    crude_jpy_corr: '0.876300068',
    gold_btc_corr: '-0.2320522705',
    gold_eth_corr: '-0.4346784331',
    gold_jpy_corr: '-0.06176396992',
    btc_eth_corr: '0.8301493723',
    btc_jpy_corr: '-0.8096003653',
    eth_jpy_corr: '-0.4414273049',
  },
  {
    date: '2024-07-31',
    crude: '109.5836324',
    gold: '140.6032488',
    btc: '318.9495938',
    eth: '214.307762',
    jpy: '111.7945521',
    gold_crude_corr: '0.1723362801',
    crude_btc_corr: '-0.867003874',
    crude_eth_corr: '-0.6187825843',
    crude_jpy_corr: '0.8784185018',
    gold_btc_corr: '-0.2179734684',
    gold_eth_corr: '-0.4426409102',
    gold_jpy_corr: '-0.1333089419',
    btc_eth_corr: '0.8246047212',
    btc_jpy_corr: '-0.7794825648',
    eth_jpy_corr: '-0.3910670665',
  },
  {
    date: '2024-08-01',
    crude: '110.5886576',
    gold: '140.1972181',
    btc: '322.5934699',
    eth: '212.335913',
    jpy: '109.6519551',
    gold_crude_corr: '0.1279173587',
    crude_btc_corr: '-0.8628508554',
    crude_eth_corr: '-0.5902798732',
    crude_jpy_corr: '0.8584944845',
    gold_btc_corr: '-0.2005642631',
    gold_eth_corr: '-0.4545471288',
    gold_jpy_corr: '-0.211718855',
    btc_eth_corr: '0.8115949895',
    btc_jpy_corr: '-0.7315373547',
    eth_jpy_corr: '-0.3111509287',
  },
  {
    date: '2024-08-02',
    crude: '107.896626',
    gold: '139.9071993',
    btc: '303.1342902',
    eth: '198.0400035',
    jpy: '109.2543369',
    gold_crude_corr: '0.07055052504',
    crude_btc_corr: '-0.8188469722',
    crude_eth_corr: '-0.505619046',
    crude_jpy_corr: '0.8568874966',
    gold_btc_corr: '-0.2072929459',
    gold_eth_corr: '-0.4742954363',
    gold_jpy_corr: '-0.2757010192',
    btc_eth_corr: '0.8070529047',
    btc_jpy_corr: '-0.6375974565',
    eth_jpy_corr: '-0.1864578339',
  },
  {
    date: '2024-08-03',
    crude: '107.896626',
    gold: '139.9071993',
    btc: '299.5065906',
    eth: '192.5599411',
    jpy: '109.2543369',
    gold_crude_corr: '0.01840787308',
    crude_btc_corr: '-0.7715722256',
    crude_eth_corr: '-0.41468521',
    crude_jpy_corr: '0.8583208519',
    gold_btc_corr: '-0.2160919741',
    gold_eth_corr: '-0.4949266349',
    gold_jpy_corr: '-0.327677051',
    btc_eth_corr: '0.803290988',
    btc_jpy_corr: '-0.553905222',
    eth_jpy_corr: '-0.06933641226',
  },
  {
    date: '2024-08-04',
    crude: '107.896626',
    gold: '139.9071993',
    btc: '286.8554814',
    eth: '178.1688225',
    jpy: '109.2543369',
    gold_crude_corr: '-0.02926989265',
    crude_btc_corr: '-0.7034732462',
    crude_eth_corr: '-0.2974250182',
    crude_jpy_corr: '0.861468665',
    gold_btc_corr: '-0.2356589199',
    gold_eth_corr: '-0.5159113114',
    gold_jpy_corr: '-0.3709463978',
    btc_eth_corr: '0.8020247973',
    btc_jpy_corr: '-0.453915845',
    eth_jpy_corr: '0.06285293343',
  },
  {
    date: '2024-08-05',
    crude: '97.91816224',
    gold: '138.1670534',
    btc: '266.4926208',
    eth: '160.3152815',
    jpy: '106.6057394',
    gold_crude_corr: '-0.07369182798',
    crude_btc_corr: '-0.4993066283',
    crude_eth_corr: '-0.05601851343',
    crude_jpy_corr: '0.8865725385',
    gold_btc_corr: '-0.2515399322',
    gold_eth_corr: '-0.5076821927',
    gold_jpy_corr: '-0.3806588248',
    btc_eth_corr: '0.812850077',
    btc_jpy_corr: '-0.2704713221',
    eth_jpy_corr: '0.2457037756',
  },
  {
    date: '2024-08-06',
    crude: '101.758794',
    gold: '137.0649621',
    btc: '276.5758262',
    eth: '163.068833',
    jpy: '106.7565809',
    gold_crude_corr: '-0.1093769416',
    crude_btc_corr: '-0.3756235678',
    crude_eth_corr: '0.1098009099',
    crude_jpy_corr: '0.9061263785',
    gold_btc_corr: '-0.2419402302',
    gold_eth_corr: '-0.4789844873',
    gold_jpy_corr: '-0.3785051823',
    btc_eth_corr: '0.808534172',
    btc_jpy_corr: '-0.1554759125',
    eth_jpy_corr: '0.3732847239',
  },
  {
    date: '2024-08-07',
    crude: '102.2254128',
    gold: '136.8909553',
    btc: '271.6061594',
    eth: '154.9685603',
    jpy: '105.9818478',
    gold_crude_corr: '-0.1270998837',
    crude_btc_corr: '-0.2502496158',
    crude_eth_corr: '0.2651097473',
    crude_jpy_corr: '0.918930594',
    gold_btc_corr: '-0.2436288859',
    gold_eth_corr: '-0.4595319917',
    gold_jpy_corr: '-0.3698240197',
    btc_eth_corr: '0.8057215665',
    btc_jpy_corr: '-0.03580670502',
    eth_jpy_corr: '0.4959742829',
  },
  {
    date: '2024-08-08',
    crude: '103.2304379',
    gold: '139.0371208',
    btc: '304.590707',
    eth: '177.9667945',
    jpy: '107.0011563',
    gold_crude_corr: '-0.1583977925',
    crude_btc_corr: '-0.2025589147',
    crude_eth_corr: '0.3581655082',
    crude_jpy_corr: '0.9316783395',
    gold_btc_corr: '-0.2555763887',
    gold_eth_corr: '-0.4887410201',
    gold_jpy_corr: '-0.3921353423',
    btc_eth_corr: '0.7799748697',
    btc_jpy_corr: '-0.01450147319',
    eth_jpy_corr: '0.5528661643',
  },
  {
    date: '2024-08-09',
    crude: '105.2404882',
    gold: '139.3851566',
    btc: '300.4938529',
    eth: '172.4120058',
    jpy: '108.1325121',
    gold_crude_corr: '-0.2200939833',
    crude_btc_corr: '-0.163182152',
    crude_eth_corr: '0.424453875',
    crude_jpy_corr: '0.9391306684',
    gold_btc_corr: '-0.2309923054',
    gold_eth_corr: '-0.4893868285',
    gold_jpy_corr: '-0.4297044317',
    btc_eth_corr: '0.7596963258',
    btc_jpy_corr: '0.008464746614',
    eth_jpy_corr: '0.5965433585',
  },
  {
    date: '2024-08-10',
    crude: '105.2404882',
    gold: '139.3851566',
    btc: '300.818133',
    eth: '173.1033731',
    jpy: '108.1325121',
    gold_crude_corr: '-0.2814243783',
    crude_btc_corr: '-0.1251759428',
    crude_eth_corr: '0.485983494',
    crude_jpy_corr: '0.9464993952',
    gold_btc_corr: '-0.2023937092',
    gold_eth_corr: '-0.4878217587',
    gold_jpy_corr: '-0.4653686359',
    btc_eth_corr: '0.7405496813',
    btc_jpy_corr: '0.02836513818',
    eth_jpy_corr: '0.6345742548',
  },
  {
    date: '2024-08-11',
    crude: '105.2404882',
    gold: '139.3851566',
    btc: '289.8293572',
    eth: '169.3381921',
    jpy: '108.1325121',
    gold_crude_corr: '-0.3430282038',
    crude_btc_corr: '-0.07157889625',
    crude_eth_corr: '0.5476072536',
    crude_jpy_corr: '0.9537538422',
    gold_btc_corr: '-0.1761277705',
    gold_eth_corr: '-0.4831379247',
    gold_jpy_corr: '-0.4998053949',
    btc_eth_corr: '0.7321894757',
    btc_jpy_corr: '0.06262771399',
    eth_jpy_corr: '0.671205051',
  },
  {
    date: '2024-08-12',
    crude: '105.2404882',
    gold: '141.763346',
    btc: '292.9637631',
    eth: '180.6912652',
    jpy: '107.6733842',
    gold_crude_corr: '-0.402463622',
    crude_btc_corr: '-0.02814625827',
    crude_eth_corr: '0.5932480945',
    crude_jpy_corr: '0.9615512593',
    gold_btc_corr: '-0.1619619886',
    gold_eth_corr: '-0.4916231021',
    gold_jpy_corr: '-0.536187835',
    btc_eth_corr: '0.7254410739',
    btc_jpy_corr: '0.08419392184',
    eth_jpy_corr: '0.6931827401',
  },
  {
    date: '2024-08-13',
    crude: '109.1170136',
    gold: '141.5313222',
    btc: '299.1584796',
    eth: '179.3144085',
    jpy: '107.629439',
    gold_crude_corr: '-0.4331802329',
    crude_btc_corr: '-0.01227435293',
    crude_eth_corr: '0.6117111908',
    crude_jpy_corr: '0.9599037353',
    gold_btc_corr: '-0.1487546027',
    gold_eth_corr: '-0.5060557903',
    gold_jpy_corr: '-0.5651277749',
    btc_eth_corr: '0.7181747623',
    btc_jpy_corr: '0.09338953413',
    eth_jpy_corr: '0.70838254',
  },
  {
    date: '2024-08-14',
    crude: '108.2196698',
    gold: '140.4292309',
    btc: '289.9171417',
    eth: '176.6112951',
    jpy: '107.621383',
    gold_crude_corr: '-0.4566997676',
    crude_btc_corr: '0.009485506444',
    crude_eth_corr: '0.6307898014',
    crude_jpy_corr: '0.9592135441',
    gold_btc_corr: '-0.1396306873',
    gold_eth_corr: '-0.5127294492',
    gold_jpy_corr: '-0.5841631912',
    btc_eth_corr: '0.716200807',
    btc_jpy_corr: '0.1133187669',
    eth_jpy_corr: '0.7243846939',
  },
  {
    date: '2024-08-15',
    crude: '106.5685571',
    gold: '140.8352727',
    btc: '284.1068221',
    eth: '170.454762',
    jpy: '107.8278905',
    gold_crude_corr: '-0.491656368',
    crude_btc_corr: '0.03977766391',
    crude_eth_corr: '0.6506302234',
    crude_jpy_corr: '0.9613494665',
    gold_btc_corr: '-0.1368154339',
    gold_eth_corr: '-0.5254472613',
    gold_jpy_corr: '-0.6087882532',
    btc_eth_corr: '0.7204277299',
    btc_jpy_corr: '0.1391146031',
    eth_jpy_corr: '0.7388798493',
  },
  {
    date: '2024-08-16',
    crude: '108.6145011',
    gold: '143.9095116',
    btc: '290.6912571',
    eth: '171.9867384',
    jpy: '109.2697116',
    gold_crude_corr: '-0.5093135515',
    crude_btc_corr: '0.05452947527',
    crude_eth_corr: '0.6629549099',
    crude_jpy_corr: '0.9628304318',
    gold_btc_corr: '-0.1469210338',
    gold_eth_corr: '-0.5470108981',
    gold_jpy_corr: '-0.6209835516',
    btc_eth_corr: '0.7186395098',
    btc_jpy_corr: '0.1496687731',
    eth_jpy_corr: '0.747222825',
  },
  {
    date: '2024-08-17',
    crude: '108.6145011',
    gold: '143.9095116',
    btc: '293.5780618',
    eth: '173.4034114',
    jpy: '109.2697116',
    gold_crude_corr: '-0.5260027414',
    crude_btc_corr: '0.06620897081',
    crude_eth_corr: '0.6780126602',
    crude_jpy_corr: '0.9643289785',
    gold_btc_corr: '-0.1476661902',
    gold_eth_corr: '-0.561175885',
    gold_jpy_corr: '-0.6321426097',
    btc_eth_corr: '0.7128011683',
    btc_jpy_corr: '0.1561741687',
    eth_jpy_corr: '0.7567896824',
  },
  {
    date: '2024-08-18',
    crude: '108.6145011',
    gold: '143.9095116',
    btc: '288.666873',
    eth: '173.3245076',
    jpy: '109.2697116',
    gold_crude_corr: '-0.5418693066',
    crude_btc_corr: '0.08118618506',
    crude_eth_corr: '0.6952187911',
    crude_jpy_corr: '0.9658441349',
    gold_btc_corr: '-0.1530068303',
    gold_eth_corr: '-0.5716029034',
    gold_jpy_corr: '-0.6423737304',
    btc_eth_corr: '0.7090264242',
    btc_jpy_corr: '0.1655717808',
    eth_jpy_corr: '0.7674902094',
  },
  {
    date: '2024-08-19',
    crude: '104.6661881',
    gold: '143.7935107',
    btc: '293.6495349',
    eth: '174.9128808',
    jpy: '108.3426622',
    gold_crude_corr: '-0.575524825',
    crude_btc_corr: '0.09842165012',
    crude_eth_corr: '0.7149477676',
    crude_jpy_corr: '0.9696433866',
    gold_btc_corr: '-0.1428258028',
    gold_eth_corr: '-0.5792307754',
    gold_jpy_corr: '-0.6571893699',
    btc_eth_corr: '0.7031147618',
    btc_jpy_corr: '0.1694355837',
    eth_jpy_corr: '0.7753885282',
  },
  {
    date: '2024-08-20',
    crude: '102.9432879',
    gold: '144.3155423',
    btc: '291.2770784',
    eth: '170.6550088',
    jpy: '107.4222164',
    gold_crude_corr: '-0.6007295895',
    crude_btc_corr: '0.111634216',
    crude_eth_corr: '0.7287122103',
    crude_jpy_corr: '0.9711038427',
    gold_btc_corr: '-0.1454401589',
    gold_eth_corr: '-0.5908908471',
    gold_jpy_corr: '-0.6727528106',
    btc_eth_corr: '0.6999162834',
    btc_jpy_corr: '0.177212621',
    eth_jpy_corr: '0.783860247',
  },
  {
    date: '2024-08-21',
    crude: '101.7946877',
    gold: '144.1415354',
    btc: '301.9503082',
    eth: '174.5208556',
    jpy: '106.4321935',
    gold_crude_corr: '-0.6216239753',
    crude_btc_corr: '0.1060863644',
    crude_eth_corr: '0.7388697136',
    crude_jpy_corr: '0.9723565302',
    gold_btc_corr: '-0.1283409485',
    gold_eth_corr: '-0.5950718781',
    gold_jpy_corr: '-0.6893681935',
    btc_eth_corr: '0.6874702987',
    btc_jpy_corr: '0.1698470775',
    eth_jpy_corr: '0.7918392261',
  },
  {
    date: '2024-08-22',
    crude: '100.0717875',
    gold: '142.4013896',
    btc: '298.0348265',
    eth: '173.9608219',
    jpy: '106.2645024',
    gold_crude_corr: '-0.6342531301',
    crude_btc_corr: '0.1060285559',
    crude_eth_corr: '0.7474543877',
    crude_jpy_corr: '0.9733389372',
    gold_btc_corr: '-0.1232724968',
    gold_eth_corr: '-0.6015203027',
    gold_jpy_corr: '-0.6984765893',
    btc_eth_corr: '0.6790942188',
    btc_jpy_corr: '0.1674172592',
    eth_jpy_corr: '0.7983007219',
  },
  {
    date: '2024-08-23',
    crude: '101.758794',
    gold: '144.0835295',
    btc: '316.3588039',
    eth: '183.3451711',
    jpy: '107.058275',
    gold_crude_corr: '-0.6485878104',
    crude_btc_corr: '0.07868329758',
    crude_eth_corr: '0.7485760981',
    crude_jpy_corr: '0.9739582991',
    gold_btc_corr: '-0.08389671905',
    gold_eth_corr: '-0.5955111812',
    gold_jpy_corr: '-0.7075296401',
    btc_eth_corr: '0.6591842761',
    btc_jpy_corr: '0.1405011142',
    eth_jpy_corr: '0.7986827057',
  },
  {
    date: '2024-08-24',
    crude: '101.758794',
    gold: '144.0835295',
    btc: '316.7765564',
    eth: '183.6729787',
    jpy: '107.058275',
    gold_crude_corr: '-0.6611639796',
    crude_btc_corr: '0.05127835464',
    crude_eth_corr: '0.7490171298',
    crude_jpy_corr: '0.9745056224',
    gold_btc_corr: '-0.04374030209',
    gold_eth_corr: '-0.5889118386',
    gold_jpy_corr: '-0.7154106226',
    btc_eth_corr: '0.6389563446',
    btc_jpy_corr: '0.1133227782',
    eth_jpy_corr: '0.7984781921',
  },
  {
    date: '2024-08-25',
    crude: '101.758794',
    gold: '144.0835295',
    btc: '317.5393926',
    eth: '182.3311453',
    jpy: '107.058275',
    gold_crude_corr: '-0.6722654248',
    crude_btc_corr: '0.02702061401',
    crude_eth_corr: '0.7494485671',
    crude_jpy_corr: '0.9749877674',
    gold_btc_corr: '-0.01038066086',
    gold_eth_corr: '-0.5854777306',
    gold_jpy_corr: '-0.7222814395',
    btc_eth_corr: '0.6204255068',
    btc_jpy_corr: '0.08948522535',
    eth_jpy_corr: '0.7982745025',
  },
];
