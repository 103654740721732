'use client';

import { headerSocialLinks } from '@/constants/landing';
import { ArrowRight } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import PriceMarquee from './PriceMarquee';
import Metrics from './Metrics';
import { motion } from 'framer-motion';
const Hero = () => {
  const visitTradePage = () => {
    window.location.href = `${window.origin}/trade`;
  };
  return <div className="w-full h-[1209px] md:h-[850px] relative flex flex-col" data-sentry-component="Hero" data-sentry-source-file="Hero.tsx">
      <Image className="z-2 hidden md:block" src="/images/landing/hero/bg-new.svg" alt="hero-bg" fill objectFit="scale-down" data-sentry-element="Image" data-sentry-source-file="Hero.tsx" data-sentry-element="Image" />
      <Image className="z-2 block md:hidden bg-top" src="/images/landing/hero/bg-mobile.svg" alt="hero-bg" fill data-sentry-element="Image" data-sentry-source-file="Hero.tsx" data-sentry-element="Image" />
      <div className="flex w-full gap-4 items-center [&>*]:z-10 md:p-4">
        <div className="md:px-[60px] p-4 md:py-3 bg-[#0E0E1380] md:rounded-md w-full md:w-fit border-t border-b md:border-none border-grey-600">
          <Image className="py-1" src="/images/avantis-logo.svg" alt="brand" width={98} height={18} data-sentry-element="Image" data-sentry-source-file="Hero.tsx" data-sentry-element="Image" />
        </div>
        <div className="hidden md:flex items-center justify-center gap-2 px-[60px] py-3 bg-[#0E0E1380] rounded-md flex-1">
          {headerSocialLinks.map(link => <Link className="bg-grey-500 px-2 py-1 rounded-md flex items-center gap-2" key={link.name} href={link.link} target="_blank">
              <Image src={link.icon} alt={link.name} width={14} height={14} />
              <span className="text-grey-200 text-xs font-medium">{link.name}</span>
            </Link>)}
        </div>

        <motion.div whileHover="hover" className="group relative group bg-[#0E0E1380] rounded-md overflow-hidden hidden md:flex" data-sentry-element="unknown" data-sentry-source-file="Hero.tsx" data-sentry-element="unknown">
          <div role="button" onClick={visitTradePage} className="flex h-full w-full px-[60px] py-3 z-20 relative">
            <span className="text-white group-hover:text-grey-1200 font-medium">Launch App</span>
          </div>
          <motion.div className="absolute inset-0 bg-primary-200" initial={{
          x: '-100%'
        }} variants={{
          hover: {
            x: 0
          }
        }} transition={{
          type: 'tween',
          ease: 'easeInOut',
          duration: 0.3
        }} data-sentry-element="unknown" data-sentry-source-file="Hero.tsx" data-sentry-element="unknown" />
        </motion.div>
      </div>
      <div className="py-[64px] px-[20px] md:py-[72px] md:px-[60px] md:flex-1 z-10 relative flex flex-col justify-between gap-4 md:gap-10">
        <div className="w-fit text-center md:text-left flex flex-col gap-4">
          <p className="text-white text-[52px] leading-[120%]">Cross-Asset Leverage.</p>
          <p className="text-[#D68CFF] text-[52px] leading-[120%]">Trusted. Decentralized.</p>
          <div className="flex items-center self-center md:self-start gap-3 rounded-md mt-6 px-4 py-2 bg-gradient-to-l from-[#171717] to-[#292929] w-fit">
            <span className="font-fono font-medium">Built on and backed by</span>
            <Image src="/images/landing/hero/base.svg" alt="base" width={60} height={16} data-sentry-element="Image" data-sentry-source-file="Hero.tsx" data-sentry-element="Image" />
          </div>
        </div>
        <div className="md:max-w-[466px] space-y-6 text-center md:text-right md:self-end ">
          <p className="text-grey-050 md:text-2xl">
            Trade crypto, forex, metals, commodities, indices, straight from your wallet.
          </p>

          <motion.div whileHover="hover" className="group relative left-[50%] -translate-x-[50%] md:left-0 md:translate-x-0 overflow-hidden text-white hover:text-grey-1200 border hover:border-transparent border-grey-050 rounded-md w-fit md:float-right" data-sentry-element="unknown" data-sentry-source-file="Hero.tsx" data-sentry-element="unknown">
            <div role="button" onClick={visitTradePage} className="flex items-center p-4 gap-2 font-medium z-20 relative">
              <span className="text-white group-hover:text-grey-1200 font-medium">Trade now</span>
              <ArrowRight className="size-5" data-sentry-element="ArrowRight" data-sentry-source-file="Hero.tsx" data-sentry-element="ArrowRight" />
            </div>
            <motion.div className="absolute inset-0 bg-primary-200" initial={{
            x: '-100%'
          }} variants={{
            hover: {
              x: 0
            }
          }} transition={{
            type: 'tween',
            ease: 'easeInOut',
            duration: 0.3
          }} data-sentry-element="unknown" data-sentry-source-file="Hero.tsx" data-sentry-element="unknown" />
          </motion.div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center z-10 p-4 px-0 w-full justify-end gap-4">
        <PriceMarquee data-sentry-element="PriceMarquee" data-sentry-source-file="Hero.tsx" data-sentry-element="PriceMarquee" />
        <Metrics data-sentry-element="Metrics" data-sentry-source-file="Hero.tsx" data-sentry-element="Metrics" />
      </div>
    </div>;
};
export default Hero;