import { fetchLatestPriceForAllPairs } from '@/services/trade/api';
import { useQuery } from '@tanstack/react-query';

const useLatestPrice = () => {
  const { data: latestPrice } = useQuery({
    queryKey: ['latestPrice'],
    queryFn: fetchLatestPriceForAllPairs,
  });

  return { latestPrice: latestPrice || {} };
};

export default useLatestPrice;
