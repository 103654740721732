import { filteredPairs } from '@/constants/pairs';
import pairsdata from '../../../pairData.json';
import { useMemo } from 'react';

export interface IPair {
  feeIndex: number;
  feed: {
    id: string;
    maxDeviationP: string;
  };
  from: string;
  groupIndex: number;
  isDelisted: boolean;
  maxWalletOI: number;
  to: string;
  _index: number;
}

export type IndexablePairs = {
  [x: number]: IPair;
};

const usePairs = () => {
  const pairsObj: IndexablePairs = useMemo(
    () =>
      filteredPairs.reduce((acc, pair) => {
        return {
          ...acc,
          [pair._index]: pair,
        };
      }, {}),
    []
  );

  const pairsObjAll: IndexablePairs = useMemo(
    () =>
      pairsdata.pairs.reduce((acc, pair) => {
        return {
          ...acc,
          [pair._index]: pair,
        };
      }, {}),
    []
  );

  return {
    pairs: filteredPairs || [],
    pairsObj: pairsObj || {},
    pairsCount: filteredPairs.length || 0,
    maxTradesPerPair: pairsdata?.maxTradesPerPair || 0,
    pairsObjAll,
  };
};

export default usePairs;
