import { Separator } from '@radix-ui/react-dropdown-menu';
import { roundDownTrim } from '@/lib/numbers';
import { cn } from '@/lib/utils';
const InfoPanel = ({
  key1,
  key2,
  key1Returns,
  key2Returns,
  description
}: {
  key1: string;
  key2: string;
  key1Returns: {
    '6m': number;
    '1y': number;
  };
  key2Returns: {
    '6m': number;
    '1y': number;
  };
  description: string;
}) => {
  return <>
      <Separator className="mb-4 md:my-8 bg-gradient-to-r from-[#24272F] to-[rgba(36,39,47,0.50)] h-[1px]" data-sentry-element="Separator" data-sentry-source-file="InfoPanel.tsx" data-sentry-element="Separator" />
      <p className="text-xl text-grey-100">{description}</p>
      <Separator className="my-4 md:my-8 bg-gradient-to-r from-[#24272F] to-[rgba(36,39,47,0.50)] h-[1px]" data-sentry-element="Separator" data-sentry-source-file="InfoPanel.tsx" data-sentry-element="Separator" />
      <div className="space-y-6">
        <div className="grid grid-cols-3 text-grey-100">
          <p>Asset</p>
          <p className="text-right">6-month return</p>
          <p className="text-right">1-year return</p>
        </div>
        <div className="grid grid-cols-3 text-xl md:text-4xl font-medium">
          <p className="whitespace-nowrap">{key1}</p>
          <p className={cn('text-right text-error-100', key1Returns['6m'] > 0 && 'text-primary-100')}>
            {roundDownTrim(key1Returns['6m'], 2)}%
          </p>
          <p className={cn('text-right text-error-100', key1Returns['1y'] > 0 && 'text-primary-100')}>
            {roundDownTrim(key1Returns['1y'], 2)}%
          </p>
        </div>
        <div className="grid grid-cols-3 text-xl md:text-4xl font-medium">
          <p className="whitespace-nowrap">{key2}</p>
          <p className={cn('text-right text-error-100', key2Returns['6m'] > 0 && 'text-primary-100')}>
            {roundDownTrim(key2Returns['6m'], 2)}%
          </p>
          <p className={cn('text-right text-error-100', key2Returns['1y'] > 0 && 'text-primary-100')}>
            {roundDownTrim(key2Returns['1y'], 2)}%
          </p>
        </div>
      </div>
    </>;
};
export default InfoPanel;