import { useQuery } from '@tanstack/react-query';
import usePairs from '../pairs/usePairs';
import { usePairStore } from '@/store/pairs';
import { useMemo } from 'react';
import useLatestPrice from './useLatestPrice';
import { fetchPairHistory } from '@/services/trade/api';
export interface IndexablePriceDelta {
  [priceId: string]: number;
}

const useLastDayPythPrice = () => {
  const { pairs } = usePairs();
  const { latestPrice } = useLatestPrice();
  const prices = usePairStore((state) => state.pairCurrentPricePure);

  const response = useQuery({
    queryKey: ['pairHistory'],
    queryFn: fetchPairHistory,
  });

  const pricesDelta: IndexablePriceDelta = useMemo(
    () =>
      response && response.data
        ? pairs.reduce((acc, pair) => {
            const currentPairLastDayPrice = response.data[pair._index] || null;
            const currentPairPrice =
              prices[pair._index]?.price || latestPrice?.[pair._index]?.price || null;
            const priceDelta =
              currentPairLastDayPrice && currentPairPrice
                ? ((currentPairPrice - currentPairLastDayPrice) / currentPairLastDayPrice) * 100
                : null;

            return {
              ...acc,
              [pair._index]: priceDelta,
            };
          }, {})
        : {},
    [pairs, prices, response]
  );

  return { lastDayPrice: response, priceDelta: pricesDelta };
};

export default useLastDayPythPrice;
