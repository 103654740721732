import React from 'react';
import AnimatedCard from './AnimatedCard';
import Image from 'next/image';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'usehooks-ts';
const PositiveSlippage = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return <AnimatedCard data-sentry-element="AnimatedCard" data-sentry-component="PositiveSlippage" data-sentry-source-file="PositiveSlippage.tsx">
      <motion.div className="relative h-full" data-sentry-element="unknown" data-sentry-source-file="PositiveSlippage.tsx" data-sentry-element="unknown">
        <div className="px-4 md:px-[60px] py-6 md:py-12 space-y-4">
          <p className="text-[#DDABF8] text-sm font-medium leading-none">TRADER</p>
          <p className="text-[32px] font-medium leading-none">Positive Slippage</p>
          <p className="text-grey-100 leading-[24px]">
            Traders are rewarded with positive slippage when they help balance open interest,
            helping them enter trades at prices better than market price.
          </p>
        </div>
        <Image className="absolute bottom-16 left-0 right-0" src="/images/landing/features/card-lines-bg.svg" alt="feature-1" width={652} height={282} data-sentry-element="Image" data-sentry-source-file="PositiveSlippage.tsx" data-sentry-element="Image" />
        <Image className="hidden md:block absolute top-40 left-[20%]" src="/images/landing/features/card-center-blur.svg" alt="feature-1" width={400} height={255} data-sentry-element="Image" data-sentry-source-file="PositiveSlippage.tsx" data-sentry-element="Image" />
        <Image className="absolute bottom-40 left-[50%] translate-x-[-50%] group-hover:opacity-0 transition-opacity duration-400" src="/images/landing/features/slippage/initial.svg" alt="feature-1" width={isMobile ? 300 : 528} height={isMobile ? 190 : 64} data-sentry-element="Image" data-sentry-source-file="PositiveSlippage.tsx" data-sentry-element="Image" />
        <Image className="absolute bottom-0 left-[50%] translate-x-[-50%] group-hover:opacity-100 group-hover:-translate-y-20 transition-all duration-500 opacity-0" src="/images/landing/features/slippage/final.svg" alt="feature-1" width={isMobile ? 300 : 528} height={isMobile ? 140 : 224} data-sentry-element="Image" data-sentry-source-file="PositiveSlippage.tsx" data-sentry-element="Image" />
      </motion.div>
    </AnimatedCard>;
};
export default PositiveSlippage;