export const socialLinks = [
  {
    name: 'Twitter/X',
    icon: '/images/landing/hero/socials/twitter.svg',
    link: 'https://x.com/avantisfi',
    text: 'Follow us on X',
  },
  {
    name: 'Discord',
    icon: '/images/landing/hero/socials/discord.svg',
    link: 'https://discord.gg/qJabbFzcUn',
    text: 'Join Discord',
  },
];

export const headerSocialLinks = [
  ...socialLinks,
  {
    name: 'Docs',
    icon: '/images/landing/hero/socials/docs.svg',
    link: 'https://docs.avantisfi.com/',
    text: 'View docs',
  },
  {
    name: 'SDK',
    icon: '/images/landing/hero/cmd.svg',
    link: 'https://sdk.avantisfi.com/',
    text: 'View SDK',
  },
];

export enum strategyTabsType {
  METALS_X_CRYPTO = 'metals-vs-crypto',
  OIL_X_CRYPTO = 'oil-vs-crypto',
  FOREX_X_CRYPTO = 'forex-vs-crypto',
}

export const strategyTabLabelMapping = {
  [strategyTabsType.METALS_X_CRYPTO]: 'Metals x Crypto',
  [strategyTabsType.OIL_X_CRYPTO]: 'Oil x Crypto',
  [strategyTabsType.FOREX_X_CRYPTO]: 'Forex x Crypto',
};

export const strategyTabs = [
  {
    key: strategyTabsType.METALS_X_CRYPTO,
    label: strategyTabLabelMapping[strategyTabsType.METALS_X_CRYPTO],
  },
  {
    key: strategyTabsType.OIL_X_CRYPTO,
    label: strategyTabLabelMapping[strategyTabsType.OIL_X_CRYPTO],
  },
  {
    key: strategyTabsType.FOREX_X_CRYPTO,
    label: strategyTabLabelMapping[strategyTabsType.FOREX_X_CRYPTO],
  },
];

export const investors = [
  {
    key: 'pantera',
    logo: '/images/landing/investors/pantera.svg',
    width: 168,
    height: 42,
  },
  {
    key: 'founders-fund',
    logo: '/images/landing/investors/founders-fund.svg',
    width: 194,
    height: 40,
  },
  {
    key: 'base',
    logo: '/images/landing/investors/base.svg',
    width: 94,
    height: 48,
  },
  {
    key: 'galaxy',
    logo: '/images/landing/investors/galaxy.svg',
    width: 135,
    height: 40,
  },
  {
    key: 'modular-capital',
    logo: '/images/landing/investors/modular-capital.svg',
    width: 140,
    height: 40,
  },
];

export const partners = [
  {
    key: 'zellic',
    logo: '/images/landing/partners/zellic.svg',
    width: 104,
    height: 28,
  },
  {
    key: 'zokyo',
    logo: '/images/landing/partners/zokyo.svg',
    width: 104,
    height: 26,
  },
  {
    key: 'alchemy',
    logo: '/images/landing/partners/alchemy.svg',
    width: 104,
    height: 22,
  },
  {
    key: 'copin',
    logo: '/images/landing/partners/copin.svg',
    width: 156,
    height: 35,
  },
  {
    key: 'superform',
    logo: '/images/landing/partners/superform.svg',
    width: 120,
    height: 33,
  },
  {
    key: 'sherlock',
    logo: '/images/landing/partners/sherlock.svg',
    width: 150,
    height: 44,
  },
  {
    key: 'pyth',
    logo: '/images/landing/partners/pyth.svg',
    width: 120,
    height: 40,
  },
  {
    key: 'base',
    logo: '/images/landing/investors/base.svg',
    width: 94,
    height: 48,
  },
];

export const testimonials = [
  {
    username: '@bapemg',
    name: 'Bape',
    profile: '/images/landing/testimonials/bape.svg',
    comment:
      'The team at Avantis gives me the utmost confidence that they will be the number one trading platform on Base. The platform is a frictionless, easy-to-use experience that will continue to exceed expectations. Very excited to see what the future holds for Avantis.',
  },
  {
    username: '@pipiwurstje',
    name: 'Pipiwurstje',
    profile: '/images/landing/testimonials/pipiwurstje.svg',
    comment:
      'Avantis is the most promising project on Base with a turbo-focused team and the potential to become a one-stop shop for on-chain margin trading, all while paying sustainable high single-asset yield to LPs.',
  },
  {
    username: '@birckmega',
    name: 'Birck',
    profile: '/images/landing/testimonials/birck.svg',
    comment:
      "Avantis has been a game-changer for my crypto trading. It's user-friendly, secure, and offers great tools for making informed decisions. The low fees and real-time data make it perfect for both beginners and experienced traders. Definitely worth trying!",
  },
  {
    username: '@theack',
    name: 'ACK',
    profile: '/images/landing/testimonials/ack.svg',
    comment:
      "In my country, there aren't many options to leverage my trades with RWAs such as commodities, forex and crypto. Avantis gives me this opportunity with low fees. My favorite product on Base!",
  },
  {
    username: '@binacrypt',
    name: 'binaCrypt',
    profile: '/images/landing/testimonials/binacrypt.svg',
    comment: 'Avantis makes Base feel like home for perp traders. Chad teams for Chad traders.',
  },
  {
    username: '@meekdonald_',
    name: 'Meekdonald',
    profile: '/images/landing/testimonials/meekdonald.svg',
    comment:
      "It's rare to see a very good product with a proactive team. Avantis defies the odds, providing one of the best perp DEXs in the market.",
  },
  {
    username: '@rednatic',
    name: 'rednatic',
    profile: '/images/landing/testimonials/rednatic.svg',
    comment:
      'Love at first sight. I use Avantis for their innovation such as isolated positions and loss protection rebates. Never looked back since.',
  },
  {
    username: '@rpkita11',
    name: 'Ro Kita',
    profile: '/images/landing/testimonials/rokita.svg',
    comment:
      "Avantis entered the market recently and quickly gained prominence on the Base network and in the DeFi world with its increasingly comprehensive perpetuals platform and its amazing rewards system. We're excited about the promising future of the platform and the entire Avantis family.",
  },
];
