'use client';

import React, { useState } from 'react';
import AnimatedCard from './AnimatedCard';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { Separator } from '@radix-ui/react-dropdown-menu';
import { AnimatedCounter } from '@/components/shared/AnimatedCounter';
const LiquidityProvider = () => {
  const [apr, setApr] = useState(15.32);
  return <AnimatedCard data-sentry-element="AnimatedCard" data-sentry-component="LiquidityProvider" data-sentry-source-file="LiquidityProvider.tsx">
      <motion.div className="relative h-full" onMouseEnter={() => setApr(32.97)} onMouseLeave={() => setApr(15.32)} data-sentry-element="unknown" data-sentry-source-file="LiquidityProvider.tsx" data-sentry-element="unknown">
        <div className="px-4 md:px-[60px] py-6 md:py-12 space-y-4">
          <p className="text-[#DDABF8] text-sm font-medium leading-none">LIQUIDITY PROVIDER</p>
          <p className="text-[32px] font-medium leading-none">Optimized for LPs</p>
          <p className="text-grey-100 leading-[24px]">
            Our dynamic risk-engine optimizes LP returns across all market conditions. Our LPs have
            already earned &gt;$1M in USDC fees.
          </p>
        </div>
        <div className="md:mx-[60px] mx-2 mb-12 h-[324px] relative flex items-center justify-center">
          <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full py-9 px-5 rounded-[20px] border opacity-50 bg-[linear-gradient(180deg,rgba(138,66,168,0.1)_0%,rgba(155,81,186,0.1)_100%)]">
            <div className="absolute top-6 bottom-6 left-4 right-4 rounded-[20px] border opacity-50 bg-[linear-gradient(180deg,rgba(138,66,168,0.1)_0%,rgba(155,81,186,0.1)_100%)]"></div>
          </div>
          <div className="h-[202px] w-[300px] md:w-[465px] p-6 bg-grey-800 rounded-[20px] border border-[#1F1F30] z-20 relative">
            <Image src="/images/landing/features/liquidity/chart.svg" alt="chart" width={465} height={202} data-sentry-element="Image" data-sentry-source-file="LiquidityProvider.tsx" data-sentry-element="Image" />
            <div className="bg-[#DDABF8] w-[3px] h-[60px] md:h-[80px] absolute top-8 left-24 md:left-32 z-[31] group-hover:translate-x-32 md:group-hover:translate-x-60 transition-all duration-300"></div>
            <Separator className="h-[1px] bg-[#222235] my-5" data-sentry-element="Separator" data-sentry-source-file="LiquidityProvider.tsx" data-sentry-element="Separator" />
            <div className="flex items-center justify-between text-2xl font-medium">
              <span>APR</span>

              <div className="text-primary-100 mt-1">
                <AnimatedCounter value={apr} fontSize="24px" letterSpacing={1} showAs="percent" data-sentry-element="AnimatedCounter" data-sentry-source-file="LiquidityProvider.tsx" data-sentry-element="AnimatedCounter" />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatedCard>;
};
export default LiquidityProvider;