import * as React from 'react';
import { cn } from '@/lib/utils';
import { cva, VariantProps } from 'class-variance-authority';
const inputVariants = cva('flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50', {
  variants: {
    variant: {
      default: 'border-0 bg-grey-600 ring-0 focus-visible:ring-0 focus-visible:ring-offset-0 h-6 p-0 text-white text-base'
    }
  },
  defaultVariants: {
    variant: 'default'
  }
});
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {}
const Input = React.forwardRef<HTMLInputElement, InputProps>(({
  variant = 'default',
  className,
  type,
  ...props
}, ref) => {
  return <input type={type} className={cn(inputVariants({
    variant,
    className
  }))} ref={ref} {...props} />;
});
Input.displayName = 'Input';
export { Input };