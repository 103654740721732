import { cn } from '@/lib/utils';
import React from 'react';
const AnimatedCard = ({
  className,
  children
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return <div className="group relative w-full min-h-[526px] md:min-h-[576px] border border-[#27232D] hover:border-transparent rounded-3xl bg-[#0E0E1380] hover:bg-transparent z-20" data-sentry-component="AnimatedCard" data-sentry-source-file="AnimatedCard.tsx">
      <div className="absolute hidden group-hover:block inset-0 rounded-3xl p-[1px] bg-gradient-to-b from-[#3B0E54CF] to-transparent">
        <div className="h-full w-full bg-[rgba(14,14,19,0.50)] rounded-[23px] group-hover:gradient-bg-from-b"></div>
      </div>
      <div className={cn('relative z-20 h-full', className)}>{children}</div>
    </div>;
};
export default AnimatedCard;