'use client';

import { Skeleton } from '@/components/ui/skeleton';
import useLeaderBoardMetrics from '@/hooks/leaderboard/useLeaderBoardMetrics';
import { numberWithCommas } from '@/lib/utils';
const Metrics = () => {
  const {
    totalTraders,
    totalPositionSize,
    uniqueTradesCount,
    isLoading
  } = useLeaderBoardMetrics();
  return <div className="flex flex-col md:grid md:grid-cols-[1.4fr_1fr_1fr] gap-4 md:min-w-[55%]" data-sentry-component="Metrics" data-sentry-source-file="Metrics.tsx">
      <div className="py-6 px-12 rounded-md bg-[#0E0E134D] text-center md:text-right w-full md:w-auto">
        {isLoading ? <Skeleton className="h-10 w-full" /> : <p className="text-[28px] font-fono">
            {totalPositionSize ? `$${numberWithCommas(Math.round(totalPositionSize))}` : 'N/A'}
          </p>}
        <p className="text-grey-200 font-medium md:text-right">Total Traded Volume</p>
      </div>
      <div className="py-6 px-12 rounded-md bg-[#0E0E134D] text-center md:text-right w-full md:w-auto">
        {isLoading ? <Skeleton className="h-10 w-full" /> : <p className="text-[28px] font-fono">
            {uniqueTradesCount ? numberWithCommas(uniqueTradesCount) : 'N/A'}
          </p>}
        <p className="text-grey-200 font-medium">Trades</p>
      </div>
      <div className="py-6 px-12 rounded-md bg-[#0E0E134D] text-center md:text-right w-full md:w-auto">
        {isLoading ? <Skeleton className="h-10 w-full" /> : <p className="text-[28px] font-fono">
            {totalTraders ? numberWithCommas(totalTraders) : 'N/A'}
          </p>}
        <p className="text-grey-200 font-medium">Traders</p>
      </div>
    </div>;
};
export default Metrics;