'use client';

import { Button } from '../ui/button';
import Link from 'next/link';
const XPAnnoucement = () => {
  return <div className="relative z-30 py-[72px] md:py-[128px] px-4 md:px-[60px] flex justify-center items-center" data-sentry-component="XPAnnoucement" data-sentry-source-file="XPAnnoucement.tsx">
      <div className="max-w-[833px] relative z-30 text-center p-4 py-[64px] md:p-16 bg-[rgba(14,14,19,0.90)] border border-[#CC81F6] rounded-3xl shadow-[1px_1px_30px_0px_#DDABF8] pulsate-shadow">
        <h2 className="text-[36px] md:text-[52px] font-medium text-center">
          Now distributing:{' '}
          <span className="bg-[linear-gradient(90deg,_#FFF_0%,_#96E21B_169.78%)] bg-clip-text text-transparent">
            Avantis XP
          </span>
        </h2>
        <p className="text-grey-200 mt-4">
          With over $1B in traded volume, $15M+ locked, and $10M+ daily trade volume, don&apos;t
          miss the opportunity to collect Avantis XP for incentives.
        </p>
        <div className="flex flex-col md:flex-row items-center justify-center gap-6 mt-10">
          <Link href="/trade" data-sentry-element="Link" data-sentry-source-file="XPAnnoucement.tsx" data-sentry-element="Link">
            <Button variant="long" className="h-[56px] w-[206px] text-lg" data-sentry-element="Button" data-sentry-source-file="XPAnnoucement.tsx" data-sentry-element="Button">
              Trade Now
            </Button>
          </Link>
          <Link href="/earn" data-sentry-element="Link" data-sentry-source-file="XPAnnoucement.tsx" data-sentry-element="Link">
            <Button variant="outline" className="text-white text-lg bg-grey-800 border-[rgba(255, 255, 255, 0.04)] h-[56px]  w-[206px]" data-sentry-element="Button" data-sentry-source-file="XPAnnoucement.tsx" data-sentry-element="Button">
              Provide Liquidity
            </Button>
          </Link>
        </div>
      </div>
    </div>;
};
export default XPAnnoucement;